import { rest } from 'msw';

import {
  balanceFixture,
  coolspotsFixture,
  currenciesFixture,
  totalEarningsFixture,
  walletIdFixture
} from '../fixture/wallet';

export const walletAddress = rest.get(
  '/wallet/api/v2/wallets/me/wallet',
  (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(walletIdFixture));
  }
);

export const currencies = rest.get(
  '/wallet/api/v2/currencies',
  (req, res, ctx) => {
    return res(ctx.delay(1000), ctx.status(200), ctx.json(currenciesFixture));
  }
);

export const total = rest.get(
  '/wallet/api/v2/earnings/me/total',
  (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json(totalEarningsFixture)
    );
  }
);

export const balance = rest.get(
  '/wallet/api/v2/earnings/me/balance',
  (req, res, ctx) => {
    return res(ctx.delay(1000), ctx.status(200), ctx.json(balanceFixture));
  }
);

export const coolspots = rest.get(
  '/wallet/api/v2/coolspots',
  (req, res, ctx) => {
    return res(ctx.delay(1000), ctx.status(200), ctx.json(coolspotsFixture));
  }
);

export const details = rest.get(
  '/wallet/api/v2/:id/detals',
  (req, res, ctx) => {
    return res(ctx.delay(1000), ctx.status(200), ctx.json(coolspotsFixture));
  }
);

export const walletBalance = rest.get(
  '/wallet/api/v2/wallets/me/balance',
  (req, res, ctx) => {
    return res(ctx.delay(1000), ctx.status(500));
  }
);

export const getTotalEarnings = rest.get(
  '/wallet/api/v2/earnings/me/total',
  (req, res, ctx) => {
    return res(ctx.delay(1000), ctx.status(500));
  }
);

export const editWallet = rest.patch(
  '/wallet/api/v2/wallets/me`',
  (req, res, ctx) => {
    return res(ctx.delay(1000), ctx.status(201));
  }
);
