import {
  ChangeEvent,
  FC,
  KeyboardEvent,
  useEffect,
  useRef,
  useState
} from 'react';

import { useApplyCouponCode } from '../../shared/api/Order/Order.queries';
import { Button } from '../Button';
import { BUTTON_SIZE } from '../Button/Button.types';
import { Input, InputProps } from '../Input';
import { CouponCodeInputContainer } from './CouponCodeInput.styles';
import { CouponCodeInputProps } from './CouponCodeInput.types';

export const CouponCodeInput: FC<CouponCodeInputProps> = ({
  onBlur,
  onChange,
  inputRef,
  name,
  label,
  placeholder,
  productVariantIds
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [isMissingProductVariantsError, setIsMissingProductVariantsError] =
    useState<boolean>(false);

  const previousProductVariantIdsRef = useRef<string>(
    productVariantIds?.join(',') || ''
  );

  const {
    mutate: applyCouponCode,
    data: applyCouponCodeData,
    isLoading: isApplyCouponCodeLoading,
    isError: isApplyCouponCodeError,
    error: applyCouponCodeError,
    reset: resetApplyCouponCode
  } = useApplyCouponCode({
    onSuccess(data) {
      onChange(data);
      onBlur();
    }
  });

  useEffect(() => {
    if (productVariantIds?.join(',') !== previousProductVariantIdsRef.current) {
      resetApplyCouponCode();
      onChange(null);
      setInputValue('');

      previousProductVariantIdsRef.current = productVariantIds?.join(',') || '';
    }
    // disabling exhaustive deps because of resetApplyCouponCode function
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productVariantIds]);

  useEffect(() => {
    if (productVariantIds?.length && isMissingProductVariantsError) {
      setIsMissingProductVariantsError(false);
    }
  }, [isMissingProductVariantsError, productVariantIds]);

  const handleApplyCouponCode = () => {
    resetApplyCouponCode();

    if (productVariantIds?.length) {
      applyCouponCode({
        couponCode: inputValue,
        productVariantIds
      });
    } else {
      setIsMissingProductVariantsError(true);
    }
  };

  const handleApplyClick = () => {
    handleApplyCouponCode();
  };

  const handleInputKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.currentTarget.blur();
      handleApplyCouponCode();
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    resetApplyCouponCode();
    onChange(null);
    setInputValue(e.currentTarget.value);
  };

  const captionAndState: Partial<InputProps> | null =
    (isApplyCouponCodeLoading && {}) ||
    (isApplyCouponCodeError && {
      error:
        applyCouponCodeError?.data?.message || 'Failed to apply coupon code'
    }) ||
    (isMissingProductVariantsError && {
      error: 'Please select earning plan first.'
    }) ||
    (applyCouponCodeData && {
      isValid: true,
      caption: 'Coupon code applied!'
    }) ||
    null;

  return (
    <CouponCodeInputContainer>
      <Input
        {...captionAndState}
        autoComplete="off"
        label={label}
        name={name}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        placeholder={placeholder}
        ref={inputRef}
        value={inputValue}
        renderButton={() => (
          <Button
            type="button"
            onClick={handleApplyClick}
            size={BUTTON_SIZE.SMALL}
            variant="white"
            iconRight="ArrowRight"
            isLoading={isApplyCouponCodeLoading}
            disabled={!!applyCouponCodeData}
            style={{ minWidth: 125 }}
          >
            Apply
          </Button>
        )}
      />
    </CouponCodeInputContainer>
  );
};
