import { useIntl } from 'react-intl';
import * as yup from 'yup';

export const useAntennaDbiValidation = () => {
  const { formatMessage } = useIntl();

  return yup.object().shape({
    /* eslint-disable newline-per-chained-call */
    value: yup
      .number()
      .typeError(
        formatMessage({
          defaultMessage: 'Please enter correct dBi value',
          id: 'assignAntenna.inputError'
        })
      )
      .min(0)
      .max(15.0)
    /* eslint-enable newline-per-chained-call */
  });
};
