import { FC, useEffect } from 'react';

import { FullPageLoader } from '../../components/FullPageLoader';
import { SignUpLayout } from '../../components/Layout';
import { Stepper } from '../../components/Stepper';
import { useGetCountries } from '../../shared/api/Order';
import {
  userTracking,
  UserTrackingEvent
} from '../../shared/utils/user-tracking';
import { ErrorPage } from '../ErrorPage';
import { ErrorPageType } from '../ErrorPage/ErrorPage.types';
import { ConfirmationScreen } from './ConfirmationScreen';
import { InstallationAddress } from './InstallationAddress';

export const ApplyForACoolspot: FC = () => {
  const countryQuery = useGetCountries();

  useEffect(() => {
    userTracking(UserTrackingEvent.RegisteredUser);
  }, []);

  if (countryQuery.isLoading) {
    return <FullPageLoader />;
  }

  if (countryQuery.isError) {
    return <ErrorPage type={ErrorPageType.LinkInvalid} />;
  }

  return (
    <Stepper
      steps={[
        <InstallationAddress countryData={countryQuery.data || []} />,
        <ConfirmationScreen />
      ]}
    >
      {(step) => <SignUpLayout>{step}</SignUpLayout>}
    </Stepper>
  );
};
