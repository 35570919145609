import { useIntl } from 'react-intl';
import * as yup from 'yup';

export const useTermsAndConditionsValidation = () => {
  const { formatMessage } = useIntl();

  return yup.object().shape({
    acceptTermsAndConditions: yup
      .bool()
      .required(
        formatMessage({
          id: 'termsAndConditionsValidation.agreeNewVersion',
          defaultMessage:
            'To continue please agree to the new version of Terms of Service using the checkbox above.'
        })
      )
      .oneOf(
        [true],
        formatMessage({
          id: 'termsAndConditionsValidation.agreeNewVersion',
          defaultMessage:
            'To continue please agree to the new version of Terms of Service using the checkbox above.'
        })
      )
  });
};
