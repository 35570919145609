import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { AlertVariant } from '../../../../components/Alert/Alert.types';
import { Box } from '../../../../components/Box';
import { Button } from '../../../../components/Button';
import { DashboardBoxCenterWrapper } from '../../../../components/DashboardBox/DashboardBox.styles';
import { Heading, Text } from '../../../../components/Typography';
import { VerificationInput } from '../../../../components/VerificationInput';
import { useSendOneTimeAuthCode } from '../../../../shared/api/Identity/Identity.queries';
import { useEditWallet } from '../../../../shared/api/Wallet';
import { useNotification } from '../../../../shared/utils/notifications/notification-context';
import { EditWalletEmailValidationProps } from './EditWalletEmailValidation.types';

export const EditWalletEmailValidation: FC<EditWalletEmailValidationProps> = ({
  email,
  handleNextStep,
  previousStepValues,
  handlePreviousStep
}) => {
  const { addNotification } = useNotification();

  const {
    formState: { errors },
    handleSubmit,
    setValue
  } = useForm<{ verificationCode: string }>({
    mode: 'onBlur'
  });
  const { mutate: editWallet, isLoading: isEditWalletLoading } = useEditWallet({
    onError: (error) => {
      addNotification({
        id: 'walletError',
        text: error.message,
        variant: AlertVariant.Danger
      });
    },
    onSuccess: () => {
      if (handleNextStep) {
        handleNextStep(previousStepValues);
      }
    }
  });

  const { mutate: sendOneTimeAuthCode } = useSendOneTimeAuthCode({
    onError: (error) => {
      const { response } = error;

      addNotification({
        id: 'oneTimeAuthCodeError',
        text: response?.message,
        variant: AlertVariant.Danger
      });
    }
  });

  useEffect(() => {
    if (email) {
      sendOneTimeAuthCode(email);
    }
  }, [email, sendOneTimeAuthCode]);

  const isLoading = isEditWalletLoading;

  const onSubmit = (values: { verificationCode: string }) => {
    if (values?.verificationCode) {
      editWallet({
        heliumWalletId: previousStepValues?.walletAddress!,
        code: values.verificationCode
      });
    }
  };

  return (
    <DashboardBoxCenterWrapper contentWidth={592}>
      <Box>
        <Heading size="h4" align="center" variant="grey">
          <FormattedMessage
            id="editWalletEmailValidation.title"
            defaultMessage="Validate your email"
          />
        </Heading>
      </Box>
      <Box mt="l" mb="m">
        <Text level="big">
          <FormattedMessage
            id="editWalletEmailValidation.subtitle"
            defaultMessage="Enter validation code we emailed you"
          />
        </Text>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <VerificationInput
            name="Verification Code"
            error={errors.verificationCode?.message}
            onChange={async (value) => {
              setValue('verificationCode', value);
            }}
          />
        </Box>
        <Box mt="l">
          <Button isPrimary fullWidth isLoading={isLoading} type="submit">
            <FormattedMessage
              id="resetPassword2.verify"
              defaultMessage="Verify"
            />
          </Button>
        </Box>
      </form>
      <Box mt="s">
        <Button
          isPlain
          fullWidth
          onClick={() => handlePreviousStep?.()}
          disabled={isLoading}
        >
          <FormattedMessage
            id="editWalletEmailValidation.cancel"
            defaultMessage="cancel"
          />
        </Button>
      </Box>
    </DashboardBoxCenterWrapper>
  );
};
