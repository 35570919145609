import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Box } from '../../../../../components/Box';
import { Input } from '../../../../../components/Input';
import { ContentWrapper } from '../../../../../components/Layout';
import { Heading, Text } from '../../../../../components/Typography';
import { StepProps } from '../../../../../components/Stepper';
import { Button } from '../../../../../components/Button';
import { useAddWaitList } from '../../../../../shared/api/Identity/Identity.queries';
import { useGetCountries } from '../../../../../shared/api/Order';
import { useQueryParams } from '../../../../../shared/utils/use-query-params';
import { FullPageLoader } from '../../../../../components/FullPageLoader';

const schema = yup.object().shape({
  /* eslint-disable newline-per-chained-call */
  email: yup
    .string()
    .email('Email address is not valid')
    .required('Email is a required field')
  /* eslint-enable newline-per-chained-call */
});

export const NewsletterRegistration: FC<StepProps> = ({ handleNextStep }) => {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const [isDeleteEmailLoading, setIsDeleteEmailLoading] =
    useState<boolean>(false);
  const [params] = useQueryParams({
    country: '',
    email: ''
  });

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    setError
  } = useForm({
    defaultValues: {
      country: params.country,
      email: params.email
    },
    mode: 'onBlur',
    resolver: yupResolver(schema)
  });

  const handleError = (error: Error) => {
    setError('email', {
      message: error.message,
      type: 'manual'
    });
  };

  const { mutate: addToWaitList, isLoading } = useAddWaitList({
    onError: (error) => {
      setIsDeleteEmailLoading(false);
      handleError(error);
    },
    onSuccess: (_, variables) => {
      if (!variables.email) {
        history.push('/login');
      }

      if (handleNextStep) {
        handleNextStep(variables);
      }
    }
  });

  const { data: countries, isLoading: isCountriesLoading } = useGetCountries();

  if (isCountriesLoading) {
    return <FullPageLoader />;
  }

  // eslint-disable-next-line no-console
  const onSubmit = (data: any) => {
    addToWaitList({
      country: params.country,
      email: data.email
    });
  };

  const handleDeleteEmail = () => {
    setIsDeleteEmailLoading(true);
    addToWaitList({
      country: params.country
    });
  };

  const isEmailValid = touchedFields?.email && !errors.email;

  return (
    <ContentWrapper>
      <Heading size="h4" element="h1" variant="grey">
        <FormattedMessage
          id="addToQueue.title"
          defaultMessage="We are not in your area yet!"
        />
        <Text type="default" level="big">
          <FormattedMessage
            id="addToQueue.subtitle"
            defaultMessage="Our community is growing quickly. Before you know it we will be nextdoor. Enter your email below and we will let you know when we are in {country}."
            values={{
              country:
                countries &&
                countries.find((country) => country.code === params.country)
                  ?.name
            }}
          />
        </Text>
      </Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mt="m">
          <Input
            {...register('email')}
            iconLeft="Mail"
            label={formatMessage({
              defaultMessage: 'Email address',
              id: 'addToQueue.emailAddress'
            })}
            placeholder="email@sample.com"
            error={errors.email?.message}
            isValid={isEmailValid}
            caption={
              isEmailValid
                ? formatMessage({
                    defaultMessage: 'Email address ok!',
                    id: 'signUp.emailAddressValid'
                  })
                : undefined
            }
            defaultValue={params.email}
          />
        </Box>
        <Box mt="l">
          <Button isPrimary fullWidth type="submit" isLoading={isLoading}>
            <FormattedMessage
              id="addToQueue.submitButton"
              defaultMessage="Keep me informed"
            />
          </Button>
        </Box>
      </form>
      <Box mt="m">
        <Button
          type="button"
          fullWidth
          isPlain
          onClick={handleDeleteEmail}
          isLoading={isDeleteEmailLoading}
        >
          <FormattedMessage
            id="addToQueue.cancelButton"
            defaultMessage="No thanks"
          />
        </Button>
      </Box>
    </ContentWrapper>
  );
};
