import styled, { css } from 'styled-components';

import { getTypography } from '../../../shared/utils/style-utils';

export const StyledExchangeValue = styled.p<{
  earningsDeltaSign: number;
}>`
  display: flex;
  align-items: center;
  gap: 6px;
  margin: 0;
  padding: 4px 12px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.lightGrey};

  ${({ earningsDeltaSign }) =>
    earningsDeltaSign > 0 &&
    css`
      border-color: ${({ theme }) => theme.color.green};
    `}
  ${({ earningsDeltaSign }) =>
    earningsDeltaSign < 0 &&
    css`
      border-color: ${({ theme }) => theme.color.secondaryRed};
    `}

  background: #f7f8fb;
  ${({ theme }) => getTypography(theme.typography.body.big)};
`;

export const TipWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
