import { Dashboard } from './features/Dashboard';
import { EditWallet } from './features/Dashboard/EditWallet';
import { UpgradeEarnings } from './features/Dashboard/UpgradeEarnings';
import { ApplyForACoolspot } from './features/ApplyForACoolspot';
import { CoolspotOrderConfirmation } from './features/CoolspotOrderConfirmation';
import { OrderACoolspot } from './features/OrderACoolspot';
import { RemoveFromWaitList } from './features/RemoveFromWaitList';
import { ReadyToShip } from './features/OrderACoolspot/ReadyToShip';
import { LetMeKnowNextTime } from './features/ApplyForACoolspot/LetMeKnowNextTime';
import { UpgradeEarningsSuccess } from './features/Dashboard/UpgradeEarnings/UpgradeEarningsSuccess/UpgradeEarningsSuccess';
import { PayoutHistory } from './features/Dashboard/PayoutHistory';
import { TermsAndConditions } from './features/TermsAndConditions';

export const authorizedRoutes = [
  { path: '/dashboard', component: Dashboard },
  { path: '/edit-wallet', component: EditWallet },
  { path: '/apply-for-a-coolspot', component: ApplyForACoolspot },
  { path: '/order-a-coolspot', component: OrderACoolspot },
  {
    path: '/coolspot-order-confirmation',
    component: CoolspotOrderConfirmation
  },
  { path: '/ready-to-ship', component: ReadyToShip },
  { path: '/let-me-know-later', component: LetMeKnowNextTime },
  { path: '/remove-from-wait-list', component: RemoveFromWaitList },
  { path: '/upgrade', component: UpgradeEarnings },
  { path: '/upgrade-confirmation', component: UpgradeEarningsSuccess },
  { path: '/payout-history', component: PayoutHistory },
  { path: '/terms-and-conditions', component: TermsAndConditions }
];
