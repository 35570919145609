import { FC } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { Box } from '../../../../components/Box';
import { Heading, Subtitle } from '../../../../components/Typography';
import { LoaderWrapper, StyledPrice, Wrapper } from './TotalRewards.styles';
import { formatHelium } from '../../../../shared/utils/format-helium';
import { Link } from '../../../../components/Link';
import { useGetTotalEarningsData } from '../../../../shared/api/Wallet/Wallet.queries';
import { Loader } from '../../../../components/Loader';

export const TotalRewards: FC = () => {
  const { data, isLoading, isError } = useGetTotalEarningsData({
    retry: false
  });

  if (isError) {
    return null;
  }

  if (isLoading) {
    return (
      <LoaderWrapper>
        <Loader variant="blue" />
      </LoaderWrapper>
    );
  }

  return (
    <Wrapper>
      <Subtitle size="big" variant="grey">
        <FormattedMessage
          id="dashboard.emritEarningsSubtitle2"
          defaultMessage="My total earnings history"
        />
      </Subtitle>
      <Box mt="s">
        <Heading size="h4" element="h2">
          {formatHelium(data?.totalEarnings?.hnt || 0)}
        </Heading>
      </Box>
      <Box mt="xs">
        <StyledPrice level="big">
          <FormattedNumber
            value={data?.totalEarnings?.usd!}
            style="currency"
            currency="USD"
          />
        </StyledPrice>
      </Box>
      <Box mt="m">
        <FormattedMessage
          id="dashboard.payoutHistoryLink"
          defaultMessage="See {payoutHistory}"
          values={{
            payoutHistory: (
              <Link to="/payout-history">
                <FormattedMessage
                  id="dashboard.payoutHistory"
                  defaultMessage="Payout history"
                />
              </Link>
            )
          }}
        />
      </Box>
    </Wrapper>
  );
};
