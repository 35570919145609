import styled from 'styled-components';

import { Tab, TabList } from '../../../../../components/Tabs';

export const StyledTabList = styled(TabList)`
  width: 100%;
  padding: 4px;
  border-radius: ${({ theme }) => theme.borderRadius};
  background: ${({ theme }) => theme.color.secondaryWhiteBlue};
`;

export const StyledTab = styled(Tab)<{ isSelected?: boolean }>`
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius};
  border: none;
`;

export const TabBox = styled.div`
  margin-top: 16px;
  min-height: 56px;
`;
