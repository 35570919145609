import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Alert } from '../../../components/Alert';
import { AlertVariant } from '../../../components/Alert/Alert.types';
import { Box } from '../../../components/Box';
import { BackButton, DashboardBox } from '../../../components/DashboardBox';
import { Icon } from '../../../components/Icon';
import { DashboardLayout } from '../../../components/Layout';
import { Loader } from '../../../components/Loader';
import { useGetWalletAddress } from '../../../shared/api/Wallet';
import { useGetEarnerPayments } from '../../../shared/api/Wallet/Wallet.queries';
import { theme } from '../../../themes/main';
import { DashboardContentWrapper } from '../Dashboard.styles';
import { AddWallet } from './AddWallet';
import { PayoutHistoryTable } from './PayoutHistoryTable';

export const PayoutHistory: FC = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { data: walletAddress, isLoading: isGetWalletAddressLoading } =
    useGetWalletAddress({ cacheTime: 0, retry: false });
  const { data: earnerPayments, isLoading: isGetEarnerPaymentsLoading } =
    useGetEarnerPayments();

  const isLoading = isGetWalletAddressLoading || isGetEarnerPaymentsLoading;

  if (isLoading) {
    return (
      <DashboardLayout>
        <DashboardContentWrapper>
          <DashboardBox
            isFullHeight
            isLoading={isLoading}
            showBackButton
            title={formatMessage({
              id: 'payoutHistory.title',
              defaultMessage: 'Payout History'
            })}
            renderBackButton={
              <BackButton onClick={() => history.push('/dashboard')}>
                <Icon icon="ArrowLeft" color={theme.color.blue} />
                <FormattedMessage
                  id="payoutHistory.backButton"
                  defaultMessage="Go back to dashboard"
                />
              </BackButton>
            }
          >
            <Loader />
          </DashboardBox>
        </DashboardContentWrapper>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardContentWrapper>
        <DashboardBox
          isFullHeight
          isLoading={isLoading}
          showBackButton
          title={formatMessage({
            id: 'payoutHistory.title',
            defaultMessage: 'Payout history'
          })}
          renderBackButton={
            <BackButton onClick={() => history.push('/dashboard')}>
              <Icon icon="ArrowLeft" color={theme.color.blue} />
              <FormattedMessage
                id="payoutHistory.backButton"
                defaultMessage="Go back to dashboard"
              />
            </BackButton>
          }
        >
          {walletAddress?.heliumWalletId ? (
            <>
              <Box mt="m">
                <Alert variant={AlertVariant.SecondaryWarning}>
                  <FormattedMessage
                    id="payoutHistory."
                    defaultMessage="Note the payout history below is only for the payouts to the <strong>current</strong> wallet on file. We’re working on showing payouts to historical wallets. Stay tuned!"
                    values={{
                      strong: (chunks: string) => <strong>{chunks}</strong>
                    }}
                  />
                </Alert>
              </Box>
              <PayoutHistoryTable payoutHistory={earnerPayments?.entities} />
            </>
          ) : (
            <AddWallet />
          )}
        </DashboardBox>
      </DashboardContentWrapper>
    </DashboardLayout>
  );
};
