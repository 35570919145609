import { UseMutationOptions, UseQueryOptions } from 'react-query';

import { useAuthMutation, useAuthQuery } from '../../auth/auth-helpers';
import { WALLET_SERVICE } from '../endpoints';
import { fetcher } from '../fetcher';
import { HTTPError } from '../fetcher.types';
import { WalletMutationKeys, WalletQueryKeys } from './Wallet.keys';
import {
  BalanceResponse,
  CoolspotEarningResponse,
  CoolspotFilters,
  CoolspotMetadata,
  CoolspotResponse,
  CoolspotsFilters,
  EarnerPaymentsResponse,
  EditWalletProps,
  HeliumPriceResponse,
  TotalEarningsResponse,
  WalletAddressResponse,
  WalletsHistoryResponse
} from './Wallet.types';

const editWallet = async (params: EditWalletProps) => {
  const { jwt, code, ...json } = params;

  const { data } = await fetcher(`${WALLET_SERVICE}/wallets/me`, {
    json,
    query: { code },
    jwt: jwt || undefined,
    method: 'PATCH'
  }).json();

  return data;
};

export const useEditWallet = (
  options?: UseMutationOptions<boolean, HTTPError, EditWalletProps>
) => {
  return useAuthMutation(WalletMutationKeys.EditWallet, editWallet, options);
};

const getHeliumPrice = async () => {
  const { data } = await fetcher<HeliumPriceResponse>(
    `${WALLET_SERVICE}/currencies`
  ).json();

  return data;
};

export const useGetHeliumPrice = (
  options?: UseQueryOptions<HeliumPriceResponse, HTTPError>
) => {
  return useAuthQuery<HeliumPriceResponse>(
    WalletQueryKeys.HeliumPrice,
    getHeliumPrice,
    options
  );
};

const getWalletAddress = async () => {
  const { data } = await fetcher<WalletAddressResponse>(
    `${WALLET_SERVICE}/wallets/me/wallet`
  ).json();

  if (!data?.heliumWalletId) {
    throw new Error('No wallet address');
  }

  return data;
};

export const useGetWalletAddress = (
  options?: UseQueryOptions<WalletAddressResponse, HTTPError>
) => {
  return useAuthQuery(WalletQueryKeys.WalletAddress, getWalletAddress, options);
};

const getTotalEarningsData = async () => {
  const { data } = await fetcher<TotalEarningsResponse>(
    `${WALLET_SERVICE}/earnings/me/total`
  ).json();

  return data;
};

export const useGetTotalEarningsData = (
  options?: UseQueryOptions<TotalEarningsResponse, HTTPError>
) => {
  return useAuthQuery(WalletQueryKeys.TotalEarningsData, getTotalEarningsData, {
    ...options
  });
};

const getBalanceData = async () => {
  const { data } = await fetcher<BalanceResponse>(
    `${WALLET_SERVICE}/wallets/me/balance`
  ).json();

  return data;
};

export const useGetBalanceData = (
  options?: UseQueryOptions<BalanceResponse, HTTPError>
) => {
  return useAuthQuery(WalletQueryKeys.BalanceData, getBalanceData, {
    ...options
  });
};

const getCoolspotsData = async (filters?: CoolspotFilters) => {
  const { data } = await fetcher<CoolspotResponse>(
    `${WALLET_SERVICE}/coolspots`,
    {
      query: filters
    }
  ).json();

  return {
    ...data,
    entities: data.entities.map((entity) => ({
      ...entity,
      status: 'loading'
    }))
  };
};

export const useGetCoolspotsData = (
  filters?: CoolspotsFilters,
  options?: UseQueryOptions<CoolspotResponse, HTTPError>
) => {
  return useAuthQuery(
    [WalletQueryKeys.CoolspotsData, filters],
    () => getCoolspotsData(filters),
    {
      ...options
    }
  );
};

const getCoolspotMetadataData = async (id: string) => {
  const { data } = await fetcher<CoolspotMetadata>(
    `${WALLET_SERVICE}/coolspots/${id}/metadata`
  ).json();

  return data;
};

export const useGetCoolspotMetadataData = (
  id: string,
  options?: UseQueryOptions<CoolspotMetadata, HTTPError>
) => {
  return useAuthQuery(
    [WalletQueryKeys.Coolspot, id],
    () => getCoolspotMetadataData(id),
    {
      ...options,
      retry: false
    }
  );
};

const getCoolspotEarningsData = async (id: string, period: string) => {
  const { data } = await fetcher<CoolspotEarningResponse>(
    `${WALLET_SERVICE}/earnings/me/coolspots/${id}?periods[]=${period}&periods[]=${period}`
  ).json();

  return data;
};

export const useGetCoolspotEarningsData = (
  id: string,
  period: string,
  options?: UseQueryOptions<CoolspotEarningResponse, HTTPError>
) => {
  return useAuthQuery(
    [WalletQueryKeys.Coolspot, id, period],
    () => getCoolspotEarningsData(id, period),
    {
      ...options,
      retry: false
    }
  );
};
const getEarnerPayments = async () => {
  const { data } = await fetcher<EarnerPaymentsResponse>(
    `${WALLET_SERVICE}/payments`
  ).json();

  return data;
};

export const useGetEarnerPayments = (
  options?: UseQueryOptions<EarnerPaymentsResponse, HTTPError>
) =>
  useAuthQuery(WalletQueryKeys.EarnerPayments, getEarnerPayments, {
    ...options,
    retry: false
  });

const getWalletsHistory = async () => {
  const { data } = await fetcher<WalletsHistoryResponse>(
    `${WALLET_SERVICE}/wallets/history`
  ).json();

  return data;
};

export const useGetWalletsHistory = (
  options?: UseQueryOptions<WalletsHistoryResponse, HTTPError>
) => {
  return useAuthQuery(
    WalletQueryKeys.WalletsHistory,
    getWalletsHistory,
    options
  );
};
