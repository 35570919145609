import { FC } from 'react';

import { StyledText } from './Text.styles';
import { TextProps } from './Text.types';

export const Text: FC<TextProps> = ({
  children,
  type = 'default',
  level = 'big',
  ...props
}) => {
  return (
    <StyledText level={level} type={type} {...props}>
      {children}
    </StyledText>
  );
};
