import { FC } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { theme } from '../../../../../themes/main';
import { Icon } from '../../../../../components/Icon';
import {
  Currency,
  EarningFactor,
  StyledCaption,
  StyledPrice,
  UpgradedLabel,
  ValueContainer,
  Wrapper
} from './Statistics.styles';
import { StatisticsProps } from './Statistics.types';
import { useGetHeliumPrice } from '../../../../../shared/api/Wallet/Wallet.queries';
import { Subtitle } from '../../../../../components/Typography';

export const Statistics: FC<StatisticsProps> = ({
  bigCurrency,
  earningFactor,
  isUpgraded,
  message,
  value
}) => {
  const { data: currencyData } = useGetHeliumPrice();

  return (
    <Wrapper>
      <StyledCaption>
        {isUpgraded && (
          <UpgradedLabel>
            <Icon color={theme.color.green} icon="ArrowUp" />
            <FormattedMessage
              id="dashboard.deviceUpgraded"
              defaultMessage="Upgraded"
            />
          </UpgradedLabel>
        )}
        {message}
        {earningFactor && (
          <EarningFactor isUpgraded={isUpgraded}>{earningFactor}</EarningFactor>
        )}
      </StyledCaption>
      <ValueContainer>
        <StyledPrice level="big">
          {value.toFixed(4)}&nbsp;
          {bigCurrency ? 'HNT' : <Currency>HNT</Currency>}
        </StyledPrice>
      </ValueContainer>
      {currencyData?.current && (
        <ValueContainer>
          <Subtitle size="big" variant="grey">
            <FormattedNumber
              style="currency"
              value={value * currencyData.current.exchange}
              currency={currencyData.current.currency}
            />
          </Subtitle>
        </ValueContainer>
      )}
    </Wrapper>
  );
};
