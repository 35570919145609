import styled, { css } from 'styled-components';

import { DesktopWrapperProps, WrapperProps } from './MobileCollapse.types';

const buttonContainerHeight = '69px';

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  transition: padding-top 0.5s ease-in-out;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    max-height: 0;

    ${({ isMapVisible }) =>
      isMapVisible &&
      css`
        padding-top: 200px;
      `}
    ${({ isExpanded }) =>
      isExpanded &&
      css`
        padding-top: calc(100% + ${buttonContainerHeight});
      `}
      ${({ isExpanded }) =>
      !isExpanded &&
      css`
        &:after {
          z-index: 2;
          content: '';
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          max-height: 100%;
          background: ${({ theme }) =>
            `linear-gradient(0deg, ${theme.color.secondaryWhiteBlue} 0, transparent 50%);`};
        }
      `};
  }
`;

export const DesktopWrapper = styled.div<DesktopWrapperProps>`
  display: flex;
  width: 100%;

  ${({ isMapVisible }) =>
    isMapVisible &&
    css`
      min-height: 640px;
    `}
`;

export const InnerWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  padding-bottom: ${buttonContainerHeight};
`;

export const ExpandButton = styled.button`
  z-index: 3;
  display: none;
  justify-content: center;
  align-items: center;
  gap: 17px;
  padding: ${({ theme }) => theme.padding.s};

  position: absolute;
  bottom: 0;

  color: ${({ theme }) => theme.color.darkBlue};
  background: none;
  border: 0;

  font-family: inherit;
  font-size: ${({ theme }) => theme.typography.body.small.fontSize};
  line-height: ${({ theme }) => theme.typography.body.small.lineHeight};
  font-weight: 700;
  text-transform: uppercase;
  outline: none;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    display: inline-flex;
  }
`;
