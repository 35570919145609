import { FC } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { StyledExchangeValue, TipWrapper } from './ExchangeValue.styles';
import { ExchangeValueProps } from './ExchangeValue.types';
import { Icon } from '../../../components/Icon';
import { Text } from '../../../components/Typography';
import { Tooltip } from '../../../components/Tooltip';

export const ExchangeValue: FC<ExchangeValueProps> = ({
  exchange,
  lastHourAverage
}) => {
  const value = exchange - lastHourAverage;

  return (
    <div>
      <Tooltip
        content={
          <TipWrapper>
            <Text level="big">
              <FormattedMessage
                defaultMessage="The value change vs last hour"
                id="dashboard.exchangeValueTooltip"
              />
            </Text>
          </TipWrapper>
        }
      >
        <StyledExchangeValue earningsDeltaSign={value}>
          {value < 0 && <Icon icon="CaretDown" />}
          {value === 0 && <Icon icon="Circle" />}
          {value > 0 && <Icon icon="CaretUp" />}
          <span>
            HNT{' '}
            <FormattedNumber value={exchange} style="currency" currency="USD" />
          </span>
        </StyledExchangeValue>
      </Tooltip>
    </div>
  );
};
