import * as yup from 'yup';
import * as heliumCrypto from '@helium/crypto';

export const addWalletSchema = yup.object().shape({
  walletAddress: yup
    .string()
    .required('Wallet address is a required field')
    .test('id-correct-helium', 'Helium address incorrect', (value) => {
      if (!value) {
        return false;
      }

      return heliumCrypto.Address.isValid(value);
    })
});
