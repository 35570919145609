import { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { theme } from '../../themes/main';
import { Box } from '../Box';
import { Icon } from '../Icon';
import { Loader } from '../Loader';
import { Heading } from '../Typography';
import {
  BackButton,
  DashboardBoxWrapper,
  LoaderWrapper
} from './DashboardBox.styles';
import { DashboardBoxProps } from './DashboardBox.types';

export const DashboardBox = forwardRef<HTMLElement, DashboardBoxProps>(
  (
    {
      children,
      isFullHeight = false,
      isLoading,
      variant = 'white',
      showBackButton,
      title,
      renderBackButton,
      ...props
    },
    ref
  ) => {
    const loaderVariant = variant === 'blue' ? 'white' : 'blue';

    const history = useHistory();

    return (
      <DashboardBoxWrapper
        isFullHeight={isFullHeight}
        variant={variant}
        ref={ref}
        {...props}
      >
        {isLoading ? (
          <LoaderWrapper>
            <Loader variant={loaderVariant} size="big" />
          </LoaderWrapper>
        ) : (
          <>
            {showBackButton && (
              <Box>
                {renderBackButton || (
                  <BackButton onClick={() => history.goBack()}>
                    <Icon icon="ArrowLeft" color={theme.color.blue} />
                    <FormattedMessage
                      id="dashboardBox.backButton"
                      defaultMessage="Go back"
                    />
                  </BackButton>
                )}
              </Box>
            )}
            {title && (
              <Box mt="m">
                <Heading size="h5" element="h1" variant="black">
                  {title}
                </Heading>
              </Box>
            )}
            {children}
          </>
        )}
      </DashboardBoxWrapper>
    );
  }
);
