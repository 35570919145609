import styled, { css } from 'styled-components';

import { FieldVariant } from '../../FieldCaption';
import { BasicInput } from '../BasicInput/BasicInput';
import { BasicInputProps } from '../Input.types';

export const IconInputWrapper = styled.div<{ variant: FieldVariant }>`
  position: relative;

  svg {
    stroke: ${({ theme }) => theme.color.dimmed};
  }

  ${({ variant, theme }) =>
    variant === FieldVariant.PREFILLED &&
    css`
      svg {
        stroke: ${theme.color.blue};
      }
    `}
  ${({ variant, theme }) =>
    variant === FieldVariant.DISABLED &&
    css`
      svg {
        stroke: ${theme.color.lightGrey};
      }
    `}
`;

export const StyledIconInput = styled(BasicInput)<{
  variant: FieldVariant;
  iconLeft?: boolean;
  iconRight?: boolean;
}>`
  ${({ iconLeft, theme }) =>
    iconLeft &&
    css`
      padding-left: calc(${theme.padding.s} + 20px + ${theme.padding.xs});
    `}

  ${({ iconRight, theme }) =>
    iconRight &&
    css`
      padding-right: calc(${theme.padding.s} + 20px + ${theme.padding.xs});
    `}

  &[readonly]::selection {
    background: transparent;
  }
`;

const IconWrapper = styled.span<Pick<BasicInputProps, 'onClick'>>`
  height: 20px;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
`;

export const IconRightWrapper = styled(IconWrapper)<
  Pick<BasicInputProps, 'onClick'>
>`
  right: 0;

  padding-right: ${({ theme }) => theme.padding.s};

  ${({ onClick }) =>
    onClick &&
    css`
      &,
      & > * {
        cursor: pointer;
      }
    `}
`;

export const IconLeftWrapper = styled(IconWrapper)<
  Pick<BasicInputProps, 'onClick'>
>`
  left: 0;

  padding-left: ${({ theme }) => theme.padding.s};

  ${({ onClick }) =>
    onClick &&
    css`
      &,
      & > * {
        cursor: pointer;
      }
    `}
`;
