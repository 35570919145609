import styled, { css } from 'styled-components';

import { Heading } from '../Typography';
import { ModalHeaderProps, ModalStyleProps, OverlayStyle } from './Modal.types';

export const StyledOverlay = styled.div<{ overlayStyle?: OverlayStyle }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(20, 73, 209, 0.2);
  transition: opacity 0.33s ease;
  opacity: 0;

  ${({ overlayStyle }) =>
    overlayStyle === OverlayStyle.Blur &&
    css`
      backdrop-filter: blur(6px);
    `}

  &.ReactModal__Overlay--after-open {
    opacity: 1;
  }

  &.ReactModal__Overlay--before-close {
    opacity: 0;
  }
`;

export const ModalStyle = styled.div<ModalStyleProps>`
  display: flex;
  position: relative;
  width: 100%;
  max-width: ${({ maxWidth = 652 }) => `${maxWidth}px`};
  padding: 84px 102px;
  max-height: 75vh;
  margin ${({ theme }) => theme.margin.xl};
  box-shadow: ${({ theme }) => theme.shadow.blue.xl};
  border-radius: ${({ theme }) => theme.borderRadius};
  background: ${({ theme }) => theme.color.white};
  outline: none;

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
      padding: ${({ theme }) => theme.padding.xxl};
    `}


  @media (${({ theme }) => theme.breakpoints.tablet}) {
    margin ${({ theme }) => theme.margin.m};
    padding: ${({ theme }) => theme.padding.xl};
  }

  @media (${({ theme }) => theme.breakpoints.phone}) {
    max-height: 85vh;
    padding: ${({ theme }) => theme.padding.l};
  }
`;

export const ModalHeader = styled(Heading).attrs({
  element: 'h2',
  size: 'h4'
})<ModalHeaderProps>`
  ${({ hasCloseButton }) =>
    hasCloseButton &&
    css`
      margin-right: ${({ theme }) => theme.margin.l};
    `}
  margin-bottom: ${({ theme }) => theme.margin.m};
  color: ${({ theme }) => theme.color.grey};
  text-align: ${({ center }) => (center ? 'center' : 'left')};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 24px;
  }
`;

export const CloseButton = styled.button.attrs({ type: 'button' })`
  position: absolute;
  top: 29px;
  right: 29px;
  border: none;
  background: none;
  cursor: pointer;

  svg {
    stroke: ${({ theme }) => theme.color.dimmed};
  }
`;
