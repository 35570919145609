import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Box } from '../../../components/Box';
import { ContentWrapper, LoginLayout } from '../../../components/Layout';
import { Heading, Text } from '../../../components/Typography';
import { Link } from '../../../components/Link';
import { StyledBottomSection } from './Login.styles';
import { LoginForm } from '../../../components/LoginForm';

export const Login: FC = () => {
  return (
    <LoginLayout>
      <ContentWrapper hideLogo showHero>
        <Heading size="h4" element="h1" variant="grey">
          <FormattedMessage id="login.title" defaultMessage="Login to Emrit" />
        </Heading>
        <LoginForm />
        <Box mt="l">
          <Link to="/forgot-password">
            <FormattedMessage
              id="login.forgotPassword"
              defaultMessage="Forgot password?"
            />
          </Link>
        </Box>
        <Box mt="m">
          <Text type="default" level="big">
            <FormattedMessage
              id="login.dontHaveAccount"
              defaultMessage="Don't have a CoolSpot™?"
            />{' '}
            <Link to="/sign-up">
              <FormattedMessage
                id="login.signup"
                defaultMessage="Become a host"
              />
            </Link>
          </Text>
        </Box>
        <StyledBottomSection mt="xl" pt="xl">
          <Text type="default" level="big">
            <FormattedMessage
              id="login.bodyText"
              defaultMessage="Did you order or receive an Emrit CoolSpot™ from our earlier
            application or directly from Shopify?"
            />{' '}
            <Link to="/forgot-password">
              <FormattedMessage
                id="login.shopifyLink"
                defaultMessage="Click here"
              />
            </Link>
          </Text>
        </StyledBottomSection>
      </ContentWrapper>
    </LoginLayout>
  );
};
