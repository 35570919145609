import debounce from 'lodash.debounce';
import { useEffect, useState } from 'react';

import { Icon } from '../Icon';
import { StyledButton } from './ScrollTopButton.styles';

export const ScrollTopButton = () => {
  const [showButton, setShowButton] = useState<boolean>(false);

  const handleOnClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const onScroll = debounce(() => {
    setShowButton(window.pageYOffset > window.innerHeight);
  }, 25);

  useEffect(() => {
    window.addEventListener('scroll', onScroll, { passive: true });

    return () => window.removeEventListener('scroll', onScroll);
  }, [onScroll]);

  return (
    <StyledButton onClick={handleOnClick} isVisible={showButton}>
      <Icon icon="ArrowUp" />
    </StyledButton>
  );
};
