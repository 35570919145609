import { useIntl } from 'react-intl';
import * as yup from 'yup';

import { ElevationUnit } from './ElevationForm.types';

export const useElevationFormValidation = () => {
  const { formatMessage } = useIntl();

  return yup.object().shape({
    /* eslint-disable newline-per-chained-call */
    value: yup
      .number()
      .when('unit', {
        is: ElevationUnit.Metric,
        then: yup
          .number()
          .typeError(
            formatMessage({
              defaultMessage: 'Please enter correct number value',
              id: 'changeElevation.inputError'
            })
          )
          .min(-499)
          .max(9999)
      })
      .when('unit', {
        is: ElevationUnit.Imperial,
        then: yup
          .number()
          .typeError(
            formatMessage({
              defaultMessage: 'Please enter correct number value',
              id: 'changeElevation.inputError'
            })
          )
          .min(-1638)
          .max(32805)
      })
    /* eslint-enable newline-per-chained-call */
  });
};
