export enum ErrorCodes {
  INVALID_TOKEN = 3,
  VALIDATION_FAILED = 7,
  MISSING_TOKEN = 8,
  EXPIRED_TOKEN = 9,
  SESSION_EXPIRED = 10,
  MISSING_SESSION_TOKEN = 11,
  TERMS_AND_CONDITIONS_NOT_ACCEPTED = 12,
  INVALID_VERIFICATION_CODE = 106,
  MAX_RETRIES_EXCEEDED = 109,
  LEGACY_USER_LOGGED_IN = 114,
  EMAIL_NOT_VERIFIED = 117,
  WAIT_BEFORE_NEXT_CODE_RESEND = 118,
  NO_CONTENT = 204,
  LEAD_ORDER_DOES_NOT_EXIST = 300,
  EMAIL_IS_ALREADY_IN_USE = 400,
  WALLET_DOES_NOT_EXIST = 401,
  NOT_EXIST = 404
}
