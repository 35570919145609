const baseUrl = `https://${process.env.REACT_APP_ENV_DOMAIN}`;

const getUrl = (url: string) =>
  process.env.NODE_ENV === 'development' ? url : baseUrl + url;

export const IDENTITY_SERVICE = getUrl(`/identity/api/v2`);

export const WALLET_SERVICE = getUrl('/wallet/api/v2');

export const ORDER_SERVICE = getUrl('/order/api/v2');
