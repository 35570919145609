import { FC } from 'react';

import {
  StyledTooltipMenuItem,
  TextWrapper,
  TooltipMenuListItem
} from './TooltipMenuItem.styles';
import { TooltipMenuItemProps } from './TooltipMenuItem.types';

export const TooltipMenuItem: FC<TooltipMenuItemProps> = ({
  onClick,
  children
}) => {
  return (
    <TooltipMenuListItem>
      <StyledTooltipMenuItem type="button" onClick={onClick}>
        <TextWrapper>{children}</TextWrapper>
      </StyledTooltipMenuItem>
    </TooltipMenuListItem>
  );
};
