import { useId } from '@reach/auto-id';
import { forwardRef } from 'react';

import {
  ButtonContainer,
  InputContainer,
  InputWrapper,
  ProgressBarWrapper,
  StyledLabel
} from './Input.styles';
import { InputProps } from './Input.types';
import { getVariant } from '../../shared/utils/style-utils';
import { IconInput } from './IconInput/IconInput';
import { FieldCaption, FieldVariant } from '../FieldCaption';
import { ProgressBar } from '../ProgressBar';

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      error,
      label,
      placeholder,
      isValid = false,
      caption,
      disabled = false,
      prefilled = false,
      progress,
      onChange,
      onIconClick,
      onKeyDown,
      selectable = true,
      renderButton,
      ...props
    },
    ref
  ) => {
    const id = useId(props.id);
    const variant = getVariant<FieldVariant>({
      [FieldVariant.PREFILLED]: prefilled,
      [FieldVariant.DISABLED]: disabled,
      [FieldVariant.ERROR]: !!error,
      [FieldVariant.VALID]: isValid
    });

    return (
      <InputWrapper {...props}>
        {label && (
          <StyledLabel variant={variant} htmlFor={id}>
            {label}
          </StyledLabel>
        )}
        <InputContainer>
          <IconInput
            id={id}
            variant={variant}
            disabled={disabled}
            aria-invalid={!!error || undefined}
            ref={ref}
            onChange={onChange}
            onIconClick={onIconClick}
            onKeyDown={onKeyDown}
            selectable={selectable}
            placeholder={placeholder}
            {...props}
          />
          {renderButton && <ButtonContainer>{renderButton()}</ButtonContainer>}
        </InputContainer>
        {(caption || error) && (
          <>
            {!!progress && (
              <ProgressBarWrapper>
                <ProgressBar progress={progress} />
              </ProgressBarWrapper>
            )}
            <FieldCaption variant={variant}>{error || caption}</FieldCaption>
          </>
        )}
      </InputWrapper>
    );
  }
);
