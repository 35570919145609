import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { AlertVariant } from '../../../../components/Alert/Alert.types';
import { StepProps } from '../../../../components/Stepper';
import { ValidateLayout } from '../../../../components/Layout/ValidateLayout';
import { VerificationInput } from '../../../../components/VerificationInput';
import { Button } from '../../../../components/Button';
import { Box } from '../../../../components/Box';
import { InputWrapper } from '../../../../components/Layout/ValidateLayout/ValidateLayout.styles';
import { ErrorCodes } from '../../../../shared/api/error-codes';
import {
  useResetPassword,
  useVerifyForgotPasswordCode
} from '../../../../shared/api/Identity/Identity.queries';
import { useNotification } from '../../../../shared/utils/notifications/notification-context';
import { LegacySignupData } from '../../LegacySignUp/LegacySignup.types';

export const ResetPasswordValidation: FC<StepProps> = ({
  handleNextStep,
  handlePreviousStep,
  previousStepValues
}) => {
  const { formatMessage } = useIntl();
  const { addNotification } = useNotification();
  const history = useHistory();

  const {
    formState: { errors },
    register,
    handleSubmit: onFormSubmit,
    setValue,
    setError
  } = useForm<{ verificationCode: string }>({
    mode: 'onBlur'
  });

  useEffect(() => {
    register('verificationCode', { required: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { mutate, isLoading } = useVerifyForgotPasswordCode({
    onError: (apiError, { code }) => {
      if (apiError?.data?.errorCode === ErrorCodes.LEGACY_USER_LOGGED_IN) {
        const legacySignupData: LegacySignupData = {
          email: previousStepValues?.email!,
          verificationCode: code
        };

        history.push({
          pathname: '/legacy-sign-up',
          state: { legacySignupData }
        });

        return;
      }

      setError('verificationCode', {
        message: apiError?.data?.message,
        type: 'manual'
      });

      if (
        apiError?.data?.statusCode >= 400 &&
        apiError?.data?.errorCode !== ErrorCodes.INVALID_VERIFICATION_CODE
      ) {
        addNotification({
          id: 'verifyCodeError',
          text: apiError.data.message,
          variant: AlertVariant.Danger
        });
      }
    },
    onSuccess: (_, variables) => {
      if (handleNextStep) {
        handleNextStep({ verificationCode: variables.code });
      }
    }
  });

  const { mutate: resendCode } = useResetPassword({
    onError: (error) => {
      const { message, statusCode } = error.response?.data || {};

      if (statusCode >= 400) {
        addNotification({
          id: 'resendCodeError',
          text: message,
          variant: AlertVariant.Danger
        });
      }
    },
    onSuccess: () => {
      addNotification({
        id: 'legacyUserEmailVerifySuccess',
        text: formatMessage({
          id: 'legacyUserEmailVerifySuccess',
          defaultMessage:
            'Please check your email inbox for a verification code.'
        }),
        variant: AlertVariant.Success
      });
    }
  });

  return (
    <ValidateLayout
      enteredEmail={previousStepValues?.email}
      onResendCode={() => resendCode(previousStepValues?.email)}
    >
      <form
        onSubmit={onFormSubmit((values) =>
          mutate({
            code: values.verificationCode,
            email: previousStepValues?.email
          })
        )}
      >
        <InputWrapper>
          <VerificationInput
            name="Verification Code"
            error={errors.verificationCode?.message}
            onChange={async (value) => {
              setValue('verificationCode', value);
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <Button isPrimary fullWidth isLoading={isLoading} type="submit">
            <FormattedMessage
              id="resetPassword2.verify"
              defaultMessage="Verify"
            />
          </Button>
        </InputWrapper>
      </form>
      <Box mt="s">
        <Button
          fullWidth
          disabled={isLoading}
          onClick={() => handlePreviousStep?.()}
        >
          <FormattedMessage id="resetPassword2.back" defaultMessage="Back" />
        </Button>
      </Box>
    </ValidateLayout>
  );
};
