import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { FormattedMessage, useIntl } from 'react-intl';
import Skeleton from 'react-loading-skeleton';

import {
  CenterRow,
  CoolspotLabel,
  DataPane,
  DetailsWrapper,
  HeaderPane,
  IconCircle,
  IconPane,
  MultiplierCaption,
  PricesRow,
  SettingsMenuPane,
  StyledAddress,
  StyledAddressPane,
  StyledDashboardBox,
  StyledSubtitle
} from './DeviceCard.styles';
import { Label } from './Label/Label';
import { Status } from './Status/Status';
import { RewardScale } from './RewardScale/RewardScale';
import { SettingsMenu } from './SettingsMenu/SettingsMenu';
import { CoolspotResponse } from '../../../../shared/api/Wallet/Wallet.types';
import { Icon } from '../../../../components/Icon';
import { useGetCoolspotMetadataData } from '../../../../shared/api/Wallet/Wallet.queries';
import { EarningsTabs } from './EarningsTabs/EarningsTabs';
import { AntennaValue } from './AntennaValue/AntennaValue';
// import { UpgradeEarningsButton } from './UpgradeEarningsButton/UpgradeEarningsButton';
import { formatCoolspotName } from '../../../../shared/utils/format-coolspot-name';
import { DeviceCardProps, DeviceCardRef } from './DeviceCard.types';
import { WalletQueryKeys } from '../../../../shared/api/Wallet';

export const DeviceCard = forwardRef<DeviceCardRef, DeviceCardProps>(
  ({ data }, ref) => {
    const { formatMessage } = useIntl();
    const queryClient = useQueryClient();
    const deviceCardRef = useRef<HTMLElement>(null);
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const {
      data: coolspotDetailsData,
      isLoading: isCoolspotsDataLoading,
      error: coolspotsError
    } = useGetCoolspotMetadataData(data.coolspotId, {
      onSuccess: (successData) => {
        queryClient.setQueryData(
          [
            WalletQueryKeys.CoolspotsData,
            {
              withReferrals: true
            }
          ],
          (previous: CoolspotResponse | undefined): CoolspotResponse => {
            const index = previous!.entities.findIndex(
              (item) => item.coolspotId === successData.coolspotId
            );

            if (index > -1) {
              previous!.entities[index] = {
                ...previous!.entities[index],
                ...successData
              };
            }

            return previous!;
          }
        );
      }
    });

    const isLoading =
      coolspotsError || isCoolspotsDataLoading || !coolspotDetailsData;

    const focus = () => {
      setIsFocused(true);
    };

    const unfocus = () => {
      setIsFocused(false);
    };

    useImperativeHandle(ref, () => ({
      deviceCardRef,
      focus,
      unfocus
    }));

    return (
      <StyledDashboardBox
        ref={deviceCardRef}
        status={coolspotDetailsData?.status}
        isFocused={isFocused}
      >
        <DataPane>
          <HeaderPane>
            <IconPane>
              <IconCircle>
                <Icon icon="Device" />
              </IconCircle>
            </IconPane>
            <DetailsWrapper>
              <CoolspotLabel>CoolSpot™</CoolspotLabel>
              <StyledSubtitle size="big" isPlaceholder={!data.name}>
                {data.name
                  ? formatCoolspotName(data.name)
                  : formatMessage({
                      defaultMessage: 'Unnamed',
                      id: 'dashboard.deviceUnnamed'
                    })}
              </StyledSubtitle>
              <StyledAddressPane>
                <StyledAddress>{data.installationAddress}</StyledAddress>
              </StyledAddressPane>
            </DetailsWrapper>
            {!data.isReferral && (
              <SettingsMenuPane>
                <SettingsMenu deviceId={data.coolspotId} />
              </SettingsMenuPane>
            )}
          </HeaderPane>
          <CenterRow>
            {!isLoading && (
              <>
                <Status status={coolspotDetailsData!.status} />
                <Label>
                  <MultiplierCaption>
                    <FormattedMessage
                      id="dashboard.deviceMultiplier"
                      defaultMessage="Multiplier"
                    />
                  </MultiplierCaption>
                  <RewardScale rewardScale={coolspotDetailsData!.rewardScale} />
                </Label>
                <AntennaValue dbi={coolspotDetailsData!.gain} />
              </>
            )}
            {isLoading && (
              <>
                <Skeleton width={92} height={32} />
                <Skeleton width={149} height={32} />
                <Skeleton width={73} height={30} />
              </>
            )}
          </CenterRow>
          <PricesRow>
            <EarningsTabs
              deviceId={data.coolspotId}
              isUpgraded={data.isUpgraded && !data.isReferral}
            />
          </PricesRow>
        </DataPane>
        {/* {data.isUpgradeable && !data.isReferral && (
          <UpgradeEarningsButton deviceId={data.coolspotId} />
        )} */}
      </StyledDashboardBox>
    );
  }
);
