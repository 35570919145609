import { GeocodeResult } from 'use-places-autocomplete';

import { Nullable } from '../../../types/common';

export type Order = {
  variantId: string;
  shippingAddress: {
    googleMapsObject: GeocodeResult;
    additionalAddress: string;
    phone: string;
  };
  installationAddress: {
    googleMapsObject: GeocodeResult;
  };
};

export type OrderResponse = {
  order: {
    id: number;
    orderStatusUrl: string;
  };
};

export type RecalculateLeadOrderProxScoreProps = {
  leadOrderId: string;
  email?: string;
};

export type RecalculateLeadOrderProxScoreResponse = FetchedLeadOrder;

export type ValidateInstallationAddressResponse =
  | {
      placeId: string;
    }
  | number;

export type LeadOrder = {
  installationAddress: {
    googleMapsObject: GeocodeResult;
  };
};

export type LeadOrderResponse = {
  leadOrderId: string;
};

export type LeadOrderCheckout = {
  leadOrderId: string;
  productVariantId: string;
  couponCode?: string;
};

export type LeadOrderCheckoutResponse = {
  checkoutUrl?: string;
};

export enum ShopifyOrderStatus {
  Created = 'CREATED',
  ShopifyOrderCreated = 'SHOPIFY_ORDER_CREATED',
  ShopifyOrderCancelled = 'SHOPIFY_ORDER_CANCELLED',
  ShopifyOrderUpdated = 'SHOPIFY_ORDER_UPDATED',
  CoolspotCreated = 'COOLSPOT_CREATED',
  Ordered = 'ORDERED'
}

export enum ShopifyPaymentStatus {
  Authorized = 'authorized',
  Paid = 'paid',
  PartiallyPaid = 'partially_paid',
  PartiallyRefunded = 'partially_refunded',
  Pending = 'pending',
  Refunded = 'refunded',
  Voided = 'voided'
}

export enum ShopifyFulfillmentStatus {
  Fulfilled = 'fulfilled',
  Partial = 'partial',
  Restocked = 'restocked'
}

export interface ShopifyTransaction {
  id: string;
  currency: string;
  amountPaid: number;
  gateway: string;
}

export interface ShopifyOrder {
  id: string;
  shopifyOrderId: number | null;
  shopifyVariantId: number | null;
  status: ShopifyOrderStatus;
  paymentStatus: ShopifyPaymentStatus;
  transaction: ShopifyTransaction;
}

export enum LeadOrderStatus {
  Created = 'CREATED',
  CheckoutCreated = 'CHECKOUT_CREATED',
  Transferred = 'TRANSFERRED',
  NotResponded = 'NOT_RESPONDED',
  WaitingForResponse = 'WAITING_FOR_RESPONSE',
  Postponed = 'POSTPONED'
}

export interface FetchedLeadOrder {
  checkoutUrl: string | null;
  id: string;
  installationAddress: string;
  orderNumber: string | null;
  shippingAddress: string | null;
  status: LeadOrderStatus;
  daysLeft?: number;
  country: string;
  order: ShopifyOrder | null;
}

export type GetLeadOrderResponse = FetchedLeadOrder;

export type GetLeadOrdersResponse = Nullable<Array<FetchedLeadOrder>>;

export type PostponeLeadOrder = {
  leadOrderId: string;
  leadOrderEmail: string;
};

export type Country = {
  code: string;
  name: string;
  isSupported: boolean;
  productVariantId: string;
};

export type OrderCardData = {
  orderId: string;
  shopifyOrderNumber: number;
  installationAddress: string;
  createdAt: string;
  status: LeadOrderStatus;
};

export type UpgradeDeviceRequest = {
  couponCode?: string;
  devices: {
    productVariantId: string;
    coolspotId: string;
  }[];
};

export type UpgradeDeviceResponse = {
  checkoutUrl?: string;
};

export type ApplyCouponCodeRequest = {
  couponCode: string;
  productVariantIds: string[];
};

export type ApplyCouponCodeResponse = {
  couponCode: string;
  productVariantIds: string[];
};

export enum UpgradePlanStatus {
  Created = 'CREATED',
  CheckoutCreated = 'CHECKOUT_CREATED',
  Failed = 'FAILED',
  Upgraded = 'UPGRADED',
  Cancelled = 'CANCELLED',
  Transferred = 'TRANSFERRED',
  ShopifyDetailsUpdated = 'SHOPIFY_DETAILS_UPDATED',
  Fulfilled = 'FULFILLED'
}

export type GetUpgradedPlanDetailsResponse = {
  id: string;
  status: UpgradePlanStatus;
  checkoutUrl: string;
  shopifyOrderUrl: string;
  orderNumber: string;
  createdAt: string;
};

export type ProductType = 'COOLSPOT' | 'UPGRADE';

export type GetProductVariantsRequest = {
  type: 'COOLSPOT' | 'UPGRADE';
  countryCode?: string;
};

export type GetProductVariantsResponse = {
  entities: ProductVariant[];
  count: number;
};

export type ProductVariant = {
  id: string;
  name: string;
  description?: string;
  variantId: string;
  earningsFactor: number;
  price: number;
  productType: ProductType;
};

export type ValidateLeadOrderInstallationAddressProps = {
  email?: string;
  installationAddress: any;
};

export type ValidateLeadOrderInstallationAddressResponse = {
  existingLeadOrderId: string | null;
};
