import { useIntl } from 'react-intl';
import * as yup from 'yup';

import { phoneNumberValidator } from '../../shared/utils/phone-validator';

const nameValidator = (value?: string) => {
  if (!value) {
    return false;
  }

  if (value.match(/^\s+.*$|^.*\s+$/) || value.match(/[<>"'&/]/)) {
    return false;
  }

  return true;
};

export const useCreateLeadUserValidation = () => {
  const { formatMessage } = useIntl();

  return yup.object().shape({
    /* eslint-disable newline-per-chained-call */
    age: yup
      .bool()
      .required(
        formatMessage({
          defaultMessage: 'Please confirm your age',
          id: 'createAccount.ageRequired'
        })
      )
      .oneOf(
        [true],
        formatMessage({
          defaultMessage: 'Please confirm your age',
          id: 'createAccount.ageRequired'
        })
      ),
    agreePolicies: yup
      .bool()
      .required(
        formatMessage({
          defaultMessage:
            'You have to agree the Terms of Service and Privacy Policy',
          id: 'createAccount.termsRequired'
        })
      )
      .oneOf(
        [true],
        formatMessage({
          defaultMessage:
            'You have to agree the Terms of Service and Privacy Policy',
          id: 'createAccount.termsRequired'
        })
      ),
    email: yup
      .string()
      .trim()
      .email(
        formatMessage({
          defaultMessage: 'Email address is not valid',
          id: 'createAccount.emailNotValid'
        })
      )
      .required(
        formatMessage({
          defaultMessage: 'Email address is a required field',
          id: 'createAccount.emailRequired'
        })
      ),
    firstName: yup
      .string()
      .trim()
      .max(50)
      .required(
        formatMessage({
          defaultMessage: 'First name is a required field',
          id: 'createAccount.firstNameRequired'
        })
      )
      .test({
        message: formatMessage({
          defaultMessage: 'First name contains disallowed characters',
          id: 'createAccount.firstNameValidation'
        }),
        name: 'firstNameValidation',
        test: nameValidator
      }),
    lastName: yup
      .string()
      .trim()
      .max(100)
      .required(
        formatMessage({
          defaultMessage: 'Last name is a required field',
          id: 'createAccount.lastNameRequired'
        })
      )
      .test({
        message: formatMessage({
          defaultMessage: 'Last name contains disallowed characters',
          id: 'createAccount.lastNameValidation'
        }),
        name: 'lastNameValidation',
        test: nameValidator
      }),
    phone: yup
      .string()
      .required(
        formatMessage({
          defaultMessage: 'Phone number is a required field',
          id: 'createAccount.phoneRequired'
        })
      )
      .test({
        message: formatMessage({
          defaultMessage: 'Phone number is not valid',
          id: 'createAccount.phoneValid'
        }),
        name: 'phone-valid',
        test: (value) => {
          if (!value) {
            return false;
          }

          return phoneNumberValidator(value);
        }
      })
    /* eslint-enable newline-per-chained-call */
  });
};
