import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { DashboardBox } from '../../../components/DashboardBox';
import { DashboardBoxCenterWrapper } from '../../../components/DashboardBox/DashboardBox.styles';
import { DashboardLayout } from '../../../components/Layout';
import { Stepper } from '../../../components/Stepper';
import { Heading } from '../../../components/Typography';
import { DashboardContentWrapper } from '../Dashboard.styles';
import { ShopifyCheckout } from './ShopifyCheckout';
import { UpgradeEarningsForm } from './UpgradeEarningsForm/UpgradeEarningsForm';

export const UpgradeEarnings: FC = () => {
  return (
    <Stepper steps={[<UpgradeEarningsForm />, <ShopifyCheckout />]}>
      {(step) => (
        <DashboardLayout>
          <DashboardContentWrapper>
            <DashboardBox isFullHeight>
              <Heading size="h5" element="h1" variant="black">
                <FormattedMessage
                  id="upgradeCoolspot.header"
                  defaultMessage="Upgrade your CoolSpot™ earnings plan from 20% to 50% today!"
                />
              </Heading>
              <DashboardBoxCenterWrapper contentWidth={720}>
                {step}
              </DashboardBoxCenterWrapper>
            </DashboardBox>
          </DashboardContentWrapper>
        </DashboardLayout>
      )}
    </Stepper>
  );
};
