import { FormattedDate, FormattedMessage } from 'react-intl';
import ReactMarkdown from 'react-markdown';

import { FullPageLoader } from '../../components/FullPageLoader';
import { FullPageLayout } from '../../components/Layout/FullPageLayout';
import { ScrollTopButton } from '../../components/ScrollTopButton';
import { Heading, Text } from '../../components/Typography';
import { useGetTermsAndConditions } from '../../shared/api/Identity/Identity.queries';
import { Container, Footer, Header } from './TermsAndConditions.styles';

export const TermsAndConditions = () => {
  const { data: termsAndConditions, isLoading } = useGetTermsAndConditions();

  if (isLoading) {
    return <FullPageLoader />;
  }

  return (
    <Container>
      <FullPageLayout>
        <Header>
          <Heading element="h1" size="h5">
            <FormattedMessage
              id="termsAndConditions.title"
              defaultMessage="Emrit Terms and Conditions"
            />
          </Heading>
        </Header>
        <Text level="big">
          {termsAndConditions?.data && (
            <ReactMarkdown>{termsAndConditions.data}</ReactMarkdown>
          )}
        </Text>
        <Footer>
          <FormattedMessage
            id="termsAndConditions.lastUpdated"
            defaultMessage="Last updated on {date}"
            values={{
              date: (
                <FormattedDate
                  value={termsAndConditions?.updatedAt}
                  day="2-digit"
                  month="2-digit"
                  year="numeric"
                />
              )
            }}
          />
        </Footer>
        <ScrollTopButton />
      </FullPageLayout>
    </Container>
  );
};
