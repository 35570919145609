import styled, { ComponentSize } from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledLink = styled(Link)<{ size: ComponentSize }>`
  font-weight: 700;

  text-decoration: none;

  color: ${({ theme }) => theme.color.blue};

  &:hover,
  &:active {
    color: ${({ theme }) => theme.color.darkBlue};
  }
`;
