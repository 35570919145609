import { FC } from 'react';
import { useId } from '@reach/auto-id';

import {
  DropdownWrapper,
  IconWrapper,
  StyledLabel,
  StyledOption,
  StyledSelect
} from './Dropdown.styles';
import { DropdownProps, OptionProps } from './Dropdown.types';
import { Icon } from '../Icon';
import { getVariant } from '../../shared/utils/style-utils';
import { FieldCaption, FieldVariant } from '../FieldCaption';
import { Loader } from '../Loader';

const CustomOption: FC<OptionProps> = ({
  children,
  data,
  innerProps,
  isDisabled,
  isFocused,
  isSelected
}) => {
  if (isDisabled) {
    return null;
  }

  return (
    <StyledOption isSelected={isSelected} isFocused={isFocused} {...innerProps}>
      {data?.icon && (
        <IconWrapper isSelected={isSelected} isFocused={isFocused}>
          {' '}
          <Icon icon={data.icon} />
        </IconWrapper>
      )}
      {children}
    </StyledOption>
  );
};

const LoadingIndicator = () => <Loader variant="blue" />;

export const Dropdown: FC<DropdownProps> = ({
  caption,
  className,
  defaultMenuIsOpen,
  defaultValue,
  error,
  filterOption,
  formatOptionLabel,
  hideButton = false,
  inputRef,
  isDisabled,
  isLoading,
  isValid,
  label,
  menuIsOpen,
  name,
  onBlur,
  onChange,
  onInputChange,
  options,
  placeholder,
  value
}) => {
  const id = useId();
  const variant = getVariant<Exclude<FieldVariant, FieldVariant.PREFILLED>>({
    [FieldVariant.DISABLED]: !!isDisabled,
    [FieldVariant.ERROR]: !!error,
    [FieldVariant.VALID]: isValid
  });

  return (
    <DropdownWrapper>
      <StyledLabel variant={variant} htmlFor={id}>
        {label}
      </StyledLabel>
      <StyledSelect
        className={className}
        classNamePrefix="select"
        components={{
          LoadingIndicator,
          Option: CustomOption
        }}
        defaultMenuIsOpen={defaultMenuIsOpen}
        defaultValue={defaultValue}
        filterOption={filterOption}
        formatOptionLabel={formatOptionLabel}
        hideButton={hideButton}
        id={id}
        isDisabled={isLoading || isDisabled}
        isLoading={isLoading}
        menuIsOpen={menuIsOpen}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onInputChange={onInputChange}
        options={options}
        placeholder={placeholder}
        ref={inputRef}
        variant={variant}
        value={value}
      />
      {(caption || error) && (
        <FieldCaption variant={variant}>{error || caption}</FieldCaption>
      )}
    </DropdownWrapper>
  );
};
