export const ellipsizeText = (text: string, maxLength: number): string => {
  const ellipsis = '...';
  const textLeft: Array<string> = [];
  const textRight: Array<string> = [];

  Object.values(text).forEach((letter, letterIndex) => {
    const currentTextLength =
      textLeft.length + ellipsis.length + textRight.length;

    if (currentTextLength < maxLength && currentTextLength < text.length) {
      textLeft.push(letter);
      textRight.unshift(text[text.length - letterIndex]);
    }
  });

  return `${textLeft.join('')}${ellipsis}${textRight.join('')}`;
};
