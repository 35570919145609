import {
  MutationFunction,
  QueryFunction,
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions
} from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';

import { AlertVariant } from '../../components/Alert/Alert.types';
import { ErrorCodes } from '../api/error-codes';
import { HTTPError } from '../api/fetcher.types';
import { useNotification } from '../utils/notifications/notification-context';
import { useTermsAndConditions } from '../utils/terms-and-conditions/terms-and-conditions-context';
import { useAuth } from './auth-context';

const isAuthError = (error: HTTPError) =>
  [
    ErrorCodes.EXPIRED_TOKEN,
    ErrorCodes.INVALID_TOKEN,
    ErrorCodes.MISSING_TOKEN
  ].includes(error?.errorCode);

function authInterceptor<
  TQueryFnData = unknown,
  TData = unknown,
  TVariables = void
>(
  fn: QueryFunction<TQueryFnData> | MutationFunction<TData, TVariables>,
  handleError: (error: HTTPError) => void
) {
  return async function callbackFn(...props: [any]) {
    try {
      return await fn(...props);
    } catch (error: any) {
      if (isAuthError(error?.data)) {
        await handleError(error);

        return await fn(...props);
      }

      return Promise.reject(new HTTPError(error));
    }
  };
}

export function useAuthQuery<TQueryFnData = unknown, TData = TQueryFnData>(
  key: QueryKey,
  fn: QueryFunction<TQueryFnData>,
  options: UseQueryOptions<
    TQueryFnData,
    HTTPError,
    TData,
    string | readonly unknown[]
  > = {}
) {
  const { addNotification } = useNotification();
  const { logout, refreshToken } = useAuth();
  const { showTermsAndConditions } = useTermsAndConditions();
  const { pathname } = useLocation();

  const { onError, ...rest } = options;

  const handleError = async (error: HTTPError) => {
    try {
      await refreshToken.refetch();
    } catch (refreshTokenError) {
      await logout.refetch();

      addNotification({
        id: 'authError',
        text: error.message,
        variant: AlertVariant.Danger
      });
    }
  };

  return useQuery(key, authInterceptor(fn, handleError), {
    onError: async (error) => {
      if (
        error?.errorCode === ErrorCodes.TERMS_AND_CONDITIONS_NOT_ACCEPTED &&
        pathname !== '/terms-and-conditions'
      ) {
        showTermsAndConditions(true);
      }

      if (isAuthError(error?.data)) {
        await handleError(error);
      } else if (onError) {
        onError(error);
      }
    },
    retry: (_, error) => {
      if (
        error?.data?.errorCode === ErrorCodes.TERMS_AND_CONDITIONS_NOT_ACCEPTED
      ) {
        showTermsAndConditions(true);
      }

      if (isAuthError(error?.data)) {
        return false;
      }

      return true;
    },
    ...rest
  });
}

export function useAuthMutation<
  TData = unknown,
  TVariables = void,
  TContext = unknown
>(
  key: string,
  fn: MutationFunction<TData, TVariables>,
  options: UseMutationOptions<TData, HTTPError, TVariables, TContext> = {}
) {
  const { addNotification } = useNotification();
  const { logout, refreshToken } = useAuth();
  const history = useHistory();
  const { showTermsAndConditions } = useTermsAndConditions();

  const { onError, ...rest } = options;

  const handleError = async (error: HTTPError) => {
    try {
      await refreshToken.refetch();
    } catch (refreshTokenError) {
      await logout.refetch();

      addNotification({
        id: 'authError',
        text: error.message,
        variant: AlertVariant.Danger
      });

      history.push({
        pathname: '/login',
        search: `redirectUrl=${encodeURIComponent(
          history.location.pathname + history.location.search
        )}`
      });
    }
  };

  return useMutation(key, authInterceptor(fn, handleError), {
    onError: async (error, variables, context) => {
      if (
        error?.data?.errorCode === ErrorCodes.TERMS_AND_CONDITIONS_NOT_ACCEPTED
      ) {
        showTermsAndConditions(true);
      } else if (onError) {
        onError(error, variables, context);
      }
    },
    ...rest
  });
}
