import { FC } from 'react';
import styled, { css, Sizing } from 'styled-components';

export type BoxProps = {
  p?: Sizing;
  pr?: Sizing;
  pl?: Sizing;
  pt?: Sizing;
  pb?: Sizing;
  m?: Sizing;
  mr?: Sizing;
  ml?: Sizing;
  mt?: Sizing;
  mb?: Sizing;
  width?: string;
  relative?: boolean;
};

const StyledBox = styled.div<BoxProps>`
  padding: ${({ theme, p }) => p && theme.padding[p]};
  padding-right: ${({ theme, pr }) => pr && theme.padding[pr]};
  padding-left: ${({ theme, pl }) => pl && theme.padding[pl]};
  padding-top: ${({ theme, pt }) => pt && theme.padding[pt]};
  padding-bottom: ${({ theme, pb }) => pb && theme.padding[pb]};

  margin: ${({ theme, m }) => m && theme.margin[m]};
  margin-right: ${({ theme, mr }) => mr && theme.margin[mr]};
  margin-left: ${({ theme, ml }) => ml && theme.margin[ml]};
  margin-top: ${({ theme, mt }) => mt && theme.margin[mt]};
  margin-bottom: ${({ theme, mb }) => mb && theme.margin[mb]};

  width: ${({ width }) => width};

  ${({ relative }) =>
    relative &&
    css`
      position: relative;
    `};
`;

export const Box: FC<BoxProps> = ({ children, ...rest }) => (
  <StyledBox {...rest}>{children}</StyledBox>
);
