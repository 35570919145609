import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { tooltipMenuItemStyle } from '../TooltipMenuItem/TooltipMenuItem.styles';

export const StyledTooltipMenuLink = styled(Link)`
  ${tooltipMenuItemStyle}

  text-decoration: none;
`;
