import styled, { css, DefaultTheme } from 'styled-components';

import { Box } from '../Box';
import { AlertVariant } from './Alert.types';

const alertVariantStyles = (theme: DefaultTheme, variant: AlertVariant) =>
  ({
    [AlertVariant.Default]: css`
      background: ${theme.color.white};
      color: ${theme.color.black};
      box-shadow: ${theme.shadow.blue.s};

      svg {
        stroke: ${theme.color.grey};
      }
    `,
    [AlertVariant.Primary]: css`
      background: ${theme.color.blue};
      color: ${theme.color.white};

      svg {
        stroke: ${theme.color.white};
      }

      a {
        color: ${theme.color.white};

        &:hover {
          color: ${theme.color.lightGrey};
        }
      }
    `,
    [AlertVariant.Success]: css`
      background: ${theme.color.secondaryGreen};
      color: ${theme.color.white};

      svg {
        stroke: ${theme.color.white};
      }

      a {
        color: ${theme.color.white};

        &:hover {
          color: ${theme.color.lightGrey};
        }
      }
    `,
    [AlertVariant.Secondary]: css`
      background: ${theme.color.secondaryWhiteBlue};
      color: ${theme.color.grey};

      svg {
        stroke: ${theme.color.grey};
      }

      a {
        color: ${theme.color.blue};
      }
    `,
    [AlertVariant.Warning]: css`
      background: ${theme.color.secondaryYellow};
      color: ${theme.color.grey};

      svg {
        stroke: ${theme.color.grey};
      }

      a {
        color: ${theme.color.grey};

        &:hover {
          color: ${theme.color.dimmed};
        }
      }
    `,
    [AlertVariant.SecondaryWarning]: css`
      border: ${theme.border(theme.color.secondaryYellow)};
      color: ${theme.color.black};
      background: ${theme.color.white};

      svg {
        stroke: ${theme.color.secondaryYellow};
      }

      a {
        color: ${theme.color.black};

        &:hover {
          color: ${theme.color.dimmed};
        }
      }
    `,
    [AlertVariant.Danger]: css`
      background: ${theme.color.secondaryRed};
      color: ${theme.color.white};

      svg {
        stroke: ${theme.color.white};
      }

      a {
        color: ${theme.color.white};

        &:hover {
          color: ${theme.color.lightGrey};
        }
      }
    `
  }[variant]);

export const ChildrenWrapper = styled.div`
  display: block;
  flex-grow: 1;
  white-space: pre-line;
`;

export const StyledCloseButton = styled.button`
  display: flex;

  margin: 0;
  padding: 0;
  padding-left: ${({ theme }) => theme.padding.m};

  background: none;
  border: none;

  cursor: pointer;
`;

export const StyledIconBox = styled(Box)`
  padding-right: ${({ theme }) => theme.padding.m};
  margin: 0;
`;

export const TopSection = styled.div`
  display: flex;
  flex-direction: row;
  line-height: 24px;
`;

export const NavItems = styled.div`
  display: flex;
  flex-direction: column;

  padding-top: ${({ theme }) => theme.padding.s};
`;

export const StyledAlert = styled.div<{ variant: AlertVariant }>`
  display: flex;
  flex-direction: column;
  ${({ theme, variant }) => alertVariantStyles(theme, variant)}

  padding: ${({ theme }) => theme.padding.m};

  border-radius: 16px;
`;
