import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import TagManager from 'react-gtm-module';
import Skeleton from 'react-loading-skeleton';

import {
  IconWrapper,
  ImageBox,
  Label,
  OrderNumberPanel,
  Value
} from './CoolspotOrderConfirmation.styles';
import { ReactComponent as SuccessTick } from '../../assets/images/SuccessTick.svg';
import { Box } from '../../components/Box';
import { ContentWrapper, SignUpLayout } from '../../components/Layout';
import { Icon } from '../../components/Icon';
import { Caption, Heading, Subtitle, Text } from '../../components/Typography';
import { LinkButton } from '../../components/Button';
import { ErrorPage } from '../ErrorPage';
import {
  useGetTransferredLeadOrder,
  useGetTransferredLeadOrderWithShopifyData
} from '../../shared/api/Order/Order.queries';
import { useQueryParams } from '../../shared/utils/use-query-params';
import { theme } from '../../themes/main';
import { ProcessingOrder } from '../../components/ProcessingOrder';
import { PaymentProviderIcon } from '../../components/PaymentProviderIcon';

export const CoolspotOrderConfirmation: FC = () => {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const [{ coolspotUpgradePlanId, leadOrderId }] = useQueryParams({
    coolspotUpgradePlanId: '',
    leadOrderId: ''
  });

  const {
    refetch: getLeadOrder,
    data: leadOrder,
    isLoading: isGetLeadOrderLoading,
    isError: isGetLeadOrderError
  } = useGetTransferredLeadOrder(leadOrderId);

  const {
    refetch: getLeadOrderWithShopifyData,
    data: leadOrderWithShopifyData
  } = useGetTransferredLeadOrderWithShopifyData(leadOrderId);

  const shopifyData =
    leadOrder?.order?.transaction ||
    leadOrderWithShopifyData?.order?.transaction;

  useEffect(() => {
    if (coolspotUpgradePlanId !== '') {
      history.replace(
        `/upgrade-confirmation?coolspotUpgradePlanId=${coolspotUpgradePlanId}`
      );
    }

    getLeadOrder();
  }, [coolspotUpgradePlanId, history, getLeadOrder]);

  useEffect(() => {
    if (leadOrder) {
      TagManager.dataLayer({ dataLayer: { ecommerce: null } }); // Clear the previous ecommerce object.

      TagManager.dataLayer({
        dataLayer: {
          event: 'purchase',
          ecommerce: {
            transaction_id: leadOrder.orderNumber,
            affiliation: 'Emrit',
            value:
              leadOrder.order?.transaction.amountPaid ??
              process.env.REACT_APP_PLAN_PRICE ??
              0,
            currency: leadOrder.order?.transaction.currency ?? 'USD'
          }
        }
      });
    }

    if (!leadOrder?.order?.transaction) {
      getLeadOrderWithShopifyData();
    }
  }, [getLeadOrderWithShopifyData, leadOrder]);

  if (isGetLeadOrderLoading) {
    return (
      <SignUpLayout>
        <ContentWrapper>
          <Heading size="h4" element="h1" variant="grey">
            <FormattedMessage
              id="processingOrder.title"
              defaultMessage="Please wait..."
            />
          </Heading>
          <ProcessingOrder
            label={formatMessage({
              id: 'coolspotOrderConfirmation.progressBarLabel',
              defaultMessage: "We're processing your order"
            })}
          />
        </ContentWrapper>
      </SignUpLayout>
    );
  }

  if (isGetLeadOrderError) {
    return <ErrorPage />;
  }

  return (
    <SignUpLayout>
      <ContentWrapper>
        <Heading size="h4" element="h1" variant="grey">
          <FormattedMessage
            id="orderPlaced.title"
            defaultMessage="Order Confirmation"
          />
        </Heading>
        <Box mt="m">
          <Text level="big">
            <FormattedMessage
              id="orderPlaced.subtitle"
              defaultMessage="Congratulations! A CoolSpot™ is on the way."
            />
          </Text>
        </Box>
        <ImageBox>
          <SuccessTick />
        </ImageBox>
        <OrderNumberPanel>
          <Box>
            <Heading size="h5" element="h5" variant="grey" bold={false}>
              <FormattedMessage
                id="orderPlaced.orderConfirmation"
                defaultMessage="Order confirmation"
              />
            </Heading>
          </Box>
          <Box>
            <Label>
              <FormattedMessage
                id="orderPlaced.yourOrderNumber"
                defaultMessage="Order number"
              />
            </Label>
            <Value>
              <IconWrapper>
                <Icon icon="Analytics" color={theme.color.dimmed} />
              </IconWrapper>
              {leadOrder?.orderNumber}
            </Value>
          </Box>
          <Box mt="s">
            <Label>
              <FormattedMessage
                id="orderPlaced.payment"
                defaultMessage="Payment"
              />
            </Label>
            {shopifyData && (
              <Value>
                <IconWrapper>
                  <PaymentProviderIcon
                    paymentProvider={leadOrder?.order?.transaction?.gateway}
                  />
                </IconWrapper>
                <FormattedNumber
                  value={shopifyData?.amountPaid || 0}
                  style="currency"
                  currency="USD"
                />
              </Value>
            )}
            {!shopifyData && (
              <>
                <Value>
                  <Skeleton
                    width={24}
                    height={16}
                    style={{ borderRadius: 8, marginRight: 16 }}
                  />
                  <Skeleton
                    width={70}
                    height={16}
                    style={{ borderRadius: 8 }}
                  />
                </Value>
                <Box mt="xxs">
                  <Value>
                    <IconWrapper alingSelf="flex-start" mr="xs">
                      <Icon icon="Info" color={theme.color.dimmed} />
                    </IconWrapper>
                    <Caption color="grey">
                      <FormattedMessage
                        id="orderPlaced.processingPaymentInfo"
                        defaultMessage="We're processing the payment information, this may take up to 5 mins. Thank you for your patience."
                      />
                    </Caption>
                  </Value>
                </Box>
              </>
            )}
          </Box>
          <Box mt="s">
            <Label>
              <FormattedMessage
                id="orderPlaced.coolspotInstallationAddress"
                defaultMessage="Installation address"
              />
            </Label>
            <Value>
              <IconWrapper>
                <Icon icon="Location" color={theme.color.dimmed} />
              </IconWrapper>
              {leadOrder?.installationAddress}
            </Value>
          </Box>
          <Box mt="s">
            <Label>
              <FormattedMessage
                id="orderACoolspot.shippingAddress"
                defaultMessage="Shipping address"
              />
            </Label>
            <Value>
              <IconWrapper>
                <Icon icon="Home" color={theme.color.dimmed} />
              </IconWrapper>
              {leadOrder?.shippingAddress}
            </Value>
          </Box>
        </OrderNumberPanel>
        <Box mt="xl">
          <LinkButton to="/dashboard" fullWidth variant="blue">
            <FormattedMessage
              id="orderPlaced.goToDashboardButtonLabel"
              defaultMessage="Go to dashboard"
            />
          </LinkButton>
        </Box>
        <Box mt="l">
          <Subtitle variant="grey" size="big">
            <FormattedMessage
              id="orderPlaced.emailSent"
              defaultMessage="We’ve sent an email with your order confirmation and purchase receipt."
            />
          </Subtitle>
        </Box>
        <Box mt="m">
          <Subtitle variant="grey" size="big">
            <FormattedMessage
              id="orderPlaced.emailSentSubtitle"
              defaultMessage="If you do not receive an email, please check your junk mail folder and mark the email as not spam."
            />
          </Subtitle>
        </Box>
      </ContentWrapper>
    </SignUpLayout>
  );
};
