import TagManager from 'react-gtm-module';

export enum UserTrackingEvent {
  NewUser = 'new_user',
  RegisteredUser = 'registered_user'
}

export const userTracking = (event: UserTrackingEvent) => {
  if (process.env.REACT_APP_GTM_ID && process.env.NODE_ENV === 'production') {
    TagManager.dataLayer({
      dataLayer: {
        event
      }
    });
  }
};
