import { FC, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import qs from 'query-string';

import { Alert } from '../../../../components/Alert';
import { AlertVariant } from '../../../../components/Alert/Alert.types';
import { Box } from '../../../../components/Box';
import { Button, LinkButton } from '../../../../components/Button';
import { BUTTON_SIZE } from '../../../../components/Button/Button.types';
import { FullPageLoader } from '../../../../components/FullPageLoader';
import { ContentWrapper } from '../../../../components/Layout';
import { Link } from '../../../../components/Link';
import { Heading, Subtitle, Text } from '../../../../components/Typography';
import { useQueryParams } from '../../../../shared/utils/use-query-params';
import { useGetLeadUserOrder } from '../../../../shared/api/Order/Order.queries';
import { ErrorPage } from '../../../ErrorPage';
import { ErrorPageType } from '../../../ErrorPage/ErrorPage.types';
import { useSendOneTimeAuthCode } from '../../../../shared/api/Identity/Identity.queries';
import { useNotification } from '../../../../shared/utils/notifications/notification-context';
import { LeadOrderStatus } from '../../../../shared/api/Order/Order.types';
import OrderInvalid from '../../../OrderACoolspot/OrderInvalid';
import OrderNotFound from '../../../OrderACoolspot/OrderNotFound';
import { CreateAccountStepProps } from '../CreateAccountPage.types';
import { AddressBox } from '../../../../components/AddressBox';
import { useAuth } from '../../../../shared/auth/auth-context';

export const ReadyToShip: FC<CreateAccountStepProps> = ({ handleNextStep }) => {
  const { addNotification } = useNotification();
  const [{ email, leadOrderId }] = useQueryParams({
    email: '',
    leadOrderId: ''
  });
  const { leadUserData } = useAuth();

  const removeMeFromWaitListLinkParams = qs.stringify({ leadOrderId, email });

  const {
    refetch: getLeadOrder,
    data: leadOrder,
    isLoading: isGetLeadOrderLoading,
    isError: isGetLeadOrderError
  } = useGetLeadUserOrder(leadOrderId, email, {
    retry: false
  });

  const {
    mutate: sendOneTimeAuthCode,
    isLoading: isSendOneTimeAuthCodeLoading
  } = useSendOneTimeAuthCode({
    onError: (error) => {
      const { response } = error;

      addNotification({
        id: 'oneTimeAuthCodeError',
        text: response?.message,
        variant: AlertVariant.Danger
      });
    },
    onSuccess: async () => {
      if (handleNextStep) {
        handleNextStep({ email, leadOrderId, leadOrder });
      }
    }
  });

  useEffect(() => {
    getLeadOrder();
  }, [getLeadOrder]);

  if (isGetLeadOrderLoading) {
    return <FullPageLoader />;
  }

  if (leadOrder?.status === LeadOrderStatus.Transferred) {
    return <OrderNotFound />;
  }

  if (
    leadOrder?.status === LeadOrderStatus.NotResponded ||
    leadOrder?.status === LeadOrderStatus.Postponed
  ) {
    return (
      <OrderInvalid
        email={email}
        leadOrderId={leadOrder.id}
        leadOrderStatus={leadOrder.status}
      />
    );
  }

  if (isGetLeadOrderError) {
    return <ErrorPage isOverlay type={ErrorPageType.LinkInvalid} />;
  }

  const handleConfirm = () => {
    if (leadUserData) {
      handleNextStep?.({
        email,
        leadOrderId,
        leadOrder,
        leadUser: leadUserData
      });
    } else {
      sendOneTimeAuthCode(email);
    }
  };

  return (
    <ContentWrapper showHero hideLogo>
      <Heading size="h4" element="h1" variant="grey">
        <FormattedMessage
          id="readyToShip.title"
          defaultMessage="Good news! We have stock available."
        />
      </Heading>
      <Box mt="l">
        <Subtitle size="big" variant="grey">
          <FormattedMessage
            id="readyToShip.firstStep"
            defaultMessage="Please confirm your installation address to create an account and place your order:"
          />
        </Subtitle>
      </Box>
      <AddressBox mt="m">{leadOrder?.installationAddress}</AddressBox>
      <Box mt="l">
        <Alert variant={AlertVariant.Secondary}>
          <FormattedMessage
            id="readyToShip.infoBox"
            defaultMessage="If you want to add a different CoolSpot™ installation address please fill a form Become a host again so we can check your eligibility."
          />{' '}
          <Link to="/sign-up">
            <FormattedMessage
              id="readyToShip.clickHere"
              defaultMessage="Click here"
            />
          </Link>
        </Alert>
      </Box>
      <Box mt="l">
        <Button
          fullWidth
          isLoading={isSendOneTimeAuthCodeLoading}
          isPrimary
          onClick={handleConfirm}
          variant="blue"
        >
          <FormattedMessage
            id="readyToShip.confirmButtonLabel"
            defaultMessage="Confirm"
          />
        </Button>
      </Box>
      <Box mt="m">
        <LinkButton
          to="https://emrit.io"
          external
          isPlain
          isPrimary={false}
          fullWidth
          size={BUTTON_SIZE.BIG}
        >
          <FormattedMessage
            id="readyToShip.cancelButtonLabel"
            defaultMessage="Cancel"
          />
        </LinkButton>
      </Box>
      <Box mt="xl">
        <Text type="default" level="big">
          <FormattedMessage
            id="readyToShip.unsubscribe"
            defaultMessage="If you don’t want to be on the list for a CoolSpot™ at this installation address {clickHere}."
            values={{
              clickHere: (
                <Link
                  to={`/remove-from-wait-list?${removeMeFromWaitListLinkParams}`}
                >
                  <FormattedMessage
                    id="readyToShip.unsubscribeLink"
                    defaultMessage="click here"
                  />
                </Link>
              )
            }}
          />
        </Text>
      </Box>
    </ContentWrapper>
  );
};
