import { FC, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { Box } from '../../../../components/Box';
import { LinkButton } from '../../../../components/Button';
import { BUTTON_SIZE } from '../../../../components/Button/Button.types';
import { ContentWrapper } from '../../../../components/Layout';
import { CreateLeadUserForm } from '../../../../components/CreateLeadUserForm';
import { CreateLeadUserFormWrapper } from '../../../../components/CreateLeadUserForm/CreateLeadUserForm.styles';
import { CreateLeadUserFormValues } from '../../../../components/CreateLeadUserForm/CreateLeadUserForm.types';
import { Heading } from '../../../../components/Typography';
import { useQueryParams } from '../../../../shared/utils/use-query-params';
import { StyledSubtitle } from './CreateLeadUserPage.styles';
import {
  userTracking,
  UserTrackingEvent
} from '../../../../shared/utils/user-tracking';
import { CreateLeadUserStepProps } from '../CreateLeadUser.types';

export const CreateLeadUserPage: FC<
  {
    onSubmit?: (data?: CreateLeadUserFormValues) => void;
  } & CreateLeadUserStepProps
> = ({ handleNextStep, onSubmit, previousStepValues = null }) => {
  const [params] = useQueryParams({
    country: '',
    email: ''
  });

  useEffect(() => {
    userTracking(UserTrackingEvent.NewUser);
  }, []);

  const handleSubmit = (leadUser: CreateLeadUserFormValues) => {
    if (onSubmit) {
      onSubmit(leadUser);
    }

    if (handleNextStep) {
      handleNextStep(leadUser);
    }
  };

  return (
    <>
      <ContentWrapper>
        <Heading size="h4" element="h1" variant="grey">
          <FormattedMessage
            id="createLeadUser.title"
            defaultMessage="Want to be a CoolSpot™ host?"
          />
        </Heading>
        <StyledSubtitle variant="grey" size="big">
          <FormattedMessage
            id="createLeadUser.subtitle"
            defaultMessage="Submit a CoolSpot request by adding your installation address, and we will assess your location for immediate shipping eligibility."
          />
        </StyledSubtitle>
        <CreateLeadUserFormWrapper>
          <CreateLeadUserForm
            onSubmit={handleSubmit}
            defaultValue={{
              email: params.email,
              ...previousStepValues
            }}
          />
        </CreateLeadUserFormWrapper>
        <Box mt="m">
          <LinkButton
            to="/feedback"
            isPlain
            isPrimary={false}
            fullWidth
            size={BUTTON_SIZE.BIG}
          >
            <FormattedMessage
              id="createLeadUser.cancelButton"
              defaultMessage="Cancel"
            />
          </LinkButton>
        </Box>
      </ContentWrapper>
    </>
  );
};
