import { FC, useCallback, useLayoutEffect, useRef, useState } from 'react';

import { ellipsizeText } from '../../shared/utils/ellipsize-text';
import { EllipsizedTextWrapper } from './EllipsizedText.styles';
import { EllipsizedTextProps } from './EllipsizedText.types';

export const EllipsizedText: FC<EllipsizedTextProps> = ({
  children,
  maxLength
}) => {
  const [ellipsizedText, setEllipsizedText] = useState<string>(children);
  const wrapperRef = useRef<HTMLSpanElement | null>(null);
  const averageLetterWidth = useRef<number>(0);

  const handleTextChange = useCallback(
    (width: number) => {
      const lettersCanFit = Math.floor(width / averageLetterWidth.current);

      const text = ellipsizeText(
        children,
        maxLength ? Math.min(maxLength, lettersCanFit) : lettersCanFit
      );

      if (lettersCanFit >= children.length) {
        setEllipsizedText(children);
      } else {
        setEllipsizedText(text);
      }
    },
    [children, maxLength]
  );

  const handleParentResize = useCallback<ResizeObserverCallback>(
    (entries: ResizeObserverEntry[]) => {
      const [entry] = entries;
      const { inlineSize } = entry.contentBoxSize[0];

      handleTextChange(inlineSize);
    },
    [handleTextChange]
  );

  useLayoutEffect(() => {
    averageLetterWidth.current =
      wrapperRef?.current?.scrollWidth! / children.length;

    const isTextWiderThanContainer =
      wrapperRef?.current?.scrollWidth! > wrapperRef?.current?.offsetWidth!;

    if (wrapperRef.current && isTextWiderThanContainer) {
      handleTextChange(wrapperRef.current.offsetWidth);
    }
  }, [children, handleParentResize, handleTextChange]);

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(handleParentResize);
    const parentElement = wrapperRef?.current;

    if (parentElement) {
      resizeObserver.observe(parentElement);
    }

    return () => {
      if (parentElement) {
        resizeObserver.unobserve(parentElement);
      }
    };
  }, [handleParentResize, wrapperRef]);

  return (
    <EllipsizedTextWrapper ref={wrapperRef}>
      {ellipsizedText}
    </EllipsizedTextWrapper>
  );
};
