import styled, { css } from 'styled-components';

import { HeadingProps } from './Heading.types';

export const StyledHeading = styled.h1<HeadingProps>`
  margin: 0;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme, size }) => theme.typography.heading[size].fontSize};
  letter-spacing: ${({ theme, size }) =>
    theme.typography.heading[size].letterSpacing};
  line-height: ${({ theme, size }) =>
    theme.typography.heading[size].lineHeight};
  text-align: ${({ align }) => align};
  color: ${({ theme, variant }) =>
    variant ? theme.color[variant] : 'inherit'};
  font-weight: ${({ bold }) => (bold ? 700 : 300)};

  ${({ whiteSpace }) =>
    whiteSpace &&
    css`
      white-space: ${whiteSpace};
    `}
`;

StyledHeading.displayName = 'Heading';
StyledHeading.defaultProps = {
  align: 'left',
  size: 'h1'
};
