import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Box } from '../../../../components/Box';
import { Button } from '../../../../components/Button';
import { BUTTON_SIZE } from '../../../../components/Button/Button.types';
import { GetWallet } from '../../../Auth/SignUp/SupportedCountry/GetWallet';
import { FormContainer, MobileAppLinkBox } from './EditWalletForm.styles';
import { EditWalletFormProps } from './EditWalletForm.types';
import {
  WalletForm,
  WalletFormValues
} from '../../../../components/WalletForm';
import { Text } from '../../../../components/Typography';
import { ReactComponent as AppStore } from '../../../../assets/images/AppStore.svg';
import { ReactComponent as GooglePlay } from '../../../../assets/images/GooglePlay.svg';
import { WalletHistory } from './WalletHistory';
import { DashboardBoxCenterWrapper } from '../../../../components/DashboardBox/DashboardBox.styles';

export const EditWalletForm: FC<EditWalletFormProps> = ({
  handleNextStep,
  defaultValues
}) => {
  const [showTutorial, setShowTutorial] = useState(false);
  const history = useHistory();

  const handleCancel = async () => {
    history.push('/dashboard');
  };

  const handleOnSubmit = (values: WalletFormValues) => {
    if (handleNextStep) {
      handleNextStep(values);
    }
  };

  if (showTutorial) {
    return <GetWallet onClose={() => setShowTutorial(false)} />;
  }

  return (
    <>
      <DashboardBoxCenterWrapper contentWidth={592}>
        <FormContainer>
          <Box>
            <Text level="big">
              <FormattedMessage
                id="editWalletForm.title"
                defaultMessage="Please paste your current Helium wallet address into the field below."
              />
            </Text>
          </Box>
          <WalletForm defaultValues={defaultValues} onSubmit={handleOnSubmit} />
          <Box mt="m">
            <Button
              onClick={handleCancel}
              isPlain
              isPrimary={false}
              fullWidth
              size={BUTTON_SIZE.BIG}
            >
              <FormattedMessage
                id="editWallet.cancel"
                defaultMessage="Cancel"
              />
            </Button>
          </Box>
          {!defaultValues?.walletAddress && (
            <>
              <Box mt="l">
                <Text level="big">
                  <FormattedMessage
                    id="editWalletForm.emritAppTitle"
                    defaultMessage="If you don't have a Helium wallet please create one on the Emrit app. After you create the wallet the address will automatically appear above."
                  />
                </Text>
              </Box>
              <MobileAppLinkBox mt="l">
                <a
                  href={process.env.REACT_APP_APP_STORE_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  <AppStore />
                </a>
                <a
                  href={process.env.REACT_APP_GOOGLE_PLAY_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  <GooglePlay />
                </a>
              </MobileAppLinkBox>
            </>
          )}
        </FormContainer>
      </DashboardBoxCenterWrapper>
      <WalletHistory currentWalletId={defaultValues.walletAddress} />
    </>
  );
};
