import { FC, useMemo } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { Box } from '../../../../../components/Box';
import { CopyBox } from '../../../../../components/CopyBox';
import { Table, TableCell, TableRow } from '../../../../../components/Table';
import { TooltipPosition } from '../../../../../components/Tooltip/Tooltip.types';
import { Heading, Text } from '../../../../../components/Typography';
import { useGetWalletsHistory } from '../../../../../shared/api/Wallet/Wallet.queries';
import { WalletHistoryEntity } from '../../../../../shared/api/Wallet/Wallet.types';
import { StyledCaption } from './WalletHistory.styles';
import { WalletHistoryProps } from './WalletHistory.types';

const sortByCreationTime = (walletHistory: WalletHistoryEntity[]) =>
  walletHistory.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );

export const WalletHistory: FC<WalletHistoryProps> = ({ currentWalletId }) => {
  const { data } = useGetWalletsHistory();

  const walletChangeHistory = useMemo(
    () => sortByCreationTime(data?.entities || []),
    [data?.entities]
  );

  return (
    <Box mt="xl">
      <Heading size="h5" element="h3" variant="black">
        <FormattedMessage
          id="walletHistory.title"
          defaultMessage="Wallet change history"
        />
      </Heading>
      <Table>
        <tbody>
          {walletChangeHistory.map(({ heliumWalletId, createdAt }) => (
            <TableRow key={createdAt}>
              <TableCell>
                <Box>
                  <FormattedMessage
                    id="walletHistory.dateTitle"
                    defaultMessage="Wallet address changed to:"
                  />
                </Box>
                <StyledCaption>
                  <FormattedDate
                    value={createdAt}
                    year="numeric"
                    month="2-digit"
                    day="2-digit"
                  />
                </StyledCaption>
              </TableCell>
              <TableCell align="right">
                <Text level="small">
                  <CopyBox tooltipPosition={TooltipPosition.Left}>
                    {heliumWalletId}
                  </CopyBox>
                </Text>
                {currentWalletId === heliumWalletId && (
                  <StyledCaption>
                    <FormattedMessage
                      id="walletHistory.currentWalletAddress"
                      defaultMessage="Current wallet address"
                    />
                  </StyledCaption>
                )}
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </Box>
  );
};
