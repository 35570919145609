import { cloneElement, FC, useEffect, useState } from 'react';

import { StepperProps } from './Stepper.types';

export const Stepper: FC<StepperProps> = ({
  children,
  initialStepIndex = 0,
  initialValues = {},
  steps
}) => {
  const [stepIndex, setStepIndex] = useState(initialStepIndex);
  const [stepValues, setStepValues] = useState(initialValues);

  const currentStep = steps[stepIndex];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  const next = (newData: object) => {
    setStepValues((previousData) => Object.assign(previousData, newData));
    setStepIndex(stepIndex + 1);
  };

  const previous = (newData: object) => {
    setStepValues((previousData) => Object.assign(previousData, newData));
    setStepIndex(stepIndex - 1);
  };

  const reset = (newData: object) => {
    setStepValues({ ...newData });
    setStepIndex(0);
  };

  const stepToRender = cloneElement(currentStep, {
    ...currentStep.props,
    handleNextStep: next,
    handlePreviousStep: previous,
    handleStepsReset: reset,
    previousStepValues: stepValues
  });

  return <>{children(stepToRender, stepIndex)}</>;
};
