type Options = {
  currency: string;
  fractionDigits: number;
};

const defaultOptions: Options = {
  currency: 'HNT',
  fractionDigits: 2
};

export const formatHelium = (amount: number, options?: Partial<Options>) => {
  const { currency, fractionDigits } = { ...defaultOptions, ...options };

  return `${amount.toFixed(fractionDigits)} ${currency}`;
};
