import { FC } from 'react';

import {
  TextWrapper,
  TooltipMenuListItem
} from '../TooltipMenuItem/TooltipMenuItem.styles';
import { StyledTooltipMenuLink } from './TooltipMenuLink.styles';
import { TooltipMenuLinkProps } from './TooltipMenuLink.types';

export const TooltipMenuLink: FC<TooltipMenuLinkProps> = ({
  children,
  external = false,
  to
}) => {
  if (external) {
    return (
      <TooltipMenuListItem>
        <StyledTooltipMenuLink
          as="a"
          href={to}
          target="_blank"
          rel="noopener noreferrer"
        >
          <TextWrapper>{children}</TextWrapper>
        </StyledTooltipMenuLink>
      </TooltipMenuListItem>
    );
  }

  return (
    <TooltipMenuListItem>
      <StyledTooltipMenuLink to={to}>
        <TextWrapper>{children}</TextWrapper>
      </StyledTooltipMenuLink>
    </TooltipMenuListItem>
  );
};
