import styled from 'styled-components';

export const StyledNavbarMenu = styled.ul`
  display: flex;
  flex-direction: row;

  list-style: none;

  margin: 0;
  padding: 0;

  gap: ${({ theme }) => theme.margin.m};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    width: auto;
  }
`;
