import styled, { css } from 'styled-components';

import { AlignmentProps } from './CarouselArrow.types';

export const Background = styled.div<AlignmentProps>`
  position: absolute;
  background: ${({ theme }) => theme.color.lightGrey};
  width: 20px;
  height: 100%;
  pointer-events: none;

  ${({ left }) =>
    left &&
    css`
      top: 0;
      left: 0;
      background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0.9) 100%
      );
    `};

  ${({ right }) =>
    right &&
    css`
      top: 0;
      right: 0;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0.9) 100%
      );
    `};
`;

interface ArrowContainerProps extends AlignmentProps {
  disabled?: boolean;
}

export const ArrowContainer = styled.div<ArrowContainerProps>`
  cursor: pointer;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);

  svg {
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  ${({ left }) =>
    left &&
    css`
      left: -${({ theme }) => theme.margin.xs};
    `};

  ${({ right }) =>
    right &&
    css`
      left: auto;
      right: -${({ theme }) => theme.margin.xs};
    `};

  ${({ disabled, theme }) =>
    disabled &&
    css`
      cursor: unset;
      --fill-color: ${theme.color.lightGrey};
      --stroke-color: ${theme.color.dimmed};
    `};
`;
