import { StyledPin } from './Pin.styles';

export const Pin = () => (
  <StyledPin
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.3709 30.5373L24.592 38.3161C23.9045 39.003 22.9724 39.3888 22.0006 39.3888C21.0288 39.3888 20.0967 39.003 19.4092 38.3161L11.6285 30.5373C9.57742 28.4861 8.18063 25.8727 7.61478 23.0277C7.04892 20.1827 7.33941 17.2337 8.44952 14.5538C9.55963 11.8739 11.4395 9.58329 13.8514 7.97173C16.2633 6.36017 19.0989 5.5 21.9997 5.5C24.9004 5.5 27.7361 6.36017 30.148 7.97173C32.5599 9.58329 34.4397 11.8739 35.5498 14.5538C36.66 17.2337 36.9504 20.1827 36.3846 23.0277C35.8187 25.8727 34.4219 28.4861 32.3709 30.5373Z"
      fill="#3267F1"
      stroke="#3267F1"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 25.666C25.0376 25.666 27.5 23.2036 27.5 20.166C27.5 17.1284 25.0376 14.666 22 14.666C18.9624 14.666 16.5 17.1284 16.5 20.166C16.5 23.2036 18.9624 25.666 22 25.666Z"
      stroke="white"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </StyledPin>
);
