import { FC } from 'react';

import { Stepper } from '../../../components/Stepper';
import { LoginLayout } from '../../../components/Layout';
import { NewPassword } from './NewPassword';
import { ResetPassword } from './ResetPassword';
import { ResetPasswordSuccess } from './ResetPasswordSuccess';
import { ResetPasswordValidation } from './ResetPasswordValidation/ResetPasswordValidation';

export const ForgotPassword: FC = () => {
  return (
    <Stepper
      steps={[
        <ResetPassword />,
        <ResetPasswordValidation />,
        <NewPassword />,
        <ResetPasswordSuccess />
      ]}
    >
      {(step) => <LoginLayout>{step}</LoginLayout>}
    </Stepper>
  );
};
