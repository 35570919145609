import styled, { css } from 'styled-components';

import { Text } from '../../../../components/Typography';
import { Box } from '../../../../components/Box';

export const StyledPrice = styled(Text)`
  font-weight: 600;
`;

export const StyledEarningsText = styled(StyledPrice)<{
  earnings: number;
}>`
  ${({ earnings }) =>
    Math.sign(earnings) > 0 &&
    css`
      color: ${({ theme }) => theme.color.green};

      &::before {
        content: '+';
      }
    `}
  ${({ earnings }) =>
    Math.sign(earnings) < 0 &&
    css`
      color: ${({ theme }) => theme.color.secondaryRed};

      &::before {
        content: '-';
      }
    `}
  ${({ earnings }) =>
    Math.sign(earnings) === 0 &&
    css`
      display: none;
    `}
`;

export const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

export const Wrapper = styled.div`
  min-height: 110px;
  flex: 1;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    min-height: auto;
  }
`;
