import { FC } from 'react';

import { StyledContentWrapper, StyledLogo } from './ContentWrapper.styles';
import { HeroSection } from '../HeroSection';

export const ContentWrapper: FC<{ showHero?: boolean; hideLogo?: boolean }> = ({
  children,
  hideLogo = false,
  showHero = false,
  ...rest
}) => {
  return (
    <>
      {showHero && <HeroSection />}
      <StyledContentWrapper {...rest}>
        {!hideLogo && <StyledLogo variant="black" />}
        {children}
      </StyledContentWrapper>
    </>
  );
};
