import { ProductVariantMeta } from './ProductVariant.types';

export const productVariantsMeta: ProductVariantMeta[] = [
  {
    earningsFactor: 0.25,
    highlight: 'Lowest Risk',
    header: '25% Earnings Share',
    subheader: 'one-time payment',
    copy: `Good choice if you’re new to Helium mining and want to test the water
- Automatic upgrade to 50% earnings share after 6 months
- Activation and ongoing technical support from our team of experts
- Easy returns, free repairs for malfunctioning devices.
`
  },
  {
    earningsFactor: 0.3,
    highlight: 'Lowest Risk',
    header: '30% Earnings Share',
    subheader: 'one-time payment',
    copy: `Good choice if you’re new to Helium mining and want to test the water
- Automatic upgrade to 50% earnings share when the device mines 12 HNT or is online for 12 months
- Activation and ongoing technical support from our team of experts
- Easy returns, free repairs for malfunctioning devices.
`
  },
  {
    earningsFactor: 0.5,
    highlight: 'Balance Risk and Reward',
    header: '50% Earnings Share',
    subheader: 'one-time payment',
    copy: `Great if you’ve done research and are ready to mine
- Pay just 1/3 of the full retail cost of a miner for 50% of the earnings
- Activation and ongoing technical support from our team of experts
- Easy returns, free repairs for malfunctioning devices.
`
  },
  {
    earningsFactor: 0.7,
    highlight: 'Highest Potential',
    header: '70% Earnings Share',
    subheader: 'one-time payment',
    copy: `Best if you’re confident in your location's earning potential
- Pay 50% of the retail cost for 70% of the earnings
- Activation and ongoing technical support from our team of experts
- Easy returns, free repairs for malfunctioning devices.
`
  },
  {
    earningsFactor: 0.75,
    highlight: 'Highest Potential',
    header: '75% Earnings Share',
    subheader: 'one-time payment',
    copy: `Best if you’re confident in your location's earning potential
- Pay 50% of the retail cost for 75% of the earnings
- Activation and ongoing technical support from our team of experts
- Easy returns, free repairs for malfunctioning devices.
`
  }
];
