import React, { MouseEvent } from 'react';
import { Color } from 'styled-components';

import { Icons } from '../../icons';

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum BUTTON_SIZE {
  'BIG' = 'big',
  'SMALL' = 'small'
}
export interface StyledButtonProps {
  fullWidth?: boolean;
  isDestructive?: boolean;
  isPlain?: boolean;
  isPrimary?: boolean;
  disableHoverStyles?: boolean;
  size?: BUTTON_SIZE.BIG | BUTTON_SIZE.SMALL;
  variant?:
    | Extract<Color, 'blue' | 'white'>
    | 'blueOverBlueBackground'
    | 'blueOverLightBlueBackground';
}

export interface ButtonProps
  extends React.ComponentPropsWithoutRef<'button'>,
    StyledButtonProps {
  isIconButton?: boolean;
  iconLeft?: Icons;
  iconRight?: Icons;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  isLoading?: boolean;
  className?: string;
}
