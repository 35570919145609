import styled from 'styled-components';

import { IconButton } from '..';

export const StyledMenuButton = styled(IconButton)`
  padding: ${({ theme }) => theme.padding.xxs};

  background-color: ${({ theme }) => theme.color.darkBlue};
  border-color: ${({ theme }) => theme.color.darkBlue};
  border-radius: 50%;

  svg {
    stroke: ${({ theme }) => theme.color.darkBlue};
    fill: ${({ theme }) => theme.color.white};
  }

  &:focus {
    background-color: ${({ theme }) => theme.color.darkBlue};
    border-color: ${({ theme }) => theme.color.darkBlue};

    svg {
      stroke: ${({ theme }) => theme.color.darkBlue};
      fill: ${({ theme }) => theme.color.white};
    }
  }
`;
