import styled, { css } from 'styled-components';

import { Text } from '../../../../components/Typography';
import { Box } from '../../../../components/Box';
import { StyledTooltip, Tooltip } from '../../../../components/Tooltip';

export const StyledPrice = styled(Text)`
  font-weight: 600;
`;

export const StyledEarningsTooltip = styled(Tooltip)`
  @media (${({ theme }) => theme.breakpoints.tablet}) {
    position: static;

    ${StyledTooltip} {
      top: auto;
      margin-top: ${({ theme }) => theme.margin.xs};
    }
  }
`;

export const StyledEarningsText = styled(StyledPrice)<{
  earningsDeltaSign: number;
}>`
  ${({ earningsDeltaSign }) =>
    earningsDeltaSign > 0 &&
    css`
      color: ${({ theme }) => theme.color.green};

      &::before {
        content: '+';
      }
    `}
  ${({ earningsDeltaSign }) =>
    earningsDeltaSign < 0 &&
    css`
      color: ${({ theme }) => theme.color.secondaryRed};

      &::before {
        content: '-';
      }
    `}
    ${({ earningsDeltaSign }) =>
    earningsDeltaSign === 0 &&
    css`
      display: none;
    `}
`;

export const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

export const TooltipWrapper = styled.div`
  width: 430px;
  white-space: normal;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    width: calc(100vw - 120px);
  }
`;

export const TooltipIconWrapper = styled.div`
  display: flex;
  margin-left: 4px;
`;

export const Wrapper = styled.div`
  min-height: 110px;
  flex: 1;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    min-height: auto;
  }
`;
