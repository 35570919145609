import styled from 'styled-components';

import { Box } from '../Box';

export const RegistrationFormWrapper = styled(Box)`
  margin-top: ${({ theme }) => theme.margin.xl};

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    margin-top: ${({ theme }) => theme.margin.l};
  }
`;
