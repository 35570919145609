import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { AlertVariant } from '../../../components/Alert/Alert.types';
import { Box } from '../../../components/Box';
import { Button, LinkButton } from '../../../components/Button';
import { FullPageLoader } from '../../../components/FullPageLoader';
import { ContentWrapper, SignUpLayout } from '../../../components/Layout';
import { Heading, Text } from '../../../components/Typography';
import {
  useGetLeadOrder,
  usePostponeLeadOrder
} from '../../../shared/api/Order/Order.queries';
import { useAuth } from '../../../shared/auth/auth-context';
import { useNotification } from '../../../shared/utils/notifications/notification-context';
import { useQueryParams } from '../../../shared/utils/use-query-params';
import { ReactComponent as SuccessTick } from '../../../assets/images/SuccessTick.svg';
import { ErrorPage } from '../../ErrorPage';
import { ErrorPageType } from '../../ErrorPage/ErrorPage.types';
import { StyledSuccessBox } from './LetMeKnowNextTime.styles';
import { LeadOrderStatus } from '../../../shared/api/Order/Order.types';
import OrderInvalid from '../../OrderACoolspot/OrderInvalid';
import OrderNotFound from '../../OrderACoolspot/OrderNotFound';

export const LetMeKnowNextTime = () => {
  const history = useHistory();
  const { isLoggedIn } = useAuth();
  const { addNotification } = useNotification();
  const [{ leadOrderId, email }] = useQueryParams({
    leadOrderId: '',
    email: ''
  });

  const decodedEmail = decodeURIComponent(email);

  const hasPreviousRoute = history.length > 2;

  const goBack = () => {
    if (hasPreviousRoute) {
      history.goBack();
    } else if (isLoggedIn()) {
      history.push('/dashboard');
    } else {
      window.location.href = 'https://emrit.io';
    }
  };

  const {
    data: leadOrder,
    refetch: getLeadOrder,
    isLoading: isGetLeadOrderLoading,
    isError: isGetLeadOrderError
  } = useGetLeadOrder(leadOrderId, decodedEmail);

  const {
    mutate: postponeLeadOrder,
    isLoading,
    isSuccess
  } = usePostponeLeadOrder({
    onError: (error) => {
      addNotification({
        id: 'removeLeadOrderError',
        text: error.message,
        variant: AlertVariant.Danger
      });
    }
  });

  useEffect(() => {
    getLeadOrder();
  }, [getLeadOrder]);

  if (isGetLeadOrderLoading) {
    return <FullPageLoader />;
  }

  if (isGetLeadOrderError) {
    return <ErrorPage type={ErrorPageType.LinkInvalid} />;
  }

  if (leadOrder?.status === LeadOrderStatus.Transferred) {
    return <OrderNotFound />;
  }

  if (
    leadOrder?.status === LeadOrderStatus.NotResponded ||
    leadOrder?.status === LeadOrderStatus.Postponed
  ) {
    return (
      <SignUpLayout>
        <OrderInvalid
          email={email}
          leadOrderId={leadOrderId}
          leadOrderStatus={leadOrder.status}
        />
      </SignUpLayout>
    );
  }

  return (
    <SignUpLayout>
      {!isSuccess && (
        <ContentWrapper>
          <Heading size="h4" element="h1" variant="grey">
            <FormattedMessage
              id="letMeKnowNextTime.header"
              defaultMessage="Let me know next time"
            />
          </Heading>
          <Box mt="l">
            <Text level="small">
              <FormattedMessage
                id="letMeKnowNextTime.copy"
                defaultMessage="Are you sure you don’t want to order this CoolSpot™?"
              />
            </Text>
          </Box>
          <Box mt="xl">
            <Button
              isLoading={isLoading}
              isPrimary
              fullWidth
              onClick={() =>
                postponeLeadOrder({ leadOrderId, leadOrderEmail: email })
              }
            >
              <FormattedMessage
                id="letMeKnowNextTime.button"
                defaultMessage="Let me know next time"
              />
            </Button>
          </Box>
          <Box mt="m">
            <Button fullWidth isPlain onClick={goBack}>
              {hasPreviousRoute && (
                <FormattedMessage
                  id="letMeKnowNextTime.back"
                  defaultMessage="Back"
                />
              )}

              {!hasPreviousRoute && (
                <FormattedMessage
                  id="removeFromWaitList.cancel"
                  defaultMessage="Cancel"
                />
              )}
            </Button>
          </Box>
        </ContentWrapper>
      )}
      {isSuccess && (
        <ContentWrapper>
          <Heading size="h4" element="h1" variant="grey">
            <FormattedMessage
              id="letMeKnowNextTime.title"
              defaultMessage="Thank you for your interest."
            />
          </Heading>
          <Box mt="xl">
            <Text level="big">
              <FormattedMessage
                id="letMeKnowNextTime.subtitle"
                defaultMessage="You’re still on the CoolSpot™ waiting list. We will notify you next time when we have a CoolSpot™ for you."
              />
            </Text>
          </Box>
          <StyledSuccessBox mt="xl">
            <SuccessTick />
          </StyledSuccessBox>
          <Box mt="xl">
            <LinkButton
              to={isLoggedIn() ? '/dashboard' : '/login'}
              fullWidth
              isPrimary
            >
              <FormattedMessage
                id="letMeKnowNextTime.doneButtonLabel"
                defaultMessage="Done"
              />
            </LinkButton>
          </Box>
        </ContentWrapper>
      )}
    </SignUpLayout>
  );
};
