import { FC } from 'react';

import { InputDisplay } from './VerificationInput.styles';
import { VerificationInputProps } from './VerificationInput.types';
import { FieldCaption, FieldVariant } from '../FieldCaption';

export const VerificationInput: FC<VerificationInputProps> = ({
  error,
  name,
  ...rest
}) => {
  return (
    <>
      <InputDisplay
        fields={6}
        name={name}
        inputMode="numeric"
        hasError={!!error}
        autoFocus={false}
        {...rest}
      />
      {error && (
        <FieldCaption variant={FieldVariant.ERROR}>{error}</FieldCaption>
      )}
    </>
  );
};
