import { useId } from '@reach/auto-id';
import { forwardRef } from 'react';

import { StyledLabel, TextAreaWrapper } from './TextArea.styles';
import { getVariant } from '../../shared/utils/style-utils';
import { FieldCaption, FieldVariant } from '../FieldCaption';
import { TextAreaProps } from './TextArea.types';
import { StyledInput } from '../Input/BasicInput/BasicInput.styles';

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      error,
      label,
      isValid = false,
      caption,
      disabled = false,
      maxLines = 1,
      ...props
    },
    ref
  ) => {
    const id = useId(props.id);
    const variant = getVariant<Exclude<FieldVariant, FieldVariant.PREFILLED>>({
      [FieldVariant.DISABLED]: disabled,
      [FieldVariant.ERROR]: !!error,
      [FieldVariant.VALID]: isValid
    });

    return (
      <TextAreaWrapper>
        {label && (
          <StyledLabel variant={variant} htmlFor={id}>
            {label}
          </StyledLabel>
        )}
        <StyledInput
          as="textarea"
          id={id}
          disabled={disabled}
          rows={maxLines}
          aria-invalid={!!error || undefined}
          ref={ref}
          variant={variant}
          {...props}
        />
        {(caption || error) && (
          <FieldCaption variant={variant}>{error || caption}</FieldCaption>
        )}
      </TextAreaWrapper>
    );
  }
);
