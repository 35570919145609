import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import ReactMapGL, { MapRef, Marker } from 'react-map-gl';
import { GeocodeResult, getGeocode } from 'use-places-autocomplete';
import formatcoords from 'formatcoords';

import { FieldCaption, FieldVariant } from '../FieldCaption';
import {
  GoToPinButton,
  LatLngPickerContainer,
  MapWrapper
} from './LatLngPicker.styles';
import { LatLngPickerProps, MarkerPosition } from './LatLngPicker.types';
import { Pin } from './Pin/Pin';
import { Icon } from '../Icon';

const plusCodeRegex = /([A-Z]|[0-9])+\+([A-Z]|[0-9])+/;

const formatAddress = (
  address: string,
  latitude: number,
  longitude: number
): string =>
  address.replace(plusCodeRegex, formatcoords(latitude, longitude).format());

export const LatLngPicker = forwardRef<any, LatLngPickerProps>(
  ({ lat, lng, onChange, error }, ref) => {
    const mapRef = useRef<MapRef>(null);
    const [viewport, setViewport] = useState<any>({
      latitude: 0,
      longitude: 0,
      zoom: 1
    });

    const [marker, setMarker] = useState<MarkerPosition>({
      latitude: 0,
      longitude: 0
    });

    useEffect(() => {
      if (!lat || !lng) {
        return;
      }

      setViewport({
        ...viewport,
        latitude: lat,
        longitude: lng,
        zoom: 17
      });

      setMarker({
        latitude: lat,
        longitude: lng
      });

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lat, lng]);

    const onMarkerDragEnd = useCallback(
      async ({ lngLat }) => {
        const [longitude, latitude] = lngLat;

        setMarker({
          longitude,
          latitude
        });

        if (!Number.isFinite(latitude) || !Number.isFinite(longitude)) {
          return;
        }

        const geoData = (
          (await getGeocode({
            location: { lat: latitude, lng: longitude }
          })) as GeocodeResult[]
        )?.[0];

        const componentTypes = Array.from(
          geoData.address_components.reduce((uniqueTypes, { types }) => {
            types.forEach((type) => uniqueTypes.add(type));

            return uniqueTypes;
          }, new Set())
        ).flat() as String[];

        const isAddressInaccurate = componentTypes.includes('plus_code');

        if (onChange) {
          onChange({
            ...geoData,
            ...(isAddressInaccurate && {
              formatted_address: formatAddress(
                geoData.formatted_address,
                latitude,
                longitude
              )
            })
          });
        }
      },
      [onChange]
    );

    const navigateToMarker = () => {
      setViewport({
        ...viewport,
        latitude: marker.latitude,
        longitude: marker.longitude,
        zoom: 17
      });
    };

    const showMarker = Boolean(marker.latitude && marker.latitude);

    return (
      <LatLngPickerContainer>
        <MapWrapper ref={ref} isError={!!error}>
          <ReactMapGL
            {...viewport}
            ref={mapRef}
            width="100%"
            height="100%"
            mapStyle="mapbox://styles/mapbox/light-v9"
            mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_API || ''}
            dragRotate={false}
            touchRotate={false}
            dragPan={showMarker}
            scrollZoom={false}
            doubleClickZoom={false}
            touchZoom={false}
            keyboard={false}
            onViewportChange={setViewport}
            minZoom={1}
            maxZoom={17}
            attributionControl={false}
          >
            {showMarker && (
              <>
                <GoToPinButton type="button" onClick={navigateToMarker}>
                  <Icon icon="Pin" />
                </GoToPinButton>
                <Marker
                  longitude={marker.longitude}
                  latitude={marker.latitude}
                  draggable
                  onDragEnd={onMarkerDragEnd}
                >
                  <Pin />
                </Marker>
              </>
            )}
          </ReactMapGL>
        </MapWrapper>
        {error?.message && (
          <FieldCaption variant={FieldVariant.ERROR}>
            {error?.message}
          </FieldCaption>
        )}
      </LatLngPickerContainer>
    );
  }
);
