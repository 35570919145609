import {
  FocusEvent,
  forwardRef,
  KeyboardEvent,
  useEffect,
  useState
} from 'react';
import usePlacesAutocomplete, {
  GeocodeResult,
  getGeocode
} from 'use-places-autocomplete';
import debounce from 'lodash.debounce';

import { Input } from '../Input';
import {
  AddressSearchContainer,
  StyledDropdown,
  StyledOption
} from './AddressSearch.styles';
import { AddressSearchProps } from './AddressSearch.types';

export const AddressSearch = forwardRef<HTMLInputElement, AddressSearchProps>(
  (
    { onChange, searchCountry, onBlur, value, icon = 'Pin', disabled, ...rest },
    ref
  ) => {
    const [inputValue, setInputValue] = useState<string>(
      value?.formatted_address || ''
    );
    const [showDropdown, setShowDropdown] = useState<boolean>(false);
    const [selectedGeoData, setSelectedGeoData] = useState<any>(value);

    useEffect(() => {
      if (value !== undefined) {
        setInputValue(value?.formatted_address || '');
      }
    }, [value]);

    const {
      ready,
      suggestions: { data },
      setValue
    } = usePlacesAutocomplete({
      debounce: 500,
      requestOptions: {
        componentRestrictions: searchCountry
          ? {
              country: searchCountry
            }
          : undefined,
        types: ['address']
      }
    });

    const handleChange = (event: any) => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { value } = event.target;

      setInputValue(value);

      if (value !== inputValue) {
        setShowDropdown(true);
      }

      if (value !== selectedGeoData?.formatted_address && onChange) {
        onChange(undefined);
      }

      setValue(value);
    };

    const handleSelect = async (item: any) => {
      let geoData;

      try {
        geoData = (
          (await getGeocode({ placeId: item.place_id })) as GeocodeResult[]
        )?.[0];

        setInputValue(geoData?.formatted_address);
        setSelectedGeoData(geoData);
      } catch (error) {
        setInputValue(item.description);
      }

      if (typeof onChange === 'function') {
        onChange(geoData);
      }
    };

    const handleOnBlur = debounce((event: FocusEvent<HTMLInputElement>) => {
      setTimeout(() => setShowDropdown(false), 500);

      if (typeof onBlur === 'function') {
        onBlur(event);
      }
    }, 250);

    const handleOnKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    };

    return (
      <AddressSearchContainer>
        <Input
          autoComplete="off"
          ref={ref}
          name="address-search"
          iconLeft={icon}
          onChange={handleChange}
          disabled={!ready || disabled}
          onFocus={handleChange}
          onBlur={handleOnBlur}
          value={inputValue}
          onKeyDown={handleOnKeyDown}
          {...rest}
        />
        {data.length > 0 && showDropdown && (
          <StyledDropdown>
            {data.map((result) => (
              <StyledOption
                key={result.place_id}
                onClick={() => handleSelect(result)}
              >
                {result.description}
              </StyledOption>
            ))}
          </StyledDropdown>
        )}
      </AddressSearchContainer>
    );
  }
);
