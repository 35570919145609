import styled from 'styled-components';

import { getTypography } from '../../shared/utils/style-utils';
import { TableCellProps } from './Table.types';

export const Table = styled.table`
  width: 100%;
  table-layout: fixed;

  margin-top: ${({ theme }) => theme.margin.m};
  border-collapse: collapse;
  color: ${({ theme }) => theme.color.grey};
`;

export const TableCell = styled.td<TableCellProps>`
  padding: ${({ theme }) => theme.padding.m};

  border-bottom: 1px solid ${({ theme }) => theme.color.lightGrey};

  text-align: ${({ align = 'left' }) => align};

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`;

export const TableHeadCell = styled(TableCell).attrs({
  as: 'th'
})`
  padding-top: ${({ theme }) => theme.padding.xs};
  padding-bottom: ${({ theme }) => theme.padding.xs};
  vertical-align: top;

  border-bottom: 0;

  ${({ theme }) => getTypography(theme.typography.subtitle.small)};

  color: ${({ theme }) => theme.color.grey};
`;

export const TableRow = styled.tr``;

export const TableBody = styled.tbody``;

export const TableHead = styled.thead``;
