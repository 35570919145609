import {
  addToWaitlist,
  getCurrentUser,
  legacyCodeSend,
  legacyCodeVerify,
  login,
  logout,
  passwordCodeSend,
  passwordCodeVerify,
  passwordReset,
  signUp,
  userEmailVerify,
  usersEmailResend,
  verifyEmail,
  waitlistEmailResend,
  waitlistEmailVerify
} from './identity';
import { getCountries, getLeadOrders, getOrders, placeOrder } from './order';
import {
  coolspots,
  currencies,
  editWallet,
  getTotalEarnings,
  walletAddress,
  walletBalance
} from './wallet';

export const handlers = [
  login,
  legacyCodeVerify,
  logout,
  getCountries,
  walletAddress,
  currencies,
  legacyCodeSend,
  coolspots,
  editWallet,
  getCurrentUser,
  signUp,
  userEmailVerify,
  waitlistEmailVerify,
  waitlistEmailResend,
  usersEmailResend,
  passwordCodeSend,
  passwordCodeVerify,
  passwordReset,
  verifyEmail,
  placeOrder,
  addToWaitlist,
  walletBalance,
  getLeadOrders,
  getOrders,
  getTotalEarnings
];
