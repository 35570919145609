import { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { AlertVariant } from '../../../../components/Alert/Alert.types';
import { Box } from '../../../../components/Box';
import { Button } from '../../../../components/Button';
import { BUTTON_SIZE } from '../../../../components/Button/Button.types';
import { CreateLeadUserFormValues } from '../../../../components/CreateLeadUserForm/CreateLeadUserForm.types';
import {
  InstallationAddressForm,
  SubmissionError
} from '../../../../components/InstallationAddressForm';
import { ContentWrapper } from '../../../../components/Layout';
import { Heading, Subtitle } from '../../../../components/Typography';
import { ErrorCodes } from '../../../../shared/api/error-codes';
import { HTTPError } from '../../../../shared/api/fetcher.types';
import {
  useCreateLeadUserAccount,
  useSendOneTimeAuthCode
} from '../../../../shared/api/Identity/Identity.queries';
import { useValidateLeadOrderInstallationAddress } from '../../../../shared/api/Order/Order.queries';
import { useNotification } from '../../../../shared/utils/notifications/notification-context';
import {
  userTracking,
  UserTrackingEvent
} from '../../../../shared/utils/user-tracking';
import { Nullable } from '../../../../types/common';
import {
  InstallationAddressFormValues,
  InstallationAddressProps
} from './InstallationAddress.types';

export const InstallationAddress: FC<InstallationAddressProps> = ({
  previousStepValues,
  handleNextStep,
  handlePreviousStep,
  countryData
}) => {
  const { addNotification } = useNotification();
  const [formData, setFormData] =
    useState<Nullable<InstallationAddressFormValues>>(null);
  const [submissionError, setSubmissionError] = useState<SubmissionError>();

  useEffect(() => {
    userTracking(UserTrackingEvent.NewUser);
  }, []);

  const {
    mutate: createLeadUserAccount,
    isLoading: isCreateLeadUserAccountLoading
  } = useCreateLeadUserAccount({
    onError: (error: HTTPError) => {
      if (error.errorCode === ErrorCodes.EMAIL_IS_ALREADY_IN_USE) {
        addNotification({
          id: 'waitBeforeResendError',
          variant: AlertVariant.Danger,
          text: error.message
        });
      } else {
        setSubmissionError({
          fieldName: 'installationAddress',
          message: error.message
        });
      }
    },
    onSuccess: async (_response, variables) => {
      if (handleNextStep) {
        handleNextStep({ ...previousStepValues, ...formData, ...variables });
      }
    }
  });

  const { mutate: sendOneTimeAuthCode } = useSendOneTimeAuthCode();

  const { mutate: validateInstallationAddress } =
    useValidateLeadOrderInstallationAddress({
      onError: (error) => {
        setSubmissionError({
          fieldName: 'installationAddress',
          message: error.message
        });
      },
      onSuccess: async (response, variables) => {
        const { existingLeadOrderId } = response;

        if (existingLeadOrderId) {
          await sendOneTimeAuthCode(previousStepValues?.email);

          handleNextStep?.({
            ...previousStepValues,
            ...formData,
            ...variables,
            existingLeadOrderId
          });
        } else {
          await createLeadUserAccount({
            ...previousStepValues,
            ...variables
          } as CreateLeadUserFormValues);
        }
      }
    });

  const handleSubmit = (data: InstallationAddressFormValues) => {
    const selectedCountry = data.installationAddress?.address_components.find(
      ({ types }) => types.includes('country')
    );
    const countryCode = selectedCountry?.short_name;

    const newData = {
      ...data,
      countryIsSupported: !!countryData?.find(
        ({ code }) => code === countryCode
      )?.isSupported
    };

    setFormData(newData);

    if (data.installationAddress) {
      validateInstallationAddress({
        email: previousStepValues?.email,
        installationAddress: data.installationAddress
      });
    }
  };

  return (
    <ContentWrapper>
      <Heading size="h4" element="h1" variant="grey">
        <FormattedMessage
          id="installationAddress.title"
          defaultMessage="CoolSpot™ Installation Address"
        />
      </Heading>
      <Box mt="m">
        <Subtitle size="big" variant="grey">
          <FormattedMessage
            id="installationAddress.subtitle"
            defaultMessage="Start with typing installation address for your CoolSpot™."
          />
        </Subtitle>
        <Subtitle size="big" variant="grey">
          <FormattedMessage
            id="installationAddress.subtitle2"
            defaultMessage="After that you can move the pin on the map to clarify the installation address."
          />
        </Subtitle>
      </Box>
      <InstallationAddressForm
        onSubmit={handleSubmit}
        isLoading={isCreateLeadUserAccountLoading}
        submissionError={submissionError}
      />
      <Box mt="m">
        <Button
          onClick={handlePreviousStep}
          isPlain
          isPrimary={false}
          fullWidth
          size={BUTTON_SIZE.BIG}
        >
          <FormattedMessage
            id="installationAddress.backButton"
            defaultMessage="Back"
          />
        </Button>
      </Box>
    </ContentWrapper>
  );
};
