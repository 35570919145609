import styled, { css } from 'styled-components';

import { getTypography } from '../../../shared/utils/style-utils';
import { CaptionProps } from './Caption.types';

export const StyledCaption = styled.span<CaptionProps>`
  ${({ theme }) => getTypography(theme.typography.caption)};

  ${({ theme, color }) =>
    color &&
    css`
      color: ${theme.color[color]};
    `};
`;
