import { FC } from 'react';

import { Icon } from '../../Icon';
import { LeftElementWrapper, RightElementWrapper } from '../Button.styles';
import { BUTTON_SIZE } from '../Button.types';
import { StyledLinkButton } from './LinkButton.styles';
import { LinkButtonProps } from './LinkButton.types';

export const LinkButton: FC<LinkButtonProps> = ({
  external,
  to,
  size = BUTTON_SIZE.BIG,
  isPrimary = true,
  fullWidth = false,
  children,
  variant,
  iconLeft,
  iconRight,
  openInNewTab,
  ...rest
}) => {
  const renderContent = () => (
    <>
      {iconLeft && (
        <LeftElementWrapper size={size}>
          <Icon icon={iconLeft} />
        </LeftElementWrapper>
      )}
      {children}
      {iconRight && (
        <RightElementWrapper size={size}>
          <Icon icon={iconRight} />
        </RightElementWrapper>
      )}
    </>
  );

  if (external) {
    return (
      <StyledLinkButton
        as="a"
        variant={variant}
        isPrimary={isPrimary}
        size={size}
        fullWidth={fullWidth}
        href={to as string}
        {...(openInNewTab &&
          external && {
            rel: 'noopener',
            target: '_blank'
          })}
        {...rest}
      >
        {renderContent()}
      </StyledLinkButton>
    );
  }

  return (
    <StyledLinkButton
      variant={variant}
      isPrimary={isPrimary}
      size={size}
      fullWidth={fullWidth}
      to={to}
      {...rest}
    >
      {renderContent()}
    </StyledLinkButton>
  );
};
