import { FC } from 'react';

import { Icon } from '../Icon';
import { Address, StyledAddressBox } from './AddressBox.styles';
import { AddressBoxProps } from './AddressBox.types';

export const AddressBox: FC<AddressBoxProps> = ({
  children,
  isSmall,
  ...props
}) => (
  <StyledAddressBox {...props}>
    <Icon icon="Location" />
    <Address isSmall>{children}</Address>
  </StyledAddressBox>
);
