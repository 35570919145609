import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Redirect, useHistory } from 'react-router-dom';

import { AlertVariant } from '../../../components/Alert/Alert.types';
import { FullPageLoader } from '../../../components/FullPageLoader';
import { LoginLayout } from '../../../components/Layout';
import { useVerifyLegacyUser } from '../../../shared/api/Identity/Identity.queries';
import { useGetCountries } from '../../../shared/api/Order';
import { useAuth } from '../../../shared/auth/auth-context';
import { useNotification } from '../../../shared/utils/notifications/notification-context';
import { ErrorPage } from '../../ErrorPage';
import { CreateLegacyAccount } from './CreateLegacyAccount';
import { LegacySignupData } from './LegacySignup.types';

export const LegacySignUp: FC = () => {
  const countryQuery = useGetCountries();
  const { login } = useAuth();
  const history = useHistory();
  const { legacySignupData } =
    (history.location.state as {
      legacySignupData?: LegacySignupData;
    }) || {};
  const { email, verificationCode } = legacySignupData || {};
  const { addNotification } = useNotification();
  const { formatMessage } = useIntl();

  const {
    data: userData,
    mutate: verifyLegacyUser,
    isLoading: isVerifyLegacyUserLoading,
    isError: isVerifyLegacyUserError
  } = useVerifyLegacyUser({
    onSuccess() {
      addNotification({
        id: 'legacySignUp.welcomeBackNotification',
        text: formatMessage({
          id: 'legacySignUp.welcomeBackNotification',
          defaultMessage:
            'Welcome back! We have updated the Emrit application. Please confirm your info.'
        }),
        variant: AlertVariant.Success
      });
    }
  });

  useEffect(() => {
    if (email && verificationCode) {
      verifyLegacyUser({ email: email!, code: verificationCode! });
    }
  }, [email, verificationCode, verifyLegacyUser]);

  if (!email || !verificationCode || isVerifyLegacyUserError) {
    return <Redirect to="/login" />;
  }

  if (countryQuery.isLoading || login.isLoading || isVerifyLegacyUserLoading) {
    return <FullPageLoader />;
  }

  if (countryQuery.isError || countryQuery.isIdle) {
    return <ErrorPage />;
  }

  const supportedCountries = countryQuery.data.filter(
    (country) => country.isSupported
  );

  return (
    <LoginLayout>
      <CreateLegacyAccount
        verificationCode={verificationCode!}
        userData={userData}
        countries={supportedCountries}
      />
    </LoginLayout>
  );
};
