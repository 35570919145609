import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Box } from '../../../../components/Box';
import { Heading } from '../../../../components/Typography';
import { Icon } from '../../../../components/Icon';
import {
  StyledDashboardBox,
  StyledSubtitle,
  TabletContainer,
  TabletDashboardBox,
  TabletPrice
} from './MyPayoutWallet.styles';
import { formatHelium } from '../../../../shared/utils/format-helium';
import { useBreakpoints } from '../../../../shared/utils/use-breakpoints';
import {
  useGetBalanceData,
  useGetWalletAddress
} from '../../../../shared/api/Wallet/Wallet.queries';
import { Button } from '../../../../components/Button';
import { BUTTON_SIZE } from '../../../../components/Button/Button.types';

export const MyPayoutWallet: FC = () => {
  const { isDesktop } = useBreakpoints();
  const { data: balanceData, isLoading: isGetBalanceDataLoading } =
    useGetBalanceData();
  const { data: walletAddressData, isLoading: isGetWalletAddressLoading } =
    useGetWalletAddress({ cacheTime: 0, retry: false });

  const balance = balanceData?.balance || 0;

  const history = useHistory();

  const hasWalletAddress = !!walletAddressData?.heliumWalletId;

  const isLoading = isGetBalanceDataLoading || isGetWalletAddressLoading;

  if (!hasWalletAddress) {
    return (
      <StyledDashboardBox variant="blue" isLoading={isLoading}>
        <StyledSubtitle size="big" variant="white">
          <Icon icon="Wallet" color="white" />
          <FormattedMessage
            id="myPayoutWallet.subtitle1"
            defaultMessage="My wallet balance"
          />
        </StyledSubtitle>
        <Box mt="s">
          <FormattedMessage
            id="myPayoutWallet.noWalletTitle"
            defaultMessage="There is no wallet added to your account."
          />
        </Box>
        <Box mt="xs">
          <Button
            iconLeft="Plus"
            isPrimary
            variant="blueOverLightBlueBackground"
            size={BUTTON_SIZE.SMALL}
            onClick={() => history.push('/edit-wallet')}
          >
            <FormattedMessage
              id="myPayoutWallet.addWallet"
              defaultMessage="Update wallet"
            />
          </Button>
        </Box>
      </StyledDashboardBox>
    );
  }

  if (isDesktop) {
    return (
      <TabletDashboardBox variant="blue" isLoading={isLoading}>
        <TabletContainer>
          <StyledSubtitle size="big" variant="white">
            <Icon icon="Wallet" color="#ffffff" />
            <FormattedMessage
              id="myPayoutWallet.subtitle1"
              defaultMessage="My wallet balance"
            />
          </StyledSubtitle>
          <TabletPrice level="big">{formatHelium(balance)}</TabletPrice>
        </TabletContainer>
      </TabletDashboardBox>
    );
  }

  return (
    <StyledDashboardBox variant="blue" isLoading={isLoading}>
      <StyledSubtitle size="big" variant="white">
        <Icon icon="Wallet" color="white" />
        <FormattedMessage
          id="myPayoutWallet.subtitle1"
          defaultMessage="My wallet balance"
        />
      </StyledSubtitle>
      <Box mt="s">
        <Heading size="h4" element="h2">
          {formatHelium(balance)}
        </Heading>
      </Box>
    </StyledDashboardBox>
  );
};
