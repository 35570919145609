import { FC, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll
} from 'body-scroll-lock';
import { useQueryClient } from 'react-query';
import ReactMarkdown from 'react-markdown';
import { QueryFilters } from 'react-query/types/core/utils';

import { Box } from '../../../../components/Box';
import { Modal, OverlayStyle } from '../../../../components/Modal';
import { Heading, Text } from '../../../../components/Typography';
import { AcceptTermsAndConditionsForm } from './AcceptTermsAndConditionsForm';
import { Container, Content, Header } from './TermsAndConditions.styles';
import {
  useAcceptTermsAndConditions,
  useGetTermsAndConditions
} from '../../../api/Identity/Identity.queries';
import { useAuth } from '../../../auth/auth-context';
import { useNotification } from '../../notifications/notification-context';
import { AlertVariant } from '../../../../components/Alert/Alert.types';

const queriesPredicate = ({ queryKey }: QueryFilters) => {
  const authQueryKeys = ['logout', 'initializeAuth'];

  if (Array.isArray(queryKey)) {
    return true;
  }

  return !authQueryKeys.includes(queryKey as string);
};

export const TermsAndConditions: FC<{
  showTermsAndConditions: boolean;
  setShowTermsAndConditions: (showTermsAndConditions: boolean) => void;
}> = ({ showTermsAndConditions, setShowTermsAndConditions }) => {
  const { isLoggedIn, logout } = useAuth();
  const { addNotification } = useNotification();
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();
  const {
    data: termsAndConditions,
    isLoading: isGetTermsAndConditionsLoading
  } = useGetTermsAndConditions();

  const { mutate, isLoading: isUpdateCurrentUserLoading } =
    useAcceptTermsAndConditions({
      onSuccess: async () => {
        await queryClient.refetchQueries(['refreshToken']);
        setShowTermsAndConditions(false);

        await queryClient.refetchQueries({
          predicate: queriesPredicate
        });
      }
    });

  const acceptTermsAndConditions = () => {
    mutate({ termsAndConditions: true });
  };

  const handleDecline = () => {
    logout.refetch();

    addNotification({
      id: 'termsAndConditionsDeclined',
      text: formatMessage({
        id: 'termsAndConditions.declinedNotification',
        defaultMessage:
          "You've been logged out because you rejected our terms and conditions.\n If this was in error, please login and accept."
      }),
      variant: AlertVariant.Warning
    });
  };

  const contentRef = useRef<HTMLDivElement>(null);

  const handleOnAfterOpen = () => {
    if (contentRef.current) {
      disableBodyScroll(contentRef.current);
    }
  };

  const handleOnAfterClose = () => {
    if (contentRef.current) {
      enableBodyScroll(contentRef.current);
    }
  };

  useEffect(() => {
    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  useEffect(() => {
    const cancelAllQueries = async () => {
      await queryClient.cancelQueries({
        predicate: queriesPredicate
      });
    };

    if (showTermsAndConditions && !isGetTermsAndConditionsLoading) {
      cancelAllQueries();
    }
  }, [isGetTermsAndConditionsLoading, queryClient, showTermsAndConditions]);

  if (!isLoggedIn()) {
    return null;
  }

  return (
    <Modal
      isOpen={showTermsAndConditions && !isGetTermsAndConditionsLoading}
      hasCloseButton={false}
      setIsOpen={setShowTermsAndConditions}
      overlayStyle={OverlayStyle.Blur}
      maxWidth={1008}
      onAfterOpen={handleOnAfterOpen}
      onAfterClose={handleOnAfterClose}
      preventScroll
    >
      <Container>
        <Header>
          <Heading size="h4" element="h2" variant="grey">
            <FormattedMessage
              id="termsAndConditions.modalTitle"
              defaultMessage="We have changed the Terms of Service"
            />
          </Heading>
          <Box mt="xs">
            <Text level="big">
              <FormattedMessage
                id="termsAndConditions.modalSubtitle"
                defaultMessage="To proceed further please accept the following:"
              />
            </Text>
          </Box>
        </Header>
        <Content ref={contentRef}>
          {termsAndConditions?.data && (
            <ReactMarkdown>{termsAndConditions.data}</ReactMarkdown>
          )}
        </Content>
        <AcceptTermsAndConditionsForm
          onSubmit={acceptTermsAndConditions}
          onDecline={handleDecline}
          isLoading={isUpdateCurrentUserLoading}
        />
      </Container>
    </Modal>
  );
};
