import styled from 'styled-components';

import { Box } from '../../../components/Box';
import { StyledLinkButton } from '../../../components/Button/LinkButton/LinkButton.styles';

export const ButtonsWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;

  @media (${({ theme }) => theme.breakpoints.phone}) {
    ${StyledLinkButton} {
      padding: 10px 20px;
      margin-right: 15px;
      font-size: 14px;
    }
  }
`;
