import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { AlertVariant } from '../../../../components/Alert/Alert.types';
import { Box } from '../../../../components/Box';
import { Button } from '../../../../components/Button';
import { ValidateLayout } from '../../../../components/Layout/ValidateLayout/ValidateLayout';
import { VerificationInput } from '../../../../components/VerificationInput';
import {
  useGetLeadUserByEmail,
  useSendOneTimeAuthCode
} from '../../../../shared/api/Identity/Identity.queries';
import { useNotification } from '../../../../shared/utils/notifications/notification-context';
import { InputWrapper } from './EmailValidate.styles';
import { ErrorCodes } from '../../../../shared/api/error-codes';
import { GetLeadUserByEmailResponse } from '../../../../shared/api/Identity/Identity.types';

interface EmailValidateProps {
  email: string;
  onVerifySuccess: (leadUser: GetLeadUserByEmailResponse) => void;
  onBackClick?: () => void;
}

export const EmailValidate: FC<EmailValidateProps> = ({
  email,
  onVerifySuccess,
  onBackClick
}) => {
  const {
    formState: { errors },
    register,
    handleSubmit,
    setValue,
    setError
  } = useForm<{ code: string }>({
    mode: 'onBlur'
  });
  const { formatMessage } = useIntl();
  const { addNotification } = useNotification();

  useEffect(() => {
    register('code', { required: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { mutate: sendOneTimeAuthCode } = useSendOneTimeAuthCode({
    onError: (error) => {
      const { message, statusCode } = error.response?.data || {};

      if (statusCode >= 400) {
        addNotification({
          id: 'resendCodeError',
          text: message,
          variant: AlertVariant.Danger
        });
      }
    },
    onSuccess: async () => {
      addNotification({
        id: 'emailVerifySuccess',
        text: formatMessage({
          id: 'emailVerifySuccess',
          defaultMessage:
            'Please check your email inbox for a verification code.'
        }),
        variant: AlertVariant.Success
      });
    }
  });

  const { mutate: getLeadUserByEmail, isLoading: isVerifyCodeLoading } =
    useGetLeadUserByEmail({
      onSuccess: onVerifySuccess,
      onError: (apiError) => {
        setError('code', {
          message: apiError.data.message,
          type: 'manual'
        });

        if (
          apiError?.data?.statusCode >= 400 &&
          apiError?.data?.errorCode !== ErrorCodes.INVALID_VERIFICATION_CODE
        ) {
          addNotification({
            id: 'verifyCodeError',
            text: apiError.data.message,
            variant: AlertVariant.Danger
          });
        }
      }
    });

  return (
    <ValidateLayout
      enteredEmail={email}
      onResendCode={() => sendOneTimeAuthCode(email)}
    >
      <form
        onSubmit={handleSubmit(({ code }) =>
          getLeadUserByEmail({
            code,
            email
          })
        )}
      >
        <InputWrapper>
          <VerificationInput
            name="Verification Code"
            error={errors.code?.message}
            onChange={async (value) => {
              setValue('code', value);
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <Button
            isPrimary
            fullWidth
            isLoading={isVerifyCodeLoading}
            type="submit"
          >
            <FormattedMessage
              id="resetPassword2.verify"
              defaultMessage="Verify"
            />
          </Button>
        </InputWrapper>
      </form>
      <Box mt="s">
        <Button
          fullWidth
          onClick={() => onBackClick?.()}
          disabled={isVerifyCodeLoading}
        >
          <FormattedMessage id="resetPassword2.back" defaultMessage="Back" />
        </Button>
      </Box>
    </ValidateLayout>
  );
};
