import styled, { css } from 'styled-components';

import { getTypography } from '../../../../shared/utils/style-utils';

export const tooltipMenuItemStyle = css`
  width: 100%;
  background-color: transparent;
  border: none;
  cursor: pointer;
  appearance: none;
  text-align: left;

  ${({ theme }) => getTypography(theme.typography.body.big)};
  font-family: inherit;
  font-weight: 400;

  color: ${({ theme }) => theme.color.grey};

  display: flex;
  align-items: center;

  padding: 12px 20px;

  &:hover {
    background-color: ${({ theme }) => theme.color.secondaryWhiteBlue};
    color: ${({ theme }) => theme.color.darkBlue};
  }

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    display: flex;
    justify-content: center;

    width: auto;
  }
`;

export const StyledTooltipMenuItem = styled.button`
  ${tooltipMenuItemStyle}
`;

export const TextWrapper = styled.span``;

export const TooltipMenuListItem = styled.li``;
