import { IDENTITY_SERVICE } from '../api/endpoints';
import { HTTPError } from '../api/fetcher.types';

const headers = {
  'Content-Type': 'application/json'
};

const JWTName = 'jwt-token';

class AuthService {
  getToken = () => {
    return localStorage.getItem(JWTName) || '';
  };

  setToken = (token: string) => {
    localStorage.setItem(JWTName, token);
  };

  resetToken = () => {
    localStorage.setItem(JWTName, '');
  };

  logout = async () => {
    const response = await fetch(`${IDENTITY_SERVICE}/auth/logout`, {
      headers: { Authorization: `Bearer ${this.getToken()}`, ...headers },
      credentials: 'include',
      method: 'POST'
    });

    this.resetToken();

    if (response?.statusText === 'No Content') {
      return false;
    }

    const data = await response.json();

    if (!response.ok) {
      throw new HTTPError(data);
    }

    return true;
  };

  refreshToken = async () => {
    const response = await fetch(`${IDENTITY_SERVICE}/auth/tokens`, {
      headers: {
        ...headers
      },
      credentials: 'include',
      method: 'POST'
    });

    const data = await response.json();

    if (!response.ok) {
      throw new HTTPError(data);
    }

    this.setToken(data.jwt);

    return data;
  };

  login = async (credentials: { email: string; password: string }) => {
    const { email, password } = credentials;

    const response = await fetch(`${IDENTITY_SERVICE}/auth/login`, {
      body: JSON.stringify({ email, password }),
      headers: {
        ...headers
      },
      credentials: 'include',
      method: 'POST'
    });

    const data = await response.json();

    if (!response.ok) {
      throw new HTTPError(data);
    }

    this.setToken(data.jwt);

    return data;
  };
}

export default new AuthService();
