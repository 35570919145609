import styled from 'styled-components';

import { Box } from '../../../components/Box';
import { Caption } from '../../../components/Typography';

export const StyledBox = styled(Box)`
  display: flex;
  gap: 8px;
  white-space: normal;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }
`;

export const InformationPane = styled.div`
  display: flex;
  height: 100%;
`;

export const DashboardCaption = styled(Caption)`
  color: ${({ theme }) => theme.color.grey};
`;
