import styled from 'styled-components';

import { ReactComponent as CoolspotImage } from '../../../../assets/images/SignUp.svg';
import { Logo } from '../../../Logo';

export const StyledHeroSection = styled.div`
  background-color: ${({ theme }) => theme.color.blue};
  height: 30%;

  padding-top: 40px;

  display: none;

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    display: block;
  }
`;

export const ImageContainer = styled.div`
  position: relative;

  width: 60%;
  margin: 0 auto;

  display: flex;
  justify-content: center;
  align-items: flex-end;

  height: 100%;
`;

export const StyledLogoEmrit = styled(Logo)`
  position: absolute;
  top: 0;
  left: 0;

  height: 40px;
  width: 123px;
`;

export const StyledImage = styled(CoolspotImage)`
  height: 100%;

  @media (${({ theme }) => theme.breakpoints.phone}) {
    height: 80%;
  }
`;
