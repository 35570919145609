import styled, { css } from 'styled-components';

import { IconButton } from '../Button';
import { mobileMenuItemStyle } from './MobileMenuItem/MobileMenuItem.styles';

export const Divider = styled.hr`
  border: 0;
  border-top: 1px solid ${({ theme }) => theme.color.darkBlue};
`;

export const StyledMobileMenu = styled.ul<{
  isActive: boolean;
}>`
  list-style: none;

  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.color.blue};

  margin: 0;
  padding: ${({ theme }) => theme.padding.l};

  position: fixed;
  z-index: 11;
  top: 0;
  right: -100%;
  transition: all 0.2s ease-in-out;

  ${({ isActive }) =>
    isActive &&
    css`
      right: 0;
    `}
`;

export const MobileMenuAccordionHeader = styled.button<{
  isActive?: boolean;
}>`
  ${mobileMenuItemStyle}

  justify-content: space-between;

  border: none;

  svg {
    stroke: #fff;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${({ theme }) => theme.color.darkBlue};
    `}
`;

export const MobileMenuLabel = styled.span`
  display: inline-flex;
`;

export const MobileMenuSublist = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const StyledIconButton = styled(IconButton)`
  position: absolute;
  bottom: ${({ theme }) => theme.margin.m};
  right: ${({ theme }) => theme.margin.m};
  border-radius: 100%;
  background: ${({ theme }) => theme.color.darkBlue};

  svg {
    stroke: #fff;
  }
`;
