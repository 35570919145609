import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';

import { Box } from '../Box';
import { Button } from '../Button';
import { AddressSearch } from '../AddressSearch';
import {
  InstallationAddressBox,
  StyledButtonWrapper,
  StyledForm
} from './InstallationAddressForm.styles';
import {
  InstallationAddressFormProps,
  InstallationAddressFormValues
} from './InstallationAddressForm.types';
import { useShippingFormValidation } from './InstallationAddressForm.validation';
import { LatLngPicker } from '../LatLngPicker';
import { AddressBox } from '../AddressBox';
import { Caption } from '../Typography';

export const InstallationAddressForm: FC<InstallationAddressFormProps> = ({
  isLoading,
  onSubmit,
  submissionError
}) => {
  const { formatMessage } = useIntl();
  const {
    handleSubmit,
    control,
    setError,
    formState: { isValid },
    watch,
    clearErrors,
    setValue
  } = useForm<InstallationAddressFormValues>({
    mode: 'all',
    resolver: yupResolver(useShippingFormValidation())
  });

  useEffect(() => {
    if (submissionError) {
      const { fieldName, message } = submissionError;

      setError(fieldName, {
        message,
        type: 'manual'
      });
    }
  }, [submissionError, setError]);

  const handleAddressSearch = (searchPhrase: any) => {
    clearErrors();

    if (searchPhrase) {
      setValue('installationAddress', searchPhrase, { shouldValidate: true });
    }
  };

  const installationAddress = watch('installationAddress');

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <Box mt="s">
        <AddressSearch
          label={formatMessage({
            defaultMessage: 'Search for the address',
            id: 'shippingForm.addressLabel'
          })}
          onChange={handleAddressSearch}
          icon="Search"
        />
      </Box>
      <InstallationAddressBox mt="l">
        <Controller
          name="installationAddress"
          control={control}
          render={({
            field: { value, ref, onChange },
            fieldState: { error }
          }) => (
            <LatLngPicker
              ref={ref}
              error={error}
              onChange={onChange}
              lat={value?.geometry?.location?.lat()}
              lng={value?.geometry?.location?.lng()}
            />
          )}
        />
        {installationAddress?.formatted_address && (
          <Box pt="l" pr="l" pb="l" pl="l">
            <Caption>
              <FormattedMessage
                id="installationAddressForm.installationAddressCaption"
                defaultMessage="CoolSpot™ Installation address"
              />
            </Caption>
            <AddressBox isSmall>
              {installationAddress?.formatted_address}
            </AddressBox>
          </Box>
        )}
      </InstallationAddressBox>
      <StyledButtonWrapper>
        <Button
          isPrimary
          fullWidth
          disabled={!isValid}
          type="submit"
          isLoading={isLoading}
        >
          <FormattedMessage
            id="installationAddressForm.submitButton"
            defaultMessage="Qualify my location"
          />
        </Button>
      </StyledButtonWrapper>
    </StyledForm>
  );
};
