/* eslint-disable max-classes-per-file */
import { StringifyOptions } from 'query-string';

export type HttpMethod =
  | 'GET'
  | 'HEAD'
  | 'POST'
  | 'PUT'
  | 'PATCH'
  | 'OPTIONS'
  | 'DELETE';

export type HttpResponseType =
  | 'arrayBuffer'
  | 'blob'
  | 'formData'
  | 'json'
  | 'text';

export type HttpOptions = {
  baseUrl?: string;
  method?: HttpMethod;
  authorized?: boolean;
  jwt?: string;
  json?: { [key: string]: any };
  headers?: { [key: string]: any } & RequestInit['headers'];
  query?: { [key: string]: any };
  queryArrayFormat?: StringifyOptions['arrayFormat'];
} & RequestInit;

export type HttpResponse<T> = {
  url: string;
  status: number;
  data: T;
  response: Response;
};

export type FetchInstance<T> = {
  [key in HttpResponseType]: () => Promise<HttpResponse<T>>;
} & {
  flush: () => Promise<{ url: string; status: number; response: Response }>;
};

export class HTTPError extends Error {
  errorCode: number;

  message: string;

  statusCode: number;

  details: { errorCode: number; message: string }[];

  data: any;

  constructor(public response: any) {
    super(response.message);

    this.name = 'HTTPError';
    this.errorCode = response.errorCode;
    this.message = response.message;
    this.statusCode = response.statusCode;
    this.details = response.details;
    this.data = response.data;
  }
}
