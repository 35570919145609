import { FC, useMemo } from 'react';

import { DashboardLayout } from '../../components/Layout';
import { ReferAFriend } from './ReferAFriend/ReferAFriend';
import { BottomInfo } from './BottomInfo';
import { DashboardContentWrapper, DashboardRow } from './Dashboard.styles';
import { Devices } from './Devices/Devices';
import {
  useGetCoolspotsData,
  useGetWalletAddress
} from '../../shared/api/Wallet/Wallet.queries';
import { Map } from './Map';
import {
  useGetLeadOrders,
  useGetOrdersData
} from '../../shared/api/Order/Order.queries';
import {
  FetchedLeadOrder,
  LeadOrderStatus
} from '../../shared/api/Order/Order.types';
import { Coolspot } from '../../shared/api/Wallet/Wallet.types';
import { Header } from './Header';
import { DeviceScrollProvider } from './Devices/device-scroll-context';
import { ErrorPage } from '../ErrorPage';
import { AddWalletNotification } from './AddWalletNotification';

const readyToOrderLeadOrderStatuses: Array<LeadOrderStatus> = [
  LeadOrderStatus.WaitingForResponse,
  LeadOrderStatus.CheckoutCreated
];

export const Dashboard: FC = () => {
  const {
    data: coolspotsData,
    isLoading: isCoolspotsDataLoading,
    error: coolspotsError
  } = useGetCoolspotsData({
    withReferrals: true
  });

  const {
    data: leadOrdersList,
    isLoading: isLeadOrdersListLoading,
    error: leadOrdersListError
  } = useGetLeadOrders();

  const { data: ordersData } = useGetOrdersData();

  const { data: walletAddressData, isLoading: isGetWalletAddressLoading } =
    useGetWalletAddress({
      cacheTime: 0
    });

  const hasError = !!coolspotsError || !!leadOrdersListError;
  const isLoading =
    !hasError && (isCoolspotsDataLoading || isLeadOrdersListLoading);

  const coolspots =
    coolspotsData?.entities.filter(
      ({ name, isReferral, isReferralVisible }: Coolspot) =>
        name != null && (!isReferral || isReferralVisible)
    ) || [];

  const readyToOrderLeadOrders: Array<FetchedLeadOrder> = useMemo(
    () =>
      Array.isArray(leadOrdersList)
        ? leadOrdersList.filter(({ status }) =>
            readyToOrderLeadOrderStatuses.includes(status)
          )
        : [],
    [leadOrdersList]
  );

  const hasCoolspots = coolspots.length! > 0;
  const hasReadyToOrderLeadOrders = readyToOrderLeadOrders.length! > 0;
  const hasOrders = ordersData?.length! > 0;
  const hasDeviceOrOrder =
    hasCoolspots || hasReadyToOrderLeadOrders || hasOrders;
  const showWalletNotification =
    !isGetWalletAddressLoading && !walletAddressData && hasDeviceOrOrder;

  if (hasError) {
    return <ErrorPage />;
  }

  return (
    <DashboardLayout>
      <DashboardContentWrapper>
        <Header coolspots={coolspotsData?.entities} />
        {showWalletNotification && <AddWalletNotification />}
        {!hasCoolspots && (
          <ReferAFriend
            coolspotsData={coolspotsData}
            isCoolspotsDataLoading={isCoolspotsDataLoading}
          />
        )}
      </DashboardContentWrapper>
      <DashboardRow
        deviceCount={coolspots.length || 0}
        isLoading={isLoading}
        readyToOrderLeadOrdersCount={readyToOrderLeadOrders.length || 0}
      >
        <DeviceScrollProvider coolspotsData={coolspots}>
          <Map hotspotData={coolspots} isLoading={isLoading} />
          <Devices
            isCoolspotsOrLeadOrdersLoading={isLoading}
            coolspotsData={coolspots}
            readyToOrderLeadOrders={readyToOrderLeadOrders}
          />
        </DeviceScrollProvider>
      </DashboardRow>
      {hasCoolspots && (
        <DashboardContentWrapper>
          <ReferAFriend
            coolspotsData={coolspotsData}
            isCoolspotsDataLoading={isCoolspotsDataLoading}
          />
          <BottomInfo />
        </DashboardContentWrapper>
      )}
    </DashboardLayout>
  );
};
