import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../../components/Button';
import { EmptyStateBox } from '../../../../components/EmptyStateBox';

export const AddWallet = () => {
  const history = useHistory();

  return (
    <EmptyStateBox>
      <FormattedMessage
        id="payoutHistory.noWalletTitle"
        defaultMessage="You have no wallet added"
      />
      <Button
        isPrimary
        iconLeft="Plus"
        onClick={() =>
          history.push({
            pathname: '/edit-wallet',
            state: { fromAddWallet: true }
          })
        }
      >
        <FormattedMessage
          id="payoutHistory.addWalletButton"
          defaultMessage="Add wallet"
        />
      </Button>
    </EmptyStateBox>
  );
};
