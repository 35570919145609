import { FC } from 'react';

import { TabContentWrapper } from './Tabs.styles';
import { TabProps } from './Tabs.types';

import { TabContext } from '.';

export const TabContent: FC<TabProps> = ({ children, index }) => {
  const { Consumer } = TabContext;

  return (
    <Consumer>
      {({ selectedTab }) => (
        <TabContentWrapper isSelected={selectedTab === index}>
          {children}
        </TabContentWrapper>
      )}
    </Consumer>
  );
};
