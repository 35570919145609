import { FC, useEffect } from 'react';
import TagManager from 'react-gtm-module';

import { FullPageLoader } from '../../../../components/FullPageLoader';
import { StepProps } from '../../../../components/Stepper';

export const ShopifyCheckout: FC<StepProps> = ({ previousStepValues }) => {
  useEffect(() => {
    if (process.env.REACT_APP_GTM_ID && process.env.NODE_ENV === 'production') {
      TagManager.dataLayer({
        dataLayer: {
          ecommerce: null // Clear the previous ecommerce object.
        }
      });
      TagManager.dataLayer({
        dataLayer: {
          event: 'add_payment_info',
          ecommerce: {
            value: Number(process.env.REACT_APP_UPGRADE_PLAN_PRICE),
            tax: 0.0,
            shipping: 0.0,
            currency: 'USD'
          }
        }
      });
    }

    window.location.replace(previousStepValues?.checkoutUrl);
  }, [previousStepValues]);

  return <FullPageLoader />;
};
