import { FC, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import qs from 'query-string';

import { AddressBox } from '../../../../components/AddressBox';
import { Alert } from '../../../../components/Alert';
import { AlertVariant } from '../../../../components/Alert/Alert.types';
import { Box } from '../../../../components/Box';
import { ContentWrapper } from '../../../../components/Layout';
import { LinkButton } from '../../../../components/Button';
import { Heading, Subtitle, Text } from '../../../../components/Typography';
import { Paragraph, StyledLink } from './ConfirmationScreen.styles';
import {
  userTracking,
  UserTrackingEvent
} from '../../../../shared/utils/user-tracking';
import { useGetLeadOrder } from '../../../../shared/api/Order/Order.queries';
import { LeadOrderStatus } from '../../../../shared/api/Order/Order.types';
import { FullPageLoader } from '../../../../components/FullPageLoader';
import { CreateLeadUserStepProps } from '../CreateLeadUser.types';

export const ConfirmationScreen: FC<CreateLeadUserStepProps> = ({
  handleStepsReset,
  previousStepValues
}) => {
  useEffect(() => {
    userTracking(UserTrackingEvent.NewUser);
  }, []);

  const { leadOrderId, email, countryIsSupported, installationAddress } =
    previousStepValues || {};

  const { data: leadOrder, isLoading: isLeadOrderLoading } = useGetLeadOrder(
    leadOrderId,
    email,
    {
      enabled: !!(leadOrderId && email)
    }
  );

  const isCoolspotRedyToOrder =
    leadOrder?.status === LeadOrderStatus.WaitingForResponse;

  if (isLeadOrderLoading) {
    return <FullPageLoader />;
  }

  return (
    <ContentWrapper>
      <Heading size="h4" element="h1" variant="grey" whiteSpace="pre-line">
        {isCoolspotRedyToOrder && (
          <FormattedMessage
            id="confirmationScreen.redyToOrder.title"
            defaultMessage="You qualify!"
          />
        )}
        {!isCoolspotRedyToOrder && (
          <FormattedMessage
            id="confirmationScreen.notRedyToOrder.title"
            defaultMessage="We’re analyzing your location’s earning potential!"
          />
        )}
      </Heading>
      <Box mt="xl">
        <Subtitle variant="grey" size="big">
          {isCoolspotRedyToOrder && (
            <FormattedMessage
              id="confirmationScreen.redyToOrder.subtitle"
              defaultMessage="You have qualified to host a CoolSpot™ at the following location:"
            />
          )}
          {!isCoolspotRedyToOrder && (
            <FormattedMessage
              id="confirmationScreen.notRedyToOrder.subtitle"
              defaultMessage="CoolSpot™ installation address"
            />
          )}
        </Subtitle>
      </Box>
      <AddressBox mt="m">
        {typeof installationAddress === 'string'
          ? installationAddress
          : installationAddress?.formatted_address}
      </AddressBox>
      {isCoolspotRedyToOrder && (
        <Box mt="xl">
          <Text level="big" whiteSpace="pre-line">
            <FormattedMessage
              id="confirmationScreen.redyToOrder.copy"
              defaultMessage="Next, you will create your account, select an earnings plan, and order your CoolSpot™."
            />
          </Text>
        </Box>
      )}
      {!isCoolspotRedyToOrder && countryIsSupported && (
        <>
          <Box mt="xl">
            <Text level="big" whiteSpace="pre-line">
              <FormattedMessage
                id="confirmationScreen.notRedyToOrder.countryIsSupported.copy"
                defaultMessage={
                  'If your location is approved for immediate shipping, you will receive an email offer to purchase your CoolSpot in the next hour!\n\nOtherwise you will be added to our wait list to receive a CoolSpot, dependent on availability.'
                }
              />
            </Text>
          </Box>
          <Box mt="xl">
            <Alert variant={AlertVariant.Secondary}>
              <div>
                <Paragraph>
                  <FormattedMessage
                    id="confirmationScreen.notRedyToOrder.countryIsSupported.infoBox"
                    defaultMessage="If you want to change the CoolSpot™ Installation Address please fill the request flow again so we can check your eligibility."
                  />
                </Paragraph>
                <Paragraph>
                  <StyledLink
                    type="button"
                    onClick={() => handleStepsReset?.()}
                  >
                    <FormattedMessage
                      id="login.shopifyLink"
                      defaultMessage="Click here"
                    />
                  </StyledLink>
                </Paragraph>
              </div>
            </Alert>
          </Box>
        </>
      )}
      {!isCoolspotRedyToOrder && !countryIsSupported && (
        <Box mt="xl">
          <Text level="big">
            <FormattedMessage
              id="confirmationScreen.notRedyToOrder.countryIsNotSupported.copy"
              defaultMessage="This country for the CoolSpot™ installation address is not supported. We will email you as soon as a CoolSpot™ is available for your installation address. We are shipping thousands of CoolSpots™ every month, prioritizing the wait list based on host location’s earning potential."
            />
          </Text>
        </Box>
      )}
      <Box mt="xl">
        {isCoolspotRedyToOrder && (
          <LinkButton
            to={{
              pathname: '/order-a-coolspot',
              search: qs.stringify({ leadOrderId, email }),
              params: { leadUser: previousStepValues }
            }}
            fullWidth
          >
            <FormattedMessage
              id="confirmationScreen.orderACoolspot"
              defaultMessage="Continue"
            />
          </LinkButton>
        )}
        {!isCoolspotRedyToOrder && (
          <LinkButton
            to="https://emrit.io"
            external
            fullWidth
            openInNewTab={false}
          >
            <FormattedMessage
              id="confirmationScreen.gotIt"
              defaultMessage="Got it"
            />
          </LinkButton>
        )}
      </Box>
    </ContentWrapper>
  );
};
