import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { mobileMenuItemStyle } from '../MobileMenuItem/MobileMenuItem.styles';

export const StyledMobileMenuLink = styled(Link)`
  ${mobileMenuItemStyle}

  text-decoration: none;
`;
