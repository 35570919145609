import styled from 'styled-components';

import { DashboardBox } from '../../../components/DashboardBox';

export const TopBarContainer = styled(DashboardBox)<{
  deviceCount: number;
}>`
  flex-direction: column;
  flex: ${({ deviceCount }) => (deviceCount ? '2.3' : '1')};
  width: 100%;
  height: 216px;
  border-radius: ${({ theme }) =>
    `${theme.borderRadius} 0 0  ${theme.borderRadius}`};

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    min-height: 219px;
    border-radius: ${({ theme }) =>
      `${theme.borderRadius} ${theme.borderRadius} 0 0`};
  }

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    min-height: ${({ deviceCount, isLoading }) =>
      deviceCount > 0 || isLoading ? '388px' : '219px'};
  }
`;

export const CardContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    flex-direction: column;
  }
`;

export const StatisticsContainer = styled.div`
  display: flex;
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
    gap: ${({ theme }) => theme.margin.m};
  }
`;
