import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Box } from '../../../components/Box';
import { LinkButton } from '../../../components/Button';
import { BUTTON_SIZE } from '../../../components/Button/Button.types';
import { ContentWrapper } from '../../../components/Layout';
import { RegistrationForm } from '../../../components/RegistrationForm';
import { RegistrationFormWrapper } from '../../../components/RegistrationForm/RegistrationForm.styles';
import { RegistrationFormValues } from '../../../components/RegistrationForm/RegistrationForm.types';
import { StepProps } from '../../../components/Stepper';
import { Heading } from '../../../components/Typography';
import { Country } from '../../../shared/api/Order';
import { useQueryParams } from '../../../shared/utils/use-query-params';
import { StyledSubtitle } from './CreateAccountPage.styles';

export const CreateAccountPage: FC<
  {
    onSubmit?: (data?: RegistrationFormValues) => void;
    countries: Country[];
  } & StepProps
> = ({ handleNextStep, onSubmit, countries }) => {
  const [params] = useQueryParams({
    country: '',
    email: ''
  });

  const handleSubmit = (data: RegistrationFormValues) => {
    if (onSubmit) {
      onSubmit(data);
    }

    if (handleNextStep) {
      handleNextStep(data);
    }
  };

  const getCountryValue = (countryCode: string) => {
    const result =
      countries && countries.find((country) => country.code === countryCode);

    if (!result) {
      return undefined;
    }

    return { label: result.name, value: result.code };
  };

  return (
    <>
      <ContentWrapper>
        <Heading size="h4" element="h1" variant="grey">
          <FormattedMessage id="createAccount.title" defaultMessage="Sign Up" />
        </Heading>
        <StyledSubtitle variant="grey" size="big">
          <FormattedMessage
            id="createAccount.subtitle"
            defaultMessage="Your Emrit Dashboard allows you to manage all of your devices in one place."
          />
        </StyledSubtitle>
        <RegistrationFormWrapper>
          <RegistrationForm
            onSubmit={handleSubmit}
            defaultValue={{
              country: getCountryValue(params.country),
              email: params.email
            }}
          />
        </RegistrationFormWrapper>
        <Box mt="m">
          <LinkButton
            to="/feedback"
            isPlain
            isPrimary={false}
            fullWidth
            size={BUTTON_SIZE.BIG}
          >
            <FormattedMessage
              id="createAccount.cancelButton"
              defaultMessage="Cancel"
            />
          </LinkButton>
        </Box>
      </ContentWrapper>
    </>
  );
};
