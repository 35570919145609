import styled from 'styled-components';

import { getTypography } from '../../shared/utils/style-utils';
import { Button } from '../Button';
import { Logo } from '../Logo';

export const NavbarWrapper = styled.nav`
  height: 240px;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    height: 146px;
  }

  background-color: ${({ theme }) => theme.color.blue};
`;

export const NavbarContent = styled.div`
  width: 85%;
  height: 96px;
  max-width: 1280px;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;

  @media (${({ theme }) => theme.breakpoints.phone}) {
    width: 100%;
    padding-left: ${({ theme }) => theme.padding.s};
    padding-right: ${({ theme }) => theme.padding.s};
  }
`;

export const NavbarLogo = styled(Logo)`
  width: 74px;
  height: 24px;

  @media (${({ theme }) => theme.breakpoints.phone}) {
    width: 100px;
    height: 32px;
  }
`;

export const MobileMenuButton = styled(Button).attrs({
  isPrimary: true,
  variant: 'blueOverLightBlueBackground'
})`
  position: fixed;
  bottom: ${({ theme }) => theme.margin.m};
  right: ${({ theme }) => theme.margin.m};
  z-index: 10;
  box-shadow: ${({ theme }) => theme.shadow.darkBlue.l};
  border-color: ${({ theme }) => theme.color.blueOverLightBlueBackground};

  border-radius: 28px;
  ${({ theme }) => getTypography(theme.typography.button.small)};

  svg {
    stroke: none;
    fill: ${({ theme }) => theme.color.white};
  }

  &:hover {
    background: ${({ theme }) => theme.color.blue};
    color: ${({ theme }) => theme.color.white};
    border-color: ${({ theme }) => theme.color.blueOverLightBlueBackground};

    svg {
      stroke: none;
      fill: ${({ theme }) => theme.color.white};
    }
  }
`;

export const TimeToPayoutWrapper = styled.div`
  @media (${({ theme }) => theme.breakpoints.tablet}) {
    order: 3;
    width: 100%;

    display: flex;
    justify-content: center;
  }
`;
