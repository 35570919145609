import { useHistory, useLocation } from 'react-router-dom';
import isNumber from 'lodash.isnumber';

type ParamSetter<T> = (newParams: T) => void;

export function useQueryParams<T extends { [k: string]: string | number }>(
  defaultParams: T
): [T, ParamSetter<T>] {
  const location = useLocation();
  const history = useHistory();

  const searchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(
    Object.entries(defaultParams).map(([key, defaultValue]) => {
      const value = searchParams.get(key) ?? defaultValue;
      const typedValue = isNumber(defaultValue) ? Number(value) : value;

      return [key, typedValue];
    })
  );

  const setParams: ParamSetter<T> = (newParams) => {
    const nonDefaultParamEntries = Object.entries(newParams)
      .filter(([key, value]) => value !== defaultParams[key])
      .map(([key, value]) => [key, String(value)]);
    const newSearchParamsString = new URLSearchParams(
      nonDefaultParamEntries
    ).toString();
    const path =
      newSearchParamsString.length > 0
        ? `${location.pathname}?${newSearchParamsString}`
        : location.pathname;

    history.replace(path);
  };

  return [params as T, setParams];
}
