import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  Redirect,
  Route,
  RouteProps,
  Switch,
  useHistory,
  useLocation
} from 'react-router';

import { useAuth } from './shared/auth/auth-context';
import { NotFound } from './features/NotFound';
import { NoConnection } from './features/NoConnection';
import { useQueryParams } from './shared/utils/use-query-params';
import { TermsAndConditionsProvider } from './shared/utils/terms-and-conditions/terms-and-conditions-context';

const OfflinePage: FC<{ handleRefresh: () => void }> = ({ handleRefresh }) => {
  return (
    <Switch>
      <Route>
        <Route exact path="/no-connection">
          <NoConnection handleRefresh={handleRefresh} />
        </Route>
        <Redirect push to="/no-connection" />
      </Route>
    </Switch>
  );
};

type RouteType = Omit<RouteProps, 'path'> & { path: string };

type RoutesProps = {
  unauthorizedRoutes: Array<RouteType>;
  authorizedRoutes: Array<RouteType>;
};

export const Routes: FC<RoutesProps> = ({
  authorizedRoutes,
  unauthorizedRoutes
}) => {
  const { isLoggedIn } = useAuth();
  const history = useHistory();
  const [isOffline, setIsOffline] = useState(false);
  const location = useLocation();
  const [{ redirectUrl }] = useQueryParams({
    redirectUrl: '/dashboard'
  });

  useEffect(() => {
    setIsOffline(!navigator.onLine);
  }, [location]);

  const handleRefresh = () => {
    if (navigator.onLine) {
      setIsOffline(true);
      history.goBack();
    }
  };

  const UnauthorizedRoutes = useMemo(
    () => (
      <Switch>
        {unauthorizedRoutes.map((route) => (
          <Route exact {...route} key={route.path} />
        ))}
        <Route path="/" exact>
          <Redirect to="/login" />
        </Route>
        <Route component={NotFound} />
      </Switch>
    ),
    [unauthorizedRoutes]
  );
  const AuthorizedRoutes = useMemo(
    () => (
      <TermsAndConditionsProvider>
        <Switch>
          {authorizedRoutes.map((route) => (
            <Route exact {...route} key={route.path} />
          ))}
          {redirectUrl && (
            <Route>
              <Redirect to={decodeURIComponent(redirectUrl)} />
            </Route>
          )}
        </Switch>
      </TermsAndConditionsProvider>
    ),
    [authorizedRoutes, redirectUrl]
  );

  if (isOffline) {
    return <OfflinePage handleRefresh={handleRefresh} />;
  }

  if (isLoggedIn()) {
    return AuthorizedRoutes;
  }

  return UnauthorizedRoutes;
};
