import styled, { css } from 'styled-components';

import { getTypography } from '../../../../../shared/utils/style-utils';

export const DevicesToUpgradeWrapper = styled.div<{
  horizontal?: boolean;
}>`
  display: flex;
  flex-direction: ${({ horizontal }) => (horizontal ? 'row' : 'column')};

  ${({ horizontal }) =>
    horizontal &&
    css`
      flex-wrap: wrap;
    `}
`;

export const RadioLabel = styled.label`
  position: relative;
  display: block;
  margin-right: ${({ theme }) => theme.margin.s};
  padding-top: ${({ theme }) => theme.padding.s};
  padding-left: ${({ theme }) => theme.padding.xl};
  padding-bottom: ${({ theme }) => theme.padding.s};
  border: ${({ theme }) => theme.border('transparent')};
  border-bottom: ${({ theme }) => theme.border('lightGrey', 1)};
  color: ${({ theme }) => theme.color.black};
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z' stroke='%239E9E9E' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    transition: all 0.2s ease-in-out;
  }
`;

export const DeviceItemHeader = styled.p`
  display: flex;
  justify-content: space-between;
  margin: 0;
`;

export const DeviceName = styled.span``;

export const InstallationAddress = styled.span`
  display: block;
  ${({ theme }) => getTypography(theme.typography.body.small)};
  color: ${({ theme }) => theme.color.grey};
  cursor: inherit;
`;

export const Price = styled.span`
  color: ${({ theme }) => theme.color.grey};
  font-weight: 700;
  cursor: inherit;
`;

export const RadioInput = styled.input`
  position: absolute;
  width: 0;
  height: 0;
  display: none;

  &:checked + ${RadioLabel} {
    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z' stroke='%233267F1' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Ccircle cx='10' cy='10' r='4' fill='%233267F1'/%3E%3C/svg%3E%0A");
    }
  }

  &:focus + ${RadioLabel} {
    box-shadow: ${({ theme }) => theme.shadow.blue.m};
    border: ${({ theme }) => theme.border(theme.color.blue)};
  }
`;
