import styled, { css } from 'styled-components';

export const ChildWrapper = styled.div`
  overflow: hidden;
`;

export const ChildWrapperInner = styled.div<{ isExpanded: boolean }>`
  transition: margin-top 0.5s ease-in-out;
  margin-top: -100%;

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      margin-top: 0;
    `}
`;
