import { ReactComponent as Alert } from './Alert.svg';
import { ReactComponent as Analytics } from './Analytics.svg';
import { ReactComponent as AntennaOn } from './AntennaOn.svg';
import { ReactComponent as AntennaOff } from './AntennaOff.svg';
import { ReactComponent as ArrowLeft } from './ArrowLeft.svg';
import { ReactComponent as ArrowRight } from './ArrowRight.svg';
import { ReactComponent as ArrowUp } from './ArrowUp.svg';
import { ReactComponent as ArrowDown } from './ArrowDown.svg';
import { ReactComponent as ArrowTrendDown } from './ArrowTrendDown.svg';
import { ReactComponent as ArrowTrendUp } from './ArrowTrendUp.svg';
import { ReactComponent as BurgerMenu } from './BurgerMenu.svg';
import { ReactComponent as Cancel } from './Cancel.svg';
import { ReactComponent as CaretDown } from './CaretDown.svg';
import { ReactComponent as CaretUp } from './CaretUp.svg';
import { ReactComponent as ChevronDown } from './ChevronDown.svg';
import { ReactComponent as ChevronUp } from './ChevronUp.svg';
import { ReactComponent as Circle } from './Circle.svg';
import { ReactComponent as CircleAdd } from './CircleAdd.svg';
import { ReactComponent as CircleCancel } from './CircleCancel.svg';
import { ReactComponent as CircleWhite } from './CircleWhite.svg';
import { ReactComponent as CoolSpot } from './CoolSpot.svg';
import { ReactComponent as CreditCard } from './CreditCard.svg';
import { ReactComponent as Dashboard } from './Dashboard.svg';
import { ReactComponent as Device } from './Device.svg';
import { ReactComponent as Document } from './Document.svg';
import { ReactComponent as Eye } from './Eye.svg';
import { ReactComponent as EyeOff } from './EyeOff.svg';
import { ReactComponent as Facebook } from './Facebook.svg';
import { ReactComponent as Faq } from './Faq.svg';
import { ReactComponent as FileCopy } from './FileCopy.svg';
import { ReactComponent as Home } from './Home.svg';
import { ReactComponent as Info } from './Info.svg';
import { ReactComponent as LoaderElipse } from './LoaderElipse.svg';
import { ReactComponent as Location } from './Location.svg';
import { ReactComponent as Lock } from './Lock.svg';
import { ReactComponent as Logout } from './Logout.svg';
import { ReactComponent as Mail } from './Mail.svg';
import { ReactComponent as MapSpot } from './MapSpot.svg';
import { ReactComponent as Minus } from './Minus.svg';
import { ReactComponent as MoneyMultiple } from './MoneyMultiple.svg';
import { ReactComponent as More } from './More.svg';
import { ReactComponent as Multiplier0 } from './Multiplier0.svg';
import { ReactComponent as Multiplier1 } from './Multiplier1.svg';
import { ReactComponent as Multiplier2 } from './Multiplier2.svg';
import { ReactComponent as Multiplier3 } from './Multiplier3.svg';
import { ReactComponent as Multiplier4 } from './Multiplier4.svg';
import { ReactComponent as PaymentProviderAmazonPay } from './PaymentProviderAmazonPay.svg';
import { ReactComponent as PaymentProviderAuthorizeNet } from './PaymentProviderAuthorizeNet.svg';
import { ReactComponent as PaymentProviderCoinbase } from './PaymentProviderCoinbase.svg';
import { ReactComponent as PaymentProviderPaypal } from './PaymentProviderPaypal.svg';
import { ReactComponent as Person } from './Person.svg';
import { ReactComponent as Phone } from './Phone.svg';
import { ReactComponent as Pin } from './Pin.svg';
import { ReactComponent as Plus } from './Plus.svg';
import { ReactComponent as Point } from './Point.svg';
import { ReactComponent as Refresh } from './Refresh.svg';
import { ReactComponent as Search } from './Search.svg';
import { ReactComponent as Sensor } from './Sensor.svg';
import { ReactComponent as Settings } from './Settings.svg';
import { ReactComponent as Terms } from './Terms.svg';
import { ReactComponent as Tick } from './Tick.svg';
import { ReactComponent as Tick2 } from './Tick2.svg';
import { ReactComponent as Twitter } from './Twitter.svg';
import { ReactComponent as Wallet } from './Wallet.svg';
import { ReactComponent as Youtube } from './Youtube.svg';

const svgList = {
  Alert,
  Analytics,
  AntennaOn,
  AntennaOff,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  ArrowDown,
  ArrowTrendDown,
  ArrowTrendUp,
  BurgerMenu,
  Cancel,
  CaretDown,
  CaretUp,
  ChevronDown,
  ChevronUp,
  Circle,
  CircleAdd,
  CircleCancel,
  CircleWhite,
  CoolSpot,
  CreditCard,
  Dashboard,
  Device,
  Document,
  Eye,
  EyeOff,
  Facebook,
  Faq,
  FileCopy,
  Home,
  Info,
  LoaderElipse,
  Location,
  Lock,
  Logout,
  Mail,
  MapSpot,
  Minus,
  MoneyMultiple,
  More,
  Multiplier0,
  Multiplier1,
  Multiplier2,
  Multiplier3,
  Multiplier4,
  PaymentProviderAmazonPay,
  PaymentProviderAuthorizeNet,
  PaymentProviderCoinbase,
  PaymentProviderPaypal,
  Person,
  Phone,
  Pin,
  Plus,
  Point,
  Refresh,
  Search,
  Sensor,
  Settings,
  Terms,
  Tick,
  Tick2,
  Twitter,
  Wallet,
  Youtube
};

export type Icons = keyof typeof svgList;

export default ({ name }: { name: Icons }) => {
  return svgList[name];
};
