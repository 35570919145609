/* eslint-disable sort-keys */
import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
  breakpoints: {
    phone: 'max-width: 480px',
    tablet: 'max-width: 768px',
    desktop: 'max-width: 1024px',
    widescreen: 'max-width: 1440px'
  },
  borderRadius: '8px',
  borderRadiusWithinBorderedItem: '6px',
  color: {
    blue: '#3267F1',
    black: '#000000',
    darkBlue: '#1449D1',
    hoverBlue: '#4377ff',
    dimmed: '#9E9E9E',
    green: '#20DB93',
    grey: '#5B5B5B',
    lightBlue: '#2797E9',
    lightGrey: '#E5E5E5',
    secondaryGreen: '#1BC282',
    secondaryRed: '#D94B2B',
    secondaryYellow: '#F5BE2F',
    secondaryWhiteBlue: '#F7F8FB',
    secondaryWhiteYellow: '#FFF2CF',
    white: '#FFFFFF',
    blueOverBlueBackground: '#3267F1', // blue
    blueOverLightBlueBackground: '#4377ff' // hover blue
  },
  shadow: {
    white: {
      xs: '0px 0px 1px rgba(50, 103, 241, 0.25)',
      s: '0px 2px 6px rgba(50, 103, 241, 0.1)',
      m: '0px 6px 12px rgba(50, 103, 241, 0.1)',
      l: '0px 12px 24px rgba(50, 103, 241, 0.1)',
      xl: '0px 24px 48px rgba(50, 103, 241, 0.1)'
    },
    blue: {
      xs: '0px 0px 2px rgba(50, 103, 241, 0.75)',
      s: '0px 2px 6px rgba(50, 103, 241, 0.2)',
      m: '0px 6px 12px rgba(50, 103, 241, 0.2)',
      l: '0px 12px 24px rgba(50, 103, 241, 0.2)',
      xl: '0px 24px 48px rgba(50, 103, 241, 0.2)'
    },
    darkBlue: {
      s: '0px 2px 6px rgba(10, 39, 115, 0.25)',
      m: '0px 6px 12px rgba(10, 39, 115, 0.25)',
      l: '0px 12px 24px rgba(10, 39, 115, 0.25)',
      xl: '0px 24px 48px rgba(10, 39, 115, 0.25)'
    }
  },
  border: (color: string, size: number = 2) => `${size}px ${color} solid`,
  margin: {
    xxs: '8px',
    xs: '12px',
    s: '16px',
    m: '24px',
    l: '40px',
    xl: '64px',
    xxl: '96px'
  },
  padding: {
    xxs: '8px',
    xs: '12px',
    s: '16px',
    m: '18px',
    l: '24px',
    xl: '42px',
    xxl: '56px'
  },
  typography: {
    fontFamily: 'Nunito, sans-serif',
    bodyFontSize: '16px',
    bodyLineHeight: '1.33em',
    heading: {
      h1: {
        fontSize: '96px',
        letterSpacing: '-1.5px',
        lineHeight: '1.66em'
      },
      h2: {
        fontSize: '60px',
        letterSpacing: '-0.5px',
        lineHeight: '1.33em'
      },
      h3: {
        fontSize: '48px',
        letterSpacing: '0',
        lineHeight: '1.66em'
      },
      h4: {
        fontSize: '36px',
        letterSpacing: '0',
        lineHeight: '1.33em'
      },
      h5: {
        fontSize: '24px',
        letterSpacing: '0',
        lineHeight: '1.33em'
      },
      h6: {
        fontSize: '20px',
        letterSpacing: '0',
        lineHeight: '1.33em'
      }
    },
    subtitle: {
      big: {
        fontSize: '16px',
        letterSpacing: '0.15px'
      },
      small: {
        fontSize: '14px',
        letterSpacing: '0.1px'
      }
    },
    body: {
      big: {
        fontSize: '16px',
        lineHeight: '1.5em',
        letterSpacing: '0.5px'
      },
      small: {
        fontSize: '14px',
        lineHeight: '1.5em',
        letterSpacing: '0.25px'
      }
    },
    button: {
      big: {
        fontSize: '18px',
        letterSpacing: '1.25px'
      },
      small: {
        fontSize: '14px',
        letterSpacing: '1.25px'
      }
    },
    caption: {
      fontSize: '12px',
      letterSpacing: '0.4px'
    },
    overline: {
      fontSize: '10px',
      letterSpacing: '1.5px'
    }
  }
};
