import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Tooltip } from '../../../../../components/Tooltip';
import {
  TooltipMenu,
  TooltipMenuItem
} from '../../../../../components/Tooltip/TooltipMenu';
import {
  TooltipActiveMethod,
  TooltipAlignment,
  TooltipVariant
} from '../../../../../components/Tooltip/Tooltip.types';
import { IconContainer } from './SettingsMenu.styles';
import { AntennaModal } from '../../AntennaModal/AntennaModal';
import { ElevationModal } from '../../ElevationModal/ElevationModal';
import { Icon } from '../../../../../components/Icon';

export const SettingsMenu: FC<{ deviceId: string }> = ({ deviceId }) => {
  const [antennaModalIsOpen, setAntennaModalIsOpen] = useState(false);
  const [elevationModalIsOpen, setElevationModalIsOpen] = useState(false);

  const openAntennaModal = () => setAntennaModalIsOpen(true);

  const openElevationModal = () => setElevationModalIsOpen(true);

  return (
    <>
      <Tooltip
        align={TooltipAlignment.End}
        method={TooltipActiveMethod.Click}
        offset={8}
        padding={0}
        variant={TooltipVariant.blueBorder}
        content={
          <TooltipMenu>
            <TooltipMenuItem onClick={openAntennaModal}>
              <FormattedMessage
                id="deviceSettings.assignAntenna"
                defaultMessage="Assign antenna"
              />
            </TooltipMenuItem>
            <TooltipMenuItem onClick={openElevationModal}>
              <FormattedMessage
                id="deviceSettings.addEditElevation"
                defaultMessage="Add / edit elevation"
              />
            </TooltipMenuItem>
          </TooltipMenu>
        }
      >
        <IconContainer>
          <Icon icon="Settings" />
        </IconContainer>
      </Tooltip>
      <AntennaModal
        deviceId={deviceId}
        isOpen={antennaModalIsOpen}
        setIsOpen={setAntennaModalIsOpen}
      />
      <ElevationModal
        deviceId={deviceId}
        isOpen={elevationModalIsOpen}
        setIsOpen={setElevationModalIsOpen}
      />
    </>
  );
};
