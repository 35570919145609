import styled from 'styled-components';

import { getTypography } from '../../../shared/utils/style-utils';

export const Paragraph = styled.p`
  margin: 0;
`;

export const StyledLink = styled.button`
  display: inline;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  color: ${({ theme }) => theme.color.darkBlue};
  font-weight: 700;
  cursor: pointer;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  ${({ theme }) => getTypography(theme.typography.body.small)};

  text-decoration: none;

  &:hover,
  &:active {
    color: ${({ theme }) => theme.color.blue};
  }
`;
