import styled from 'styled-components';

export const MainContentWrapper = styled.div`
  position: relative;
  flex: 1;
  margin-top: -144px;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: -38px;
  }
`;

export const LayoutHeader = styled.header`
  display: flex;
  flex-direction: column;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column-reverse;
  }
`;

export const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;

  min-height: 100vh;
  overflow: hidden;

  background-color: ${({ theme }) => theme.color.secondaryWhiteBlue};

  padding-bottom: ${({ theme }) => theme.margin.xxl};
`;
