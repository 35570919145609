import { useMutation, UseMutationOptions, UseQueryOptions } from 'react-query';

import {
  AddToWaitListProps,
  AddToWaitListResponse,
  ConfirmLeadUserRegistrationFormResponse,
  CurrentUser,
  EmailVerificationResponse,
  GetLeadUserByEmailRequest,
  GetLeadUserByEmailResponse,
  LeadUserEmailVerifyResponse,
  LeadUserVerifyCodeProps,
  LegacyUserVerifyResponse,
  SendCodeProps,
  TermsAndConditions,
  UpdateCurrentUserProps,
  UserRequestsRequest,
  UserRequestsResponse,
  VerifyCodeProps,
  VerifyOneTimeAuthCodeProps,
  WhyAreYouLeavingProps,
  WhyAreYouLeavingReason
} from './Identity.types';
import { fetcher } from '../fetcher';
import { HTTPError } from '../fetcher.types';
import { IDENTITY_SERVICE } from '../endpoints';
import { RegistrationFormValues } from '../../../components/RegistrationForm/RegistrationForm.types';
import { useAuthMutation, useAuthQuery } from '../../auth/auth-helpers';
import { CreateLeadUserFormValues } from '../../../components/CreateLeadUserForm/CreateLeadUserForm.types';
import { ConfirmLeadUserRegistrationFormValues } from '../../../features/BecomeAHost/CreateAccount/CreateAccount/RegistrationForm/RegistrationForm.types';

const createAccount = async (formData: RegistrationFormValues) => {
  const { data } = await fetcher(`${IDENTITY_SERVICE}/auth/signup`, {
    json: {
      country: formData.country?.value,
      email: formData.email,
      firstName: formData.firstName,
      lastName: formData.lastName,
      password: formData.password,
      recommendedByUserId: formData.recommendedByUserId,
      tel: `+${formData.tel}`
    },
    method: 'POST'
  }).json();

  return data;
};

export const useCreateAccount = (
  options: UseMutationOptions<any, HTTPError, RegistrationFormValues, any>
) => {
  return useMutation(createAccount, options);
};

const createLegacyAccount = async (formData: RegistrationFormValues) => {
  const { data } = await fetcher(`${IDENTITY_SERVICE}/auth/legacy/signup`, {
    json: {
      code: formData?.code,
      country: formData.country?.value,
      email: formData.email,
      firstName: formData.firstName,
      lastName: formData.lastName,
      password: formData.password,
      tel: formData.tel.startsWith('+') ? formData.tel : `+${formData.tel}`
    },
    method: 'POST'
  }).json();

  return data;
};

export const useCreateLegacyAccount = (
  options: UseMutationOptions<any, HTTPError, RegistrationFormValues, any>
) => {
  return useMutation(createLegacyAccount, options);
};

const getLeadUserByEmail = async (query: GetLeadUserByEmailRequest) => {
  const { data } = await fetcher(`${IDENTITY_SERVICE}/leads/email`, {
    query
  }).json();

  return data;
};

export const useGetLeadUserByEmail = (
  options?: UseMutationOptions<
    GetLeadUserByEmailResponse,
    HTTPError,
    GetLeadUserByEmailRequest
  >
) => {
  return useMutation('getLeadUserByEmail', getLeadUserByEmail, options);
};

const userEmailVerify = async (json: VerifyCodeProps) => {
  const { data } = await fetcher(`${IDENTITY_SERVICE}/users/email/verify`, {
    json,
    method: 'POST'
  }).json();

  return data;
};

export const useUserEmailVerify = (
  options?: UseMutationOptions<boolean, HTTPError, VerifyCodeProps>
) => {
  return useMutation(userEmailVerify, options);
};

const leadUserEmailVerify = async (json: VerifyCodeProps) => {
  const { data } = await fetcher(`${IDENTITY_SERVICE}/leads/email/verify`, {
    json,
    method: 'PATCH'
  }).json();

  return data;
};

export const useLeadUserEmailVerify = (
  options?: UseMutationOptions<
    LeadUserEmailVerifyResponse,
    HTTPError,
    LeadUserVerifyCodeProps
  >
) => {
  return useMutation(leadUserEmailVerify, options);
};

const waitlistEmailVerify = async (json: VerifyCodeProps) => {
  const { data } = await fetcher(`${IDENTITY_SERVICE}/waitlist/email/verify`, {
    json,
    method: 'POST'
  }).json();

  return data;
};

export const useWaitlistEmailVerify = (
  options?: UseMutationOptions<boolean, HTTPError, VerifyCodeProps>
) => {
  return useMutation(waitlistEmailVerify, options);
};

const sendWaitlistCode = async (json: SendCodeProps) => {
  const { data } = await fetcher(`${IDENTITY_SERVICE}/waitlist/email/resend`, {
    authorized: true,
    json,
    method: 'POST'
  }).json();

  return data;
};

const sendSignupCode = async (json: SendCodeProps) => {
  const { data } = await fetcher(`${IDENTITY_SERVICE}/users/email/resend`, {
    authorized: true,
    json,
    method: 'POST'
  }).json();

  return data;
};

export const useSendSignupCode = (
  options?: UseMutationOptions<boolean, HTTPError, SendCodeProps>
) => {
  return useMutation(sendSignupCode, options);
};

export const useSendWaitlistCode = (
  options?: UseMutationOptions<boolean, HTTPError, SendCodeProps>
) => {
  return useMutation(sendWaitlistCode, options);
};

const verifyForgotPasswordCode = async (json: VerifyCodeProps) => {
  const { data } = await fetcher(
    `${IDENTITY_SERVICE}/auth/password/code/verify
    `,
    {
      json,
      method: 'POST'
    }
  ).json();

  return data;
};

export const useVerifyForgotPasswordCode = (
  options?: UseMutationOptions<boolean, HTTPError, VerifyCodeProps>
) => {
  return useMutation(verifyForgotPasswordCode, options);
};

const resetPassword = async (email: string) => {
  const { data } = await fetcher(
    `${IDENTITY_SERVICE}/auth/password/code/send`,
    {
      json: {
        email
      },
      method: 'POST'
    }
  ).json();

  return data;
};

export const useResetPassword = (
  options: UseMutationOptions<{}, HTTPError, string>
) => {
  return useMutation(resetPassword, options);
};

const setNewPassword = async (json: any) => {
  const { data } = await fetcher(`${IDENTITY_SERVICE}/auth/password/reset`, {
    authorized: false,
    json,
    method: 'POST'
  }).json();

  return data;
};

export const useSetNewPassword = (
  options: UseMutationOptions<{}, HTTPError, any>
) => {
  return useMutation(setNewPassword, options);
};

const verifyEmail = async (email: string) => {
  const { data } = await fetcher(
    `${IDENTITY_SERVICE}/auth/email-verification`,
    {
      json: {
        email
      },
      method: 'POST'
    }
  ).json();

  return data;
};

export const useVerifyEmail = (
  options: UseMutationOptions<EmailVerificationResponse, HTTPError, string>
) => {
  return useMutation(verifyEmail, options);
};

const addToWaitList = async (json: AddToWaitListProps) => {
  const { data } = await fetcher(`${IDENTITY_SERVICE}/waitlist`, {
    json,
    method: 'POST'
  }).json();

  return data;
};

export const useAddWaitList = (
  options: UseMutationOptions<
    AddToWaitListResponse,
    HTTPError,
    AddToWaitListProps
  >
) => {
  return useMutation(addToWaitList, options);
};

const getCurrentUser = async () => {
  const { data } = await fetcher(`${IDENTITY_SERVICE}/users/me`).json();

  return data;
};

export const useGetCurrentUser = (
  options?: UseQueryOptions<boolean, HTTPError, CurrentUser>
) => {
  return useAuthQuery('currentUser', getCurrentUser, options);
};

const acceptTermsAndConditions = async (json: UpdateCurrentUserProps) => {
  const { data } = await fetcher(
    `${IDENTITY_SERVICE}/users/me/terms-and-conditions`,
    {
      json,
      method: 'PATCH'
    }
  ).json();

  return data;
};

export const useAcceptTermsAndConditions = (
  options?: UseMutationOptions<null, HTTPError, UpdateCurrentUserProps>
) => {
  return useAuthMutation('user', acceptTermsAndConditions, options);
};

const sendLegacyUserVerificationCode = async (json: SendCodeProps) => {
  const { data } = await fetcher(`${IDENTITY_SERVICE}/auth/legacy/code/send`, {
    json,
    method: 'POST'
  }).json();

  return data;
};

export const useSendLegacyUserVerificationCode = (
  options?: UseMutationOptions<boolean, HTTPError, SendCodeProps>
) => {
  return useMutation(sendLegacyUserVerificationCode, options);
};

const verifyLegacyCode = async (json: VerifyCodeProps) => {
  const { data } = await fetcher(
    `${IDENTITY_SERVICE}/auth/legacy/code/verify`,
    {
      json,
      method: 'POST'
    }
  ).json();

  return data;
};

export const useVerifyLegacyCode = (
  options: UseMutationOptions<
    EmailVerificationResponse,
    HTTPError,
    VerifyCodeProps
  >
) => {
  return useMutation(verifyLegacyCode, options);
};

const verifyLegacyUser = async (json: VerifyCodeProps) => {
  const { data, status } = await fetcher(
    `${IDENTITY_SERVICE}/legacy-users/verify`,
    {
      json,
      method: 'POST'
    }
  ).json();

  if (status !== 200) {
    throw new HTTPError('Bad response');
  }

  return data;
};

export const useVerifyLegacyUser = (
  options?: UseMutationOptions<
    LegacyUserVerifyResponse,
    HTTPError,
    VerifyCodeProps
  >
) => {
  return useMutation(verifyLegacyUser, options);
};

const submitFeedback = async (
  json: WhyAreYouLeavingProps<WhyAreYouLeavingReason>
) => {
  const { data } = await fetcher(`${IDENTITY_SERVICE}/why-are-you-leaving`, {
    json,
    method: 'POST'
  }).json();

  return data;
};

export const useSubmitFeedback = (
  options: UseMutationOptions<
    WhyAreYouLeavingReason,
    HTTPError,
    WhyAreYouLeavingProps<WhyAreYouLeavingReason>
  >
) => {
  return useMutation(submitFeedback, options);
};

const submitUserRequest = async (json: UserRequestsRequest) => {
  const { data } = await fetcher(`${IDENTITY_SERVICE}/user-requests`, {
    json,
    method: 'POST'
  }).json();

  return data;
};

export const useCreateUserRequest = (
  options: UseMutationOptions<
    UserRequestsResponse,
    HTTPError,
    UserRequestsRequest
  >
) => {
  return useAuthMutation('userRequest', submitUserRequest, options);
};

const createLeadUserAccount = async (formData: CreateLeadUserFormValues) => {
  const { data } = await fetcher(`${IDENTITY_SERVICE}/leads/signup`, {
    json: {
      email: formData.email,
      firstName: formData.firstName,
      lastName: formData.lastName,
      recommendedByUserId: formData.recommendedByUserId,
      phone: formData.phone
    },
    method: 'POST'
  }).json();

  return data;
};

export const useCreateLeadUserAccount = (
  options: UseMutationOptions<
    ConfirmLeadUserRegistrationFormResponse,
    HTTPError,
    CreateLeadUserFormValues,
    any
  >
) => {
  return useMutation(createLeadUserAccount, options);
};
const confirmLeadUserRegistration = async (
  formData: ConfirmLeadUserRegistrationFormValues
) => {
  const { leadOrderId, ...json } = formData;

  const { data } = await fetcher(`${IDENTITY_SERVICE}/leads/signup`, {
    json,
    query: {
      leadOrderId
    },
    method: 'PATCH'
  }).json();

  return data;
};

export const useConfirmLeadUserRegistration = (
  options: UseMutationOptions<
    ConfirmLeadUserRegistrationFormResponse,
    HTTPError,
    ConfirmLeadUserRegistrationFormValues,
    any
  >
) => {
  return useMutation(confirmLeadUserRegistration, options);
};

export const sendOneTimeAuthCode = async (email: string) => {
  const { data } = await fetcher(`${IDENTITY_SERVICE}/auth/code/send`, {
    authorized: true,
    json: {
      email
    },
    method: 'POST'
  }).json();

  return data;
};

export const useSendOneTimeAuthCode = (
  options?: UseMutationOptions<void, HTTPError, string>
) => {
  return useMutation('sendOneTimeAuthCode', sendOneTimeAuthCode, options);
};

export const verifyOneTimeAuthCode = async ({
  email,
  code
}: VerifyOneTimeAuthCodeProps) => {
  const { data } = await fetcher(`${IDENTITY_SERVICE}/auth/code/verify`, {
    authorized: true,
    json: {
      email,
      code
    },
    method: 'POST'
  }).json();

  return data;
};

export const useVerifyOneTimeAuthCode = (
  options?: UseMutationOptions<void, HTTPError, VerifyOneTimeAuthCodeProps>
) => {
  return useMutation('sendOneTimeAuthCode', verifyOneTimeAuthCode, options);
};

const getTermsAndConditions = async () => {
  const { data } = await fetcher(
    `${IDENTITY_SERVICE}/configs/terms-and-conditions`
  ).json();

  return data;
};

export const useGetTermsAndConditions = (
  options?: UseQueryOptions<boolean, HTTPError, TermsAndConditions>
) => {
  return useAuthQuery('termsAndConditions', getTermsAndConditions, options);
};
