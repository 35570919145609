import { FC, useRef, useState } from 'react';

import { StyledTooltip, Wrapper } from './Tooltip.styles';
import {
  TooltipActiveMethod,
  TooltipPosition,
  TooltipProps,
  TooltipVariant
} from './Tooltip.types';

export const Tooltip: FC<TooltipProps> = ({
  align,
  children,
  content,
  offset,
  padding,
  position = TooltipPosition.Bottom,
  method = TooltipActiveMethod.MouseOver,
  variant = TooltipVariant.Default,
  ...props
}) => {
  const [isActive, setIsActive] = useState(false);
  const wrapperRef = useRef<any>(null);

  const showTip = () => setIsActive(true);

  const hideTip = () => setIsActive(false);

  const handleClickOutside = (event: { target: any }) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      hideTip();
      document.removeEventListener('mousedown', handleClickOutside);
    }
  };

  const onClick = () => {
    if (!isActive) {
      showTip();
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      hideTip();
      document.removeEventListener('mousedown', handleClickOutside);
    }
  };

  return (
    <Wrapper
      isActive={isActive}
      onClick={method === TooltipActiveMethod.Click ? onClick : undefined}
      onMouseEnter={
        method === TooltipActiveMethod.MouseOver ? showTip : undefined
      }
      onMouseLeave={
        method === TooltipActiveMethod.MouseOver ? hideTip : undefined
      }
      ref={wrapperRef}
      {...props}
    >
      {children}
      {isActive && (
        <StyledTooltip
          align={align}
          offset={offset}
          padding={padding}
          position={position}
          variant={variant}
        >
          {content}
        </StyledTooltip>
      )}
    </Wrapper>
  );
};
