import styled from 'styled-components';

import { Box } from '../../../../components/Box';

export const ButtonContainer = styled(Box)`
  width: 100%;
  max-width: 592px;
`;

export const SuccessIconContainer = styled(Box)`
  display: flex;
  justify-content: center;
`;
