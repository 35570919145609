import { FC } from 'react';

import EllipseSmall from './EllipseSmall.png';
import Ellipse from './Ellipse.png';
import { StyledLoader } from './Loader.styles';

export const Loader: FC<{
  size?: 'big' | 'small';
  variant?: 'blue' | 'white';
}> = ({ size = 'small', variant = 'white' }) => {
  if (variant === 'blue') {
    return <StyledLoader src={Ellipse} alt="Ellipse" size={size} />;
  }

  return <StyledLoader src={EllipseSmall} alt="Ellipse" size={size} />;
};
