import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { getButtonStyles } from '../Button.styles';
import { LinkButtonProps } from './LinkButton.types';

export const StyledLinkButton = styled(Link).withConfig({
  shouldForwardProp: (prop) =>
    // eslint-disable-next-line array-element-newline
    !['isPrimary', 'fullWidth', 'isPlain', 'variant'].includes(prop)
})<Omit<LinkButtonProps, 'to'>>`
  ${({ theme, isPrimary, size, fullWidth, variant = 'blue' }) =>
    getButtonStyles(theme, isPrimary, size, fullWidth, variant)};

  text-decoration: none;

  ${({ theme, isPlain }) =>
    isPlain &&
    css`
      border: ${theme.border('transparent')};

      &:focus,
      &:active,
      &:hover {
        background-color: transparent;
        box-shadow: none;

        color: ${theme.color.blue};
        border: ${theme.border(theme.color.blue)};
      }
    `}
`;
