import { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import remarkHtml from 'remark-html';

import { productVariantsMeta } from './ProductVariant.meta';
import {
  ContentWrapper,
  Copy,
  Header,
  Highlight,
  HighlightBox,
  Price,
  ProductVariantWrapper,
  RadioInput,
  RadioLabel,
  Subheader
} from './ProductVariant.styles';
import { ProductVariantsProps } from './ProductVariant.types';

export const ProductVariant = forwardRef<
  HTMLInputElement,
  ProductVariantsProps
>(
  (
    {
      className,
      name,
      variantId,
      description,
      earningsFactor,
      price,
      isActive,
      isVisible,
      onClick,
      ...props
    },
    ref
  ) => {
    const { highlight, header, subheader, copy } =
      productVariantsMeta.find(
        (productMeta) => productMeta.earningsFactor === earningsFactor
      ) || {};

    return (
      <ProductVariantWrapper className={className}>
        <RadioInput
          {...props}
          ref={ref}
          id={variantId}
          value={variantId}
          name={name}
          type="radio"
          onClick={onClick}
        />
        <RadioLabel htmlFor={variantId} isActive={isActive}>
          {highlight && (
            <HighlightBox isActive={isActive}>
              <Highlight>
                <FormattedMessage
                  id={`earningPlan.earningFactor${earningsFactor}.highlight`}
                  defaultMessage={highlight}
                />
              </Highlight>
            </HighlightBox>
          )}
          <ContentWrapper isVisible={isVisible}>
            {header && (
              <Header>
                <FormattedMessage
                  id={`earningPlan.earningFactor${earningsFactor}.header`}
                  defaultMessage={header}
                />
              </Header>
            )}
            <Price>${price}</Price>
            {subheader && (
              <Subheader>
                <FormattedMessage
                  id={`earningPlan.earningFactor${earningsFactor}.subheader`}
                  defaultMessage={subheader}
                />
              </Subheader>
            )}
            {copy && (
              <Copy>
                <ReactMarkdown remarkPlugins={[remarkHtml]}>
                  {copy}
                </ReactMarkdown>
              </Copy>
            )}
          </ContentWrapper>
        </RadioLabel>
      </ProductVariantWrapper>
    );
  }
);
