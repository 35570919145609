import React, { FC, useState } from 'react';

import { TabsWrapper } from './Tabs.styles';
import { TabContextProps, TabsProps } from './Tabs.types';

export const TabContext = React.createContext({
  selectedTab: 0,
  setSelectedTab: () => {}
} as TabContextProps);

export const Tabs: FC<TabsProps> = ({ defaultTab = 0, children }) => {
  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const value = { selectedTab, setSelectedTab };

  const { Provider } = TabContext;

  const childrenWithProps = React.Children.map(children, (child, index) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        index
      });
    }

    return child;
  });

  return (
    <Provider value={value}>
      <TabsWrapper>{childrenWithProps}</TabsWrapper>
    </Provider>
  );
};
