import { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Box } from '../../../components/Box';
import { Button } from '../../../components/Button';
import { BUTTON_SIZE } from '../../../components/Button/Button.types';
import { ContentWrapper } from '../../../components/Layout';
import { Heading, Subtitle } from '../../../components/Typography';
import {
  userTracking,
  UserTrackingEvent
} from '../../../shared/utils/user-tracking';
import { InstallationAddressProps } from './InstallationAddress.types';
import { InstallationAddressForm } from '../../../components/InstallationAddressForm';
import { Nullable } from '../../../types/common';
import { useNotification } from '../../../shared/utils/notifications/notification-context';
import {
  getLeadOrder,
  usePlaceLeadOrder,
  useRecalculateLeadOrderProxScore,
  useValidateLeadOrderInstallationAddress
} from '../../../shared/api/Order/Order.queries';
import {
  InstallationAddressFormValues,
  SubmissionError
} from '../../../components/InstallationAddressForm/InstallationAddressForm.types';
import { AlertVariant } from '../../../components/Alert/Alert.types';
import { LeadOrderStatus } from '../../../shared/api/Order/Order.types';

export const InstallationAddress: FC<InstallationAddressProps> = ({
  countryData,
  handleNextStep
}) => {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const [submissionError, setError] = useState<SubmissionError>();
  const [formData, setFormData] =
    useState<Nullable<InstallationAddressFormValues>>(null);
  const { addNotification } = useNotification();

  useEffect(() => {
    userTracking(UserTrackingEvent.RegisteredUser);
  }, []);

  const hasPreviousRoute = history.length > 2;

  const goBack = () => {
    if (hasPreviousRoute) {
      history.goBack();
    } else {
      history.push('/dashboard');
    }
  };

  const { mutateAsync: validateInstallationAddress } =
    useValidateLeadOrderInstallationAddress({
      onError: (error) => {
        setError({
          fieldName: 'installationAddress',
          message: error.message
        });
      }
    });

  const { mutateAsync: recalculateProxScore } =
    useRecalculateLeadOrderProxScore();

  const { mutate: placeLeadOrder, isLoading } = usePlaceLeadOrder({
    onSuccess: async (data) => {
      const leadOrder = await getLeadOrder(data.leadOrderId);

      handleNextStep?.({ ...formData, leadOrder });
    },
    onError: async (error, variables) => {
      if (error.errorCode === 409) {
        const { existingLeadOrderId } = await validateInstallationAddress({
          installationAddress: variables.installationAddress.googleMapsObject
        });
        const existingLeadOrder = await getLeadOrder(existingLeadOrderId!);

        if (existingLeadOrder.status !== LeadOrderStatus.Transferred) {
          await recalculateProxScore({ leadOrderId: existingLeadOrderId! });
          const leadOrder = await getLeadOrder(existingLeadOrderId!);

          handleNextStep?.({ ...formData, leadOrder });
        } else {
          setError({
            fieldName: 'installationAddress',
            message: formatMessage({
              defaultMessage:
                'You have already created a request for given installation address',
              id: 'applyForACoolspot.existedInstallationAddress'
            })
          });
        }
      } else if (error.message) {
        addNotification({
          id: 'orderError',
          variant: AlertVariant.Danger,
          text: error.message
        });
      }
    }
  });

  const handleOnSubmit = (data: InstallationAddressFormValues) => {
    const selectedCountry = data.installationAddress?.address_components.find(
      ({ types }) => types.includes('country')
    );
    const countryCode = selectedCountry?.short_name;

    const newData = {
      ...data,
      countryIsSupported: !!countryData?.find(
        ({ code }) => code === countryCode
      )?.isSupported
    };

    setFormData(newData);

    if (data.installationAddress) {
      placeLeadOrder({
        installationAddress: { googleMapsObject: data.installationAddress }
      });
    }
  };

  return (
    <ContentWrapper>
      <Heading size="h4" element="h1" variant="grey">
        <FormattedMessage
          id="installationAddress.title"
          defaultMessage="Qualify your new CoolSpot™ installation address."
        />
      </Heading>
      <Box mt="m">
        <Subtitle size="big" variant="grey" whiteSpace="pre-line">
          <FormattedMessage
            id="installationAddress.subtitle"
            defaultMessage={
              'Please type the desired installation address for your new CoolSpot™.\n\nYou may move the pin on the map to clarify the correct address if needed. '
            }
          />
        </Subtitle>
      </Box>
      <InstallationAddressForm
        onSubmit={handleOnSubmit}
        isLoading={isLoading}
        submissionError={submissionError}
      />
      <Box mt="m">
        <Button
          onClick={goBack}
          isPlain
          isPrimary={false}
          fullWidth
          size={BUTTON_SIZE.BIG}
        >
          <FormattedMessage
            id="installationAddress.backButton"
            defaultMessage="Back"
          />
        </Button>
      </Box>
    </ContentWrapper>
  );
};
