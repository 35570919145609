import styled from 'styled-components';

import { Box } from '../../../../components/Box';

export const FormContainer = styled.div`
  width: 100%;
`;

export const FormElementWrapper = styled(Box)`
  margin-top: ${({ theme }) => theme.margin.l};

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    margin-top: ${({ theme }) => theme.margin.xl};
  }

  @media (${({ theme }) => theme.breakpoints.phone}) {
    margin-top: ${({ theme }) => theme.margin.l};
  }
`;

export const MobileAppLinkBox = styled(Box)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
`;
