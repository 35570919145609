import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { AlertVariant } from '../../../../../components/Alert/Alert.types';
import { Box } from '../../../../../components/Box';
import { Button } from '../../../../../components/Button';
import { ValidateLayout } from '../../../../../components/Layout/ValidateLayout/ValidateLayout';
import { StepProps } from '../../../../../components/Stepper';
import { VerificationInput } from '../../../../../components/VerificationInput';
import { ErrorCodes } from '../../../../../shared/api/error-codes';
import {
  useSendWaitlistCode,
  useWaitlistEmailVerify
} from '../../../../../shared/api/Identity/Identity.queries';
import { InputWrapper } from '../../../ForgotPassword/NewPassword/NewPassword.styles';
import { useNotification } from '../../../../../shared/utils/notifications/notification-context';

export const WaitlistEmailValidate: FC<StepProps> = ({
  handleNextStep,
  handlePreviousStep,
  previousStepValues
}) => {
  const { formatMessage } = useIntl();
  const { addNotification } = useNotification();

  const {
    formState: { errors },
    register,
    handleSubmit,
    setValue,
    setError
  } = useForm<{ code: string }>({
    mode: 'onBlur'
  });

  useEffect(() => {
    register('code', { required: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { mutate: verifyCode, isLoading: isVerifyCodeLoading } =
    useWaitlistEmailVerify({
      onError: (apiError) => {
        setError('code', {
          message: apiError.message,
          type: 'manual'
        });
      },
      onSuccess: () => {
        if (handleNextStep) {
          handleNextStep();
        }
      }
    });

  const { mutate: sendCode } = useSendWaitlistCode({
    onError: (error) => {
      const { response } = error;

      if (
        response?.data?.errorCode === ErrorCodes.WAIT_BEFORE_NEXT_CODE_RESEND
      ) {
        addNotification({
          id: 'legacyUserEmailVerifyError',
          text: response?.data?.message,
          variant: AlertVariant.Danger
        });
      } else if (response?.statusCode >= 400) {
        addNotification({
          id: 'legacyUserEmailVerifyError',
          text: response?.message,
          variant: AlertVariant.Danger
        });
      }
    },
    onSuccess: () => {
      addNotification({
        id: 'legacyUserEmailVerifySuccess',
        text: formatMessage({
          id: 'legacyUserEmailVerifySuccess',
          defaultMessage:
            'Please check your email inbox for a verification code.'
        }),
        variant: AlertVariant.Success
      });
    }
  });

  return (
    <ValidateLayout
      enteredEmail={previousStepValues?.email}
      onResendCode={() => sendCode({ email: previousStepValues?.email })}
    >
      <form
        onSubmit={handleSubmit(({ code }) =>
          verifyCode({ code, email: previousStepValues?.email })
        )}
      >
        <InputWrapper>
          <VerificationInput
            name="Verification Code"
            error={errors.code?.message}
            onChange={async (value) => {
              setValue('code', value);
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <Button
            isPrimary
            fullWidth
            isLoading={isVerifyCodeLoading}
            type="submit"
          >
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <FormattedMessage
              id="resetPassword2.verify"
              defaultMessage="Verify"
            />
          </Button>
        </InputWrapper>
      </form>
      <Box mt="s">
        <Button
          fullWidth
          onClick={() => handlePreviousStep?.()}
          disabled={isVerifyCodeLoading}
        >
          <FormattedMessage id="resetPassword2.back" defaultMessage="Back" />
        </Button>
      </Box>
    </ValidateLayout>
  );
};
