import { FC } from 'react';

import { StatusIndicator, StatusLabel } from './Status.styles';
import { StatusProps } from './Status.types';

export const Status: FC<StatusProps> = ({ status }) => {
  return (
    <StatusLabel status={status}>
      <StatusIndicator status={status} />
      <span>{status}</span>
    </StatusLabel>
  );
};
