import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { FC } from 'react';

import { Box } from '../../../../components/Box';
import { RadioGroup } from '../../../../components/RadioGroup';
import { Button, LinkButton } from '../../../../components/Button';
import { useFeedbackValidation } from './FeedbackForm.validation';
import { FeedbackValues } from './FeedbackForm.types';
import { TextArea } from '../../../../components/TextArea';

export const FeedbackForm: FC<{
  handleSubmit: (data: any) => void;
  isLoading: boolean;
}> = ({ handleSubmit, isLoading }) => {
  const { formatMessage } = useIntl();

  const feedbackOptions = [
    {
      label: formatMessage({
        defaultMessage: 'The product is confusing',
        id: 'feedback.optionOne'
      }),
      value: FeedbackValues.ConfusingProduct
    },
    {
      label: formatMessage({
        defaultMessage: 'Not sure I’m tech savvy enough to pull this off',
        id: 'feedback.optionTwo'
      }),
      value: FeedbackValues.NotSureIfTechSavvyEnough
    },
    {
      label: formatMessage({
        defaultMessage: 'Concerned it will take to long to get hardware',
        id: 'feedback.optionThree'
      }),
      value: FeedbackValues.TooLongToGetHardware
    },
    {
      label: formatMessage({
        defaultMessage: 'Unsure it’s worth the effort for the payout',
        id: 'feedback.optionFour'
      }),
      value: FeedbackValues.UnsureIfWorthTheEffort
    },
    {
      label: formatMessage({
        defaultMessage: 'Changed my mind',
        id: 'feedback.optionFive'
      }),
      value: FeedbackValues.ChangedMind
    },
    {
      label: formatMessage({
        defaultMessage: 'Other',
        id: 'feedback.other'
      }),
      value: FeedbackValues.Other
    }
  ];

  const {
    register,
    handleSubmit: onFormSubmit,
    formState: { errors },
    watch
  } = useForm<{
    reason: string;
    reasonMessage?: string;
  }>({
    defaultValues: {
      reason: feedbackOptions[0].value
    },
    mode: 'onChange',
    resolver: yupResolver(useFeedbackValidation())
  });

  const watchReason = watch('reason');

  return (
    <form onSubmit={onFormSubmit(handleSubmit)}>
      <Box mt="xl">
        <RadioGroup
          options={feedbackOptions}
          defaultValue={feedbackOptions[0].value}
          {...register('reason')}
        />
      </Box>
      {watchReason === FeedbackValues.Other && (
        <Box>
          <TextArea
            error={errors.reasonMessage?.message}
            label={formatMessage({
              defaultMessage: 'Tell us more',
              id: 'feedback.tellUsMore'
            })}
            maxLines={4}
            {...register('reasonMessage')}
          />
        </Box>
      )}
      <Box mt="l">
        <Button isPrimary fullWidth type="submit" isLoading={isLoading}>
          <FormattedMessage id="feedback.submit" defaultMessage="Submit" />
        </Button>
      </Box>
      <Box mt="s">
        <LinkButton
          isPrimary={false}
          isPlain
          fullWidth
          external
          to="https://emrit.io/"
          openInNewTab={false}
        >
          <FormattedMessage
            id="feedback.leave"
            defaultMessage="Leave without feedback"
          />
        </LinkButton>
      </Box>
    </form>
  );
};
