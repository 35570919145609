import styled, { css } from 'styled-components';

import { Caption, Text } from '../../../../../components/Typography';
import { getTypography } from '../../../../../shared/utils/style-utils';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledPrice = styled(Text)`
  margin-top: 4px;
  color: #000;
  ${({ theme }) => getTypography(theme.typography.heading.h5)};
  font-weight: 700;
  white-space: nowrap;
`;

export const StyledCaption = styled(Caption)`
  display: inline-flex;
  align-items: center;
  min-height: 20px;
  color: ${({ theme }) => theme.color.grey};
  line-height: 16px;
`;

export const UpgradedLabel = styled.span`
  display: inline-flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.margin.xxs};
  color: ${({ theme }) => theme.color.green};
  font-weight: 700;
`;

export const EarningFactor = styled.span<{ isUpgraded?: boolean }>`
  display: inline-block;
  margin-left: 4px;
  ${(isUpgraded) =>
    !isUpgraded &&
    css`
      font-weight: 700;
      color: ${({ theme }) => theme.color.black};
    `}
`;

export const Currency = styled.span`
  font-size: 8px;
  font-weight: 400;
`;

export const ValueContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
