import { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Skeleton from 'react-loading-skeleton';

import { Statistics } from '../Statistics/Statistics';
import {
  TabContent,
  TabContentList,
  TabContext,
  Tabs
} from '../../../../../components/Tabs';
import { Earnings } from '../../../../../shared/api/Wallet/Wallet.types';
import { useGetCoolspotEarningsData } from '../../../../../shared/api/Wallet/Wallet.queries';
import { StyledTab, StyledTabList, TabBox } from './EarningsTabs.styles';
import { EarnignsTabsProps } from './EarningsTabs.types';

const EarningsTabsKeys = ['current', '24h', '7days', '30days'];

export const EarningsTabs: FC<EarnignsTabsProps> = ({
  deviceId,
  isUpgraded
}) => {
  const { formatMessage } = useIntl();
  const [currentTab, setCurrentTab] = useState<keyof Earnings>('current');
  const [fetchedData, setFetchedData] = useState<Partial<Earnings>>({});

  const {
    data,
    isLoading: isCoolspotEarningsLoading,
    error: earningsError
  } = useGetCoolspotEarningsData(deviceId, currentTab);

  const isLoading = earningsError || isCoolspotEarningsLoading || !data;

  if (!fetchedData[currentTab] && data?.earnings[currentTab] && !isLoading) {
    setFetchedData({
      ...fetchedData,
      ...data?.earnings
    });
  }

  const LoadingEarningsSkeleton: FC = () => (
    <>
      <Skeleton width={110} height={14} />
      <Skeleton width={140} height={29} />
    </>
  );

  return (
    <Tabs>
      <TabContext.Consumer>
        {({ selectedTab }) => {
          const period = EarningsTabsKeys[selectedTab] as keyof Earnings;

          if (currentTab !== period) {
            setCurrentTab(period);
          }

          return (
            <>
              {fetchedData.current && (
                <StyledTabList>
                  <StyledTab>
                    <FormattedMessage
                      id="dashboard.deviceEarningsCurrent"
                      defaultMessage="Current"
                    />
                  </StyledTab>
                  <StyledTab>
                    <FormattedMessage
                      id="dashboard.deviceEarningsLastDay"
                      defaultMessage="24 hours"
                    />
                  </StyledTab>
                  <StyledTab>
                    <FormattedMessage
                      id="dashboard.deviceEarningsLastWeek"
                      defaultMessage="7 days"
                    />
                  </StyledTab>
                  <StyledTab>
                    <FormattedMessage
                      id="dashboard.deviceEarningsLastMonth"
                      defaultMessage="30 days"
                    />
                  </StyledTab>
                </StyledTabList>
              )}
              {isLoading && !fetchedData.current && (
                <Skeleton width={326} height={39} />
              )}
              <TabContentList>
                <TabContent>
                  <TabBox>
                    {!isLoading && fetchedData.current && (
                      <Statistics
                        bigCurrency
                        earningFactor={fetchedData.earningsFactor}
                        isUpgraded={isUpgraded}
                        message={formatMessage({
                          defaultMessage: 'Current Earnings',
                          id: 'dashboard.deviceEarningsCurrentEarnings'
                        })}
                        value={fetchedData.current.amount}
                      />
                    )}
                    {isLoading && !fetchedData.current && (
                      <LoadingEarningsSkeleton />
                    )}
                  </TabBox>
                </TabContent>
                <TabContent>
                  <TabBox>
                    {!isLoading && fetchedData['24h'] && (
                      <Statistics
                        bigCurrency
                        message={formatMessage({
                          defaultMessage: 'Daily Earnings',
                          id: 'dashboard.deviceEarningsLastDay'
                        })}
                        value={fetchedData['24h'].amount}
                      />
                    )}
                    {isLoading && !fetchedData['24h'] && (
                      <LoadingEarningsSkeleton />
                    )}
                  </TabBox>
                </TabContent>
                <TabContent>
                  <TabBox>
                    {!isLoading && fetchedData['7days'] && (
                      <Statistics
                        bigCurrency
                        message={formatMessage({
                          defaultMessage: 'Weekly Earnings',
                          id: 'dashboard.deviceEarningsLastWeek'
                        })}
                        value={fetchedData['7days'].amount}
                      />
                    )}
                    {isLoading && !fetchedData['7days'] && (
                      <LoadingEarningsSkeleton />
                    )}
                  </TabBox>
                </TabContent>
                <TabContent>
                  <TabBox>
                    {!isLoading && fetchedData['30days'] && (
                      <Statistics
                        bigCurrency
                        message={formatMessage({
                          defaultMessage: 'Monthly Earnings',
                          id: 'dashboard.deviceEarningsLastMonth'
                        })}
                        value={fetchedData['30days'].amount}
                      />
                    )}
                    {isLoading && !fetchedData['30days'] && (
                      <LoadingEarningsSkeleton />
                    )}
                  </TabBox>
                </TabContent>
              </TabContentList>
            </>
          );
        }}
      </TabContext.Consumer>
    </Tabs>
  );
};
