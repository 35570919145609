import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { ConsentCheckboxes } from '../ConsentCheckboxes/ConsentCheckboxes';
import { Box } from '../Box';
import { Button } from '../Button';
import { Input } from '../Input';
import { PhoneInput } from '../Input/PhoneInput';
import {
  CreateLeadUserFormProps,
  CreateLeadUserFormValues
} from './CreateLeadUserForm.types';
import { useVerifyEmail } from '../../shared/api/Identity/Identity.queries';
import { useCreateLeadUserValidation } from './CreateLeadUserForm.validation';
import { useQueryParams } from '../../shared/utils/use-query-params';
import { scrollToTop } from '../Layout/TwoColumnsLayout/TwoColumnLayout';

const formDefaultValues: CreateLeadUserFormValues = {
  age: false,
  agreePolicies: false,
  email: '',
  firstName: '',
  lastName: '',
  password: '',
  phone: '',
  installationAddress: '',
  flatNumber: ''
};

export const CreateLeadUserForm: FC<CreateLeadUserFormProps> = ({
  onSubmit,
  defaultValue
}) => {
  const { formatMessage } = useIntl();
  const [params] = useQueryParams({
    ref: ''
  });
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setError,
    setValue,
    trigger,
    formState: { errors, touchedFields },
    watch
  } = useForm<CreateLeadUserFormValues>({
    defaultValues: {
      ...formDefaultValues,
      ...(defaultValue &&
        Object.entries(defaultValue).reduce(
          (acc, [key, value]) =>
            Object.keys(formDefaultValues).includes(key)
              ? { ...acc, [key]: value }
              : acc,
          {}
        ))
    },
    mode: 'onChange',
    resolver: yupResolver(useCreateLeadUserValidation())
  });

  const handleError = (error: Error) => {
    scrollToTop();

    setError('email', {
      message: error.message,
      type: 'manual'
    });
  };

  const { mutate: verifyEmail, isLoading: isVerifyEmailLoading } =
    useVerifyEmail({
      onError: (error) => handleError(error),
      onSuccess: (response) => {
        const values = getValues();

        if (response?.isUserV2) {
          handleError({
            message: formatMessage({
              defaultMessage: 'Email is already associated with an account',
              id: 'signUpForm.emailAlreadyTaken'
            }),
            name: 'emailAlreadyTaken'
          });
        } else {
          onSubmit({
            ...values,
            ...(params.ref && { recommendedByUserId: params.ref })
          });
        }
      }
    });

  const createLeadUserFormSubmit = (values: CreateLeadUserFormValues) => {
    verifyEmail(values.email!);
  };

  const watchEmail = watch('email');

  const isEmailValid = Boolean(
    watchEmail && watchEmail.length > 0 && touchedFields?.email && !errors.email
  );

  const triggerInstallationAddressValidation = useCallback(async () => {
    await trigger('installationAddress');
  }, [trigger]);

  useEffect(() => {
    if (touchedFields.email && touchedFields.country) {
      triggerInstallationAddressValidation();
    }
  }, [
    setValue,
    trigger,
    touchedFields.email,
    touchedFields.country,
    watchEmail,
    triggerInstallationAddressValidation
  ]);

  return (
    <form onSubmit={handleSubmit(createLeadUserFormSubmit)}>
      <Box>
        <Input
          {...register('email')}
          iconLeft="Mail"
          label={formatMessage({
            defaultMessage: 'Email address',
            id: 'createLeadUser.emailAddress'
          })}
          placeholder={formatMessage({
            defaultMessage: 'email@sample.com',
            id: 'createLeadUser.emailFieldPlaceholder'
          })}
          error={errors.email?.message}
          isValid={isEmailValid}
          caption={
            isEmailValid
              ? formatMessage({
                  defaultMessage: 'Email address ok!',
                  id: 'createLeadUser.emailAddressValid'
                })
              : formatMessage({
                  defaultMessage: 'This email will be also your login',
                  id: 'createLeadUser.emailAddressCaption'
                })
          }
        />
      </Box>
      <Box mt="m">
        <Input
          {...register('firstName')}
          iconLeft="Person"
          label={formatMessage({
            defaultMessage: 'First Name',
            id: 'createLeadUser.nameField'
          })}
          placeholder={formatMessage({
            defaultMessage: 'First Name',
            id: 'createLeadUser.nameFieldPlaceholder'
          })}
          error={errors.firstName?.message}
        />
      </Box>
      <Box mt="m">
        <Input
          {...register('lastName')}
          iconLeft="Person"
          label={formatMessage({
            defaultMessage: 'Last Name',
            id: 'createLeadUser.lastNameField'
          })}
          placeholder={formatMessage({
            defaultMessage: 'Last Name',
            id: 'createLeadUser.lastNameFieldPlaceholder'
          })}
          error={errors.lastName?.message}
        />
      </Box>
      <Box mt="m" mb="xl">
        <Controller
          name="phone"
          control={control}
          render={({ fieldState: { error } }) => (
            <PhoneInput control={control} error={error?.message} name="phone" />
          )}
        />
      </Box>
      <ConsentCheckboxes register={register} errors={errors} />
      <Box mt="l">
        <Button
          isPrimary
          fullWidth
          type="submit"
          isLoading={isVerifyEmailLoading}
        >
          <FormattedMessage
            id="createLeadUser.submitButton"
            defaultMessage="Submit"
          />
        </Button>
      </Box>
    </form>
  );
};
