import { useIntl } from 'react-intl';
import * as yup from 'yup';

export const useLoginValidation = () => {
  const { formatMessage } = useIntl();

  return yup.object().shape({
    /* eslint-disable newline-per-chained-call */
    email: yup
      .string()
      .email(
        formatMessage({
          defaultMessage: 'Please enter a valid email address',
          id: 'login.emailValid'
        })
      )
      .required(
        formatMessage({
          defaultMessage: 'Email is a required field',
          id: 'login.emailRequired'
        })
      ),
    password: yup.string().required(
      formatMessage({
        defaultMessage: 'Please enter your password',
        id: 'login.passwordRequired'
      })
    )
    /* eslint-enable newline-per-chained-call */
  });
};
