import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { theme } from '../../../../themes/main';
import { MapLegendContainer } from './MapLegend.styles';
import { LegendItem } from './LegendItem';

export const MapLegend: FC = () => {
  const { formatMessage } = useIntl();

  const layers = useMemo(
    () => [
      {
        key: 'online',
        color: theme.color.blue,
        label: formatMessage({
          defaultMessage: 'Online',
          id: 'deviceStatus.online'
        })
      },
      {
        key: 'offline',
        color: theme.color.secondaryRed,
        label: formatMessage({
          defaultMessage: 'Offline',
          id: 'deviceStatus.offline'
        })
      }
    ],
    [formatMessage]
  );

  return (
    <MapLegendContainer>
      {layers.map(({ color, label, key }) => (
        <LegendItem color={color} label={label} key={key} />
      ))}
    </MapLegendContainer>
  );
};
