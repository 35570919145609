import styled from 'styled-components';

import { Box } from '../../../components/Box';

export const FormElementWrapper = styled(Box)`
  margin-top: ${({ theme }) => theme.margin.l};

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    margin-top: ${({ theme }) => theme.margin.xl};
  }

  @media (${({ theme }) => theme.breakpoints.phone}) {
    margin-top: ${({ theme }) => theme.margin.l};
  }
`;
