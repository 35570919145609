import { useIntl } from 'react-intl';
import * as yup from 'yup';

import { phoneNumberValidator } from '../../../../../shared/utils/phone-validator';

const nameValidator = (value?: string) => {
  if (!value) {
    return false;
  }

  if (value.match(/^\s+.*$|^.*\s+$/) || value.match(/[<>"'&/]/)) {
    return false;
  }

  return true;
};

export const useRegistrationValidation = (
  setPasswordStrength: (value: number) => void
) => {
  const { formatMessage } = useIntl();

  return yup.object().shape({
    /* eslint-disable newline-per-chained-call */
    email: yup
      .string()
      .trim()
      .email(
        formatMessage({
          defaultMessage: 'Email address is not valid',
          id: 'createAccount.emailNotValid'
        })
      )
      .required(
        formatMessage({
          defaultMessage: 'Email address is a required field',
          id: 'createAccount.emailRequired'
        })
      ),
    firstName: yup
      .string()
      .trim()
      .max(50)
      .required(
        formatMessage({
          defaultMessage: 'First name is a required field',
          id: 'createAccount.firstNameRequired'
        })
      )
      .test({
        message: formatMessage({
          defaultMessage: 'First name contains disallowed characters',
          id: 'createAccount.firstNameValidation'
        }),
        name: 'firstNameValidation',
        test: nameValidator
      }),
    lastName: yup
      .string()
      .trim()
      .max(100)
      .required(
        formatMessage({
          defaultMessage: 'Last name is a required field',
          id: 'createAccount.lastNameRequired'
        })
      )
      .test({
        message: formatMessage({
          defaultMessage: 'Last name contains disallowed characters',
          id: 'createAccount.lastNameValidation'
        }),
        name: 'lastNameValidation',
        test: nameValidator
      }),
    password: yup
      .string()
      .test({
        message: formatMessage({
          defaultMessage:
            'Password must contain minimum 8 characters, one number, one special character and at least one capitalise',
          id: 'createAccount.passwordFieldHint'
        }),
        name: 'strength',
        test: (value) => {
          if (!value) {
            setPasswordStrength(0);

            return false;
          }

          // eslint-disable-next-line array-element-newline
          const tests = ['[a-z]+', '[A-Z]+', '\\W+', '[0-9]+', '^.{8,}'];
          let passed = 0;

          tests.forEach((test, index) => {
            if (new RegExp(tests[index]).test(value)) {
              passed += 1;
            }
          });

          setPasswordStrength((passed / 5) * 100);

          return passed === 5;
        }
      })
      .test({
        message: formatMessage({
          defaultMessage: 'Password contains disallowed characters',
          id: 'createAccount.passwordSpecialCharaxters'
        }),
        name: 'passwordCharactersValidation',
        test: (value) => {
          if (value?.match(/[^!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~\w]/)) {
            return false;
          }

          return true;
        }
      }),
    phone: yup
      .string()
      .required(
        formatMessage({
          defaultMessage: 'Phone number is a required field',
          id: 'createAccount.phoneRequired'
        })
      )
      .test({
        message: formatMessage({
          defaultMessage: 'Phone number is not valid',
          id: 'createAccount.phoneValid'
        }),
        name: 'phone-valid',
        test: (value) => {
          if (!value) {
            return false;
          }

          return phoneNumberValidator(value);
        }
      })
    /* eslint-enable newline-per-chained-call */
  });
};
