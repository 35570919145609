import { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Box } from '../../../../components/Box';
import { LinkButton } from '../../../../components/Button';
import { BUTTON_SIZE } from '../../../../components/Button/Button.types';
import { ContentWrapper } from '../../../../components/Layout';
import { CreateLeadUserFormWrapper } from '../../../../components/CreateLeadUserForm/CreateLeadUserForm.styles';
import { Heading, Subtitle } from '../../../../components/Typography';
import { RegistrationForm } from './RegistrationForm';
import {
  ConfirmLeadUserRegistrationFormInitialValues,
  ConfirmLeadUserRegistrationFormValues
} from './RegistrationForm/RegistrationForm.types';
import { EmailValidate } from '../EmailValidate';
import { GetLeadUserByEmailResponse } from '../../../../shared/api/Identity/Identity.types';
import { CreateAccountStepProps } from '../CreateAccountPage.types';
import { FullPageLoader } from '../../../../components/FullPageLoader';

export const CreateAccount: FC<CreateAccountStepProps> = ({
  handleNextStep,
  handlePreviousStep,
  previousStepValues
}) => {
  const { leadOrderId, email, leadUser } = previousStepValues!;

  const [registrationFormInitialValues, setRegistrationFormInitialValues] =
    useState<ConfirmLeadUserRegistrationFormInitialValues>();

  useEffect(() => {
    if (leadUser) {
      setRegistrationFormInitialValues({
        email: email!,
        leadOrderId: leadOrderId!,
        firstName: leadUser.firstName,
        lastName: leadUser.lastName,
        phone: leadUser.phone
      });
    }
  }, [email, leadOrderId, leadUser]);

  const handleEmailValidationSuccess = (data: GetLeadUserByEmailResponse) => {
    setRegistrationFormInitialValues({
      ...data,
      leadOrderId: leadOrderId!
    });
  };

  const handleRegisterSuccess = (
    registrationData: ConfirmLeadUserRegistrationFormValues
  ) => {
    if (handleNextStep) {
      handleNextStep({ registrationData });
    }
  };

  if (!leadUser && !registrationFormInitialValues) {
    return (
      <EmailValidate
        email={email!}
        onVerifySuccess={handleEmailValidationSuccess}
        onBackClick={handlePreviousStep}
      />
    );
  }

  if (!registrationFormInitialValues) {
    return <FullPageLoader />;
  }

  return (
    <ContentWrapper>
      <Heading size="h4" element="h1" variant="grey">
        <FormattedMessage
          id="createAccount.title"
          defaultMessage="Let's create your account"
        />
      </Heading>
      <Box mt="m">
        <Subtitle variant="grey" size="big">
          <FormattedMessage
            id="createAccount.subtitle"
            defaultMessage="Add your information and set up a password for your Emrit account to order a CoolSpot™."
          />
        </Subtitle>
      </Box>
      <CreateLeadUserFormWrapper>
        <RegistrationForm
          onSubmit={handleRegisterSuccess}
          defaultValue={registrationFormInitialValues}
        />
      </CreateLeadUserFormWrapper>
      <Box mt="m">
        <LinkButton
          to="/feedback"
          isPrimary={false}
          fullWidth
          size={BUTTON_SIZE.BIG}
        >
          <FormattedMessage id="createAccount.cancel" defaultMessage="Cancel" />
        </LinkButton>
      </Box>
    </ContentWrapper>
  );
};
