import styled, { css } from 'styled-components';

import { getTypography } from '../../shared/utils/style-utils';
import { StyledTooltip } from '../Tooltip';

export const CopyBoxWrapper = styled.span`
  display: inline-flex;
  position: relative;
  width: 100%;

  font-size: ${({ theme }) => theme.typography.body.small};
  color: ${({ theme }) => theme.color.black};
`;

export const CopyButton = styled.button<{ isActive?: boolean }>`
  position: relative;
  border: 0;
  background: none;

  svg {
    transition: stroke 0.25s ease-in-out;
    stroke: ${({ theme }) => theme.color.grey};

    ${({ isActive }) =>
      isActive &&
      css`
        stroke: ${({ theme }) => theme.color.blue};
      `}
  }
`;

export const CopyBoxTooltip = styled(StyledTooltip)`
  border-radius: 16px;
  gap: 6px;

  box-shadow: ${({ theme }) => theme.shadow.blue.xs};

  ${({ theme }) => getTypography(theme.typography.body.small)};
`;
