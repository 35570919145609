import { FC } from 'react';

import { IconProps } from './Icon.types';
import icons from '../../icons';

export const Icon: FC<IconProps> = ({
  icon,
  color,
  scale,
  translate,
  ...rest
}) => {
  const IconElement = icons({ name: icon });

  if (!IconElement) {
    return null;
  }

  const transformArray = [];

  if (scale) {
    transformArray.push(`scale(${scale})`);
  }

  if (translate) {
    transformArray.push(`translate(${translate})`);
  }
  const transform = transformArray.join(' ') || undefined;

  return <IconElement style={{ stroke: color, transform }} {...rest} />;
};
