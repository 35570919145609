import { FC } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as LogoSvg } from '../../assets/images/Logo.svg';
import { ReactComponent as LogoDarkSvg } from '../../assets/images/LogoDark.svg';
import { ReactComponent as LogoWhiteSvg } from '../../assets/images/LogoWhite.svg';
import { StyledPicture } from './Logo.styles';

export const Logo: FC<{
  isLinked?: boolean;
  variant?: 'default' | 'white' | 'black';
}> = ({ isLinked = true, variant = 'default', ...rest }) => {
  const getVariant = () => {
    switch (variant) {
      case 'white':
        return <LogoWhiteSvg />;
      case 'black':
        return <LogoDarkSvg />;
      default:
        return <LogoSvg />;
    }
  };

  if (isLinked) {
    return (
      <StyledPicture {...rest}>
        <Link to="/">{getVariant()}</Link>
      </StyledPicture>
    );
  }

  return <picture {...rest}>{getVariant()}</picture>;
};
