import styled, { css } from 'styled-components';

export const StyledLabel = styled.label<{ error?: boolean }>`
  display: inline-flex;
  align-items: flex-start;
  font-size: ${({ theme }) => theme.typography.body.small.fontSize};
  color: ${({ theme }) => theme.color.grey};
  cursor: pointer;

  &::before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.9997 3.33334H4.99967C4.0792 3.33334 3.33301 4.07954 3.33301 5.00001V15C3.33301 15.9205 4.0792 16.6667 4.99967 16.6667H14.9997C15.9201 16.6667 16.6663 15.9205 16.6663 15V5.00001C16.6663 4.07954 15.9201 3.33334 14.9997 3.33334Z' stroke='%239E9E9E' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-position: center center;
    background-repeat: no-repeat;
    transition: background-image 0.2s ease-in-out;

    ${({ error }) =>
      error &&
      css`
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.9997 3.33334H4.99967C4.0792 3.33334 3.33301 4.07954 3.33301 5.00001V15C3.33301 15.9205 4.0792 16.6667 4.99967 16.6667H14.9997C15.9201 16.6667 16.6663 15.9205 16.6663 15V5.00001C16.6663 4.07954 15.9201 3.33334 14.9997 3.33334Z' stroke='%23D94B2B' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        border-radius: 3px;
      `}
  }
`;

export const HiddenInput = styled.input`
  opacity: 0;
  margin: 0;
  height: 0;
  width: 0;

  &:focus-visible {
    & + ${StyledLabel}::before {
      outline: ${({ theme }) => theme.color.blue} auto 2px;
    }
  }

  &:checked + ${StyledLabel}::before {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.9997 3.33334H4.99967C4.0792 3.33334 3.33301 4.07954 3.33301 5.00001V15C3.33301 15.9205 4.0792 16.6667 4.99967 16.6667H14.9997C15.9201 16.6667 16.6663 15.9205 16.6663 15V5.00001C16.6663 4.07954 15.9201 3.33334 14.9997 3.33334Z' stroke='%233267F1' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.5 10L9.16667 11.6667L12.5 8.33334' stroke='%233267F1' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
