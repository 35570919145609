import styled from 'styled-components';

import { Subtitle, Text } from '../../../../components/Typography';
import { DashboardBox } from '../../../../components/DashboardBox';

export const StyledDashboardBox = styled(DashboardBox)`
  border-radius: ${({ theme }) =>
    `0 ${theme.borderRadius} ${theme.borderRadius} 0`};

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    border-radius: ${({ theme }) =>
      `0 0 ${theme.borderRadius} ${theme.borderRadius}`};
  }
`;

export const StyledSubtitle = styled(Subtitle)`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.color.white};
`;

export const TabletContainer = styled.div`
  display: flex;
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
  }
`;

export const TabletDashboardBox = styled(DashboardBox)`
  height: 72px;
  min-height: 72px;

  border-top-left-radius: 0;
  border-top-right-radius: 0;

  box-shadow: 0 6px 12px rgba(50, 103, 241, 0.1);

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    height: 164px;
    min-height: 164px;
  }
`;

export const TabletPrice = styled(Text)`
  font-weight: 800;
  color: ${({ theme }) => theme.color.white};

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    padding-left: ${({ theme }) => theme.margin.l};
  }

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: ${({ theme }) => theme.margin.s};
    padding-left: 0;
    font-size: ${({ theme }) => theme.typography.heading.h4.fontSize};
    letter-spacing: ${({ theme }) => theme.typography.heading.h4.letterSpacing};
    line-height: ${({ theme }) => theme.typography.heading.h4.lineHeight};
  }
`;
