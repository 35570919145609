import { FC } from 'react';

import { Icon } from '../../Icon';
import {
  IconWrapper,
  MobileMenuLabel,
  StyledMobileMenuItem,
  Wrapper
} from './MobileMenuItem.styles';
import { MobileMenuItemProps } from './MobileMenuItem.types';

export const MobileMenuItem: FC<MobileMenuItemProps> = ({
  icon,
  onClick,
  children,
  isActive,
  ...props
}) => {
  return (
    <Wrapper {...props}>
      <StyledMobileMenuItem type="button" onClick={onClick}>
        {icon && (
          <IconWrapper>
            <Icon aria-hidden icon={icon} />
          </IconWrapper>
        )}
        <MobileMenuLabel>{children}</MobileMenuLabel>
      </StyledMobileMenuItem>
    </Wrapper>
  );
};
