import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { Box } from '../../../../components/Box';
import { Button, LinkButton } from '../../../../components/Button';
import { BUTTON_SIZE } from '../../../../components/Button/Button.types';
import { PasswordInput } from '../../../../components/Input';
import { ContentWrapper } from '../../../../components/Layout';
import { Heading, Subtitle } from '../../../../components/Typography';
import { StepProps } from '../../../../components/Stepper';
import { InputWrapper } from './NewPassword.styles';
import { useSetNewPassword } from '../../../../shared/api/Identity/Identity.queries';
import { useNewPasswordValidation } from './NewPassword.validation';

export const NewPassword: FC<StepProps> = ({
  handleNextStep,
  previousStepValues
}) => {
  const {
    formState: { errors },
    register,
    handleSubmit: onFormSubmit,
    setError
  } = useForm<{ newPassword: string }>({
    mode: 'onBlur',
    resolver: yupResolver(useNewPasswordValidation())
  });

  const handleError = (error: Error) => {
    setError('newPassword', {
      message: error.message,
      type: 'manual'
    });
  };

  const { mutate, isLoading } = useSetNewPassword({
    onError: (error) => handleError(error),
    onSuccess: () => {
      if (handleNextStep) {
        handleNextStep();
      }
    }
  });

  const handleSubmit = (newPassword: string) => {
    mutate({
      code: previousStepValues?.verificationCode,
      email: previousStepValues?.email,
      password: newPassword
    });
  };

  return (
    <ContentWrapper>
      <Heading size="h4" element="h1" variant="grey">
        <FormattedMessage
          id="resetPassword3.title"
          defaultMessage="Reset password"
        />
      </Heading>
      <Box mt="s">
        <Subtitle variant="grey" size="big">
          <FormattedMessage
            id="resetPassword3.subtitle"
            defaultMessage="Set new password for your account"
          />
        </Subtitle>
      </Box>
      <form
        onSubmit={onFormSubmit((values) => handleSubmit(values.newPassword))}
      >
        <InputWrapper>
          <PasswordInput
            {...register('newPassword')}
            label="New password"
            iconLeft="Lock"
            error={errors.newPassword?.message}
            caption="Password must contain minimum 8 characters, one number, one special character and at least one capitalise"
          />
        </InputWrapper>
        <Box mt="l">
          <Button isPrimary isLoading={isLoading} fullWidth type="submit">
            <FormattedMessage
              id="resetPassword3.button"
              defaultMessage="Reset"
            />
          </Button>
        </Box>
        <Box mt="m">
          <LinkButton
            to="/"
            isPlain
            isPrimary={false}
            fullWidth
            size={BUTTON_SIZE.BIG}
          >
            <FormattedMessage
              id="resetPassword3.cancelButton"
              defaultMessage="Cancel"
            />
          </LinkButton>
        </Box>
      </form>
    </ContentWrapper>
  );
};
