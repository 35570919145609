import styled, { css, DefaultTheme } from 'styled-components';

import { getTypography } from '../../shared/utils/style-utils';
import { FieldVariant } from './FieldCaption.types';

const captionVariantStyle = (theme: DefaultTheme, variant: FieldVariant) =>
  ({
    [FieldVariant.PREFILLED]: css`
      color: ${theme.color.blue};

      svg {
        stroke: ${theme.color.blue};
      }
    `,
    [FieldVariant.DISABLED]: css`
      color: ${theme.color.dimmed};

      svg {
        stroke: ${theme.color.dimmed};
      }
    `,
    [FieldVariant.ERROR]: css`
      color: ${theme.color.secondaryRed};

      svg {
        stroke: ${theme.color.secondaryRed};
      }
    `,
    [FieldVariant.VALID]: css`
      color: ${theme.color.secondaryGreen};

      svg {
        stroke: ${theme.color.secondaryGreen};
      }
    `
  }[variant]);

export const StyledCaption = styled.div<{ variant?: FieldVariant }>`
  ${({ theme }) => getTypography(theme.typography.caption)};

  margin-top: ${({ theme }) => theme.margin.xs};

  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.color.grey};

  svg {
    stroke: ${({ theme }) => theme.color.dimmed};
    flex-shrink: 0;
  }

  ${({ theme, variant }) => variant && captionVariantStyle(theme, variant)}
`;

export const CaptionTextWrapper = styled.span`
  margin-left: ${({ theme }) => theme.margin.xxs};
`;
