/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions
} from 'react-query';

import { Nullable } from '../../../types/common';
import { useAuthMutation, useAuthQuery } from '../../auth/auth-helpers';
import { parsePhoneNumber } from '../../utils/phone-validator';
import { ORDER_SERVICE } from '../endpoints';
import { ErrorCodes } from '../error-codes';
import { fetcher } from '../fetcher';
import { HTTPError } from '../fetcher.types';
import {
  ApplyCouponCodeRequest,
  ApplyCouponCodeResponse,
  Country,
  FetchedLeadOrder,
  GetLeadOrderResponse,
  GetLeadOrdersResponse,
  GetProductVariantsRequest,
  GetProductVariantsResponse,
  GetUpgradedPlanDetailsResponse,
  LeadOrder,
  LeadOrderCheckout,
  LeadOrderCheckoutResponse,
  LeadOrderResponse,
  LeadOrderStatus,
  Order,
  OrderCardData,
  OrderResponse,
  PostponeLeadOrder,
  RecalculateLeadOrderProxScoreProps,
  RecalculateLeadOrderProxScoreResponse,
  UpgradeDeviceRequest,
  UpgradeDeviceResponse,
  UpgradePlanStatus,
  ValidateInstallationAddressResponse,
  ValidateLeadOrderInstallationAddressProps,
  ValidateLeadOrderInstallationAddressResponse
} from './Order.types';

const useFetchCountries = async () => {
  const { data } = await fetcher(`${ORDER_SERVICE}/countries`).json();

  return data;
};

export const useGetCountries = (
  options?: UseQueryOptions<boolean, HTTPError, Country[]>
) => {
  return useQuery('countries', useFetchCountries, {
    staleTime: 1000 * 60 * 60,
    ...options
  });
};

const placeOrder = async (orderBody: Order) => {
  const { data } = await fetcher<OrderResponse>(`${ORDER_SERVICE}/orders`, {
    json: {
      ...orderBody,
      shippingAddress: {
        ...orderBody.shippingAddress,
        phone: parsePhoneNumber(orderBody?.shippingAddress?.phone)
      }
    },
    method: 'POST'
  }).json();

  return data;
};

export const usePlaceOrder = (
  options?: UseMutationOptions<OrderResponse, HTTPError, Order>
) => {
  return useAuthMutation('editWallet', placeOrder, options);
};

export const validateInstallationAddress = async (placeId?: string) => {
  const { data } = await fetcher(
    `${ORDER_SERVICE}/orders/validateInstallationAddress/${placeId}`
  ).json();

  return data;
};

export const recalculateLeadOrderProxScore = async ({
  leadOrderId,
  email
}: RecalculateLeadOrderProxScoreProps) => {
  try {
    const { data } = await fetcher<RecalculateLeadOrderProxScoreResponse>(
      `${ORDER_SERVICE}/lead-orders/${leadOrderId}/recalculate`,
      {
        method: 'PATCH',
        query: {
          email
        }
      }
    ).json();

    return data;
  } catch (error: any) {
    throw new Error(error?.data?.message);
  }
};

export const useRecalculateLeadOrderProxScore = (
  options?: UseMutationOptions<
    RecalculateLeadOrderProxScoreResponse,
    HTTPError,
    RecalculateLeadOrderProxScoreProps
  >
) => {
  return useAuthMutation(
    'recalculateLeadOrderProxScore',
    recalculateLeadOrderProxScore,
    options
  );
};

export const useValidateInstallationAddress = (
  placeId?: string,
  options?: UseQueryOptions<ValidateInstallationAddressResponse, HTTPError>
) => {
  return useAuthQuery<ValidateInstallationAddressResponse>(
    ['validateInstallationAddress', placeId],
    () => validateInstallationAddress(placeId || ''),
    { enabled: false, ...options }
  );
};

const validateLeadOrderInstallationAddress = async ({
  email,
  installationAddress
}: ValidateLeadOrderInstallationAddressProps) => {
  try {
    const { data, status } =
      await fetcher<ValidateLeadOrderInstallationAddressResponse>(
        `${ORDER_SERVICE}/lead-orders/validate-installation-address`,
        {
          json: {
            installationAddress: {
              googleMapsObject: installationAddress
            }
          },
          query: {
            email
          },
          method: 'POST'
        }
      ).json();

    if (status === 200) {
      return data;
    }

    throw new Error();
  } catch (error: any) {
    if (error.errorCode === ErrorCodes.NOT_EXIST) {
      return { existingLeadOrderId: null };
    }

    throw new Error(error?.data?.message);
  }
};

export const useValidateLeadOrderInstallationAddress = (
  options?: UseMutationOptions<
    ValidateLeadOrderInstallationAddressResponse,
    HTTPError,
    ValidateLeadOrderInstallationAddressProps
  >
) => {
  return useMutation(
    'validateLeadOrderInstallationAddress',
    validateLeadOrderInstallationAddress,
    options
  );
};

const leadOrder = async (json: LeadOrder) => {
  const { data } = await fetcher<LeadOrderResponse>(
    `${ORDER_SERVICE}/lead-orders`,
    {
      json,
      method: 'POST'
    }
  ).json();

  return data;
};

export const usePlaceLeadOrder = (
  options?: UseMutationOptions<LeadOrderResponse, HTTPError, LeadOrder>
) => {
  return useAuthMutation('leadOrders', leadOrder, options);
};

const createCheckoutForLeadOrder = async ({
  leadOrderId,
  productVariantId,
  couponCode
}: LeadOrderCheckout) => {
  const { data } = await fetcher(
    `${ORDER_SERVICE}/lead-orders/${leadOrderId}`,
    {
      method: 'PATCH',
      json: { productVariantId, couponCode }
    }
  ).json();

  return data;
};

export const useCreateCheckoutForLeadOrder = (
  options?: UseMutationOptions<
    LeadOrderCheckoutResponse,
    HTTPError,
    LeadOrderCheckout
  >
) => {
  return useAuthMutation(
    'createCheckoutForLeadOrder',
    createCheckoutForLeadOrder,
    options
  );
};

export const getLeadOrders: () => Promise<GetLeadOrdersResponse> = async () => {
  const {
    data: { entities }
  } = await fetcher(`${ORDER_SERVICE}/lead-orders/me`).json();

  return entities;
};

export const useGetLeadOrders = (
  options?: UseQueryOptions<GetLeadOrdersResponse, HTTPError>
) => {
  return useQuery(['leadOrders'], getLeadOrders, options);
};

export const getLeadOrder = async (
  leadOrderId?: string,
  leadOrderEmail?: Nullable<string>
) => {
  const { data } = await fetcher<FetchedLeadOrder>(
    `${ORDER_SERVICE}/lead-orders/${leadOrderId}`,
    leadOrderEmail
      ? {
          query: {
            email: leadOrderEmail
          }
        }
      : {}
  ).json();

  return data;
};

export const useGetLeadOrder = (
  leadOrderId?: string,
  leadOrderEmail?: string,
  options?: UseQueryOptions<GetLeadOrderResponse, HTTPError>
) => {
  return useQuery(
    ['leadOrder', leadOrderId],
    () => getLeadOrder(leadOrderId, leadOrderEmail),
    options
  );
};

export const useGetLeadUserOrder = (
  leadOrderId: string,
  leadOrderEmail: string,
  options?: UseQueryOptions<GetLeadOrderResponse, HTTPError>
) => {
  return useQuery<GetLeadOrderResponse, HTTPError>(
    ['leadOrder', leadOrderId],
    () => getLeadOrder(leadOrderId || '', leadOrderEmail || ''),
    options
  );
};

export const getTransferredLeadOrder = async (leadOrderId?: string) => {
  const { data } = await fetcher(
    `${ORDER_SERVICE}/lead-orders/${leadOrderId}`
  ).json();

  if (data.status !== LeadOrderStatus.Transferred) {
    throw new Error(data.status);
  }

  return data;
};

export const useGetTransferredLeadOrder = (
  leadOrderId?: string,
  options?: UseQueryOptions<GetLeadOrderResponse, HTTPError>
) => {
  return useAuthQuery<GetLeadOrderResponse>(
    ['leadOrder', leadOrderId],
    () => getTransferredLeadOrder(leadOrderId || ''),
    {
      ...options,
      retry: (failureCount, error) =>
        error.message === LeadOrderStatus.CheckoutCreated,
      retryDelay: 5000
    }
  );
};

export const getTransferredLeadOrderWithShopifyData = async (
  leadOrderId?: string
) => {
  const { data } = await fetcher(
    `${ORDER_SERVICE}/lead-orders/${leadOrderId}`
  ).json();

  if (!data?.order?.transaction) {
    throw new Error('the transaction field is null');
  }

  return data;
};

export const useGetTransferredLeadOrderWithShopifyData = (
  leadOrderId?: string,
  options?: UseQueryOptions<GetLeadOrderResponse, HTTPError>
) => {
  return useAuthQuery<GetLeadOrderResponse>(
    ['leadOrder', leadOrderId],
    () => getTransferredLeadOrderWithShopifyData(leadOrderId || ''),
    {
      ...options,
      retry: (failureCount, error) =>
        error.message === 'the transaction field is null',
      retryDelay: 5000
    }
  );
};

export const removeLeadOrderFromWaitList = async ({
  leadOrderId,
  email
}: any) => {
  const { data } = await fetcher(
    `${ORDER_SERVICE}/lead-orders/${leadOrderId}`,
    {
      method: 'DELETE',
      query: {
        email
      }
    }
  ).json();

  return data;
};

export const useRemoveLeadOrderFromWaitList = (
  options?: UseMutationOptions<
    boolean,
    HTTPError,
    { leadOrderId?: string; email?: string }
  >
) => {
  return useMutation(
    'removeLeadOrderFromWaitList',
    removeLeadOrderFromWaitList,
    options
  );
};

const postponeLeadOrder = async ({
  leadOrderId,
  leadOrderEmail
}: PostponeLeadOrder) => {
  const { data } = await fetcher(
    `${ORDER_SERVICE}/lead-orders/${leadOrderId}/let-me-know-next-time`,
    {
      method: 'PATCH',
      query: {
        email: leadOrderEmail
      }
    }
  ).json();

  return data;
};

export const usePostponeLeadOrder = (
  options?: UseMutationOptions<unknown, HTTPError, PostponeLeadOrder>
) => {
  return useMutation('postponeLeadOrder', postponeLeadOrder, options);
};

const getOrdersData = async () => {
  const { data } = await fetcher<OrderCardData[]>(
    `${ORDER_SERVICE}/orders`
  ).json();

  return data;
};

export const useGetOrdersData = (
  options?: UseQueryOptions<OrderCardData[], HTTPError>
) => {
  return useAuthQuery('getOrdersData', getOrdersData, {
    ...options
  });
};

const createCheckoutToUpgradeDevice = async ({
  devices,
  couponCode
}: UpgradeDeviceRequest) => {
  const { data } = await fetcher(`${ORDER_SERVICE}/upgrade-plans`, {
    json: {
      data: devices,
      couponCode
    },
    method: 'POST'
  }).json();

  return data;
};

export const useCreateCheckoutToUpgradeDevice = (
  options?: UseMutationOptions<
    UpgradeDeviceResponse,
    HTTPError,
    UpgradeDeviceRequest
  >
) => {
  return useAuthMutation(
    'createCheckoutToUpgradeDevice',
    createCheckoutToUpgradeDevice,
    options
  );
};

export const getUpgradedPlanDetails = async (coolspotId?: string) => {
  const { data } = await fetcher(
    `${ORDER_SERVICE}/upgrade-plans/${coolspotId}`
  ).json();

  if (data.status !== UpgradePlanStatus.Fulfilled) {
    throw new Error(data.status);
  }

  return data;
};

export const useGetUpgradedPlanDetails = (
  coolspotId?: string,
  options?: UseQueryOptions<GetUpgradedPlanDetailsResponse, HTTPError>
) => {
  return useAuthQuery<GetUpgradedPlanDetailsResponse>(
    ['upgradedPlanDetails', coolspotId],
    () => getUpgradedPlanDetails(coolspotId || ''),
    {
      ...options,
      retry: (failureCount, error) =>
        error.message !== UpgradePlanStatus.Fulfilled &&
        error.message !== UpgradePlanStatus.Cancelled &&
        error.message !== UpgradePlanStatus.Failed,
      retryDelay: 5000
    }
  );
};
export const getProductVariants = async (query: GetProductVariantsRequest) => {
  const { data } = await fetcher(`${ORDER_SERVICE}/product-variants/`, {
    query
  }).json();

  return data;
};

export const useGetProductVariants = (
  query: GetProductVariantsRequest,
  options?: UseQueryOptions<GetProductVariantsResponse, HTTPError>
) => {
  return useAuthQuery(
    ['productVariants', query],
    () => getProductVariants(query),
    options
  );
};

const applyCouponCode = async ({
  couponCode,
  productVariantIds
}: ApplyCouponCodeRequest) => {
  await fetcher(`${ORDER_SERVICE}/coupon-codes/validate`, {
    json: {
      code: couponCode,
      productVariantIds
    },
    method: 'POST'
  }).json();

  return {
    couponCode,
    productVariantIds
  };
};

export const useApplyCouponCode = (
  options?: UseMutationOptions<
    ApplyCouponCodeResponse,
    HTTPError,
    ApplyCouponCodeRequest
  >
) => {
  return useAuthMutation('applyCouponCode', applyCouponCode, options);
};
