import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { AlertVariant } from '../../../../components/Alert/Alert.types';
import { Button } from '../../../../components/Button';
import { BUTTON_SIZE } from '../../../../components/Button/Button.types';
import { Content, LeadOrderAlert } from './LeadOrderCard.styles';
import { LeadOrderCardProps } from './LeadOrderCard.types';
import { Box } from '../../../../components/Box';

export const LeadOrderCard: FC<LeadOrderCardProps> = ({
  daysLeft,
  id,
  installationAddress
}) => {
  const history = useHistory();

  const handleOrderACoolspot = (leadOrderId: string) => {
    history.push(`/order-a-coolspot?leadOrderId=${leadOrderId}`);
  };

  const handleRemoveMeFromTheWaitlist = (leadOrderId: string) => {
    history.push(`/remove-from-wait-list?leadOrderId=${leadOrderId}`);
  };

  return (
    <LeadOrderAlert
      key={id}
      variant={AlertVariant.Primary}
      renderNavItems={() => (
        <>
          <Button
            fullWidth
            isPrimary
            size={BUTTON_SIZE.SMALL}
            variant="blueOverBlueBackground"
            onClick={() => handleOrderACoolspot(id)}
          >
            <FormattedMessage
              id="devices.leadOrderCardOrderACoolspot"
              defaultMessage="Order a CoolSpot™"
            />
          </Button>
          <Box mt="xxs">
            <Button
              fullWidth
              size={BUTTON_SIZE.SMALL}
              variant="blueOverBlueBackground"
              onClick={() => handleRemoveMeFromTheWaitlist(id)}
            >
              <FormattedMessage
                id="devices.leadOrderCardRemoveFromWaitlist"
                defaultMessage="Remove me from the waitlist"
              />
            </Button>
          </Box>
        </>
      )}
    >
      <Content>
        <FormattedMessage
          id="devices.approvedLeadOrderCopy"
          defaultMessage="We have a CoolSpot™ you applied for address: {address}."
          values={{
            address: (
              <>
                <br />
                <strong>{installationAddress}</strong>
              </>
            )
          }}
        />
        <br />
        <FormattedMessage
          id="devices.daysToOrder"
          defaultMessage="You have {daysLeftString} to order it."
          values={{
            daysLeftString:
              daysLeft && daysLeft > 1 ? `${daysLeft} days` : `the last day`
          }}
        />
      </Content>
    </LeadOrderAlert>
  );
};
