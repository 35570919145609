import { FC } from 'react';

import { StyledLink } from './Link.styles';
import { LinkProps } from './Link.types';

export const Link: FC<LinkProps> = ({
  to,
  size = 'big',
  children,
  ...rest
}) => {
  return (
    <StyledLink size={size} to={to} {...rest}>
      {children}
    </StyledLink>
  );
};
