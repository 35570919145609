import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { BUTTON_SIZE } from '../../components/Button/Button.types';
import { EmptyStateLayout } from '../../components/Layout/EmptyStateLayout';
import { EmptyLayoutSingleButtonRow } from '../../components/Layout/EmptyStateLayout/EmptyStateLayout';
import { Heading } from '../../components/Typography';
import { useAuth } from '../../shared/auth/auth-context';
import {
  ContentContainer,
  StyledHeading,
  StyledLink,
  StyledLinkButton
} from './ErrorPage.styles';
import { Copies, ErrorPageProps, ErrorPageType } from './ErrorPage.types';

const copies: Copies = {
  [ErrorPageType.LinkInvalid]: {
    subtitle: (
      <FormattedMessage
        id="errorPage.linkNoLongerValid"
        defaultMessage="This link is no longer valid"
      />
    )
  },
  [ErrorPageType.OrderNotFound]: {
    header: (
      <FormattedMessage
        id="errorPage.orderNotFound"
        defaultMessage="Order not found"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="errorPage.wrongUser"
        defaultMessage="You might be logged in as the wrong user."
      />
    ),
    copy: (
      <FormattedMessage
        id="errorPage.orderNotFoundCopy"
        defaultMessage="Please check the email and login with that account. If you still have issues email us at {email}"
        values={{
          email: (
            <StyledLink href="mailto:success@emrit.io">
              success@emrit.io
            </StyledLink>
          )
        }}
      />
    )
  },
  [ErrorPageType.Default]: {
    header: (
      <FormattedMessage id="errorPage.heading" defaultMessage="We're sorry" />
    ),
    subtitle: (
      <FormattedMessage
        id="errorPage.somethingWentWrong"
        defaultMessage="Something went wrong"
      />
    ),
    copy: (
      <FormattedMessage
        id="errorPage.subtitle2"
        defaultMessage="Please try again and if you still have issues email us at {email}"
        values={{
          email: (
            <StyledLink href="mailto:success@emrit.io">
              success@emrit.io
            </StyledLink>
          )
        }}
      />
    )
  }
};

export const ErrorPage: FC<ErrorPageProps> = ({
  isOverlay,
  type = ErrorPageType.Default
}) => {
  const { isLoggedIn } = useAuth();

  const activeCopy = copies[type];

  const dashboardLink = isLoggedIn() ? '/dashboard' : '/login';

  return (
    <EmptyStateLayout isOverlay={isOverlay}>
      <ContentContainer>
        <StyledHeading size="h2" align="center" variant="white">
          {activeCopy.header || copies[ErrorPageType.Default].header}
        </StyledHeading>

        <Heading size="h5" variant="white" align="center">
          {activeCopy.subtitle || copies[ErrorPageType.Default].subtitle}
        </Heading>
        <Heading size="h5" variant="white" align="center">
          {activeCopy.copy || copies[ErrorPageType.Default].copy}
        </Heading>
      </ContentContainer>
      <EmptyLayoutSingleButtonRow>
        <StyledLinkButton
          to={dashboardLink}
          fullWidth
          isPrimary
          size={BUTTON_SIZE.SMALL}
        >
          <FormattedMessage id="goHome" defaultMessage="Got it" />
        </StyledLinkButton>
      </EmptyLayoutSingleButtonRow>
    </EmptyStateLayout>
  );
};
