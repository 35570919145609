import { forwardRef } from 'react';

import { IconInputProps } from '../Input.types';
import {
  IconInputWrapper,
  IconLeftWrapper,
  IconRightWrapper,
  StyledIconInput
} from './IconInput.styles';
import { Icon } from '../../Icon';

export const IconInput = forwardRef<HTMLInputElement, IconInputProps>(
  ({ variant, iconLeft, iconRight, onIconClick, ...props }, ref) => {
    return (
      <IconInputWrapper variant={variant}>
        {iconLeft && (
          <IconLeftWrapper onClick={onIconClick}>
            <Icon aria-hidden icon={iconLeft} />
          </IconLeftWrapper>
        )}
        <StyledIconInput
          iconLeft={!!iconLeft}
          iconRight={!!iconRight}
          variant={variant}
          ref={ref}
          {...props}
        />
        {iconRight && (
          <IconRightWrapper onClick={onIconClick}>
            {typeof iconRight === 'string' ? (
              <Icon aria-hidden icon={iconRight} />
            ) : (
              <>{iconRight}</>
            )}
          </IconRightWrapper>
        )}
      </IconInputWrapper>
    );
  }
);
