import { FC, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { Alert } from '../../../components/Alert';
import { AlertVariant } from '../../../components/Alert/Alert.types';
import { Box } from '../../../components/Box';
import { Button, LinkButton } from '../../../components/Button';
import { ContentWrapper } from '../../../components/Layout';
import { Link } from '../../../components/Link';
import { Heading, Text } from '../../../components/Typography';
import { LeadOrderStatus } from '../../../shared/api/Order/Order.types';
import { AddressBox } from '../../../components/AddressBox';
import OrderInvalid from '../OrderInvalid';
import OrderNotFound from '../OrderNotFound';
import {
  userTracking,
  UserTrackingEvent
} from '../../../shared/utils/user-tracking';
import { OrderACoolspotStepProps } from '../OrderACoolspot.types';

export const ReadyToShip: FC<OrderACoolspotStepProps> = ({
  handleNextStep,
  previousStepValues
}) => {
  const { leadOrder, email } = previousStepValues || {};

  useEffect(() => {
    userTracking(UserTrackingEvent.RegisteredUser);
  }, []);

  const confirmOrder = () => {
    if (handleNextStep) {
      handleNextStep();
    }
  };

  if (leadOrder?.status === LeadOrderStatus.Transferred) {
    return <OrderNotFound />;
  }

  if (
    leadOrder?.status === LeadOrderStatus.NotResponded ||
    leadOrder?.status === LeadOrderStatus.Postponed
  ) {
    return (
      <OrderInvalid
        email={email}
        leadOrderId={leadOrder.id}
        leadOrderStatus={leadOrder.status}
      />
    );
  }

  return (
    <ContentWrapper>
      <Heading size="h4" element="h1" variant="grey">
        <FormattedMessage
          id="orderACoolspot.title"
          defaultMessage="Ready to order? We have stock ready to ship."
        />
      </Heading>
      <Box mt="l">
        <Text level="small" whiteSpace="pre-line">
          <FormattedMessage
            id="orderACoolspot.subtitle"
            defaultMessage={
              'On the following pages, you will select an earnings plan and complete the order for your CoolSpot™.\n\nCoolSpot™ installation address'
            }
          />
        </Text>
      </Box>
      <AddressBox mt="l">{leadOrder?.installationAddress}</AddressBox>
      <Box mt="l">
        <Alert variant={AlertVariant.Secondary}>
          <FormattedMessage
            id="orderACoolspot.infoBox"
            defaultMessage="If you want to add a different CoolSpot™ Installation Address please fill a form Apply for a CoolSpot™ again so we can check your eligability."
          />{' '}
          <Link to="/apply-for-a-coolspot">
            <FormattedMessage
              id="orderACoolspot.infoBox"
              defaultMessage="Click here"
            />
          </Link>
        </Alert>
      </Box>
      <Box mt="xl">
        <Button isPrimary fullWidth onClick={confirmOrder}>
          <FormattedMessage
            id="orderACoolspot.orderMyCoolspotButtonLabel"
            defaultMessage="Choose my plan"
          />
        </Button>
      </Box>
      <Box mt="m">
        <LinkButton to="/dashboard" isPrimary={false} fullWidth variant="blue">
          <FormattedMessage
            id="orderACoolspot.goToDashboardButtonLabel"
            defaultMessage="Go to dashboard"
          />
        </LinkButton>
      </Box>
      <Box mt="m">
        <Text level="small">
          <FormattedMessage
            id="orderACoolspot.unsubscribe"
            defaultMessage="If you don’t want to be on the list for the CoolSpot™ on this installation address {clickHere}"
            values={{
              clickHere: (
                <Link
                  to={`/remove-from-wait-list?leadOrderId=${leadOrder?.id}`}
                >
                  <FormattedMessage
                    id="orderACoolspot.unsubscribeLink"
                    defaultMessage="click here"
                  />
                </Link>
              )
            }}
          />
        </Text>
      </Box>
    </ContentWrapper>
  );
};
