import { FC } from 'react';

import { Icon } from '../../../../../components/Icon';
import { AntennaValueProps } from './AntennaValue.types';
import { AntennaContainer, Value } from './AntennaValue.styles';

export const AntennaValue: FC<AntennaValueProps> = ({ dbi = 0 }) => (
  <AntennaContainer>
    <Icon icon="AntennaOn" />
    <Value>{dbi}dBi</Value>
  </AntennaContainer>
);
