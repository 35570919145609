import styled, { css } from 'styled-components';

import { TextProps } from './Text.types';
import { getTypography } from '../../../shared/utils/style-utils';

export const StyledText = styled.p<TextProps>`
  margin: 0;
  padding: 0;

  ${({ theme, level }) => getTypography(theme.typography.body[level])};
  color: ${({ theme, color = 'grey' }) => theme.color[color]};

  ${({ type, theme }) =>
    type === 'link' &&
    css`
      color: ${theme.color.darkBlue};
      font-weight: 700;
    `}

  ${({ whiteSpace }) =>
    whiteSpace &&
    css`
      white-space: ${whiteSpace};
    `}
`;
