import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll
} from 'body-scroll-lock';
import { FC, useEffect, useRef, useState } from 'react';

import { useBreakpoints } from '../../../shared/utils/use-breakpoints';
import { MobileMenu } from '../../MobileMenu';
import { Navbar } from '../../Navbar';
import { LayoutInnerWrapper } from './FullPageLayout.styles';

export const FullPageLayout: FC = ({ children }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const { isTablet } = useBreakpoints();
  const mobileMenuRef = useRef<HTMLElement>();

  useEffect(() => {
    return () => clearAllBodyScrollLocks();
  }, []);

  const toggleMenu = (isOpen: boolean) => {
    if (isOpen) {
      setMenuOpen(true);
      disableBodyScroll(mobileMenuRef.current!);
    } else {
      enableBodyScroll(mobileMenuRef.current!);
      setMenuOpen(false);
    }
  };

  return (
    <>
      <Navbar onMenuOpen={() => setMenuOpen(true)} />
      <LayoutInnerWrapper>{children}</LayoutInnerWrapper>
      {isTablet && (
        <MobileMenu
          ref={mobileMenuRef as any}
          isActive={isMenuOpen}
          onMenuClose={() => toggleMenu(false)}
        />
      )}
    </>
  );
};
