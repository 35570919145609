import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Heading } from '../../../../components/Typography';
import {
  HeadingContainer,
  HeadingControlsContainer,
  HeadingSpacer
} from './HeadingControls.styles';
import { HeadingControlsProps } from './HeadingControls.types';

export const HeadingControls: FC<HeadingControlsProps> = ({ hotspotData }) => (
  <HeadingControlsContainer>
    <HeadingContainer>
      <Heading size="h5" variant="darkBlue">
        {hotspotData?.length}
      </Heading>
      <HeadingSpacer />
      <Heading size="h5">
        <FormattedMessage
          id="dashboard.devicesCountLabel"
          defaultMessage="{count, plural, one {Device} other {Devices}}"
          values={{ count: hotspotData?.length }}
        />
      </Heading>
    </HeadingContainer>
  </HeadingControlsContainer>
);
