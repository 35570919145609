import { forwardRef } from 'react';
import { useId } from '@reach/auto-id';

import { CheckboxWrapper, HiddenInput, StyledLabel } from './Checkbox.styles';
import { FieldCaption, FieldVariant } from '../FieldCaption';
import { CheckboxProps } from './Checkbox.types';

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ children, error, ...props }, ref) => {
    const id = useId(props.id);

    return (
      <CheckboxWrapper>
        <HiddenInput ref={ref} type="checkbox" id={id!} {...props} />
        <StyledLabel htmlFor={id!} error={!!error}>
          {children}
        </StyledLabel>
        {error && (
          <FieldCaption variant={FieldVariant.ERROR}>{error}</FieldCaption>
        )}
      </CheckboxWrapper>
    );
  }
);
