import { FC, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { Box } from '../../../components/Box';
import { LinkButton } from '../../../components/Button';
import {
  BottomSection,
  ContentWrapper,
  SignUpLayout
} from '../../../components/Layout';
import { Heading, Subtitle } from '../../../components/Typography';
import { useSubmitFeedback } from '../../../shared/api/Identity';
import { ButtonsWrapper } from './Feedback.styles';
import { FeedbackForm } from './FeedbackForm';

export const Feedback: FC = () => {
  const [isFeedbackSent, setIsFeedbackSent] = useState<boolean>(false);

  const { mutate, isLoading } = useSubmitFeedback({
    onSuccess: () => setIsFeedbackSent(true)
  });

  const handleSubmit: SubmitHandler<any> = (values: any) => {
    mutate(values);
  };

  return (
    <SignUpLayout>
      <ContentWrapper>
        {isFeedbackSent ? (
          <>
            <Heading size="h4" element="h1" variant="grey">
              <FormattedMessage
                id="feedback.successTitle"
                defaultMessage="Feedback sent!"
              />
            </Heading>
            <Box mt="s" pb="xxl">
              <Subtitle variant="grey" size="big">
                <FormattedMessage
                  id="feedback.successSubtitle"
                  defaultMessage="Thank for your time and feedback. We hope that we can meet again."
                />
              </Subtitle>
            </Box>
            <Box mt="xxl">
              <LinkButton
                isPrimary
                fullWidth
                external
                to="https://emrit.io/"
                openInNewTab={false}
              >
                <FormattedMessage id="feedback.done" defaultMessage="Done" />
              </LinkButton>
            </Box>
          </>
        ) : (
          <>
            <Heading size="h4" element="h1" variant="grey">
              <FormattedMessage
                id="feedback.title"
                defaultMessage="Why are you leaving"
              />
            </Heading>
            <Box mt="s">
              <Subtitle variant="grey" size="big">
                <FormattedMessage
                  id="feedback.subtitle"
                  defaultMessage="Please, tell us the reason that you are canceling"
                />
              </Subtitle>
            </Box>
            <FeedbackForm handleSubmit={handleSubmit} isLoading={isLoading} />
          </>
        )}
      </ContentWrapper>
      <BottomSection>
        <Heading size="h5" element="h2" variant="grey">
          <FormattedMessage
            id="feedback.bottomSectionTitle"
            defaultMessage="Do you have questions or need help?"
          />
        </Heading>
        <ButtonsWrapper mt="xl" mb="l">
          <LinkButton to="/faq" isPrimary={false}>
            <FormattedMessage id="feedback.faq" defaultMessage="FAQ" />
          </LinkButton>
          <LinkButton to="/support" isPrimary={false}>
            <FormattedMessage id="feedback.support" defaultMessage="Support" />
          </LinkButton>
          <LinkButton to="/contact" isPrimary={false}>
            <FormattedMessage id="feedback.contact" defaultMessage="Contact" />
          </LinkButton>
        </ButtonsWrapper>
      </BottomSection>
    </SignUpLayout>
  );
};
