import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';

import { Input, PasswordInput } from '../Input';
import { Box } from '../Box';
import { Button } from '../Button';
import { FieldWrapper } from './LoginForm.styles';
import { useAuth } from '../../shared/auth/auth-context';
import { useLoginValidation } from './LoginForm.validation';
import { useSendLegacyUserVerificationCode } from '../../shared/api/Identity/Identity.queries';
import { useNotification } from '../../shared/utils/notifications/notification-context';
import { AlertVariant } from '../Alert/Alert.types';
import { ErrorCodes } from '../../shared/api/error-codes';
import { LegacySignupData } from '../../features/Auth/LegacySignUp/LegacySignup.types';

type LoginFormData = {
  email: string;
  password: string;
};

export const LoginForm = () => {
  const { login, authError } = useAuth();
  const history = useHistory();
  const { formatMessage } = useIntl();
  const { addNotification } = useNotification();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(useLoginValidation())
  });

  const { mutateAsync: verifyEmail, isLoading: isEmailVerifyLoading } =
    useSendLegacyUserVerificationCode({
      onError: (error) => {
        addNotification({
          id: 'verificationError',
          text: error.message,
          variant: AlertVariant.Danger
        });
      }
    });

  useEffect(() => {
    const goToLegacySignup = async (
      email: string,
      verificationCode: string
    ) => {
      const legacySignupData: LegacySignupData = { verificationCode, email };

      history.push({
        pathname: '/legacy-sign-up',
        state: { legacySignupData }
      });
    };

    const { errorCode, data } = authError || {};
    const { email } = getValues() || {};

    if (email && errorCode === ErrorCodes.LEGACY_USER_LOGGED_IN) {
      goToLegacySignup(email, data?.code);
    }
  }, [authError, getValues, history, verifyEmail]);

  const onSubmit = async (data: LoginFormData) => {
    const { email, password } = data;

    await login.mutate({ email, password });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FieldWrapper>
        <Input
          {...register('email')}
          iconLeft="Mail"
          label={formatMessage({
            defaultMessage: 'Email address',
            id: 'login.emailLabel'
          })}
          placeholder={formatMessage({
            defaultMessage: 'email@sample.com',
            id: 'login.emailPlaceholder'
          })}
          error={errors.email?.message}
        />
      </FieldWrapper>
      <Box mt="m">
        <PasswordInput
          {...register('password')}
          label={formatMessage({
            defaultMessage: 'Password',
            id: 'login.passwordLabel'
          })}
          placeholder={formatMessage({
            defaultMessage: 'Enter your password',
            id: 'login.passwordPlaceholder'
          })}
          error={errors.password?.message}
        />
      </Box>
      <FieldWrapper>
        <Button
          isPrimary
          fullWidth
          type="submit"
          isLoading={login.isLoading || isEmailVerifyLoading}
        >
          <FormattedMessage
            id="login.submitButtonLabel"
            defaultMessage="Login"
          />
        </Button>
      </FieldWrapper>
    </form>
  );
};
