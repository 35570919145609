export enum ElevationUnit {
  Metric = 'metric',
  Imperial = 'imperial'
}

export type ElevationFormData = {
  value: number;
};

export type ElevationFormProps = {
  deviceId: string;
  isSubmitted: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setIsSubmitted: (isSubmitted: boolean) => void;
};
