import { FC, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button, LinkButton } from '../../../components/Button';
import { BUTTON_SIZE } from '../../../components/Button/Button.types';
import { useNotification } from '../../../shared/utils/notifications/notification-context';
import { AlertVariant } from '../../../components/Alert/Alert.types';
import { FullPageLoader } from '../../../components/FullPageLoader';
import { useGetProductVariants } from '../../../shared/api/Order/Order.queries';
import { ErrorPage } from '../../ErrorPage';
import {
  EarningPlanForm,
  EarningPlanFormValues
} from '../../../components/EarningPlanForm';
import { ChooseEarningPlanProps } from './ChooseEarningPlan.types';
import {
  EarningPlanFormGetValues,
  RenderBackButton
} from '../../../components/EarningPlanForm/EarningPlanForm.types';
import { DashboardLayout } from '../../../components/Layout';
import { DashboardContentWrapper } from '../../Dashboard/Dashboard.styles';
import { DashboardBox } from '../../../components/DashboardBox';
import { Heading } from '../../../components/Typography';
import { Box } from '../../../components/Box';

export const ChooseEarningPlan: FC<ChooseEarningPlanProps> = ({
  handleNextStep,
  handlePreviousStep,
  isNewUser,
  previousStepValues
}) => {
  const { addNotification } = useNotification();

  const { leadOrder } = previousStepValues || {};
  const { country, checkoutUrl } = leadOrder || {};

  useEffect(() => {
    if (checkoutUrl) {
      handleNextStep?.(previousStepValues);
    }
  }, [previousStepValues, checkoutUrl, handleNextStep]);

  const {
    data: productVariantsData,
    isLoading: isGetProductVariantsLoading,
    isError: isGetProductVariantsError
  } = useGetProductVariants(
    {
      countryCode: country,
      type: 'COOLSPOT'
    },
    {
      refetchOnMount: false,
      onError: (error) => {
        addNotification({
          id: 'getProductVariantsError',
          text: error.message,
          variant: AlertVariant.Danger
        });
      }
    }
  );

  const productVariants = [...(productVariantsData?.entities || [])].sort(
    ({ earningsFactor: a }, { earningsFactor: b }) => a - b
  );

  const handleFormSubmit = ({
    productVariantId,
    coupon
  }: EarningPlanFormValues) => {
    if (productVariantId) {
      handleNextStep?.({
        ...previousStepValues,
        productVariantId,
        coupon
      });
    }
  };

  const renderBackButton: RenderBackButton = isNewUser
    ? () => (
        <LinkButton
          to="/dashboard"
          isPlain
          isPrimary={false}
          fullWidth
          variant="blue"
        >
          <FormattedMessage
            id="orderACoolspot.goToDashboardButtonLabel"
            defaultMessage="Go to dashboard"
          />
        </LinkButton>
      )
    : (getValues: EarningPlanFormGetValues) => {
        const handleCancelClick = () => {
          const values = getValues();

          if (handlePreviousStep) {
            handlePreviousStep({ ...previousStepValues, ...values });
          }
        };

        return (
          <Button
            onClick={handleCancelClick}
            isPlain
            isPrimary={false}
            fullWidth
            size={BUTTON_SIZE.BIG}
          >
            <FormattedMessage
              id="chooseEarningPlan.backButton"
              defaultMessage="Back"
            />
          </Button>
        );
      };

  if (isGetProductVariantsLoading) {
    return <FullPageLoader />;
  }

  if (isGetProductVariantsError || !productVariantsData?.entities?.length) {
    return <ErrorPage isOverlay />;
  }

  return (
    <DashboardLayout hideMenu>
      <DashboardContentWrapper>
        <DashboardBox isFullHeight disableSidePadding>
          <Box pl="l" pr="l">
            <Heading size="h5" element="h1" variant="black">
              <FormattedMessage
                id="editWallet.title"
                defaultMessage="Choose your earning plan"
              />
            </Heading>
          </Box>
          <EarningPlanForm
            variants={productVariants}
            renderBackButton={renderBackButton}
            onFormSubmit={handleFormSubmit}
          />
        </DashboardBox>
      </DashboardContentWrapper>
    </DashboardLayout>
  );
};
