import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useBreakpoints } from '../../shared/utils/use-breakpoints';
import { theme } from '../../themes/main';
import { EllipsizedText } from '../EllipsizedText';
import { Icon } from '../Icon';
import { TooltipAlignment, TooltipPosition } from '../Tooltip/Tooltip.types';
import { CopyBoxTooltip, CopyBoxWrapper, CopyButton } from './CopyBox.styles';
import { CopyBoxProps } from './CopyBox.types';

export const CopyBox: FC<CopyBoxProps> = ({
  children,
  delay = 2000,
  tooltipPosition = TooltipPosition.Right,
  ...rest
}) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const { isTablet: isMobileDevice } = useBreakpoints();

  const copyWalletAddress = (walletAdress: string) => {
    navigator.clipboard.writeText(walletAdress);

    setShowTooltip(true);

    setTimeout(() => {
      setShowTooltip(false);
    }, delay);
  };

  return (
    <CopyBoxWrapper {...rest}>
      <EllipsizedText maxLength={15}>{children}</EllipsizedText>
      <CopyButton
        onClick={() => copyWalletAddress(children)}
        isActive={showTooltip}
      >
        <Icon icon="FileCopy" />
        {showTooltip && (
          <CopyBoxTooltip
            align={TooltipAlignment.Center}
            position={isMobileDevice ? TooltipPosition.Top : tooltipPosition}
          >
            <Icon icon="Tick2" color={theme.color.green} />
            <FormattedMessage
              id="copyBox.copySuccessMessage"
              defaultMessage="Copied to Clipboard!"
            />
          </CopyBoxTooltip>
        )}
      </CopyButton>
    </CopyBoxWrapper>
  );
};
