export const feetToMeters = (feet: number, toFixed = 0) => {
  const multiplier = toFixed > 0 ? 10 ** toFixed : 1;

  return Math.round(feet * 0.3048 * multiplier) / multiplier;
};

export const metersToFeet = (meters: number, toFixed = 0) => {
  const multiplier = toFixed > 0 ? 10 ** toFixed : 1;

  return Math.round(meters * 3.28084 * multiplier) / multiplier;
};
