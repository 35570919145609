import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { ContentWrapper } from '../../../../../components/Layout';
import { Heading } from '../../../../../components/Typography';
import CoolSpotImage from '../../../../../assets/images/CoolSpot.png';
import { Button } from '../../../../../components/Button';
import { BUTTON_SIZE } from '../../../../../components/Button/Button.types';
import { ImageWrapper, StyledSubtitle } from './SupportedSuccessPage.styles';
import { Box } from '../../../../../components/Box';
import { StepProps } from '../../../../../components/Stepper';

export const SupportedSuccessPage: FC<StepProps & { login: any }> = ({
  previousStepValues,
  login
}) => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  const handleClick = async (skipOrder?: boolean) => {
    await login.mutateAsync({
      email: previousStepValues?.email,
      password: previousStepValues?.password
    });

    if (skipOrder) {
      history.push('/apply-for-a-coolspot');
    } else {
      history.push('/dashboard');
    }
  };

  return (
    <ContentWrapper>
      <Heading size="h4" element="h1" variant="grey">
        <FormattedMessage
          id="supportedSuccess.title"
          defaultMessage="Welcome to Emrit family!"
        />
      </Heading>
      <StyledSubtitle variant="grey" size="big">
        <FormattedMessage
          id="supportedSuccess.subtitle"
          defaultMessage="Start earning by ordering your CoolSpot™!"
        />
      </StyledSubtitle>
      <ImageWrapper>
        <img
          alt={formatMessage({
            defaultMessage: 'Coolspot™',
            id: 'supportedSuccess.image'
          })}
          src={CoolSpotImage}
        />
      </ImageWrapper>
      <Button
        onClick={() => handleClick(true)}
        isPrimary
        fullWidth
        size={BUTTON_SIZE.BIG}
      >
        <FormattedMessage
          id="supportedSuccess.applyForACoolSpot"
          defaultMessage="Apply for a CoolSpot™"
        />
      </Button>
      <Box mt="m">
        <Button
          onClick={() => handleClick()}
          isPrimary={false}
          fullWidth
          size={BUTTON_SIZE.BIG}
        >
          <FormattedMessage
            id="supportedSuccess.dashboard"
            defaultMessage="Skip to dashboard"
          />
        </Button>
      </Box>
    </ContentWrapper>
  );
};
