import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '../../components/Button';
import { BUTTON_SIZE } from '../../components/Button/Button.types';
import { EmptyStateLayout } from '../../components/Layout/EmptyStateLayout';
import { EmptyLayoutButtonsRow } from '../../components/Layout/EmptyStateLayout/EmptyStateLayout';
import { Heading, Subtitle } from '../../components/Typography';

export const NoConnection: FC<{
  isOverlay?: boolean;
  handleRefresh: () => void;
}> = ({ isOverlay, handleRefresh }) => {
  return (
    <EmptyStateLayout isOverlay={isOverlay}>
      <div>
        <Heading size="h2" align="center" variant="white">
          <FormattedMessage
            id="noConnection.title"
            defaultMessage="No connection"
          />
        </Heading>
        <Subtitle size="big" variant="white">
          <FormattedMessage
            id="noConnection.subtitle"
            defaultMessage="Slow or no internet connection. Please check your internet settings."
          />
        </Subtitle>
      </div>
      <EmptyLayoutButtonsRow>
        <Button
          fullWidth
          iconRight="Refresh"
          isPrimary
          onClick={handleRefresh}
          size={BUTTON_SIZE.SMALL}
        >
          <FormattedMessage
            id="noConnection.buttonLabel"
            defaultMessage="Try again"
          />
        </Button>
      </EmptyLayoutButtonsRow>
    </EmptyStateLayout>
  );
};
