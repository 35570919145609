import styled, { css } from 'styled-components';

export const TabWrapper = styled.li<{ isSelected: boolean }>`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 6px;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey};
  color: ${({ theme }) => theme.color.grey};
  font-size: ${({ theme }) => theme.typography.body.small.fontSize};
  line-height: ${({ theme }) => theme.typography.body.small.lineHeight};
  letter-spacing: ${({ theme }) => theme.typography.body.small.letterSpacing};
  font-weight: 700;
  cursor: pointer;

  ${({ isSelected }) =>
    isSelected &&
    css`
      color: ${({ theme }) => theme.color.blue};
      border-color: ${({ theme }) => theme.color.blue};
      background: ${({ theme }) => theme.color.white};
    `}
`;

export const TabsWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const TabListWrapper = styled.ul`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0;
  padding: 0;
`;

export const TabContentWrapper = styled.div<{ isSelected: boolean }>`
  display: ${({ isSelected }) => (isSelected ? 'block' : 'none')};
`;
