import styled, { css, DefaultTheme } from 'styled-components';

import { getTypography } from '../../../shared/utils/style-utils';
import { FieldVariant } from '../../FieldCaption';

const inputVariantStyle = (theme: DefaultTheme, variant: FieldVariant) =>
  ({
    [FieldVariant.PREFILLED]: css`
      &,
      &[readonly] {
        background-color: ${theme.color.secondaryWhiteYellow};
        color: ${theme.color.blue};
        border: transparent;
        box-shadow: 0 0 0 1px ${theme.color.blue};
        padding: 18px;

        &:focus,
        &:active {
          border: transparent;
          color: ${theme.color.black};
          box-shadow: 0 0 0 2px ${theme.color.blue};
        }
      }
    `,
    [FieldVariant.DISABLED]: css``,
    [FieldVariant.ERROR]: css`
      border: ${theme.border(theme.color.secondaryRed, 2)};
      box-shadow: none;
    `,
    [FieldVariant.VALID]: css`
      border: ${theme.border(theme.color.secondaryGreen, 2)};
      box-shadow: none;
    `
  }[variant]);

export const StyledInput = styled.input<{
  selectable?: boolean;
  variant: FieldVariant;
}>`
  -webkit-appearance: none;
  ${({ theme }) => getTypography(theme.typography.body.big)};
  font-family: inherit;

  border: solid 2px transparent;
  border-radius: ${({ theme }) => theme.borderRadius};
  width: 100%;

  padding: ${({ theme }) => theme.padding.s};

  color: ${({ theme }) => theme.color.grey};
  background-color: ${({ theme }) => theme.color.white};

  text-align: left;

  box-shadow: 0 0 0 1px ${({ theme }) => theme.color.dimmed};

  &:focus {
    border: ${({ theme }) => theme.border(theme.color.blue, 2)};
    box-shadow: none;
    outline: none;
  }

  &:hover:not([read-only]),
  &:hover:not([disabled]) {
    cursor: text;
  }

  &::placeholder {
    color: ${({ theme }) => theme.color.grey};
  }

  &:disabled,
  &:read-only {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.color.lightGrey};

    background-color: ${({ theme }) => theme.color.white};
    color: ${({ theme }) => theme.color.dimmed};

    &::placeholder {
      color: ${({ theme }) => theme.color.dimmed};
    }
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  ${({ theme, variant }) => inputVariantStyle(theme, variant)}

  ${({ selectable }) =>
    selectable === false &&
    css`
      user-select: none;
      pointer-events: none;

      &:hover,
      &[readonly]:hover,
      &[disabled]:hover {
        cursor: default;
      }
    `}
`;
