export type VerifyCodeProps = {
  code: string;
  email: string;
};

export type LeadUserVerifyCodeProps = {
  code: string;
  email: string;
  installationAddress: any;
};

export type SendCodeProps = { email: string };

export type EmailVerificationResponse = {
  isLegacyUser: boolean;
  isUserV2: boolean;
};

export type AddToWaitListProps = { country: string; email?: string };

export type AddToWaitListResponse = {
  email: string;
  country: {
    code: string;
    name: string;
    isSupported: false;
    createdDate: string;
    updatedDate: string;
    deletedDate?: string | null;
  };
  deletedDate?: string | null;
  uuid: string;
  createdDate: string;
  updatedDate: string;
};

export type CurrentUser = {
  acceptedTermsAndConditions: boolean;
  country: string;
  createdDate: string;
  deletedDate: string | null;
  email: string;
  emailVerificationCodeSentAt: string | null;
  emailVerified: boolean;
  firstName: string;
  lastName: string;
  passwordResetCodeSentAt: string | null;
  phoneVerificationCodeSentAt: string | null;
  phoneVerified: boolean;
  recommendedByUserId: string | null;
  role: number;
  tel: string;
  updatedDate: string;
  userType: string;
  uuid: string;
};

export enum WhyAreYouLeavingReason {
  DontKnowHowItWork = 'DontKnowHowItWork',
  IWillComeBackLater = 'IWillComeBackLater',
  NotAttractive = 'NotAttractive',
  Other = 'Other'
}

export type WhyAreYouLeavingProps<T extends WhyAreYouLeavingReason> =
  T extends WhyAreYouLeavingReason.Other
    ? {
        reason: WhyAreYouLeavingReason.Other;
        reasonMessage: string;
      }
    : {
        reason: Omit<WhyAreYouLeavingReason, WhyAreYouLeavingReason.Other>;
      };

export enum UserRequestType {
  ChangeInstallationAddress = 'change_installation_address',
  ChangeAntennaDbi = 'change_antenna_dbi',
  ChangeElevation = 'change_elevation',
  ReturnDevice = 'return_device',
  MissingDevice = 'missing_device',
  DeviceIsNotMine = 'device_is_not_mine',
  IncorrectDevice = 'incorrect_device'
}

export type ChangeElevationRequest = {
  requestType: UserRequestType.ChangeElevation;
  data: {
    coolspotId: string;
    elevation: number;
  };
};

export type ChangeAntennaDbiRequest = {
  requestType: UserRequestType.ChangeAntennaDbi;
  data: {
    coolspotId: string;
    dbi: number;
  };
};

export type MissingDeviceRequest = {
  requestType: UserRequestType.MissingDevice;
  data: {
    description: string;
    coolspotName: string;
  };
};

export type IncorrectDeviceRequest = {
  requestType: UserRequestType.IncorrectDevice;
  data: {
    description: string;
    coolspotId: string;
  };
};

export type DeviceIsNotMineRequest = {
  requestType: UserRequestType.DeviceIsNotMine;
  data: {
    description: string;
    coolspotId: string;
  };
};

export type UserRequestsRequest =
  | ChangeElevationRequest
  | ChangeAntennaDbiRequest
  | MissingDeviceRequest
  | IncorrectDeviceRequest
  | DeviceIsNotMineRequest;

export type UserRequestsResponseData = {
  id: string;
  status: number;
};

export type UserRequestsResponse = {
  request: UserRequestsResponseData;
};

export type LeadUserEmailVerifyResponse = { leadOrderId: string };

export type LegacyUserVerifyResponse = {
  uuid: string;
  firstName: string;
  lastName: string;
  tel: string;
  email: string;
  country: string;
};

export type ConfirmLeadUserRegistrationFormResponse = {
  uuid: string;
  firstName: string;
  lastName: string;
  tel: string;
  email: string;
  emailVerified: boolean;
  phoneVerified: boolean;
  acceptedTermsAndConditions: boolean;
  country: string;
};

export type GetLeadUserByEmailRequest = {
  code: string;
  email: string;
};

export type GetLeadUserByEmailResponse = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

export type TermsAndConditions = {
  data: string;
  updatedAt: string;
};

export type UpdateCurrentUserProps = {
  termsAndConditions: boolean;
};

export type VerifyOneTimeAuthCodeProps = {
  email: string;
  code: string;
};
