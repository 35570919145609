import VisuallyHidden from '@reach/visually-hidden';
import { FC } from 'react';

import { Icon } from '../../Icon';
import { StyledIconButton } from './IconButton.styles';
import { ICON_BUTTON_SIZE, IconButtonProps } from './IconButton.types';

export const IconButton: FC<IconButtonProps> = ({
  disabled,
  icon,
  isPrimary = false,
  onClick,
  size,
  type,
  children,
  ...rest
}) => (
  <StyledIconButton
    disabled={disabled}
    isPrimary={isPrimary}
    onClick={onClick}
    size={size || ICON_BUTTON_SIZE.BIG}
    type={type}
    {...rest}
  >
    <Icon aria-hidden icon={icon} />
    <VisuallyHidden>{children}</VisuallyHidden>
  </StyledIconButton>
);
