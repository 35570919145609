import { FC, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { AddressBox } from '../../../components/AddressBox';
import { Alert } from '../../../components/Alert';
import { AlertVariant } from '../../../components/Alert/Alert.types';
import { Box } from '../../../components/Box';
import { LinkButton } from '../../../components/Button';
import { ContentWrapper } from '../../../components/Layout';
import { Heading, Subtitle, Text } from '../../../components/Typography';
import { LeadOrderStatus } from '../../../shared/api/Order/Order.types';
import {
  userTracking,
  UserTrackingEvent
} from '../../../shared/utils/user-tracking';
import { ApplyForACoolspotStepProps } from '../AppyForACoolspot.types';
import { Paragraph, StyledLink } from './ConfirmationScreen.styles';

export const ConfirmationScreen: FC<ApplyForACoolspotStepProps> = ({
  handlePreviousStep,
  previousStepValues
}) => {
  useEffect(() => {
    userTracking(UserTrackingEvent.RegisteredUser);
  }, []);

  const { leadOrder, installationAddress, countryIsSupported } =
    previousStepValues || {};
  const isCoolspotReadyToOrder =
    leadOrder?.status === LeadOrderStatus.WaitingForResponse ||
    leadOrder?.status === LeadOrderStatus.CheckoutCreated;

  return (
    <ContentWrapper>
      <Heading size="h4" element="h1" variant="grey">
        {isCoolspotReadyToOrder && (
          <FormattedMessage
            id="confirmationScreen.readyToOrder.title"
            defaultMessage="Your location qualifies!"
          />
        )}
        {!isCoolspotReadyToOrder && (
          <FormattedMessage
            id="confirmationScreen.notReadyToOrder.title"
            defaultMessage="We’re analyzing your location’s earning potential!"
          />
        )}
      </Heading>
      <Box mt="xl">
        <Subtitle variant="grey" size="big">
          {isCoolspotReadyToOrder && (
            <FormattedMessage
              id="confirmationScreen.readyToOrder.subtitle"
              defaultMessage="If the address below is correct, please confirm and continue with the checkout process."
            />
          )}
          {!isCoolspotReadyToOrder && (
            <FormattedMessage
              id="confirmationScreen.notReadyToOrder.subtitle"
              defaultMessage="CoolSpot™ installation address"
            />
          )}
        </Subtitle>
      </Box>
      <AddressBox mt="m">{installationAddress?.formatted_address}</AddressBox>
      {countryIsSupported && (
        <>
          <Box mt="xl">
            <Alert variant={AlertVariant.Default}>
              <div>
                <Paragraph>
                  <FormattedMessage
                    id="confirmationScreen.infoBox"
                    defaultMessage="If you want to change the CoolSpot™ Installation Address please fill the request flow again so we can check your eligibility."
                  />
                </Paragraph>
                <Paragraph>
                  <StyledLink
                    type="button"
                    onClick={() => handlePreviousStep?.()}
                  >
                    <FormattedMessage
                      id="login.shopifyLink"
                      defaultMessage="Click here"
                    />
                  </StyledLink>
                </Paragraph>
              </div>
            </Alert>
          </Box>
          {!isCoolspotReadyToOrder && (
            <Box mt="xl">
              <Text level="small" whiteSpace="pre-line">
                <FormattedMessage
                  id="confirmationScreen.notReadyToOrder.subtitle2"
                  defaultMessage={
                    'If your location is approved for immediate shipping, you will receive an email offer to purchase your CoolSpot in the next hour!\n\nOtherwise you will be added to our wait list to receive a CoolSpot, dependent on availability.'
                  }
                />
              </Text>
            </Box>
          )}
        </>
      )}
      {!countryIsSupported && (
        <Box mt="xl">
          <Text level="big">
            <FormattedMessage
              id="confirmationScreen.countryUnsupportedCopy"
              defaultMessage="This country for the CoolSpot™ installation address is not supported. We will email you as soon as a CoolSpot™ is available for your installation address. We are shipping thousands of CoolSpots™ every month, prioritizing the wait list based on host location’s earning potential."
            />
          </Text>
        </Box>
      )}
      <Box mt="xl">
        {isCoolspotReadyToOrder && (
          <LinkButton
            to={`/order-a-coolspot?leadOrderId=${leadOrder?.id}`}
            fullWidth
          >
            <FormattedMessage
              id="confirmationScreen.confirmAddressButton"
              defaultMessage="Confirm address"
            />
          </LinkButton>
        )}
        {!isCoolspotReadyToOrder && (
          <LinkButton to="/dashboard" fullWidth>
            <FormattedMessage
              id="confirmationScreen.goToDashboardButton"
              defaultMessage="Go to dashboard"
            />
          </LinkButton>
        )}
      </Box>
    </ContentWrapper>
  );
};
