import styled from 'styled-components';

export const Container = styled.div`
  padding-bottom: 120px;
  background: ${({ theme }) => theme.color.secondaryWhiteBlue};
`;

export const Header = styled.header`
  padding-top: ${({ theme }) => theme.padding.xxs};
  padding-bottom: ${({ theme }) => theme.padding.l};
  border-bottom: ${({ theme }) => theme.border('lightGrey', 1)};
  margin-bottom: ${({ theme }) => theme.margin.l};
`;

export const Footer = styled.footer`
  margin-top: ${({ theme }) => theme.margin.xl};
  padding-top: ${({ theme }) => theme.padding.xl};
  padding-bottom: ${({ theme }) => theme.padding.l};
  border-top: ${({ theme }) => theme.border('lightGrey', 1)};

  color: ${({ theme }) => theme.color.dimmed};
`;
