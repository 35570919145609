import { FC } from 'react';

import { Icon } from '../Icon';
import { Loader } from '../Loader';
import {
  LeftElementWrapper,
  RightElementWrapper,
  StyledButton
} from './Button.styles';
import { BUTTON_SIZE, ButtonProps } from './Button.types';

export const Button: FC<ButtonProps> = ({
  children,
  disabled,
  iconLeft,
  iconRight,
  isDestructive,
  isLoading,
  isPlain,
  fullWidth,
  isPrimary = false,
  disableHoverStyles,
  onClick,
  size = BUTTON_SIZE.BIG,
  type,
  variant,
  className
}) => (
  <StyledButton
    disabled={disabled || isLoading}
    isDestructive={isDestructive}
    isPrimary={isPrimary}
    isPlain={isPlain}
    disableHoverStyles={disableHoverStyles}
    onClick={onClick}
    size={size || BUTTON_SIZE.BIG}
    type={type}
    fullWidth={fullWidth}
    variant={variant}
    className={className}
  >
    {isLoading ? (
      <Loader
        size={size}
        variant={!(isPrimary || isDestructive) ? 'blue' : 'white'}
      />
    ) : (
      <>
        {iconLeft && (
          <LeftElementWrapper size={size}>
            <Icon icon={iconLeft} />
          </LeftElementWrapper>
        )}
        {children}
        {iconRight && (
          <RightElementWrapper size={size}>
            <Icon icon={iconRight} />
          </RightElementWrapper>
        )}
      </>
    )}
  </StyledButton>
);
