import styled from 'styled-components';

export const SubtitleWrapper = styled.div`
  margin-top: ${({ theme }) => theme.margin.xl};

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    margin-top: ${({ theme }) => theme.margin.s};
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: ${({ theme }) => theme.margin.xl};
  padding-top: ${({ theme }) => theme.padding.xl};

  @media (${({ theme }) => theme.breakpoints.phone}) {
    margin-top: ${({ theme }) => theme.margin.l};
    padding-top: 0;
  }
`;
