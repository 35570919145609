import styled, { css } from 'styled-components';
import Input from 'react-code-input';

import { getTypography } from '../../shared/utils/style-utils';

export const InputDisplay = styled(Input)<{ hasError: boolean }>`
  display: flex !important;

  input {
    -webkit-appearance: none;
    ${({ theme }) => getTypography(theme.typography.button.big)};
    font-family: ${({ theme }) => theme.typography.fontFamily};
    text-align: center;

    width: calc(100% / 6);

    padding: 16px 8px;

    border: 2px solid transparent;
    border-radius: 8px;
    margin-right: 16px;

    box-shadow: 0 0 0 1px ${({ theme }) => theme.color.grey};

    ${({ hasError, theme }) =>
      hasError &&
      css`
        border: ${theme.border(theme.color.secondaryRed)};
        box-shadow: none;
      `}

    :last-child {
      margin-right: 0;
    }

    :focus {
      outline: none;
      border: ${({ theme }) => theme.border(theme.color.blue, 2)};
      box-shadow: none;
    }
  }
`;
