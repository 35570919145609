import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import qs from 'query-string';

import { Alert } from '../../../components/Alert';
import { AlertVariant } from '../../../components/Alert/Alert.types';
import { Box } from '../../../components/Box';
import { LinkButton } from '../../../components/Button';
import { ContentWrapper } from '../../../components/Layout';
import { Link } from '../../../components/Link';
import { Heading, Text } from '../../../components/Typography';
import { LeadOrderStatus } from '../../../shared/api/Order/Order.types';
import { useAuth } from '../../../shared/auth/auth-context';
import { Copies, InvalidCoolSpotOrderProps } from './OrderInvalid.types';

const copies: Copies = {
  [LeadOrderStatus.Postponed]: {
    title: 'You already postponed the order',
    copy1:
      'However, you are still on our CoolSpot™ wait list, and we will email you when another CoolSpot™ is available for your location.'
  },
  [LeadOrderStatus.NotResponded]: {
    title: 'We’re sorry',
    copy1:
      'Due to the high demand, we are only able to hold CoolSpots™ for 7 days once available, and unfortunately you missed your 7-day window.',
    copy2:
      'However, you are still on our wait list, and we will email you when another CoolSpot™ is available for your location.'
  }
};

const InvalidCoolSpotOrder: FC<InvalidCoolSpotOrderProps> = ({
  email,
  leadOrderId,
  leadOrderStatus
}) => {
  const { isLoggedIn } = useAuth();
  const dashboardLink = isLoggedIn() ? '/dashboard' : '/login';
  const activeCopy = copies[leadOrderStatus];
  const removeMeFromWaitListLinkParams = qs.stringify({ leadOrderId, email });

  return (
    <ContentWrapper>
      <Heading size="h4" element="h1" variant="grey">
        <FormattedMessage
          id="expiredCoolspot.title"
          defaultMessage={activeCopy.title}
        />
      </Heading>
      <Box mt="l">
        <Text level="small">
          <FormattedMessage
            id="orderACoolspot.copy1"
            defaultMessage={activeCopy.copy1}
          />
        </Text>
      </Box>
      {activeCopy.copy2 && (
        <Box mt="m">
          <Text level="small">
            <FormattedMessage
              id="orderACoolspot.copy1"
              defaultMessage={activeCopy.copy2}
            />
          </Text>
        </Box>
      )}
      <Box mt="l">
        <Alert variant={AlertVariant.Secondary}>
          <Text level="small">
            <FormattedMessage
              id="orderACoolspot.unsubscribe"
              defaultMessage="If you don’t want to be on the list for the CoolSpot™ on this installation address {clickHere}"
              values={{
                clickHere: (
                  <Link
                    to={`/remove-from-wait-list?${removeMeFromWaitListLinkParams}`}
                  >
                    <FormattedMessage
                      id="orderACoolspot.unsubscribeLink"
                      defaultMessage="click here"
                    />
                  </Link>
                )
              }}
            />
          </Text>
        </Alert>
      </Box>
      <Box mt="l">
        <LinkButton to={dashboardLink} fullWidth variant="blue">
          <FormattedMessage
            id="orderPlaced.goToDashboardButtonLabel"
            defaultMessage="Got it"
          />
        </LinkButton>
      </Box>
    </ContentWrapper>
  );
};

export default InvalidCoolSpotOrder;
