import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { useAuth } from '../../shared/auth/auth-context';
import { useBreakpoints } from '../../shared/utils/use-breakpoints';
import { DesktopMenu } from '../DesktopMenu';
import {
  MobileMenuButton,
  NavbarContent,
  NavbarLogo,
  NavbarWrapper
} from './Navbar.styles';
import { NavbarProps } from './Navbar.types';

export const Navbar: FC<NavbarProps> = ({ onMenuOpen, hideMenu }) => {
  const { isLoggedIn } = useAuth();
  const { isTablet } = useBreakpoints();

  return (
    <NavbarWrapper>
      <NavbarContent>
        <NavbarLogo variant="white" />
        {isLoggedIn() && !hideMenu && (
          <>
            {!isTablet ? (
              <DesktopMenu />
            ) : (
              <MobileMenuButton iconRight="BurgerMenu" onClick={onMenuOpen}>
                <FormattedMessage id="menu" defaultMessage="Menu" />
              </MobileMenuButton>
            )}
          </>
        )}
      </NavbarContent>
    </NavbarWrapper>
  );
};
