import styled from 'styled-components';

import { InputWrapper } from '../../../../components/Input/Input.styles';
import { Text } from '../../../../components/Typography';

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: row;

  ${InputWrapper} {
    flex: 1;
  }
`;

export const UnitLabel = styled.p`
  margin: 0;
  padding-top: 48px;
  padding-left: ${({ theme }) => theme.margin.m};
`;

export const ModalCopy = styled(Text).attrs({
  level: 'small',
  variant: 'h4'
})`
  text-align: center;
`;
