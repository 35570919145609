import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { menuItemStyle } from '../NavbarMenuItem/NavbarMenuItem.styles';

export const StyledNavbarMenuLink = styled(Link)<{
  $isActive?: boolean;
  variant?: string;
}>`
  ${menuItemStyle}

  text-decoration: none;
`;
