import styled from 'styled-components';

export const HeadingControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    align-items: center;
  }
`;

export const HeadingContainer = styled.span`
  display: flex;
  align-items: center;
`;

export const HeadingSpacer = styled.p`
  width: 8px;
  height: auto;
`;
