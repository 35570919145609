import { Helmet } from 'react-helmet';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { useClearCache } from 'react-clear-cache';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import ReactGA from 'react-ga';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { ReactQueryDevtools } from 'react-query/devtools';
import 'keen-slider/keen-slider.min.css';

import { AuthProvider } from './shared/auth/auth-context';
import en from './shared/lang/en-compiled.json';
import { theme as defaultTheme } from './themes/main';
import { authorizedRoutes } from './authorizedRoutes';
import { unauthorizedRoutes } from './unauthorizedRoutes';
import { Routes } from './Routes';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-loading-skeleton/dist/skeleton.css';
import './themes/index.css';
import { NotificationProvider } from './shared/utils/notifications/notification-context';

if (process.env.REACT_APP_ENABLE_MOCKS === 'true') {
  // eslint-disable-next-line global-require
  const { worker } = require('./mocks/browser');

  worker.start();
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export const GlobalStyle = createGlobalStyle`
  html {
    height: 100vh;
  }

  body {
    margin: 0;
    font-family:  ${({ theme }) => theme.typography.fontFamily};
    font-size: ${({ theme }) => theme.typography.bodyFontSize};
    line-height: ${({ theme }) => theme.typography.bodyLineHeight};
  }

  * {
    box-sizing: border-box;
    cursor: default;
  }

  a, button {
    &, * {
      cursor: pointer;
    }
  }

  a {
    font-weight: 700;
    color: ${({ theme }) => theme.color.darkBlue};
    text-decoration: none;

    &:hover,
    &:active {
      color: ${({ theme }) => theme.color.blue};
    }
  }
`;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const newRelicEnvironments = ['production', 'alpha'];

const App = () => {
  useEffect(() => {
    if (process.env.REACT_APP_GTM_ID) {
      TagManager.initialize({
        gtmId: process.env.REACT_APP_GTM_ID
      });
    }

    if (process.env.NODE_ENV === 'production') {
      ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID!);
      ReactGA.pageview(window.location.pathname);
    }
  }, []);

  const { isLatestVersion, emptyCacheStorage } = useClearCache();

  if (!isLatestVersion) {
    emptyCacheStorage();
  }

  return (
    <QueryClientProvider client={queryClient}>
      <IntlProvider locale="en" messages={en}>
        <ThemeProvider theme={defaultTheme}>
          <NotificationProvider>
            <BrowserRouter>
              <AuthProvider
                authorizedRoutes={authorizedRoutes}
                unauthorizedRoutes={unauthorizedRoutes}
              >
                <ScrollToTop />
                <GlobalStyle />
                <Helmet>
                  {newRelicEnvironments.includes(
                    process.env.REACT_APP_ENV || ''
                  ) && (
                    <script
                      type="text/javascript"
                      src={`./newrelic.${process.env.REACT_APP_ENV}.js`}
                    />
                  )}
                </Helmet>
                <Routes
                  authorizedRoutes={authorizedRoutes}
                  unauthorizedRoutes={unauthorizedRoutes}
                />
              </AuthProvider>
            </BrowserRouter>
          </NotificationProvider>
        </ThemeProvider>
      </IntlProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
