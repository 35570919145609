import styled from 'styled-components';

import { getTypography } from '../../style-utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const Header = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.margin.l};

  @media (${({ theme }) => theme.breakpoints.phone}) {
    margin-bottom: ${({ theme }) => theme.margin.s};
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -30px;
    width: 100%;
    height: 30px;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }
`;

export const Content = styled.article`
  max-height: 100%;
  overflow-y: auto;
  margin-right: ${({ theme }) => `-${theme.margin.l}`};
  padding-top: ${({ theme }) => theme.padding.s};
  padding-right: ${({ theme }) => theme.padding.xl};

  ${({ theme }) => getTypography(theme.typography.body.big)};
  color: ${({ theme }) => theme.color.grey};

  scrollbar-color: ${({ theme }) => theme.color.lightGrey} transparent;
  scrollbar-width: thin;

  @media (${({ theme }) => theme.breakpoints.phone}) {
    margin-right: ${({ theme }) => `-${theme.margin.s}`};
    padding-right: ${({ theme }) => theme.padding.m};
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.color.lightGrey};
    border-radius: 4px;
  }
`;
