import styled from 'styled-components';

import { getTypography } from '../../shared/utils/style-utils';
import { FieldVariant } from '../FieldCaption';

export const StyledLabel = styled.label<{ variant: FieldVariant }>`
  ${({ theme }) => getTypography(theme.typography.caption)};

  margin-bottom: ${({ theme }) => theme.margin.xxs};

  color: ${({ theme, variant }) =>
    variant === FieldVariant.DISABLED ? theme.color.dimmed : theme.color.grey};
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  top: 50%;
  right: ${({ theme }) => theme.padding.m};
  transform: translateY(-50%);
`;

export const ProgressBarWrapper = styled.div`
  margin-top: ${({ theme }) => theme.margin.s};
`;
