import { FC } from 'react';
import { Popup, PopupProps } from 'react-map-gl';

import { StyledTooltip } from './Tooltip.styles';

export const Tooltip: FC<PopupProps> = ({
  children,
  anchor = 'bottom',
  closeButton = false,
  tipSize = 0,
  offsetTop = -10,
  ...props
}) => {
  return (
    <StyledTooltip>
      <Popup
        anchor={anchor}
        closeButton={closeButton}
        tipSize={tipSize}
        offsetTop={offsetTop}
        {...props}
      >
        {children}
      </Popup>
    </StyledTooltip>
  );
};
