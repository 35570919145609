import { FC } from 'react';

import { Icon } from '../../Icon';
import {
  IconWrapper,
  MobileMenuLabel
} from '../MobileMenuItem/MobileMenuItem.styles';
import { MobileMenuLinkProps } from './MobileMenuLink.types';
import { StyledMobileMenuLink } from './MobileMenuLink.styles';
import { theme } from '../../../themes/main';

export const MobileMenuLink: FC<MobileMenuLinkProps> = ({
  external = false,
  icon,
  to,
  children,
  onClick
}) => {
  if (external) {
    return (
      <li>
        <StyledMobileMenuLink
          as="a"
          href={to}
          target="_blank"
          rel="noopener noreferrer"
          onClick={onClick}
        >
          {icon && (
            <IconWrapper>
              <Icon aria-hidden icon={icon} color={theme.color.white} />
            </IconWrapper>
          )}
          <MobileMenuLabel>{children}</MobileMenuLabel>
        </StyledMobileMenuLink>
      </li>
    );
  }

  return (
    <li>
      <StyledMobileMenuLink to={to} onClick={onClick}>
        {icon && (
          <IconWrapper>
            <Icon aria-hidden icon={icon} color={theme.color.white} />
          </IconWrapper>
        )}
        <MobileMenuLabel>{children}</MobileMenuLabel>
      </StyledMobileMenuLink>
    </li>
  );
};
