import styled from 'styled-components';

import { getTypography } from '../../../../../shared/utils/style-utils';

export const StyledLegendItem = styled.p<{ color: string }>`
  display: flex;
  align-items: center;
  margin: 0;
  ${({ theme }) => getTypography(theme.typography.body.big)};

  &::before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    background: ${({ color }) => color};
    border-radius: 8px;
    margin-right: 8px;
    line-height: 16px;
  }
`;
