export enum RadioGroupVariant {
  Default = 'default',
  Simple = 'simple'
}

type Option = {
  label: string;
  value: string;
};

export type RadioGroupProps = {
  defaultValue?: string;
  horizontal?: boolean;
  options: Array<Option>;
  name: string;
  onBlur: (event: any) => void;
  onChange: (event: any) => void;
  variant?: RadioGroupVariant;
};
