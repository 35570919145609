import styled from 'styled-components';

import { Box } from '../../components/Box';
import { getTypography } from '../../shared/utils/style-utils';

export const ImageBox = styled(Box)`
  display: flex;
  justify-content: center;
  margin: ${({ theme }) => theme.margin.l} 0;
`;

export const StyledList = styled.ol`
  padding-left: 17px;
  margin: ${({ theme }) => theme.margin.m} 0;
`;

export const ListItem = styled.li`
  margin-bottom: ${({ theme }) => theme.margin.m};
  ${({ theme }) => getTypography(theme.typography.body.big)};
  color: ${({ theme }) => theme.color.grey};
`;
