import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';

export const useBreakpoints = () => {
  const { breakpoints } = useTheme();

  const isPhone = useMediaQuery({ query: `(${breakpoints.phone})` });
  const isTablet = useMediaQuery({ query: `(${breakpoints.tablet})` });
  const isDesktop = useMediaQuery({ query: `(${breakpoints.desktop})` });
  const isWidescreen = useMediaQuery({ query: `(${breakpoints.widescreen})` });

  return {
    isDesktop,
    isPhone,
    isTablet,
    isWidescreen
  };
};
