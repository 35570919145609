import styled from 'styled-components';

import { getTypography } from '../../../shared/utils/style-utils';

export const AlertStyledList = styled.ul`
  ${({ theme }) => getTypography(theme.typography.body.big)};

  padding: 0;
  margin: 0;

  margin-left: ${({ theme }) => theme.margin.xs};
  list-style-type: '- ';
`;

export const InputWrapper = styled.div`
  margin-top: ${({ theme }) => theme.margin.xl};

  @media (${({ theme }) => theme.breakpoints.phone}) {
    margin-top: ${({ theme }) => theme.margin.l};
  }
`;
