import styled from 'styled-components';

import { Subtitle } from '../../../../../components/Typography';

export const StyledSubtitle = styled(Subtitle)`
  margin-top: ${({ theme }) => theme.margin.m};
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;

  margin-top: ${({ theme }) => theme.margin.xl};
  margin-bottom: ${({ theme }) => theme.margin.xl};

  padding-top: ${({ theme }) => theme.padding.m};

  @media (${({ theme }) => theme.breakpoints.phone}) {
    margin-top: ${({ theme }) => theme.margin.l};
    padding-top: 0;
  }
`;
