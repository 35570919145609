import { rest } from 'msw';

import { countriesFixture, leadOrdersFixture } from '../fixture/order';

export const placeOrder = rest.post('/order/api/v2/orders', (req, res, ctx) => {
  return res(
    ctx.delay(1000),
    ctx.status(200),
    ctx.json({ order: { id: 123, orderStatusUrl: 'example' } })
  );
});

export const getCountries = rest.get(
  '/order/api/v2/countries',
  (req, res, ctx) => {
    return res(ctx.delay(1000), ctx.status(200), ctx.json(countriesFixture));
  }
);

export const getLeadOrders = rest.get(
  '/order/api/v2/lead-orders/me',
  (req, res, ctx) => {
    return res(ctx.delay(1000), ctx.status(200), ctx.json(leadOrdersFixture));
  }
);

export const getOrders = rest.get('/order/api/v2/orders', (req, res, ctx) => {
  return res(ctx.delay(1000), ctx.status(500));
});
