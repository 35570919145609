export enum OverlayStyle {
  Blur = 'blur'
}

export type ModalStyleProps = {
  fullWidth?: boolean;
  maxWidth?: number | string;
};

export type ModalProps = {
  hasCloseButton?: boolean;
  header?: string;
  isOpen: boolean;
  setIsOpen: (newState: boolean) => void;
  overlayStyle?: OverlayStyle;
} & ModalStyleProps &
  ReactModal.Props;

export type ModalHeaderProps = {
  center?: boolean;
  hasCloseButton?: boolean;
};
