import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Box } from '../../../../../components/Box';
import {
  Button,
  InlineLinkButton,
  LinkButton
} from '../../../../../components/Button';
import { BUTTON_SIZE } from '../../../../../components/Button/Button.types';
import { Subtitle, Text } from '../../../../../components/Typography';
import { Heading } from '../../../../../components/Typography/Heading';
import {
  StepListItem,
  StyledButton,
  StyledCaption,
  StyledContentWrapper,
  StyledSection,
  StyledStepList
} from './GetWallet.styles';

export const GetWallet: FC<{ onClose?: () => void }> = ({ onClose }) => {
  return (
    <>
      <StyledContentWrapper>
        <Heading size="h4" element="h1" variant="grey">
          <FormattedMessage
            id="getWallet.title"
            defaultMessage="How to get Helium Wallet address"
          />
        </Heading>
        <Box mt="s">
          <Subtitle variant="grey" size="big">
            <FormattedMessage
              id="getWallet.subtitle"
              defaultMessage="Paste your wallet address into the field below"
            />
          </Subtitle>
        </Box>
        <StyledStepList>
          <StepListItem>
            <FormattedMessage
              id="getWallet.step1"
              defaultMessage="1. Get Helium Hotspot app from the Goole Play or App Store"
            />
          </StepListItem>
          <StepListItem>
            <FormattedMessage
              id="getWallet.step2"
              defaultMessage="2. Proceed with instructions and create Helium account"
            />
          </StepListItem>
          <StepListItem>
            <FormattedMessage
              id="getWallet.step3"
              defaultMessage="3. Copy Helium Wallet Address to Emrit system"
            />
          </StepListItem>
        </StyledStepList>
        <Box mt="l">
          <Button isPrimary fullWidth type="button" onClick={onClose}>
            <FormattedMessage
              id="getWallet.readyButton"
              defaultMessage="Ok, I'm ready"
            />
          </Button>
        </Box>
        <Box mt="m" mb="m">
          <LinkButton
            to="/login"
            isPlain
            isPrimary={false}
            fullWidth
            size={BUTTON_SIZE.BIG}
          >
            <FormattedMessage id="getWallet.cancel" defaultMessage="Cancel" />
          </LinkButton>
        </Box>
        <StyledCaption>
          <Text type="default" level="big">
            <FormattedMessage
              id="getWallet.captionText"
              defaultMessage="Already have the wallet address? "
            />
            <InlineLinkButton type="button" onClick={onClose}>
              <FormattedMessage
                id="getWallet.captionLink"
                defaultMessage="Add wallet address here"
              />
            </InlineLinkButton>
          </Text>
        </StyledCaption>
      </StyledContentWrapper>
      <StyledSection>
        <Heading size="h5" element="h2" variant="grey">
          <FormattedMessage
            id="getWallet.bottomHeading"
            defaultMessage="Already have the wallet address?"
          />
        </Heading>
        <StyledButton fullWidth type="button" onClick={onClose}>
          <FormattedMessage
            id="getWallet.bottomButton"
            defaultMessage="Add wallet address"
          />
        </StyledButton>
      </StyledSection>
    </>
  );
};
