export const WalletQueryKeys = {
  WalletAddress: 'WalletAddress',
  HeliumPrice: 'HeliumPrice',
  TotalEarningsData: 'TotalEarningsData',
  BalanceData: 'BalanceData',
  CoolspotsData: 'CoolspotsData',
  Coolspot: 'Coolspot',
  EarnerPayments: 'EarnerPayments',
  WalletsHistory: 'WalletsHistory'
};

export const WalletMutationKeys = {
  EditWallet: 'EDIT_WALLET'
};
