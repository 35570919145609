import styled from 'styled-components';

export const MapLegendContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.margin.l};
  width: 100%;

  padding: ${({ theme }) => theme.padding.s};

  box-shadow: ${({ theme }) => theme.shadow.white.s};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius};
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius};
  background: ${({ theme }) => theme.color.white};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }
`;
