import { FC } from 'react';

import { CurrentEarnings } from './CurrentEarnings';
import { HeliumPrice } from './HeliumPrice';
import { MyPayoutWallet } from './MyPayoutWallet';
import { TotalRewards } from './TotalRewards';
import {
  CardContainer,
  StatisticsContainer,
  TopBarContainer
} from './Header.styles';
import { HeaderProps } from './Header.types';
import { useGetHeliumPrice } from '../../../shared/api/Wallet/Wallet.queries';

export const Header: FC<HeaderProps> = ({ coolspots }) => {
  const { data: currencyData } = useGetHeliumPrice();

  return (
    <CardContainer>
      <TopBarContainer deviceCount={coolspots?.length || 0}>
        <StatisticsContainer>
          {coolspots?.length! > 0 ? (
            <>
              <CurrentEarnings
                exchange={currencyData?.current.exchange || 0}
                lastHourAverage={currencyData?.current.lastHourAverage || 0}
              />
              <TotalRewards />
            </>
          ) : (
            <HeliumPrice currencyData={currencyData} />
          )}
        </StatisticsContainer>
      </TopBarContainer>
      <MyPayoutWallet />
    </CardContainer>
  );
};
