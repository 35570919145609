import styled from 'styled-components';

import { getTypography } from '../../shared/utils/style-utils';
import { EmptyStateBoxProps } from './EmptyStateBox.types';

export const EmptyStateBox = styled.div<EmptyStateBoxProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${({ direction = 'column' }) => direction};
  flex-grow: 1;
  gap: ${({ theme }) => theme.margin.m};

  margin-top: ${({ theme }) => theme.margin.m};
  padding: ${({ theme }) => theme.padding.m};

  color: ${({ theme }) => theme.color.dimmed};
  background: ${({ theme }) => theme.color.secondaryWhiteBlue};
  border-radius: ${({ theme }) => theme.borderRadius};

  ${({ theme }) => getTypography(theme.typography.heading.h5)}
`;
