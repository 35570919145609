/* eslint-disable sort-keys */
import {
  BalanceResponse,
  CoolspotEarningResponse,
  CoolspotResponse,
  HeliumPriceResponse,
  TotalEarningsResponse,
  WalletAddressResponse
} from '../../shared/api/Wallet/Wallet.types';

export const walletIdFixture: WalletAddressResponse = {
  heliumWalletId: '141FFnjBbjGFqAuwroStGgTFfkf3cTtPsqtAohUTA8CR8bnPR48'
};

export const currenciesFixture: HeliumPriceResponse = {
  current: {
    currency: 'USD',
    exchange: 9.5,
    lastHourAverage: 8.2,
    timestamp: '2021-07-23T08:36:24.000Z'
  },
  lastDay: {
    avg: 10.01,
    currency: 'USD',
    max: 10,
    min: 9
  }
};

export const totalEarningsFixture: TotalEarningsResponse = {
  currentEarnings: {
    hnt: 0,
    usd: 0
  },
  totalEarnings: {
    hnt: 0,
    usd: 0
  }
};

export const balanceFixture: BalanceResponse = {
  balance: 0
};

export const coolspotsFixture: CoolspotResponse = {
  count: 4,
  entities: [
    {
      coolspotId: 'b9dd95b7-c7e4-4a17-806b-a7f564329b97',
      earningsFactor: 0.2,
      installationAddress: 'Gdańska 141a, 90-536 Łódź, Poland',
      heliumHotspotId: null,
      name: null,
      lat: 51.7570099,
      lng: 19.4495788,
      isActive: false,
      isReferral: false,
      isReferralVisible: false,
      isReferralActive: false
    },
    {
      coolspotId: '4b914218-b82f-49a3-b0da-6d7de99c25de',
      earningsFactor: 0.2,
      installationAddress: 'Puławska 15, 00-791 Warszawa, Poland',
      heliumHotspotId: null,
      name: null,
      lat: 52.2104429,
      lng: 21.0225058,
      isActive: false,
      isReferral: false,
      isReferralVisible: false,
      isReferralActive: false
    },
    {
      coolspotId: '464532ce-7d0c-43db-a3fe-a4a42ba0fca7',
      earningsFactor: 0.2,
      installationAddress: 'Szaserów 128, 04-141 Warszawa, Poland',
      heliumHotspotId: null,
      name: null,
      lat: 52.2510925,
      lng: 21.0885589,
      isActive: false,
      isReferral: false,
      isReferralVisible: false,
      isReferralActive: false
    },
    {
      coolspotId: '618089dc-956c-415c-a651-3e06b2fc6aa3',
      earningsFactor: 0.2,
      installationAddress: 'Marszałkowska 140, 00-061 Warszawa, Poland',
      heliumHotspotId: null,
      name: null,
      lat: 52.2372032,
      lng: 21.0079118,
      isActive: false,
      isReferral: false,
      isReferralVisible: false,
      isReferralActive: false
    }
  ]
};

export const coolspotEarnings: CoolspotEarningResponse = {
  earnings: {
    '7days': {
      amount: 0,
      period: {
        min: '2022-01-25T13:31:24.224Z',
        max: '2022-01-25T13:31:24.224Z'
      }
    },
    '24h': {
      amount: 0,
      period: {
        min: '2022-01-25T13:31:24.224Z',
        max: '2022-01-25T13:31:24.224Z'
      }
    },
    '30days': {
      amount: 0,
      period: {
        min: '2022-01-25T13:31:24.224Z',
        max: '2022-01-25T13:31:24.224Z'
      }
    },
    current: {
      amount: 0,
      period: {
        min: '2022-01-25T13:31:24.224Z',
        max: '2022-01-25T13:31:24.224Z'
      }
    },
    previous7days: {
      amount: 0,
      period: {
        min: '2022-01-25T13:31:24.225Z',
        max: '2022-01-25T13:31:24.225Z'
      }
    },
    previous24h: {
      amount: 0,
      period: {
        min: '2022-01-25T13:31:24.224Z',
        max: '2022-01-25T13:31:24.224Z'
      }
    },
    previous30days: {
      amount: 0,
      period: {
        min: '2022-01-25T13:31:24.225Z',
        max: '2022-01-25T13:31:24.225Z'
      }
    },
    total: {
      amount: 0,
      period: {
        min: '2022-01-25T13:31:24.224Z',
        max: '2022-01-25T13:31:24.224Z'
      }
    },
    earningsFactor: '20%'
  }
};
