import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { AlertVariant } from '../../../../components/Alert/Alert.types';
import { Box } from '../../../../components/Box';
import { Button } from '../../../../components/Button';
import { BUTTON_SIZE } from '../../../../components/Button/Button.types';
import { FullPageLoader } from '../../../../components/FullPageLoader';
import { StepProps } from '../../../../components/Stepper';
import { Subtitle, Text } from '../../../../components/Typography';
import { useGetCoolspotsData } from '../../../../shared/api/Wallet';
import { useNotification } from '../../../../shared/utils/notifications/notification-context';
import {
  useCreateCheckoutToUpgradeDevice,
  useGetProductVariants
} from '../../../../shared/api/Order/Order.queries';
import { useQueryParams } from '../../../../shared/utils/use-query-params';
import {
  userTracking,
  UserTrackingEvent
} from '../../../../shared/utils/user-tracking';
import { ErrorPage } from '../../../ErrorPage';
import { StyledForm } from './UpgradeEarningsForm.styles';
import { DevicesToUpgrade } from './DevicesToUpgrade';
import { useBreakpoints } from '../../../../shared/utils/use-breakpoints';
import { CouponCodeInput } from '../../../../components/CouponCodeInput';
import { UpgradeEarningsFormFields } from './UpgradeEarningsForm.types';
import { upgradeEarningsFormSchema } from './UpgradeEarningsForm.validation';

const UPGRADE_EARNINGS_FACTOR = 0.21;

export const UpgradeEarningsForm: FC<{ defaultValue?: string } & StepProps> = ({
  handleNextStep
}) => {
  const history = useHistory();
  const { addNotification } = useNotification();
  const [{ deviceId }] = useQueryParams({
    deviceId: ''
  });
  const { isTablet } = useBreakpoints();

  const {
    register,
    handleSubmit,
    formState: { isValid },
    control
  } = useForm<UpgradeEarningsFormFields>({
    mode: 'onChange',
    resolver: yupResolver(upgradeEarningsFormSchema),
    defaultValues: {
      coolspotId: deviceId
    }
  });

  useEffect(() => {
    userTracking(UserTrackingEvent.RegisteredUser);
  }, []);

  const {
    data: coolspotsData,
    isLoading: isCoolspotDataLoading,
    error: coolspotsError
  } = useGetCoolspotsData({
    belowEarningsFactor: UPGRADE_EARNINGS_FACTOR,
    showOnlyProvisioned: true
  });

  const {
    data: productVariantsData,
    isLoading: isGetProductVariantsLoading,
    isError: isGetProductVariantsError
  } = useGetProductVariants(
    {
      type: 'UPGRADE'
    },
    {
      onError: (error) => {
        addNotification({
          id: 'getProductVariantsError',
          text: error.message,
          variant: AlertVariant.Danger
        });
      }
    }
  );

  const {
    mutate: createCheckout,
    isLoading: isCreateCheckoutToUpgradeDeviceLoading,
    isSuccess: isCreateCheckoutToUpgradeDeviceSuccess
  } = useCreateCheckoutToUpgradeDevice({
    onSuccess: (data) => {
      if (handleNextStep) {
        handleNextStep(data);
      }
    },
    onError: (error) => {
      addNotification({
        id: 'createCheckoutError',
        text: error.message,
        variant: AlertVariant.Danger
      });
    }
  });

  const handleCancel = async () => {
    history.push('/dashboard');
  };

  const formatName = (name: string) =>
    (name || '')
      .split('-')
      .map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
      .join(' ');

  const options = coolspotsData?.entities.map((coolspot) => ({
    label: formatName(coolspot.name ?? ''),
    installationAddress: coolspot.installationAddress,
    value: coolspot.coolspotId
  }));

  const productVariantId = productVariantsData?.entities.find(
    (variant) => variant.productType === 'UPGRADE'
  )?.variantId;

  if (isCoolspotDataLoading || isGetProductVariantsLoading) {
    return <FullPageLoader />;
  }

  if (
    coolspotsError ||
    isGetProductVariantsError ||
    !options ||
    !productVariantId
  ) {
    return <ErrorPage isOverlay />;
  }

  const onSubmit = ({ coolspotId, coupon }: UpgradeEarningsFormFields) => {
    const device = {
      productVariantId,
      coolspotId
    };

    createCheckout({
      devices: [device],
      couponCode: coupon?.couponCode
    });
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <Box mt="xl">
        <Subtitle size="big" variant="grey">
          <FormattedMessage
            id="upgradeDevices.subtitle"
            defaultMessage="Select the CoolSpot™ you'd like to upgrade."
          />
        </Subtitle>
      </Box>
      <Box mt={isTablet ? 'm' : 'xl'}>
        <DevicesToUpgrade {...register('coolspotId')} options={options} />
      </Box>
      <Box mt="xl">
        <Text level="big">
          <FormattedMessage
            id="chooseEarningPlan.couponCodeTitile"
            defaultMessage="Coupon code"
          />
        </Text>
        <Controller
          name="coupon"
          control={control}
          render={({ field: { name, value, ref, onBlur, onChange } }) => (
            <CouponCodeInput
              name={name}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              inputRef={ref}
              label="Have a coupon? enter it below and get your discount!"
              placeholder="Enter your code"
              productVariantIds={productVariantId ? [productVariantId] : null}
            />
          )}
        />
      </Box>
      <Box mt="xl">
        <Button
          disabled={!isValid}
          isLoading={
            isCreateCheckoutToUpgradeDeviceLoading ||
            isCreateCheckoutToUpgradeDeviceSuccess
          }
          isPrimary
          fullWidth
          type="submit"
        >
          <FormattedMessage
            id="upgradeDevices.goToPayment"
            defaultMessage="Go to payment"
          />
        </Button>
      </Box>
      <Box mt="m">
        <Button
          onClick={handleCancel}
          isPlain
          isPrimary={false}
          fullWidth
          size={BUTTON_SIZE.BIG}
        >
          <FormattedMessage
            id="upgradeDevices.cancelButton"
            defaultMessage="Cancel"
          />
        </Button>
      </Box>
    </StyledForm>
  );
};
