import styled, { css } from 'styled-components';
import Select from 'react-select';

import { getTypography } from '../../shared/utils/style-utils';
import { DropdownProps } from './Dropdown.types';
import { FieldVariant } from '../FieldCaption';

export const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledLabel = styled.label<{ variant?: FieldVariant }>`
  ${({ theme }) => getTypography(theme.typography.caption)};

  margin-bottom: ${({ theme }) => theme.margin.xxs};

  color: ${({ theme, variant }) =>
    variant === FieldVariant.DISABLED ? theme.color.dimmed : theme.color.grey};
`;

export const StyledSelect = styled(Select)<DropdownProps>`
  .select {
    &__control {
      border: 2px solid transparent;
      border-radius: ${({ theme }) => theme.borderRadius};
      box-shadow: 0 0 0 1px ${({ theme }) => theme.color.dimmed};
      background-color: ${({ theme }) => theme.color.white};

      ${({ theme, variant }) =>
        variant === FieldVariant.DISABLED &&
        css`
          box-shadow: 0 0 0 1px ${theme.color.lightGrey};
        `}

      ${({ theme, variant }) =>
        variant === FieldVariant.ERROR &&
        css`
          box-shadow: none;
          border: ${theme.border(theme.color.secondaryRed, 2)};
        `}

      &:hover {
        border-color: ${({ theme, variant }) =>
          variant === FieldVariant.ERROR
            ? theme.color.secondaryRed
            : 'transparent'};
      }

      &--is-focused {
        box-shadow: none;
        border: ${({ theme }) => theme.border(theme.color.blue, 2)};

        &:hover {
          border: ${({ theme }) => theme.border(theme.color.blue, 2)};
        }
      }
    }

    &__value-container {
      min-height: 56px;

      color: ${({ theme }) => theme.color.grey};

      padding-top: ${({ theme }) => `calc(${theme.padding.s} - 4px)`};
      padding-right: ${({ theme }) => `calc(${theme.padding.s} - 2px)`};
      padding-bottom: ${({ theme }) => `calc(${theme.padding.s} - 4px)`};
      padding-left: ${({ theme }) => `calc(${theme.padding.s} - 4px)`};
    }

    &__placeholder {
      color: ${({ theme }) => theme.color.grey};
      ${({ theme }) => getTypography(theme.typography.body.big)};

      ${({ theme, variant }) =>
        variant === FieldVariant.DISABLED &&
        css`
          color: ${theme.color.dimmed};
        `}
    }

    &__single-value {
      ${({ theme }) => getTypography(theme.typography.body.big)};
    }

    &__indicator-separator {
      display: none;
    }

    &__indicators {
      ${({ hideButton, isDisabled }) =>
        (hideButton || isDisabled) &&
        css`
          display: none;
        `}
    }

    &__menu {
      box-shadow: ${({ theme }) => theme.shadow.blue.s};
      border-radius: ${({ theme }) => theme.borderRadius};
      z-index: 2;
    }
  }
`;

export const StyledOption = styled.div<{
  isSelected: boolean;
  isFocused: boolean;
}>`
  display: flex;
  padding-top: ${({ theme }) => theme.padding.xs};
  padding-right: ${({ theme }) => theme.padding.s};
  padding-bottom: ${({ theme }) => theme.padding.xs};
  padding-left: ${({ theme }) => theme.padding.s};

  ${({ theme }) => getTypography(theme.typography.button.big)};
  color: ${({ theme }) => theme.color.grey};

  ${({ isSelected }) =>
    isSelected &&
    css`
      color: ${({ theme }) => theme.color.dimmed};
      background-color: #FFFFFFF;
      pointer-events: none;
    `}

  ${({ isFocused }) =>
    isFocused &&
    css`
      color: ${({ theme }) => theme.color.blue};
      background-color: ${({ theme }) => theme.color.secondaryWhiteBlue};
    `}
`;

export const IconWrapper = styled.span<{
  isSelected: boolean;
  isFocused: boolean;
}>`
  display: inline-flex;
  height: 20px;
  width: 20px;
  margin-right: 8px;

  & > svg {
    stroke: ${({ theme }) => theme.color.grey};

    ${({ isSelected }) =>
      isSelected &&
      css`
        stroke: ${({ theme }) => theme.color.dimmed};
      `}

    ${({ isFocused }) =>
      isFocused &&
      css`
        stroke: ${({ theme }) => theme.color.blue};
      `}
  }
`;
