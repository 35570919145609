import styled, { css } from 'styled-components';

import {
  StyledTooltipProps,
  TooltipAlignment,
  TooltipPosition,
  TooltipVariant
} from './Tooltip.types';

export const Wrapper = styled.div<{ isActive: boolean }>`
  position: relative;
  display: inline-block;
  height: max-content;

  &,
  * {
    cursor: pointer;
  }
`;

const getAlignmentStyles = (axis: 'x' | 'y', align: TooltipAlignment) => {
  if (axis === 'x') {
    if (align === 'center') {
      return css`
        left: 50%;
        transform: translateX(-50%);
      `;
    }

    if (align === 'end') {
      return css`
        right: 0;
      `;
    }
  } else if (axis === 'y') {
    if (align === 'start') {
      return css`
        top: 0;
      `;
    }

    if (align === 'center') {
      return css`
        top: 50%;
        transform: translateY(-50%);
      `;
    }

    if (align === 'end') {
      return css`
        bottom: 0;
      `;
    }
  }

  return '';
};

export const StyledTooltip = styled.div<StyledTooltipProps>`
  display: flex;
  align-items: center;
  position: absolute;
  ${({ align = TooltipAlignment.Center, offset = 12, position }) => {
    switch (position) {
      case TooltipPosition.TopLeft:
        return css`
          bottom: calc(100% + ${offset}px);
          right: calc(100% + ${offset}px);
        `;
      case TooltipPosition.Top:
        return css`
          bottom: calc(100% + ${offset}px);
          ${getAlignmentStyles('x', align)}
        `;
      case TooltipPosition.TopRight:
        return css`
          bottom: calc(100% + ${offset}px);
          left: calc(100% + ${offset}px);
        `;
      case TooltipPosition.Right:
        return css`
          left: calc(100% + ${offset}px);
          ${getAlignmentStyles('y', align)}
        `;
      case TooltipPosition.Center:
        return css`
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        `;
      case TooltipPosition.Left:
        return css`
          right: calc(100% + ${offset}px);
          ${getAlignmentStyles('y', align)}
        `;
      case TooltipPosition.BottomLeft:
        return css`
          top: calc(100% + ${offset}px);
          right: calc(100% + ${offset}px);
        `;
      case TooltipPosition.BottomRight:
        return css`
          top: calc(100% + ${offset}px);
          left: calc(100% + ${offset}px);
        `;
      default:
        return css`
          top: calc(100% + ${offset}px);
          ${getAlignmentStyles('x', align)}
        `;
    }
  }}
  z-index: 100;
  overflow: hidden;
  width: auto;
  height: auto;
  box-shadow: ${({ theme, variant }) =>
    variant === TooltipVariant.blueBorder
      ? '0px 0px 2px rgba(50, 103, 241, 0.75), 0px 12px 24px rgba(50, 103, 241, 0.1)'
      : `${theme.shadow.white.xs}, ${theme.shadow.white.s}`};
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: ${({ theme, padding }) =>
    typeof padding !== 'undefined'
      ? css`
          ${padding}px;
        `
      : theme.padding.xs};
  background-color: ${({ theme }) => theme.color.white};
  white-space: nowrap;

  * {
    background-color: inherit;
    color: inherit;
  }
`;
