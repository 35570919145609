import { FC } from 'react';
import { useLocation } from 'react-router';

import {
  NavbarMenuListItem,
  TextWrapper
} from '../NavbarMenuItem/NavbarMenuItem.styles';
import { StyledNavbarMenuLink } from './NavbarMenuItem.styles';
import { NavbarMenuLinkProps } from './NavbarMenuLink.types';

export const NavbarMenuLink: FC<NavbarMenuLinkProps> = ({
  children,
  external = false,
  to,
  variant
}) => {
  const location = useLocation();

  if (external) {
    return (
      <NavbarMenuListItem>
        <StyledNavbarMenuLink
          as="a"
          href={to}
          $isActive={location.pathname === to}
          target="_blank"
          rel="noopener noreferrer"
          variant={variant}
        >
          <TextWrapper>{children}</TextWrapper>
        </StyledNavbarMenuLink>
      </NavbarMenuListItem>
    );
  }

  return (
    <NavbarMenuListItem>
      <StyledNavbarMenuLink
        $isActive={location.pathname === to}
        to={to}
        variant={variant}
      >
        <TextWrapper>{children}</TextWrapper>
      </StyledNavbarMenuLink>
    </NavbarMenuListItem>
  );
};
