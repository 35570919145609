import styled, { css } from 'styled-components';

import { RadioGroupVariant } from './RadioGroup.types';

export const RadioGroupWrapper = styled.div<{
  horizontal?: boolean;
  variant: RadioGroupVariant;
}>`
  display: flex;
  flex-direction: ${({ horizontal }) => (horizontal ? 'row' : 'column')};

  ${({ horizontal }) =>
    horizontal &&
    css`
      flex-wrap: wrap;
    `}

  ${({ horizontal, variant }) =>
    horizontal &&
    variant === RadioGroupVariant.Default &&
    css`
      gap: ${({ theme }) => theme.margin.s};
    `}
`;

export const RadioLabel = styled.label<{ variant: RadioGroupVariant }>`
  position: relative;
  padding-top: ${({ theme }) => theme.padding.s};
  padding-right: ${({ theme }) => theme.padding.s};
  padding-left: ${({ theme }) => theme.padding.xl};
  padding-bottom: ${({ theme }) => theme.padding.s};
  border: ${({ theme }) => theme.border('transparent')};
  border-radius: ${({ theme }) => theme.borderRadius};
  color: ${({ theme }) => theme.color.grey};
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  ${({ variant }) =>
    variant === RadioGroupVariant.Default &&
    css`
      box-shadow: ${({ theme }) => theme.shadow.blue.s};
      margin-bottom: ${({ theme }) => theme.margin.s};
      &:hover {
        box-shadow: ${({ theme }) => theme.shadow.blue.m};
      }
    `}

  ${({ variant }) =>
    variant === RadioGroupVariant.Simple &&
    css`
      margin-right: ${({ theme }) => theme.margin.s};
    `}

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: ${({ variant }) =>
      variant === RadioGroupVariant.Default ? '11px' : '0px'};
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z' stroke='%239E9E9E' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    transition: all 0.2s ease-in-out;
  }
`;

export const RadioInput = styled.input<{ variant: RadioGroupVariant }>`
  position: absolute;
  width: 0;
  height: 0;
  display: none;

  &:checked + ${RadioLabel} {
    ${({ variant }) =>
      variant === RadioGroupVariant.Default &&
      css`
        border: ${({ theme }) => theme.border(theme.color.blue)};
      `}

    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z' stroke='%233267F1' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Ccircle cx='10' cy='10' r='4' fill='%233267F1'/%3E%3C/svg%3E%0A");
    }
  }

  ${({ variant }) =>
    variant === RadioGroupVariant.Default &&
    css`
      &:focus + ${RadioLabel} {
        box-shadow: ${({ theme }) => theme.shadow.blue.m};
        border: ${({ theme }) => theme.border(theme.color.blue)};
      }
    `}
`;
