import styled from 'styled-components';

import { Text } from '../../../../components/Typography';

export const StyledPrice = styled(Text)`
  font-weight: 600;
`;

export const Wrapper = styled.div`
  min-height: 110px;
  padding-left: ${({ theme }) => theme.padding.l};
  flex: 1;
  border-left: 1px solid ${({ theme }) => theme.color.lightGrey};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    padding-top: ${({ theme }) => theme.padding.l};
    padding-left: 0;
    border-top: 1px solid ${({ theme }) => theme.color.lightGrey};
    border-left: none;
  }

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    min-height: auto;
  }
`;

export const LoaderWrapper = styled(Wrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
