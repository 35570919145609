import styled from 'styled-components';

import { InputWrapper } from '../../../../components/Input/Input.styles';
import { Text } from '../../../../components/Typography';
import { getTypography } from '../../../../shared/utils/style-utils';

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${({ theme }) => theme.margin.s};

  ${InputWrapper} {
    flex: 1;
  }
`;

export const Label = styled.label`
  ${({ theme }) => getTypography(theme.typography.caption)};

  margin-bottom: ${({ theme }) => theme.margin.xxs};

  color: ${({ theme }) => theme.color.grey};
`;

export const UnitLabel = styled.p`
  width: 73px;
  margin: 0;
  padding-top: 48px;
  padding-left: ${({ theme }) => theme.margin.m};
`;

export const ModalCopy = styled(Text).attrs({
  level: 'small',
  variant: 'h4'
})`
  text-align: center;
`;
