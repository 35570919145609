import styled from 'styled-components';

import { Box } from '../Box';

export const NotificationContainer = styled(Box)`
  transition: transform 0.3 ease-in;
  animation: slide-in 0.5s;

  @keyframes slide-in {
    from {
      transform: translateY(100%);
      opacity: 0%;
    }
    to {
      transform: translateY(0);
      opacity: 100%;
    }
  }
`;
