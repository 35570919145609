import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { AlertVariant } from '../../../../components/Alert/Alert.types';
import { Box } from '../../../../components/Box';
import { Button } from '../../../../components/Button';
import { ValidateLayout } from '../../../../components/Layout/ValidateLayout/ValidateLayout';
import { VerificationInput } from '../../../../components/VerificationInput';
import {
  useCreateLeadUserAccount,
  useLeadUserEmailVerify,
  useVerifyOneTimeAuthCode
} from '../../../../shared/api/Identity/Identity.queries';
import { useNotification } from '../../../../shared/utils/notifications/notification-context';
import { InputWrapper } from './EmailValidate.styles';
import { ErrorCodes } from '../../../../shared/api/error-codes';
import {
  userTracking,
  UserTrackingEvent
} from '../../../../shared/utils/user-tracking';
import {
  CreateLeadUserStepProps,
  CreateLeadUserStepsValues
} from '../CreateLeadUser.types';
import { useAuth } from '../../../../shared/auth/auth-context';
import { LeadUserData } from '../../../../shared/auth/auth-context.types';
import { useRecalculateLeadOrderProxScore } from '../../../../shared/api/Order/Order.queries';

export const EmailValidate: FC<CreateLeadUserStepProps> = ({
  handleNextStep,
  handlePreviousStep,
  previousStepValues
}) => {
  const {
    formState: { errors },
    register,
    handleSubmit,
    setValue,
    setError
  } = useForm<{ code: string }>({
    mode: 'onBlur'
  });
  const { formatMessage } = useIntl();
  const { addNotification } = useNotification();
  const { setLeadUserData } = useAuth();

  useEffect(() => {
    userTracking(UserTrackingEvent.NewUser);

    register('code', { required: true });
  }, [register]);

  const { mutate: sendCode } = useCreateLeadUserAccount({
    onError: (error) => {
      const { message, statusCode } = error.response?.data || {};

      if (statusCode >= 400) {
        addNotification({
          id: 'resendCodeError',
          text: message,
          variant: AlertVariant.Danger
        });
      }
    },
    onSuccess: () => {
      addNotification({
        id: 'leadUserEmailVerifySuccess',
        text: formatMessage({
          id: 'leadUserEmailVerifySuccess',
          defaultMessage:
            'Please check your email inbox for a verification code.'
        }),
        variant: AlertVariant.Success
      });
    }
  });

  const { mutateAsync: recalculateProxScore } =
    useRecalculateLeadOrderProxScore();

  const { mutate: verifyCode, isLoading: isVerifyCodeLoading } =
    useLeadUserEmailVerify({
      onError: (apiError) => {
        setError('code', {
          message: apiError.data.message,
          type: 'manual'
        });

        if (
          apiError?.data?.statusCode >= 400 &&
          apiError?.data?.errorCode !== ErrorCodes.INVALID_VERIFICATION_CODE
        ) {
          addNotification({
            id: 'verifyCodeError',
            text: apiError.data.message,
            variant: AlertVariant.Danger
          });
        }
      },
      onSuccess: async ({ leadOrderId }) => {
        setLeadUserData(previousStepValues as LeadUserData);

        if (previousStepValues?.existingLeadOrderId) {
          await recalculateProxScore({
            email: previousStepValues.email!,
            leadOrderId: previousStepValues.existingLeadOrderId
          });
        }

        if (handleNextStep) {
          handleNextStep({
            ...previousStepValues,
            leadOrderId,
            isValidated: true
          } as CreateLeadUserStepsValues);
        }
      }
    });

  const { mutate: verifyAuthCode } = useVerifyOneTimeAuthCode({
    onError: (apiError) => {
      setError('code', {
        message: apiError.data.message,
        type: 'manual'
      });

      if (
        apiError?.data?.statusCode >= 400 &&
        apiError?.data?.errorCode !== ErrorCodes.INVALID_VERIFICATION_CODE
      ) {
        addNotification({
          id: 'verifyCodeError',
          text: apiError.data.message,
          variant: AlertVariant.Danger
        });
      }
    },
    onSuccess: async () => {
      setLeadUserData(previousStepValues as LeadUserData);

      await recalculateProxScore({
        email: previousStepValues?.email!,
        leadOrderId: previousStepValues?.existingLeadOrderId!
      });

      if (handleNextStep) {
        handleNextStep({
          ...previousStepValues,
          leadOrderId: previousStepValues?.existingLeadOrderId!,
          isValidated: true
        } as CreateLeadUserStepsValues);
      }
    }
  });

  const handleResendCode = () => {
    sendCode(previousStepValues as CreateLeadUserStepsValues);
  };

  return (
    <ValidateLayout
      enteredEmail={previousStepValues?.email}
      onResendCode={handleResendCode}
    >
      <form
        onSubmit={handleSubmit(({ code }) => {
          if (previousStepValues?.existingLeadOrderId) {
            verifyAuthCode({
              code,
              email: previousStepValues?.email!
            });
          } else {
            verifyCode({
              code,
              email: previousStepValues?.email!,
              installationAddress: {
                googleMapsObject: previousStepValues?.installationAddress
              }
            });
          }
        })}
      >
        <InputWrapper>
          <VerificationInput
            name="Verification Code"
            error={errors.code?.message}
            onChange={async (value) => {
              setValue('code', value);
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <Button
            isPrimary
            fullWidth
            isLoading={isVerifyCodeLoading}
            type="submit"
          >
            <FormattedMessage
              id="resetPassword2.verify"
              defaultMessage="Verify"
            />
          </Button>
        </InputWrapper>
      </form>
      <Box mt="s">
        <Button
          fullWidth
          onClick={() => handlePreviousStep?.()}
          disabled={isVerifyCodeLoading}
        >
          <FormattedMessage id="resetPassword2.back" defaultMessage="Back" />
        </Button>
      </Box>
    </ValidateLayout>
  );
};
