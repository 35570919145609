import { FC, useEffect, useRef, useState } from 'react';
// import { useLocation } from 'react-router-dom';
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll
} from 'body-scroll-lock';

import { useAuth } from '../../../shared/auth/auth-context';
import { useBreakpoints } from '../../../shared/utils/use-breakpoints';
import { FullPageLoader } from '../../FullPageLoader';
import { MobileMenu } from '../../MobileMenu';
import { Navbar } from '../../Navbar';
import {
  LayoutHeader,
  LayoutWrapper,
  MainContentWrapper
} from './DashboardLayout.styles';
// import { UpgradeBar } from '../../../features/Dashboard/UpgradeBar/UpgradeBar';
import { DashboardLayoutProps } from './DashbaordLayout.types';

export const DashboardLayout: FC<DashboardLayoutProps> = ({
  children,
  hideMenu
}) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const { logout } = useAuth();
  const { isTablet } = useBreakpoints();
  // const { pathname } = useLocation();
  const mobileMenuRef = useRef<HTMLElement>();

  useEffect(() => {
    return () => clearAllBodyScrollLocks();
  }, []);

  const toggleMenu = (isOpen: boolean) => {
    if (isOpen) {
      setMenuOpen(true);
      disableBodyScroll(mobileMenuRef.current!);
    } else {
      enableBodyScroll(mobileMenuRef.current!);
      setMenuOpen(false);
    }
  };

  if (logout.isLoading) {
    return <FullPageLoader />;
  }

  return (
    <LayoutWrapper>
      <LayoutHeader>
        {/* {pathname === '/dashboard' && <UpgradeBar />} */}
        <Navbar
          hideMenu={hideMenu}
          onMenuOpen={() => toggleMenu(!isMenuOpen)}
        />
      </LayoutHeader>
      <MainContentWrapper>{children}</MainContentWrapper>
      {isTablet && (
        <MobileMenu
          ref={mobileMenuRef as any}
          isActive={isMenuOpen}
          onMenuClose={() => toggleMenu(false)}
        />
      )}
    </LayoutWrapper>
  );
};
