import styled from 'styled-components';

import { getTypography } from '../../../../../shared/utils/style-utils';

export const AntennaContainer = styled.p`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.padding.xxs};
  height: 32px;
  margin: 0;
`;

export const Value = styled.span`
  ${({ theme }) => getTypography(theme.typography.body.big)};
`;
