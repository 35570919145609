import React, { MouseEvent } from 'react';

import { Icons } from '../../../icons';

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum ICON_BUTTON_SIZE {
  'BIG' = 'big',
  'SMALL' = 'small'
}

export interface IconButtonProps
  extends React.ComponentPropsWithoutRef<'button'> {
  isPrimary?: boolean;
  isIconButton?: boolean;
  size?: ICON_BUTTON_SIZE.BIG | ICON_BUTTON_SIZE.SMALL;
  icon: Icons;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}
