import styled from 'styled-components';

import { Box } from '../../../../../components/Box';

export const StyledForm = styled.form`
  width: 100%;
  margin-top: ${({ theme }) => theme.margin.l};

  @media (${({ theme }) => theme.breakpoints.phone}) {
    margin-top: ${({ theme }) => theme.margin.s};
  }
`;

export const SubmitButtonRow = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: 40px;

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    flex-direction: row;
    justify-content: flex-start;
    gap: 8px;
  }

  @media (${({ theme }) => theme.breakpoints.phone}) {
    flex-direction: column-reverse;
    margin-top: ${({ theme }) => theme.margin.s};
  }
`;
