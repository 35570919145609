import { FC } from 'react';

import { Icon } from '../../../Icon';
import { TooltipProps } from '../../../Tooltip/Tooltip.types';
import { NavbarMenuListItem } from '../NavbarMenuItem/NavbarMenuItem.styles';
import { StyledNavbarMenuTooltip } from './NavbarMenuTooltip.styles';

export const NavbarMenuTooltip: FC<TooltipProps> = ({ children, ...props }) => {
  return (
    <NavbarMenuListItem>
      <StyledNavbarMenuTooltip {...props}>
        <button type="button">
          {children}
          <Icon icon="ChevronDown" />
        </button>
      </StyledNavbarMenuTooltip>
    </NavbarMenuListItem>
  );
};
