import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Box } from '../Box';
import { Checkbox } from '../Checkbox/Checkbox';
import { ConsentCheckboxesProps } from './ConsentCheckboxes.types';

export const ConsentCheckboxes: FC<ConsentCheckboxesProps> = ({
  register,
  errors
}: ConsentCheckboxesProps) => {
  return (
    <>
      <Box mt="m">
        <Checkbox {...register('age')} error={errors.age?.message}>
          <FormattedMessage
            id="consentCheckboxes.ageConfirmation"
            defaultMessage="I confirm I’m 18 years old"
          />
        </Checkbox>
      </Box>
      <Box mt="m">
        <Checkbox
          {...register('agreePolicies')}
          error={errors.agreePolicies?.message}
        >
          <span>
            <FormattedMessage
              id="consentCheckboxes.agreePolicies"
              defaultMessage="I agree to the {termsOfService} and {privacyPolicy}"
              values={{
                privacyPolicy: (
                  <>
                    <a
                      href="https://emrit.io/privacy-policy/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FormattedMessage
                        id="consentCheckboxes.privacyPolicy"
                        defaultMessage="Privacy Policy"
                      />
                    </a>
                    &nbsp;
                  </>
                ),
                termsOfService: (
                  <>
                    &nbsp;
                    <a
                      href="/terms-and-conditions"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FormattedMessage
                        id="consentCheckboxes.termsOfService"
                        defaultMessage="Terms of Service"
                      />
                    </a>
                    &nbsp;
                  </>
                )
              }}
            />
          </span>
        </Checkbox>
      </Box>
    </>
  );
};
