import { FC } from 'react';

import { TabProps } from './Tabs.types';
import { TabWrapper } from './Tabs.styles';
import { TabContext } from './Tabs';

export const Tab: FC<TabProps> = ({ children, index, ...props }) => {
  const { Consumer } = TabContext;

  return (
    <Consumer>
      {({ selectedTab, setSelectedTab }) => (
        <TabWrapper
          isSelected={selectedTab === index}
          onClick={() => setSelectedTab(index)}
          {...props}
        >
          {children}
        </TabWrapper>
      )}
    </Consumer>
  );
};
