import styled from 'styled-components';

export const IconContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  padding: 0;
  border: 0;
  border-radius: 4px;
  background: ${({ theme }) => theme.color.secondaryWhiteBlue};
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.color.lightGrey};
  }
`;
