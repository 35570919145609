import { Country } from '../../shared/api/Order/Order.types';

export const countriesFixture: Country[] = [
  {
    code: 'US',
    isSupported: true,
    name: 'United States',
    productVariantId: '0'
  },
  {
    code: 'PL',
    isSupported: true,
    name: 'Poland',
    productVariantId: '0'
  },
  {
    code: 'DE',
    isSupported: false,
    name: 'Germany',
    productVariantId: '0'
  }
];

export const leadOrdersFixture = {
  entities: [
    {
      id: 'bfc38e4a-ffab-4736-81f4-af2a4f1e9563',
      status: 'CREATED',
      checkoutUrl: null,
      installationAddress: '5650 Yonge St, North York, ON M2M 4G3, Canada',
      country: 'CA',
      shippingAddress: null,
      orderNumber: null
    },
    {
      id: 'd5abf7fd-1e5f-438c-83a8-1b664e004603',
      status: 'CREATED',
      checkoutUrl: null,
      installationAddress:
        '346 Bến Vân Đồn, Phường 1, Quận 4, Thành phố Hồ Chí Minh, Vietnam',
      country: 'VN',
      shippingAddress: null,
      orderNumber: null
    },
    {
      id: '25ca8362-f70c-4b81-b810-109c426b8a9e',
      status: 'POSTPONED',
      checkoutUrl: null,
      installationAddress: 'Venloer Str. 419, 50825 Köln, Germany',
      country: 'DE',
      shippingAddress: null,
      orderNumber: null
    }
  ],
  count: 3
};

export const ordersFixture = [
  {
    orderId: 'cf84c8b0-0e02-4071-b2db-37c868ca3c86',
    shopifyOrderNumber: 1750,
    installationAddress: 'Stolberger Str. 364, 50933 Köln, Germany',
    createdAt: '2022-01-13T12:32:00.185Z',
    status: 'ORDERED'
  },
  {
    orderId: '315c0cc1-5c7f-47d2-b430-2642091bf413',
    shopifyOrderNumber: 1751,
    installationAddress: 'Breslauer Str. 41, 27755 Delmenhorst, Germany',
    createdAt: '2022-01-13T12:34:00.108Z',
    status: 'ORDERED'
  },
  {
    orderId: 'f8f53fbb-1e78-43da-acbd-d42e0995c8d0',
    shopifyOrderNumber: 1709,
    installationAddress: 'Messedamm 22, 14055 Berlin, Germany',
    createdAt: '2021-12-15T13:56:00.173Z',
    status: 'ORDERED'
  }
];
