import { FC, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useIntl } from 'react-intl';

import { AlertVariant } from '../../../components/Alert/Alert.types';
import { FullPageLoader } from '../../../components/FullPageLoader';
import { useCreateCheckoutForLeadOrder } from '../../../shared/api/Order/Order.queries';
import { useNotification } from '../../../shared/utils/notifications/notification-context';
import { ErrorPage } from '../../ErrorPage';
import { OrderACoolspotStepProps } from '../OrderACoolspot.types';

const track = (value: number | undefined, currency: string | undefined) => {
  if (process.env.REACT_APP_GTM_ID && process.env.NODE_ENV === 'production') {
    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null // Clear the previous ecommerce object.
      }
    });
    TagManager.dataLayer({
      dataLayer: {
        event: 'add_payment_info',
        ecommerce: {
          value: value ?? process.env.REACT_APP_PLAN_PRICE ?? 0,
          currency: currency ?? 'USD'
        }
      }
    });
  }
};

const redirectToCheckout = (
  checkoutUrl: string,
  value: number | undefined,
  currency: string | undefined
) => {
  track(value, currency);

  window.location.replace(checkoutUrl);
};

export const ShopifyCheckout: FC<OrderACoolspotStepProps> = ({
  previousStepValues,
  handlePreviousStep
}) => {
  const { formatMessage } = useIntl();
  const { addNotification } = useNotification();
  const [isOrderInvalid, setIsOrderInvalid] = useState(false);

  const handleCheckoutCreationFailure = (message?: string) => {
    addNotification({
      id: 'createCheckoutError',
      text:
        message ||
        formatMessage({
          id: 'shopifyCheckout.createCheckoutFailureMessagae',
          defaultMessage: 'Failed to create checkout.'
        }),
      variant: AlertVariant.Danger
    });

    handlePreviousStep?.();
  };

  const { mutate: createCheckoutAndRedirect } = useCreateCheckoutForLeadOrder({
    onSuccess: ({ checkoutUrl }) => {
      if (checkoutUrl) {
        redirectToCheckout(
          checkoutUrl,
          previousStepValues?.leadOrder?.order?.transaction.amountPaid,
          previousStepValues?.leadOrder?.order?.transaction.currency
        );
      } else {
        handleCheckoutCreationFailure();
      }
    },
    onError: (error) => {
      handleCheckoutCreationFailure(error.data.message);
    }
  });

  useEffect(() => {
    const { leadOrder, productVariantId, coupon } = previousStepValues || {};
    const { id: leadOrderId, checkoutUrl } = leadOrder || {};

    if (checkoutUrl) {
      redirectToCheckout(
        checkoutUrl,
        leadOrder?.order?.transaction.amountPaid,
        leadOrder?.order?.transaction.currency
      );
    } else if (leadOrderId && productVariantId) {
      createCheckoutAndRedirect({
        leadOrderId,
        productVariantId,
        couponCode: coupon?.couponCode
      });
    } else {
      setIsOrderInvalid(true);
    }
  }, [previousStepValues, createCheckoutAndRedirect, setIsOrderInvalid]);

  if (isOrderInvalid) {
    return <ErrorPage isOverlay />;
  }

  return <FullPageLoader />;
};
