import { FC } from 'react';

import { FullPageLoader } from '../../../components/FullPageLoader';
import { SignUpLayout } from '../../../components/Layout';
import { Stepper } from '../../../components/Stepper';
import { useGetCountries } from '../../../shared/api/Order';
import { useAuth } from '../../../shared/auth/auth-context';
import { ErrorPage } from '../../ErrorPage';
import { ConfirmationScreen } from './ConfirmationScreen';
import { CreateLeadUserPage } from './CreateLeadUserPage';
import { EmailValidate } from './EmailValidate';
import { InstallationAddress } from './InstallationAddress';

export const CreateLeadUser: FC = () => {
  const countryQuery = useGetCountries();

  const { login } = useAuth();

  if (countryQuery.isLoading || login.isLoading) {
    return <FullPageLoader />;
  }

  if (countryQuery.isError || countryQuery.isIdle) {
    return <ErrorPage />;
  }

  return (
    <Stepper
      steps={[
        <CreateLeadUserPage />,
        <InstallationAddress countryData={countryQuery.data} />,
        <EmailValidate />,
        <ConfirmationScreen />
      ]}
    >
      {(step) => <SignUpLayout>{step}</SignUpLayout>}
    </Stepper>
  );
};
