import { FC } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { Tooltip } from '../../../../components/Tooltip';
import { Heading, Subtitle, Text } from '../../../../components/Typography';
import { formatHelium } from '../../../../shared/utils/format-helium';
import {
  StyledBox,
  StyledEarningsText,
  StyledPrice,
  Wrapper
} from './HeliumPrice.styles';
import { HeliumPriceProps } from './HeliumPrice.types';

export const HeliumPrice: FC<HeliumPriceProps> = ({ currencyData }) => {
  const earnings =
    (((currencyData?.current!.exchange || 0) -
      (currencyData?.current!.lastHourAverage || 0)) /
      (currencyData?.current!.lastHourAverage || 0)) *
    100;

  return (
    <Wrapper>
      <Subtitle size="big" variant="grey">
        <FormattedMessage
          id="dashboard.heliumPriceSubtitle1"
          defaultMessage="Current Helium Price"
        />
      </Subtitle>
      <StyledBox mt="s">
        <Heading size="h4" element="h2">
          HNT $
          {formatHelium(currencyData?.current!.exchange || 0, { currency: '' })}
        </Heading>
        <div>
          <Tooltip
            content={
              <Text level="big">
                <FormattedMessage
                  defaultMessage="The value change vs last hour"
                  id="dashboard.exchangeValueTooltip"
                />
              </Text>
            }
          >
            <StyledEarningsText earnings={earnings || 0} level="small">
              {Math.abs(earnings).toFixed(2)}%
            </StyledEarningsText>
          </Tooltip>
        </div>
      </StyledBox>
      <StyledBox mt="xs">
        <Subtitle size="big" variant="grey">
          <FormattedMessage
            id="dashboard.heliumPriceSubtitle2"
            defaultMessage="24h Low / 24h High"
          />
        </Subtitle>
      </StyledBox>
      <StyledBox mt="xs">
        {currencyData && (
          <StyledPrice level="big">
            <FormattedNumber
              value={currencyData?.lastDay.min || 0}
              style="currency"
              currency={currencyData?.lastDay.currency}
            />{' '}
            /{' '}
            <FormattedNumber
              value={currencyData?.lastDay.max || 0}
              style="currency"
              currency={currencyData?.lastDay.currency}
            />
          </StyledPrice>
        )}
      </StyledBox>
    </Wrapper>
  );
};
