import styled from 'styled-components';

import { DashboardBox } from '../../../components/DashboardBox';

export const DeviceContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  width: 432px;
  margin-top: ${({ theme }) => theme.margin.m};
  margin-right: auto;
  margin-left: auto;
  max-width: 410px;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    flex: 0;
    max-width: 85%;
    width: 100%;
    margin-top: 0;
  }

  @media (${({ theme }) => theme.breakpoints.phone}) {
    max-width: 100%;
    padding-left: ${({ theme }) => theme.padding.s};
    padding-right: ${({ theme }) => theme.padding.s};
  }
`;

export const DeviceGrid = styled.div`
  display: flex;
  gap: 16px;
  margin-top: ${({ theme }) => theme.margin.xxs};
  padding-left: ${({ theme }) => theme.margin.xs};
  padding-top: 0;
  padding-right: ${({ theme }) => theme.margin.xs};
  padding-bottom: ${({ theme }) => theme.margin.xs};
  flex-direction: column;
  overflow-y: auto;

  scrollbar-color: ${({ theme }) => theme.color.lightGrey} transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.color.lightGrey};
    border-radius: 4px;
  }

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const DevicePlaceholder = styled(DashboardBox)<{ isLoading?: boolean }>`
  display: flex;
  min-height: 186px;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  background: ${({ theme }) => theme.color.lightGrey};
`;

export const LeadOrdersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(319px, 1fr));
  grid-auto-rows: minmax(250px, 1fr);
  gap: 16px;
`;
