import jwtDecode from 'jwt-decode';
import { createContext, FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { DecodedJWT } from '../../auth/auth-context.types';
import Auth from '../../auth/auth.service';
import { TermsAndConditions } from './TermsAndConditions';

type TermsAndConditionsContextType = {
  showTermsAndConditions: (isShown: boolean) => void;
};

export const TermsAndConditionsContext =
  createContext<TermsAndConditionsContextType>(
    {} as TermsAndConditionsContextType
  );

const TermsAndConditionsProvider: FC = ({ children }) => {
  const history = useHistory();
  const jwt = Auth.getToken();

  const [showTermsAndConditions, setShowTermsAndConditions] =
    useState<boolean>(false);

  useEffect(() => {
    if (jwt) {
      const { acceptedTermsAndConditions } = jwtDecode<DecodedJWT>(jwt);

      if (!acceptedTermsAndConditions) {
        setShowTermsAndConditions(true);
      }
    }
  }, [history, jwt]);

  return (
    <TermsAndConditionsContext.Provider
      value={{ showTermsAndConditions: setShowTermsAndConditions }}
    >
      {children}
      <TermsAndConditions
        showTermsAndConditions={showTermsAndConditions}
        setShowTermsAndConditions={setShowTermsAndConditions}
      />
    </TermsAndConditionsContext.Provider>
  );
};

const useTermsAndConditions = () => useContext(TermsAndConditionsContext);

export { TermsAndConditionsProvider, useTermsAndConditions };
