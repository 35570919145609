import styled, { css } from 'styled-components';

import { Tooltip } from '../../../Tooltip';
import { StyledTooltipMenu } from '../../../Tooltip/TooltipMenu/TooltipMenu.styles';
import { menuItemStyle } from '../NavbarMenuItem/NavbarMenuItem.styles';

export const StyledNavbarMenuTooltip = styled(Tooltip)`
  & > button {
    gap: 8px;
    ${menuItemStyle}
  }

  ${StyledTooltipMenu} {
    min-width: 200px;
  }

  svg {
    stroke: #fff;
    ${({ $isActive }) =>
      $isActive &&
      css`
        transform: scaleY(-1);
      `}
  }
`;
