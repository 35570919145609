import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Box } from '../../Box';
import { Heading, Subtitle, Text } from '../../Typography';
import { Alert } from '../../Alert';
import { AlertVariant } from '../../Alert/Alert.types';
import { AlertStyledList } from './ValidateLayout.styles';
import { ContentWrapper } from '..';
import { InlineLinkButton } from '../../Button';

export const ValidateLayout: FC<{
  enteredEmail?: string;
  onResendCode: () => void;
}> = ({ children, enteredEmail, onResendCode }) => {
  return (
    <ContentWrapper>
      <Heading size="h4" element="h1" variant="grey">
        <FormattedMessage
          id="validateLayout.title"
          defaultMessage="Validate your email."
        />
      </Heading>
      <Box mt="s">
        <Subtitle variant="grey" size="big">
          <FormattedMessage
            id="validateLayout.subtitle"
            defaultMessage="Check the inbox of the email you provided us to find your code, and enter it here to continue."
          />
        </Subtitle>
      </Box>
      {children}
      <Box mt="xl">
        <Alert variant={AlertVariant.Secondary} hideIcon>
          <div>
            <Text level="big">
              {' '}
              <FormattedMessage
                id="validateLayout.alert1"
                defaultMessage="Didn’t get the email?"
              />
            </Text>
            <AlertStyledList>
              <li>
                <Text level="big">
                  <FormattedMessage
                    id="validateLayout.alert2"
                    defaultMessage="check SPAM folder in your email system"
                  />
                </Text>
              </li>
              <li>
                <Text level="big">
                  <InlineLinkButton onClick={onResendCode}>
                    <FormattedMessage
                      id="validateLayout.resendLink"
                      defaultMessage="resend the email"
                    />
                  </InlineLinkButton>{' '}
                </Text>
              </li>
              <li>
                <Text level="big">
                  <FormattedMessage
                    id="validateLayout.alert3"
                    defaultMessage="verify the entered email address: "
                  />
                  <strong>{enteredEmail}</strong>
                </Text>
              </li>
            </AlertStyledList>
          </div>
        </Alert>
      </Box>
    </ContentWrapper>
  );
};
