import styled, { css } from 'styled-components';

export const StyledLoader = styled.img<{ size: 'big' | 'small' }>`
  animation: spin 1.1s infinite linear;

  ${({ size }) =>
    size === 'big'
      ? css`
          height: 24px;
          width: 24px;
        `
      : css`
          height: 19px;
          width: 19px;
        `}

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
