import styled from 'styled-components';

import { getTypography } from '../../../../../shared/utils/style-utils';

export const StyledLabel = styled.p`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.margin.xxs};
  height: 32px;
  margin: 0;
  padding: 0 ${({ theme }) => theme.padding.xs};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.color.secondaryWhiteBlue};
  ${({ theme }) => getTypography(theme.typography.body.big)};
  text-transform: capitalize;
`;
