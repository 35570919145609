import { ReactNode } from 'react';

export enum AlertVariant {
  Primary = 'primary',
  Success = 'success',
  Secondary = 'secondary',
  Warning = 'warning',
  SecondaryWarning = 'secondary-warning',
  Danger = 'danger',
  Default = 'default'
}

export type AlertProps = {
  variant: AlertVariant;
  showCloseButton?: boolean;
  hideIcon?: boolean;
  type?: 'assertive' | 'polite';
  onClose?: () => void;
  renderNavItems?: () => ReactNode;
};

export type NotificationType = {
  id: string;
  text: string | ReactNode;
  variant: AlertVariant;
  autoRemove?: boolean;
  autoRemoveDelay?: number;
  onClick?: () => void;
};
