import { FC } from 'react';
import { FormattedDate } from 'react-intl';

import {
  DataPane,
  IconPane,
  Label,
  OrderIdLabel,
  StatusLabel,
  StatusPane,
  StyledAddress,
  StyledDashboardBox
} from './OrderCard.styles';
import { OrderCardData } from '../../../../shared/api/Order/Order.types';
import { Icon } from '../../../../components/Icon';

export const OrderCard: FC<{ data: OrderCardData }> = ({ data }) => (
  <StyledDashboardBox>
    <IconPane>
      <Icon icon="Document" />
    </IconPane>
    <DataPane>
      <Label>CoolSpot™</Label>
      <OrderIdLabel>#{data.shopifyOrderNumber}</OrderIdLabel>
      <StyledAddress>{data.installationAddress}</StyledAddress>
    </DataPane>
    <StatusPane>
      <StatusLabel>{data.status.toLowerCase()}</StatusLabel>
      <StyledAddress>
        <FormattedDate
          value={data.createdAt}
          day="2-digit"
          month="2-digit"
          year="numeric"
        />
      </StyledAddress>
    </StatusPane>
  </StyledDashboardBox>
);
