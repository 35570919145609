import styled from 'styled-components';

import { getTypography } from '../../shared/utils/style-utils';
import { FieldVariant } from '../FieldCaption';

export const StyledLabel = styled.label<{ variant: FieldVariant }>`
  ${({ theme }) => getTypography(theme.typography.caption)};

  margin-bottom: ${({ theme }) => theme.margin.xxs};

  color: ${({ theme, variant }) =>
    variant === FieldVariant.DISABLED ? theme.color.dimmed : theme.color.grey};
`;

export const TextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
