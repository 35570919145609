import styled from 'styled-components';

import { Alert } from '../../../../components/Alert';
import { Text } from '../../../../components/Typography';

export const LeadOrderAlert = styled(Alert)`
  justify-content: space-between;
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius};
`;

export const Content = styled(Text).attrs({
  level: 'small'
})`
  color: white;
`;
