import { FC, useCallback } from 'react';

import { CaptionTextWrapper, StyledCaption } from './FieldCaption.styles';
import { Icon } from '../Icon';
import { FieldCaptionProps, FieldVariant } from './FieldCaption.types';

export const FieldCaption: FC<FieldCaptionProps> = ({ children, variant }) => {
  const renderIcon = useCallback(() => {
    switch (variant) {
      case FieldVariant.VALID:
        return <Icon icon="Tick" />;
      case FieldVariant.ERROR:
        return <Icon icon="Alert" />;
      case FieldVariant.DISABLED:
        return <Icon icon="Info" />;
      default:
        return <Icon icon="Info" />;
    }
  }, [variant]);

  return (
    <StyledCaption variant={variant}>
      {renderIcon()}
      <CaptionTextWrapper>{children}</CaptionTextWrapper>
    </StyledCaption>
  );
};
