import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

import { Box } from '../../../../components/Box';
import { Button, LinkButton } from '../../../../components/Button';
import { BUTTON_SIZE } from '../../../../components/Button/Button.types';
import { Input } from '../../../../components/Input';
import { ContentWrapper } from '../../../../components/Layout';
import { Heading, Subtitle } from '../../../../components/Typography';
import { StepProps } from '../../../../components/Stepper';
import { FormElementWrapper } from './ResetPassword.styles';
import { useResetPassword } from '../../../../shared/api/Identity/Identity.queries';
import { useNotification } from '../../../../shared/utils/notifications/notification-context';
import { AlertVariant } from '../../../../components/Alert/Alert.types';

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Email address is not valid')
    .required('Email is a required field')
});

export const ResetPassword: FC<StepProps> = ({ handleNextStep }) => {
  const {
    formState: { errors },
    register,
    handleSubmit: onFormSubmit,
    setError,
    getValues
  } = useForm<{ email: string }>({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  });
  const { addNotification } = useNotification();
  const { formatMessage } = useIntl();

  const handleError = (error: Error) => {
    setError('email', {
      message: error.message,
      type: 'manual'
    });
  };

  const { mutate, isLoading } = useResetPassword({
    onError: (error) => handleError(error),
    onSuccess: () => {
      addNotification({
        id: 'resetPassword.verificationCodeSent',
        text: formatMessage({
          id: 'resetPassword.verificationCodeSent',
          defaultMessage:
            'Please check your email inbox for a verification code.'
        }),
        variant: AlertVariant.Success
      });

      if (handleNextStep) {
        handleNextStep(getValues());
      }
    }
  });

  const handleSubmit: SubmitHandler<{ email: string }> = (values) => {
    mutate(values.email);
  };

  return (
    <ContentWrapper showHero hideLogo>
      <Heading size="h4" element="h1" variant="grey">
        <FormattedMessage
          id="resetPassword1.title"
          defaultMessage="Reset password"
        />
      </Heading>
      <Box mt="s">
        <Subtitle variant="grey" size="big">
          <FormattedMessage
            id="resetPassword1.subtitle"
            defaultMessage="Please enter your account email address."
          />
        </Subtitle>
      </Box>
      <form onSubmit={onFormSubmit(handleSubmit)}>
        <FormElementWrapper>
          <Input
            {...register('email')}
            label="Email address"
            iconLeft="Mail"
            error={errors.email?.message}
          />
        </FormElementWrapper>
        <FormElementWrapper>
          <Button isPrimary isLoading={isLoading} fullWidth type="submit">
            <FormattedMessage
              id="resetPassword1.button"
              defaultMessage="Submit"
            />
          </Button>
          <Box mt="m">
            <LinkButton
              to="/login"
              isPlain
              isPrimary={false}
              fullWidth
              size={BUTTON_SIZE.BIG}
            >
              <FormattedMessage
                id="readyToShip.cancelButtonLabel"
                defaultMessage="Cancel"
              />
            </LinkButton>
          </Box>
        </FormElementWrapper>
      </form>
    </ContentWrapper>
  );
};
