import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import qs from 'query-string';
import { useIntl } from 'react-intl';

import Auth from '../../shared/auth/auth.service';
import { AlertVariant } from '../../components/Alert/Alert.types';
import { FullPageLoader } from '../../components/FullPageLoader';
import { SignUpLayout } from '../../components/Layout';
import { Stepper } from '../../components/Stepper';
import { ErrorCodes } from '../../shared/api/error-codes';
import { useVerifyEmail } from '../../shared/api/Identity/Identity.queries';
import { useGetLeadOrder } from '../../shared/api/Order/Order.queries';
import { useAuth } from '../../shared/auth/auth-context';
import { useNotification } from '../../shared/utils/notifications/notification-context';
import { useQueryParams } from '../../shared/utils/use-query-params';
import { ErrorPage } from '../ErrorPage';
import { ErrorPageType } from '../ErrorPage/ErrorPage.types';
import { ChooseEarningPlan } from './ChooseEarningPlan';
import { ReadyToShip } from './ReadyToShip';
import { ShopifyCheckout } from './ShopifyCheckout';
import { DecodedJWT } from '../../shared/auth/auth-context.types';

export const OrderACoolspot: FC = () => {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const { addNotification } = useNotification();
  const { isLoggedIn } = useAuth();

  const [{ flow, email, leadOrderId }] = useQueryParams({
    email: '',
    flow: '',
    leadOrderId: ''
  });

  const isNewUser = flow === 'new-user';
  const jwt = Auth.getToken();

  const { email: currentUserEmail } = jwt
    ? jwtDecode<DecodedJWT>(jwt)
    : { email };

  const {
    data: leadOrder,
    isLoading: isGetLeadOrderLoading,
    isError: isGetLeadOrderError,
    refetch: getLeadOrder
  } = useGetLeadOrder(leadOrderId, currentUserEmail, {
    enabled: false,
    retry: false,
    onError: (error) => {
      if (error.errorCode !== ErrorCodes.NOT_EXIST) {
        addNotification({
          id: 'verificationError',
          text: error.message,
          variant: AlertVariant.Danger
        });

        history.push('/dashboard');
      }
    }
  });

  const { mutate: verifyEmail, isSuccess: isVerifyEmailSuccess } =
    useVerifyEmail({
      onError: (error) => {
        addNotification({
          id: 'verifyEmailError',
          text: error.message,
          variant: AlertVariant.Danger
        });
      },
      onSuccess: (response) => {
        const { isUserV2: hasAccount } = response;

        if (!hasAccount) {
          return history.push({
            pathname: '/sign-up/create-account',
            search: qs.stringify({ leadOrderId, email })
          });
        }

        if (!isLoggedIn()) {
          addNotification({
            id: 'sessionExpired',
            text: formatMessage({
              id: 'sessionExpired',
              defaultMessage: 'Please login to continue'
            }),
            variant: AlertVariant.Success
          });

          return history.push({
            pathname: '/login',
            search: qs.stringify({
              redirectUrl: `/order-a-coolspot?leadOrderId=${leadOrderId}&email=${email}`
            })
          });
        }

        getLeadOrder();

        return undefined;
      }
    });

  useEffect(() => {
    if (currentUserEmail && leadOrderId) {
      verifyEmail(currentUserEmail);
    }
  }, [currentUserEmail, leadOrderId, history, verifyEmail]);

  if (isGetLeadOrderError) {
    return <ErrorPage type={ErrorPageType.OrderNotFound} />;
  }

  if (!currentUserEmail || !leadOrderId) {
    return <ErrorPage type={ErrorPageType.LinkInvalid} />;
  }

  if (isGetLeadOrderLoading || !isVerifyEmailSuccess) {
    return <FullPageLoader />;
  }

  return (
    <Stepper
      initialStepIndex={isNewUser ? 1 : 0}
      initialValues={{ leadOrder }}
      steps={[
        <ReadyToShip />,
        <ChooseEarningPlan isNewUser={isNewUser} />,
        <ShopifyCheckout />
      ]}
    >
      {(step, index) =>
        index === 0 ? <SignUpLayout>{step}</SignUpLayout> : step
      }
    </Stepper>
  );
};
