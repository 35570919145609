import styled from 'styled-components';

import { getTypography } from '../../../../../shared/utils/style-utils';
import { ContentWrapper } from '../../../../../components/Layout';
import { Button } from '../../../../../components/Button';
import { BottomSection } from '../../../../../components/Layout/TwoColumnsLayout/BottomSection';
import { Box } from '../../../../../components/Box';

export const StyledStepList = styled.ul`
  list-style: none;

  padding: 0;
  margin: 0;

  ${({ theme }) => getTypography(theme.typography.heading.h5)};
  line-height: 1.33em;

  margin-top: ${({ theme }) => theme.margin.l};

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    margin-top: ${({ theme }) => theme.margin.xl};
  }
`;

export const StepListItem = styled.li`
  margin-top: ${({ theme }) => theme.margin.m};
`;

export const StyledContentWrapper = styled(ContentWrapper)`
  padding-bottom: 0;
`;

export const StyledButton = styled(Button)`
  margin-top: ${({ theme }) => theme.margin.m};

  @media (${({ theme }) => theme.breakpoints.phone}) {
    display: none;
  }
`;

export const StyledSection = styled(BottomSection)`
  @media (${({ theme }) => theme.breakpoints.phone}) {
    display: none;
  }
`;

export const StyledCaption = styled(Box)`
  display: none;
  border-top: ${({ theme }) => theme.border('lightGrey', 1)};

  margin-top: ${({ theme }) => theme.margin.l};
  padding-top: ${({ theme }) => theme.margin.m};
  padding-bottom: ${({ theme }) => theme.margin.m};

  @media (${({ theme }) => theme.breakpoints.phone}) {
    display: block;
  }
`;
