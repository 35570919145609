import styled from 'styled-components';

import { DashboardBox } from '../../../components/DashboardBox';
import { Heading } from '../../../components/Typography';
import { Input } from '../../../components/Input';
import { getTypography } from '../../../shared/utils/style-utils';

export const StyledDashboardBox = styled(DashboardBox)`
  width: 100%;
  display: flex;
  white-space: normal;
  margin-top: ${({ theme }) => theme.margin.l};
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 24px;
`;

export const AboutReferralLabel = styled.span`
  margin-right: 8px;
`;

export const ReferralInputColumn = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: center;
  min-width: 40%;

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    min-width: 100%;
  }

  & > div {
    flex-grow: 1;
  }
`;

export const CopyTooltipAnchor = styled.div`
  /* these are empirical coordinates of the copy icon inside ReferralInputColumn */
  position: absolute;
  bottom: 40px;
  right: 24px;
`;

export const StyledHeading = styled(Heading)`
  max-width: 45%;

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    max-width: none;
  }

  @media (${({ theme }) => theme.breakpoints.phone}) {
    ${({ theme }) => getTypography(theme.typography.subtitle.small)};
  }
`;

export const ReferralsCountSection = styled.div`
  display: flex;
  align-items: center;
  min-width: 100%;

  gap: 12px;

  color: ${({ theme }) => theme.color.grey};
  font-weight: 600;

  svg {
    stroke: ${({ theme }) => theme.color.blue};
  }
`;

export const StyledReferralsCount = styled.span`
  color: ${({ theme }) => theme.color.blue};
`;

export const StyledInput = styled(Input)`
  white-space: nowrap;
  text-overflow: ellipsis;
`;
