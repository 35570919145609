import styled, { css } from 'styled-components';

import { ProgressBarProps } from './ProgressBar.types';

export const Bar = styled.div<Pick<ProgressBarProps, 'variant'>>`
  height: 8px;
  border-radius: ${({ theme }) => theme.borderRadius};
  position: relative;
  background: ${({ theme, variant }) => {
    if (variant === 'dark') {
      return theme.color.darkBlue;
    }

    return theme.color.lightGrey;
  }};
`;

export const Progress = styled.span<ProgressBarProps>`
  display: block;
  height: 100%;

  border-radius: ${({ theme }) => theme.borderRadius};

  transition: width 0.3s ease-in;

  ${({ progress }) =>
    css`
      width: ${progress >= 100 ? 100 : progress}%;
    `};

  background-color: ${({ theme, color, progress }) => {
    if (color) {
      return color;
    }

    if (progress > 66) {
      return theme.color.secondaryGreen;
    }

    if (progress > 33) {
      return theme.color.secondaryYellow;
    }

    return theme.color.secondaryRed;
  }};
`;
