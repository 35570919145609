import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Button, LinkButton } from '../../components/Button';
import { BUTTON_SIZE } from '../../components/Button/Button.types';
import { EmptyStateLayout } from '../../components/Layout/EmptyStateLayout';
import { EmptyLayoutButtonsRow } from '../../components/Layout/EmptyStateLayout/EmptyStateLayout';
import { Heading, Subtitle } from '../../components/Typography';

export const NotFound: FC<{ isOverlay?: boolean }> = ({ isOverlay }) => {
  const history = useHistory();

  const goBack = () => history.goBack();

  return (
    <EmptyStateLayout isOverlay={isOverlay}>
      <div>
        <Heading size="h2" align="center" variant="white">
          404
        </Heading>
        <Subtitle size="big" variant="white">
          <FormattedMessage
            id="pageIsBeyondOurReach"
            defaultMessage="The the page you’re looking for is beyond our reach."
          />
        </Subtitle>
      </div>
      <EmptyLayoutButtonsRow>
        <Button
          fullWidth
          iconLeft="ArrowLeft"
          isPrimary
          onClick={goBack}
          size={BUTTON_SIZE.SMALL}
        >
          <FormattedMessage id="goBack" defaultMessage="Go back" />
        </Button>
        <LinkButton
          to="https://emrit.io"
          external
          fullWidth
          isPrimary={false}
          iconLeft="Home"
          variant="white"
          size={BUTTON_SIZE.SMALL}
        >
          <FormattedMessage id="goHome" defaultMessage="Go home" />
        </LinkButton>
      </EmptyLayoutButtonsRow>
    </EmptyStateLayout>
  );
};
