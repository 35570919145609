import VisuallyHidden from '@reach/visually-hidden';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Icon } from '../../../Icon';
import { Heading } from '../../../Typography';
import { BottomSection } from '../BottomSection';
import { ButtonWrapper, SocialButton } from './SocialsSection.styles';

export const SocialsSection: FC = () => {
  return (
    <BottomSection>
      <Heading size="h5" element="h2" variant="grey">
        <FormattedMessage
          id="socialSection.title"
          defaultMessage="Want to know more? Check out our socials"
        />
      </Heading>
      <ButtonWrapper mt="l" mb="xl">
        <SocialButton to="https://www.youtube.com" external isPrimary={false}>
          <Icon aria-hidden icon="Youtube" />
          <VisuallyHidden>
            <FormattedMessage
              id="socialSection.buttonYoutube"
              defaultMessage="Youtube"
            />
          </VisuallyHidden>
        </SocialButton>
        <SocialButton to="https://www.facebook.com" external isPrimary={false}>
          <Icon aria-hidden icon="Facebook" />
          <VisuallyHidden>
            <FormattedMessage
              id="socialSection.buttonFacebook"
              defaultMessage="Facebook"
            />
          </VisuallyHidden>
        </SocialButton>
        <SocialButton to="https://www.twitter.com" external isPrimary={false}>
          <Icon aria-hidden icon="Twitter" />
          <VisuallyHidden>
            <FormattedMessage
              id="socialSection.buttonTwitter"
              defaultMessage="Twitter"
            />
          </VisuallyHidden>
        </SocialButton>
      </ButtonWrapper>
    </BottomSection>
  );
};
