import styled from 'styled-components';

import { Subtitle } from '../../../../../components/Typography';

export const StyledSubtitle = styled(Subtitle)`
  margin-top: ${({ theme }) => theme.margin.m};

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    margin-top: ${({ theme }) => theme.margin.xl};
  }

  @media (${({ theme }) => theme.breakpoints.phone}) {
    margin-top: ${({ theme }) => theme.margin.m};
  }
`;
