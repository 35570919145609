import { FC } from 'react';

import { FullPageLoader } from '../../../../components/FullPageLoader';
import { SignUpLayout } from '../../../../components/Layout';
import { Stepper } from '../../../../components/Stepper';
import { useGetCountries } from '../../../../shared/api/Order';
import { useAuth } from '../../../../shared/auth/auth-context';
import { ErrorPage } from '../../../ErrorPage';
import { AddWallet } from '../../AddWallet';
import { CreateAccountPage } from '../../CreateAccount';
import { SignUpEmailValidate } from './SignUpEmailValidate';
import { SupportedSuccessPage } from './SupportedSuccess';

export const SupportedCountry: FC = () => {
  const countryQuery = useGetCountries();

  const { login } = useAuth();

  if (countryQuery.isLoading || login.isLoading) {
    return <FullPageLoader />;
  }

  if (countryQuery.isError || countryQuery.isIdle) {
    return <ErrorPage />;
  }

  const supportedCountries = countryQuery.data.filter(
    (country) => country.isSupported
  );

  return (
    <Stepper
      steps={[
        <CreateAccountPage countries={supportedCountries} />,
        <SignUpEmailValidate />,
        <AddWallet />,
        <SupportedSuccessPage login={login} />
      ]}
    >
      {(step) => <SignUpLayout>{step}</SignUpLayout>}
    </Stepper>
  );
};
