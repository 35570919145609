import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Box } from '../../../../../components/Box';
import { Button } from '../../../../../components/Button';
import { Input, PasswordInput } from '../../../../../components/Input';
import { PhoneInput } from '../../../../../components/Input/PhoneInput';
import {
  ConfirmLeadUserRegistrationFormProps,
  ConfirmLeadUserRegistrationFormValues
} from './RegistrationForm.types';
import {
  FieldCaption,
  FieldVariant
} from '../../../../../components/FieldCaption';
import { useRegistrationValidation } from './RegistrationForm.validation';
import { Heading } from '../../../../../components/Typography';
import { useConfirmLeadUserRegistration } from '../../../../../shared/api/Identity/Identity.queries';
import authService from '../../../../../shared/auth/auth.service';

const formDefaultValues: ConfirmLeadUserRegistrationFormValues = {
  firstName: '',
  email: '',
  lastName: '',
  password: '',
  phone: '',
  leadOrderId: ''
};

export const RegistrationForm: FC<ConfirmLeadUserRegistrationFormProps> = ({
  onSubmit,
  defaultValue
}) => {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const [apiError, setApiError] = useState<string>();
  const [passwordStrength, setPasswordStrength] = useState(0);
  const {
    register,
    control,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm<ConfirmLeadUserRegistrationFormValues>({
    defaultValues: { ...formDefaultValues, ...defaultValue },
    mode: 'onChange',
    resolver: yupResolver(useRegistrationValidation(setPasswordStrength))
  });

  useEffect(() => {
    control.defaultValuesRef.current.phone = ''; // fix: WA-1146
  }, [control]);

  const handleSuccessResponse = async () => {
    const values = getValues();

    try {
      await authService.login({
        email: values.email,
        password: values.password
      });

      onSubmit(values);
    } catch (error) {
      history.replace('/login');
    }
  };

  const handleErrorResponse = (error: any) => {
    setApiError(error?.data?.message || error.message);
  };

  const { mutate: confirmLeadUserRegistration, isLoading } =
    useConfirmLeadUserRegistration({
      onError: handleErrorResponse,
      onSuccess: handleSuccessResponse
    });

  const registrationFormSubmit = (
    values: ConfirmLeadUserRegistrationFormValues
  ) => {
    confirmLeadUserRegistration(values);
  };

  return (
    <form onSubmit={handleSubmit(registrationFormSubmit)}>
      <Box>
        <Input
          {...register('email')}
          iconLeft="Mail"
          label={formatMessage({
            defaultMessage: 'Email address',
            id: 'createAccount.emailField'
          })}
          placeholder={formatMessage({
            defaultMessage: 'email@sample.com',
            id: 'createAccount.emailFieldPlaceholder'
          })}
          readOnly
          selectable={false}
          error={errors.email?.message}
        />
      </Box>
      <Box mt="m">
        <Input
          {...register('firstName')}
          iconLeft="Person"
          label={formatMessage({
            defaultMessage: 'First Name',
            id: 'createAccount.nameField'
          })}
          placeholder={formatMessage({
            defaultMessage: 'First Name',
            id: 'createAccount.nameFieldPlaceholder'
          })}
          error={errors.firstName?.message}
        />
      </Box>
      <Box mt="m">
        <Input
          {...register('lastName')}
          iconLeft="Person"
          label={formatMessage({
            defaultMessage: 'Last Name',
            id: 'createAccount.lastNameField'
          })}
          placeholder={formatMessage({
            defaultMessage: 'Last Name',
            id: 'createAccount.lastNameFieldPlaceholder'
          })}
          error={errors.lastName?.message}
        />
      </Box>
      <Box mt="m">
        <Controller
          name="phone"
          control={control}
          render={({ fieldState: { error } }) => (
            <PhoneInput control={control} error={error?.message} name="phone" />
          )}
        />
      </Box>
      <Box mt="xl">
        <Heading size="h6" element="h2" variant="grey">
          <FormattedMessage
            id="createAccount.createPassword"
            defaultMessage="Create a password for your account"
          />
        </Heading>
      </Box>
      <Box mt="m">
        <Controller
          name="password"
          control={control}
          render={({
            field: { onChange, onBlur, ref },
            fieldState: { error }
          }) => (
            <PasswordInput
              onChange={onChange}
              onBlur={onBlur}
              ref={ref}
              progress={passwordStrength}
              label={formatMessage({
                defaultMessage: 'Password',
                id: 'createAccount.passwordField'
              })}
              placeholder={formatMessage({
                defaultMessage: 'Your password',
                id: 'createAccount.passwordField'
              })}
              iconLeft="Lock"
              error={error?.message}
              caption={formatMessage({
                defaultMessage:
                  'Password must contain minimum 8 characters, one number, one special character and at least one capitalise',
                id: 'createAccount.passwordFieldHint'
              })}
            />
          )}
        />
      </Box>
      <Box mt="m">
        {apiError && (
          <FieldCaption variant={FieldVariant.ERROR}>{apiError}</FieldCaption>
        )}
      </Box>
      <Box mt="l">
        <Button isPrimary fullWidth type="submit" isLoading={isLoading}>
          <FormattedMessage
            id="createAccount.submitButton"
            defaultMessage="Create account"
          />
        </Button>
      </Box>
    </form>
  );
};
