import styled from 'styled-components';

import { ContentWrapper } from '../ContentWrapper';

export const StyledBottomSection = styled.div`
  background-color: ${({ theme }) => theme.color.secondaryWhiteBlue};

  margin-top: auto;
`;

export const StyledContentWrapper = styled(ContentWrapper)`
  padding-top: ${({ theme }) => theme.padding.xl};
  padding-bottom: ${({ theme }) => theme.padding.xl};

  @media (${({ theme }) => theme.breakpoints.phone}) {
    margin-left: ${({ theme }) => theme.margin.s};
    margin-right: ${({ theme }) => theme.margin.s};
  }
`;
