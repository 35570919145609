import styled, { css } from 'styled-components';

import { getTypography } from '../../../shared/utils/style-utils';

export const StyledHeader = styled.button<{ isActive?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  width: 100%;
  background-color: transparent;
  border: none;
  cursor: pointer;
  appearance: none;
  border: 1px solid ${({ theme }) => theme.color.secondaryWhiteBlue};
  border-radius: ${({ theme }) => theme.borderRadius};
  text-align: left;

  ${({ theme }) => getTypography(theme.typography.subtitle.big)};
  font-family: inherit;
  font-weight: 600;

  margin-bottom: ${({ theme }) => theme.margin.xs};

  display: flex;
  align-items: center;

  padding: ${({ theme }) => theme.padding.s};

  &:hover {
    background-color: ${({ theme }) => theme.color.secondaryWhiteBlue};
    color: ${({ theme }) => theme.color.darkBlue};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${({ theme }) => theme.color.darkBlue};
      color: ${({ theme }) => theme.color.white};

      &:hover {
        background-color: ${({ theme }) => theme.color.hoverBlue};
        color: ${({ theme }) => theme.color.white};
      }

      svg {
        stroke: #fff;
      }
    `}

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    display: flex;
    justify-content: center;

    width: auto;
  }
`;

export const StyledHeaderLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
