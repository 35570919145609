import styled, { css } from 'styled-components';

import { getTypography } from '../../../shared/utils/style-utils';

export const mobileMenuItemStyle = css`
  width: 100%;
  background-color: transparent;
  border: none;
  cursor: pointer;
  appearance: none;
  border-radius: ${({ theme }) => theme.borderRadius};
  text-align: left;

  ${({ theme }) => getTypography(theme.typography.subtitle.small)};
  font-family: inherit;
  font-weight: 600;

  color: #fff;

  margin-bottom: ${({ theme }) => theme.margin.xs};

  display: flex;
  align-items: center;

  padding: ${({ theme }) => theme.padding.s};

  &:hover {
    background-color: ${({ theme }) => theme.color.darkBlue};
    color: #fff;
  }
`;

export const StyledMobileMenuItem = styled.button`
  ${mobileMenuItemStyle}
`;

export const MobileMenuLabel = styled.span`
  display: block;
  width: 100%;
`;

export const IconWrapper = styled.picture`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  margin-right: ${({ theme }) => theme.margin.s};

  stroke: ${({ theme }) => theme.color.white};
`;

export const Wrapper = styled.li`
  width: 100%;
`;
