import { FC, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Icon } from '../../../../components/Icon';
import { WrapperProps } from './MobileCollapse.types';
import { useBreakpoints } from '../../../../shared/utils/use-breakpoints';
import {
  DesktopWrapper,
  ExpandButton,
  InnerWrapper,
  Wrapper
} from './MobileCollapse.styles';

export const MobileCollapse: FC<Pick<WrapperProps, 'isMapVisible'>> = ({
  children,
  isMapVisible
}) => {
  const wrapperRef = useRef<any>();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { isTablet: isMobile } = useBreakpoints();

  const handleExpandButtonClick = () => {
    setIsExpanded(!isExpanded);

    wrapperRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  if (!isMobile) {
    return (
      <DesktopWrapper isMapVisible={isMapVisible}>{children}</DesktopWrapper>
    );
  }

  return (
    <Wrapper
      isExpanded={isExpanded}
      isMapVisible={isMapVisible}
      ref={wrapperRef}
    >
      {isMapVisible && (
        <>
          <InnerWrapper>{children}</InnerWrapper>
          <ExpandButton onClick={handleExpandButtonClick}>
            {isExpanded ? (
              <>
                <Icon icon="ChevronUp" color="darkBlue" />
                <FormattedMessage
                  id="mobileCollapse.collapseButton"
                  defaultMessage="Hide Map"
                />
              </>
            ) : (
              <>
                <Icon icon="ChevronDown" color="darkBlue" />
                <FormattedMessage
                  id="mobileCollapse.expandButton"
                  defaultMessage="Expand Map"
                />
              </>
            )}
          </ExpandButton>
        </>
      )}
    </Wrapper>
  );
};
