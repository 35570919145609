import { FC } from 'react';
import ReactModal from 'react-modal';

import { Icon } from '../Icon';
import {
  CloseButton,
  ModalHeader,
  ModalStyle,
  StyledOverlay
} from './Modal.styles';
import { ModalProps } from './Modal.types';

export const Modal: FC<ModalProps> = ({
  children,
  header,
  hasCloseButton = true,
  isOpen,
  onAfterClose,
  setIsOpen,
  overlayStyle,
  fullWidth,
  maxWidth,
  ...modalProps
}) => {
  const closeModal = () => setIsOpen(false);

  return (
    <ReactModal
      ariaHideApp={false}
      className="modal"
      closeTimeoutMS={350}
      isOpen={isOpen}
      onAfterClose={onAfterClose}
      onRequestClose={closeModal}
      overlayClassName="modal-overlay"
      contentElement={(props, content) => (
        <ModalStyle fullWidth={fullWidth} maxWidth={maxWidth} {...props}>
          {content}
        </ModalStyle>
      )}
      overlayElement={(props, contentElement) => (
        <StyledOverlay {...props} overlayStyle={overlayStyle}>
          {contentElement}
        </StyledOverlay>
      )}
      {...modalProps}
    >
      {hasCloseButton && (
        <CloseButton onClick={closeModal}>
          <Icon icon="Cancel" />
        </CloseButton>
      )}
      {header && (
        <ModalHeader hasCloseButton={hasCloseButton}>{header}</ModalHeader>
      )}
      {children}
    </ReactModal>
  );
};
