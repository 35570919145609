import { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Alert } from '../../../components/Alert';
import { AlertVariant } from '../../../components/Alert/Alert.types';
import { Box } from '../../../components/Box';
import { Link } from '../../../components/Link';

export const ADD_WALLET_NOTIFICATION_OPEN = 'addWalletNotificationOpen';

const getAddWalletNotificationOpen = () => {
  const localStorageItem = localStorage.getItem(ADD_WALLET_NOTIFICATION_OPEN);
  const isOpen = localStorageItem !== 'false';

  return isOpen;
};

export const AddWalletNotification: FC = () => {
  const [showNotification, setShowNotification] = useState(
    getAddWalletNotificationOpen()
  );

  const handleOnStorage = () => {
    setShowNotification(getAddWalletNotificationOpen());
  };

  useEffect(() => {
    window.addEventListener('storage', handleOnStorage);

    return () => {
      window.removeEventListener('storage', handleOnStorage);
    };
  }, []);

  const handleClose = () => {
    localStorage.setItem('addWalletNotificationOpen', 'false');
    setShowNotification(false);
  };

  if (!showNotification) {
    return null;
  }

  return (
    <Box mt="l">
      <Alert
        variant={AlertVariant.SecondaryWarning}
        showCloseButton
        onClose={handleClose}
      >
        <FormattedMessage
          id="dashboard.addWalletNotificationText"
          defaultMessage="The billing period is approaching. Remember to add your wallet address to be able to withdraw your funds."
        />{' '}
        <Link to="/edit-wallet">
          <FormattedMessage
            id="dashboard.addWalletNotificationLink"
            defaultMessage="Click here to add wallet"
          />
        </Link>
      </Alert>
    </Box>
  );
};
