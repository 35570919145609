import { FC } from 'react';

import { StyledCaption } from './Caption.styles';
import { CaptionProps } from './Caption.types';

const Caption: FC<CaptionProps> = ({ children, color, ...rest }) => {
  return (
    <StyledCaption color={color} {...rest}>
      {children}
    </StyledCaption>
  );
};

export default Caption;
