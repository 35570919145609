import gpn from 'google-libphonenumber';

const gpnUtil = gpn.PhoneNumberUtil.getInstance();

type PhoneValidationOptions = {
  isMobile?: boolean;
};

export const phoneNumberValidator = (
  value: string,
  options?: PhoneValidationOptions
) => {
  let parsedPhoneNumber;
  const phoneNumber = value.startsWith('+') ? value : `+${value}`;

  try {
    parsedPhoneNumber = gpnUtil.parse(phoneNumber);
  } catch (err) {
    return false;
  }

  const numberIsValid = gpnUtil.isValidNumber(parsedPhoneNumber);

  if (!numberIsValid) {
    return false;
  }

  if (options?.isMobile) {
    const numberType = gpnUtil.getNumberType(parsedPhoneNumber);

    if (numberType !== 1) {
      return false;
    }
  }

  return true;
};

export const parsePhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) {
    return null;
  }

  return phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`;
};
