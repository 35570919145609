import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { SocialsSection } from '../../../../../components/Layout';
import { ContentWrapper } from '../../../../../components/Layout/TwoColumnsLayout/ContentWrapper';
import { Heading } from '../../../../../components/Typography';
import { StyledSubtitle } from './NotSupportedSuccessPage.styles';

export const NotSupportedSuccessPage: FC = () => {
  return (
    <>
      <ContentWrapper>
        <Heading size="h4" element="h1" variant="grey">
          <FormattedMessage
            id="notSupportedSuccess.title"
            defaultMessage="Your emaill has been succesfully added to queue list!"
          />
        </Heading>
        <StyledSubtitle variant="grey" size="big">
          <FormattedMessage
            id="notSupportedSuccess.subtitle"
            defaultMessage="We will contact you when our service will be avaliable in your country"
          />
        </StyledSubtitle>
      </ContentWrapper>
      <SocialsSection />
    </>
  );
};
