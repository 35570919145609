import {
  ImageContainer,
  StyledHeroSection,
  StyledImage,
  StyledLogoEmrit
} from './HeroSection.styles';

export const HeroSection = () => {
  return (
    <StyledHeroSection>
      <ImageContainer>
        <StyledLogoEmrit variant="default" />
        <StyledImage />
      </ImageContainer>
    </StyledHeroSection>
  );
};
