import {
  createContext,
  createRef,
  FC,
  RefObject,
  useContext,
  useMemo
} from 'react';

import { Coolspot } from '../../../shared/api/Wallet/Wallet.types';
import { DeviceCardRef } from './DeviceCard';

type DeviceScrollContextType = {
  refs: { [coolspotId: string]: RefObject<DeviceCardRef> };
  scrollTo: (deviceId: string) => void;
  resetFocus: () => void;
};

type DeviceScrollProviderProps = {
  coolspotsData?: Coolspot[];
};

export const DeviceScrollContext = createContext<DeviceScrollContextType>(
  {} as DeviceScrollContextType
);
const DeviceScrollProvider: FC<DeviceScrollProviderProps> = ({
  children,
  coolspotsData
}) => {
  const refs = useMemo(() => {
    if (!coolspotsData?.length) {
      return {};
    }

    return Object.fromEntries(
      coolspotsData.map(({ coolspotId }) => [
        coolspotId,
        createRef<DeviceCardRef>()
      ])
    );
  }, [coolspotsData]);

  const handleResetFocus = () => {
    Object.values(refs).forEach((ref) => {
      ref.current?.unfocus();
    });
  };

  const handleScrollTo = (deviceId: string) => {
    const { deviceCardRef, focus } = refs[deviceId]?.current as DeviceCardRef;
    const listElement = deviceCardRef?.current?.parentElement;

    if (deviceCardRef?.current && listElement) {
      focus();

      // calculate 7.5% left margin of map container
      const mapOffset = (window.innerWidth * 7.5) / 100;

      listElement.scroll({
        // set scroll on desktop vertical view
        top: deviceCardRef?.current.offsetTop - 40,
        // set scroll on mobile horizontal list
        left: deviceCardRef?.current.offsetLeft - mapOffset,
        behavior: 'smooth'
      });
    }
  };

  return (
    <DeviceScrollContext.Provider
      value={{
        refs,
        scrollTo: handleScrollTo,
        resetFocus: handleResetFocus
      }}
    >
      {children}
    </DeviceScrollContext.Provider>
  );
};

const useDeviceScroll = () => useContext(DeviceScrollContext);

export { DeviceScrollProvider, useDeviceScroll };
