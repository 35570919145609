import styled from 'styled-components';

import { getButtonStyles } from '../Button.styles';
import { ICON_BUTTON_SIZE, IconButtonProps } from './IconButton.types';

export const StyledIconButton = styled.button<
  Pick<IconButtonProps, 'isPrimary' | 'size'>
>`
  ${({ theme, isPrimary }) => getButtonStyles(theme, isPrimary)}

  line-height: 0;

  padding: ${({ size, theme }) =>
    size === ICON_BUTTON_SIZE.BIG
      ? `${theme.padding.s}`
      : `${theme.padding.xxs}`};
`;
