import styled from 'styled-components';

import { CopyBox } from '../../../../components/CopyBox';
import { getTypography } from '../../../../shared/utils/style-utils';

export const AccordionHeader = styled.button.attrs({
  type: 'button'
})`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: ${({ theme }) => theme.padding.s};
  margin-top: ${({ theme }) => theme.margin.xxs};
  margin-bottom: ${({ theme }) => theme.margin.xxs};

  border: none;
  width: 100%;

  background: ${({ theme }) => theme.color.secondaryWhiteBlue};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 700;
  ${({ theme }) => getTypography(theme.typography.body.big)};

  svg {
    stroke: ${({ theme }) => theme.color.blue};
  }
`;

export const IconWrapper = styled.picture`
  width: 20px;
`;

export const PaymentTimeWrapper = styled.span`
  display: flex;
  align-items: center;
`;

export const CenteredCopyBox = styled(CopyBox)`
  max-width: 160px;
  align-items: center;
  justify-content: center;
  ${({ theme }) => getTypography(theme.typography.body.small)};
`;
