import React, { FC } from 'react';

import { TabContent } from '.';

export const TabContentList: FC = ({ children }) => {
  const childrenWithProps = React.Children.map(children, (child, index) => {
    if (React.isValidElement(child) && child.type === TabContent) {
      return React.cloneElement(child, {
        index
      });
    }

    return child;
  });

  return <div>{childrenWithProps}</div>;
};
