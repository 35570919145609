import { FC, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import { ReactComponent as SuccessTick } from '../../../../assets/images/SuccessTick.svg';
import { Box } from '../../../../components/Box';
import { Button } from '../../../../components/Button';
import { BUTTON_SIZE } from '../../../../components/Button/Button.types';
import { Heading, Subtitle } from '../../../../components/Typography';
import {
  ButtonContainer,
  SuccessIconContainer
} from './UpgradeEarningsSuccess.styles';
import { DashboardLayout } from '../../../../components/Layout';
import { DashboardContentWrapper } from '../../Dashboard.styles';
import { DashboardBox } from '../../../../components/DashboardBox';
import { DashboardBoxCenterWrapper } from '../../../../components/DashboardBox/DashboardBox.styles';
import { useGetUpgradedPlanDetails } from '../../../../shared/api/Order/Order.queries';
import { useQueryParams } from '../../../../shared/utils/use-query-params';
import { ProcessingOrder } from '../../../../components/ProcessingOrder';
import { ErrorPage } from '../../../ErrorPage';
import { useGetCoolspotsData } from '../../../../shared/api/Wallet';
import { useBreakpoints } from '../../../../shared/utils/use-breakpoints';

export const UpgradeEarningsSuccess: FC = () => {
  const history = useHistory();
  const { isTablet } = useBreakpoints();
  const [{ coolspotUpgradePlanId }] = useQueryParams({
    coolspotUpgradePlanId: ''
  });

  useEffect(() => {
    TagManager.dataLayer({ dataLayer: { ecommerce: null } }); // Clear the previous ecommerce object.

    TagManager.dataLayer({
      dataLayer: {
        event: 'purchase',
        ecommerce: {
          affiliation: 'Emrit',
          value: Number(process.env.REACT_APP_UPGRADE_PLAN_PRICE),
          tax: 0.0,
          shipping: 0.0,
          currency: 'USD'
        }
      }
    });
  }, []);

  const {
    data: coolspotsData,
    isLoading: isCoolspotsDataLoading,
    refetch: refetchCoolspotsData
  } = useGetCoolspotsData();

  const upgradeableDevices = useMemo(
    () =>
      coolspotsData?.entities.filter(
        (coolspot) =>
          coolspot.isUpgradeable &&
          !coolspot.isReferral &&
          coolspot.name !== null
      ),
    [coolspotsData]
  );

  const {
    isLoading: isGetUpgradedPlanDetailsLoading,
    isError: isGetUpgradedPlanDetailsError
  } = useGetUpgradedPlanDetails(coolspotUpgradePlanId, {
    onSuccess() {
      refetchCoolspotsData();
    }
  });

  const handleDone = async () => {
    history.push('/dashboard');
  };

  const handleUpgradeAnotherDevice = async () => {
    history.push('/upgrade');
  };

  if (isGetUpgradedPlanDetailsError) {
    return <ErrorPage />;
  }

  return (
    <DashboardLayout>
      <DashboardContentWrapper>
        <DashboardBox isFullHeight>
          <Heading size="h5" element="h1" variant="black">
            <FormattedMessage
              id="upgradeCoolspot.header"
              defaultMessage="Upgrade your CoolSpot™ earnings plan from 20% to 50% today!"
            />
          </Heading>
          <DashboardBoxCenterWrapper contentWidth={720}>
            {isGetUpgradedPlanDetailsLoading || isCoolspotsDataLoading ? (
              <ProcessingOrder width="80%" />
            ) : (
              <>
                <Box mt="l">
                  <Heading
                    align="center"
                    element="h1"
                    size={isTablet ? 'h5' : 'h4'}
                    variant="grey"
                  >
                    <FormattedMessage
                      id="upgradeDevice.successTitle"
                      defaultMessage="Your earnings plan has been successfully upgraded."
                    />
                  </Heading>
                  <Box mt="l">
                    <Subtitle size="big" align="center">
                      <FormattedMessage
                        id="upgradeDevice.successSubtitle"
                        defaultMessage="Your higher earnings will be included in the next monthly payment cycle!"
                      />
                    </Subtitle>
                  </Box>
                </Box>
                <SuccessIconContainer
                  mt={isTablet ? 'm' : 'xl'}
                  mb={isTablet ? 'm' : 'xl'}
                >
                  <SuccessTick />
                </SuccessIconContainer>
                <ButtonContainer mt="m">
                  <Button
                    isPrimary
                    fullWidth
                    type="button"
                    onClick={handleDone}
                  >
                    <FormattedMessage
                      id="editWallet.done"
                      defaultMessage="Done"
                    />
                  </Button>
                  {(upgradeableDevices ?? []).length > 0 && (
                    <Box mt="s">
                      <Button
                        onClick={handleUpgradeAnotherDevice}
                        isPlain
                        isPrimary={false}
                        fullWidth
                        size={BUTTON_SIZE.BIG}
                      >
                        <FormattedMessage
                          id="upgradeDevices.upgradeAnotherDevice"
                          defaultMessage="Upgrade another device"
                        />
                      </Button>
                    </Box>
                  )}
                </ButtonContainer>
              </>
            )}
          </DashboardBoxCenterWrapper>
        </DashboardBox>
      </DashboardContentWrapper>
    </DashboardLayout>
  );
};
