import { FC } from 'react';

import { Loader } from '../../../components/Loader';
import { useGetOrdersData } from '../../../shared/api/Order/Order.queries';
import { useDeviceScroll } from './device-scroll-context';
import { DeviceCard } from './DeviceCard/DeviceCard';
import {
  DeviceContainer,
  DeviceGrid,
  DevicePlaceholder,
  LeadOrdersGrid
} from './Devices.styles';
import { DevicesProps } from './Devices.types';
import { LeadOrderCard } from './LeadOrderCard/LeadOrderCard';
import { OrderCard } from './OrderCard/OrderCard';

export const Devices: FC<DevicesProps> = ({
  isCoolspotsOrLeadOrdersLoading,
  coolspotsData = [],
  readyToOrderLeadOrders
}) => {
  const { refs } = useDeviceScroll();

  const placeholdersArray = new Array(3).fill(false);
  const { data: ordersData, isLoading: isOrdersDataLoading } =
    useGetOrdersData();

  const isLoading = isCoolspotsOrLeadOrdersLoading || isOrdersDataLoading;

  if (!coolspotsData.length && !isLoading) {
    return (
      <LeadOrdersGrid>
        {readyToOrderLeadOrders.map(({ id, installationAddress, daysLeft }) => (
          <LeadOrderCard
            key={id}
            daysLeft={daysLeft}
            id={id}
            installationAddress={installationAddress}
          />
        ))}
        {ordersData?.map((order) => (
          <OrderCard data={order} key={order.orderId} />
        ))}
      </LeadOrdersGrid>
    );
  }

  return (
    <DeviceContainer>
      <DeviceGrid>
        {!isLoading && (
          <>
            {readyToOrderLeadOrders.map(
              ({ id, installationAddress, daysLeft }) => (
                <LeadOrderCard
                  key={id}
                  daysLeft={daysLeft}
                  id={id}
                  installationAddress={installationAddress}
                />
              )
            )}
            {ordersData?.map((order) => (
              <OrderCard data={order} key={order.orderId} />
            ))}
            {coolspotsData.map((coolspot) => (
              <DeviceCard
                data={coolspot}
                key={coolspot.coolspotId}
                ref={refs[coolspot.coolspotId]}
              />
            ))}
          </>
        )}
        {isLoading &&
          coolspotsData.length === 0 &&
          placeholdersArray.map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <DevicePlaceholder key={index}>
              <Loader variant="white" size="big" />
            </DevicePlaceholder>
          ))}
      </DeviceGrid>
    </DeviceContainer>
  );
};
