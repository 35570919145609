import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { AlertVariant } from '../../../components/Alert/Alert.types';
import { Box } from '../../../components/Box';
import { Button } from '../../../components/Button';
import { LoginLayout } from '../../../components/Layout';
import { ValidateLayout } from '../../../components/Layout/ValidateLayout';
import { InputWrapper } from '../../../components/Layout/ValidateLayout/ValidateLayout.styles';
import { StepProps } from '../../../components/Stepper';
import { VerificationInput } from '../../../components/VerificationInput';
import { ErrorCodes } from '../../../shared/api/error-codes';
import {
  useSendSignupCode,
  useUserEmailVerify
} from '../../../shared/api/Identity/Identity.queries';
import { useNotification } from '../../../shared/utils/notifications/notification-context';
import { useQueryParams } from '../../../shared/utils/use-query-params';

export const LoginEmailValidate: FC<StepProps> = ({ handlePreviousStep }) => {
  const [params] = useQueryParams({
    email: ''
  });

  const history = useHistory();
  const { formatMessage } = useIntl();

  const { addNotification } = useNotification();

  const {
    formState: { errors },
    register,
    handleSubmit,
    setValue,
    setError
  } = useForm<{ code: string }>({
    mode: 'onBlur'
  });

  const { mutate: sendCode } = useSendSignupCode({
    onError: (error) => {
      const { response } = error;

      if (
        response?.data?.errorCode === ErrorCodes.WAIT_BEFORE_NEXT_CODE_RESEND
      ) {
        addNotification({
          id: 'legacyUserEmailVerifyError',
          text: response?.data?.message,
          variant: AlertVariant.Danger
        });
      } else if (response?.statusCode >= 400) {
        addNotification({
          id: 'loginError',
          text: response?.data.message,
          variant: AlertVariant.Danger
        });
      }
    },
    onSuccess: () => {
      addNotification({
        id: 'legacyUserEmailVerifySuccess',
        text: formatMessage({
          id: 'legacyUserEmailVerifySuccess',
          defaultMessage:
            'Please check your email inbox for a verification code.'
        }),
        variant: AlertVariant.Success
      });
    }
  });

  useEffect(() => {
    sendCode({ email: params.email });

    register('code', { required: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { mutate: verifyCode, isLoading: isVerifyCodeLoading } =
    useUserEmailVerify({
      onError: (apiError) => {
        setError('code', {
          message: apiError.data.message,
          type: 'manual'
        });
      },
      onSuccess: async () => {
        addNotification({
          id: 'userVerified',
          variant: AlertVariant.Success,
          text: formatMessage({
            defaultMessage: 'Email verified successfully, now you can log in',
            id: 'emailVerify.notification'
          })
        });

        history.push('/login');
      }
    });

  return (
    <LoginLayout>
      <ValidateLayout
        enteredEmail={params?.email}
        onResendCode={() => sendCode({ email: params?.email })}
      >
        <form
          onSubmit={handleSubmit(({ code }) =>
            verifyCode({ code, email: params.email })
          )}
        >
          <InputWrapper>
            <VerificationInput
              name="Verification Code"
              error={errors.code?.message}
              onChange={async (value) => {
                setValue('code', value);
              }}
            />
          </InputWrapper>
          <InputWrapper>
            <Button
              isPrimary
              fullWidth
              isLoading={isVerifyCodeLoading}
              type="submit"
            >
              <FormattedMessage
                id="resetPassword2.verify"
                defaultMessage="Verify"
              />
            </Button>
          </InputWrapper>
        </form>
        <Box mt="s">
          <Button
            fullWidth
            onClick={() => handlePreviousStep?.()}
            disabled={isVerifyCodeLoading}
          >
            <FormattedMessage id="resetPassword2.back" defaultMessage="Back" />
          </Button>
        </Box>
      </ValidateLayout>
    </LoginLayout>
  );
};
