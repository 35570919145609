import { FC, useCallback } from 'react';
import VisuallyHidden from '@reach/visually-hidden';
import { FormattedMessage } from 'react-intl';

import { Icon } from '../Icon';
import {
  ChildrenWrapper,
  NavItems,
  StyledAlert,
  StyledCloseButton,
  StyledIconBox,
  TopSection
} from './Alert.styles';
import { AlertProps, AlertVariant } from './Alert.types';

export const Alert: FC<AlertProps> = ({
  variant,
  showCloseButton,
  onClose,
  children,
  hideIcon,
  type = 'polite',
  renderNavItems,
  ...props
}) => {
  const renderIcon = useCallback(() => {
    switch (variant) {
      case AlertVariant.Warning:
        return <Icon icon="Alert" />;
      case AlertVariant.Danger:
        return <Icon icon="CircleCancel" />;
      default:
        return <Icon icon="Info" />;
    }
  }, [variant]);

  return (
    <StyledAlert
      role={type === 'assertive' ? 'alert' : 'status'}
      aria-live={type}
      variant={variant}
      {...props}
    >
      <TopSection>
        {!hideIcon && <StyledIconBox mr="xs">{renderIcon()}</StyledIconBox>}
        <ChildrenWrapper>{children}</ChildrenWrapper>
        {showCloseButton && (
          <StyledCloseButton type="button" onClick={onClose}>
            <Icon icon="Cancel" />
            <VisuallyHidden>
              <FormattedMessage id="closeAlertButton" defaultMessage="Close" />
            </VisuallyHidden>
          </StyledCloseButton>
        )}
      </TopSection>
      {renderNavItems && <NavItems>{renderNavItems()}</NavItems>}
    </StyledAlert>
  );
};
