import styled, { css } from 'styled-components';

import { Button } from '../Button';
import { BUTTON_SIZE, ButtonProps } from '../Button/Button.types';

export const StyledButton = styled(Button).attrs({
  variant: 'blue',
  isPrimary: true,
  size: BUTTON_SIZE.SMALL
} as ButtonProps)<{ isVisible: boolean }>`
  position: fixed;
  bottom: 40px;
  right: 40px;

  height: 56px;
  width: 56px;
  padding: 0;

  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  pointer-events: none;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    left: 24px;
    bottom: 24px;
  }

  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
      pointer-events: all;
    `}
`;
