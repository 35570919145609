import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { LinkButton } from '../../../../components/Button';
import { ContentWrapper, SocialsSection } from '../../../../components/Layout';
import { Heading } from '../../../../components/Typography';
import { ButtonWrapper } from './ResetPasswordSuccess.styles';

export const ResetPasswordSuccess: FC = () => {
  return (
    <>
      <ContentWrapper>
        <Heading size="h4" element="h1" variant="grey">
          <FormattedMessage
            id="resetPassword4.title"
            defaultMessage="Password successfully changed!"
          />
        </Heading>
        <ButtonWrapper>
          <LinkButton fullWidth isPrimary to="/login">
            <FormattedMessage
              id="resetPassword4.signIn"
              defaultMessage="Sign in"
            />
          </LinkButton>
        </ButtonWrapper>
      </ContentWrapper>
      <SocialsSection />
    </>
  );
};
