import styled, { css } from 'styled-components';

import { Input } from '..';
import { Dropdown } from '../../Dropdown';

export const PhoneInputWrapper = styled.div<{ hasError?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 32px;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    display: block;
  }

  ${({ theme, hasError }) =>
    hasError &&
    css`
      input {
        border-color: ${theme.color.secondaryRed};
        box-shadow: none;
      }
    `}
`;

export const IconWrapper = styled.span`
  height: 20px;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  z-index: 1;
  padding-left: ${({ theme }) => theme.padding.s};
`;

export const FlagWrapper = styled.picture`
  display: inline-block;
  width: 24px;
  height: 24px;
  ${({ theme }) => theme.border('#C4C4C4', 1)};
  border-radius: 24px;
  overflow: hidden;
`;

export const StyledDropdown = styled(Dropdown)<{ hasError?: boolean }>`
  position: static !important;
  width: 178px;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    position: relative !important;
    width: 100%;
    margin-bottom: 24px;
  }

  input {
    caret-color: transparent;
  }

  ${({ theme, hasError }) =>
    hasError &&
    css`
      .select__control {
        border-color: ${theme.color.secondaryRed};
        box-shadow: none;

        &:hover {
          border-color: ${theme.color.secondaryRed};
        }
      }
    `}
`;

export const DropdownItem = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & > p {
    display: inline-flex;
    align-items: center;
    gap: ${({ theme }) => theme.padding.xxs};
    margin: 0;
  }
`;

export const DropdownSelectedItem = styled.p`
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.padding.s};
  min-height: 33px;
  margin: 0;
`;

export const StyledInput = styled(Input)`
  flex: 1;
`;
