import styled from 'styled-components';

import { Logo } from '../../../Logo';

export const StyledContentWrapper = styled.main`
  width: 60%;

  margin: 0 auto;
  padding-top: 148px;
  padding-bottom: 76px;

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    padding-top: 76px;
  }

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    width: 80%;
    padding-top: 64px;
  }

  @media (${({ theme }) => theme.breakpoints.phone}) {
    padding-top: 40px;
  }
`;

export const StyledLogo = styled(Logo)`
  display: none;

  width: 123px;
  height: 40px;
  margin-bottom: ${({ theme }) => theme.margin.xl};

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    display: block;
  }
`;
