import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useIntl } from 'react-intl';
import { RouteProps } from 'react-router';
import Cookies from 'js-cookie';

import { FullPageLoader } from '../../components/FullPageLoader';
import { ErrorCodes } from '../api/error-codes';
import { HTTPError } from '../api/fetcher.types';
import Auth from './auth.service';
import { AlertVariant } from '../../components/Alert/Alert.types';
import { useNotification } from '../utils/notifications/notification-context';
import { AuthContextType, LeadUserData } from './auth-context.types';
import { ADD_WALLET_NOTIFICATION_OPEN } from '../../features/Dashboard/AddWalletNotification';

export const AuthContext = createContext<AuthContextType>(
  {} as AuthContextType
);

type AuthProviderProps = {
  authorizedRoutes: Array<RouteProps>;
  unauthorizedRoutes: Array<RouteProps>;
};

const AuthProvider: FC<AuthProviderProps> = ({
  children,
  authorizedRoutes,
  unauthorizedRoutes
}) => {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const { addNotification } = useNotification();

  const [leadUserData, setLeadUserData] = useState<LeadUserData>(null);
  const [authError, setAuthError] = useState<HTTPError>();

  const client = useQueryClient();

  const logoutQuery = useQuery<boolean, HTTPError>('logout', Auth.logout, {
    enabled: false,
    retry: false,
    onSettled: () => {
      client.clear();
      setLeadUserData(null);

      // clears cached use-places-autocomplete data
      sessionStorage.clear();

      localStorage.removeItem(ADD_WALLET_NOTIFICATION_OPEN);

      history.push('/login');
    }
  });

  const initializeAuthQuery = useQuery<{ jwt: string }, HTTPError>(
    'initializeAuth',
    Auth.refreshToken,
    {
      retry: false,
      enabled: false,
      onError: (error) => {
        const isSessionExpired =
          error?.errorCode === ErrorCodes.SESSION_EXPIRED;

        const detailedErrorCodes = error?.details?.map(
          ({ errorCode }) => errorCode
        );

        if (
          (isSessionExpired ||
            detailedErrorCodes?.includes(ErrorCodes.MISSING_SESSION_TOKEN)) &&
          history.location.pathname !== '/login'
        ) {
          addNotification({
            id: 'sessionExpired',
            text: formatMessage({
              id: 'sessionExpired',
              defaultMessage: 'Please login to continue'
            }),
            variant: AlertVariant.Success
          });
          setLeadUserData(null);

          history.push({
            pathname: '/login',
            search: `redirectUrl=${encodeURIComponent(
              history.location.pathname + history.location.search
            )}`
          });
        }
      }
    }
  );

  const refreshTokenQuery = useQuery<{ jwt: string }, HTTPError>(
    'refreshToken',
    Auth.refreshToken,
    {
      retry: false,
      enabled: false,
      onError: (error) => {
        setAuthError(error);

        addNotification({
          id: 'sessionExpired',
          text: formatMessage({
            id: 'sessionExpired',
            defaultMessage: 'Please login to continue'
          }),
          variant: AlertVariant.Success
        });

        logoutQuery.refetch();
      }
    }
  );

  const loginMutation = useMutation<
    { jwt: string },
    HTTPError,
    { email: string; password: string }
  >('login', (data) => Auth.login(data), {
    onSuccess: () => {
      Cookies.remove('upgrade-bar');
    },
    onError: (error, variables) => {
      setAuthError(error);

      const { email } = variables;

      if (error.errorCode === ErrorCodes.EMAIL_NOT_VERIFIED) {
        history.push(
          queryString.stringifyUrl({
            query: { email },
            url: '/verify-email'
          })
        );
      } else if (error.errorCode === ErrorCodes.LEGACY_USER_LOGGED_IN) {
        history.push(
          queryString.stringifyUrl({
            url: '/login'
          })
        );
      } else if (error.statusCode >= 400) {
        addNotification({
          id: 'loginError',
          text: formatMessage(
            {
              id: 'loginErrorMessage',
              defaultMessage:
                'The email and password combination you entered is incorrect. Click {link} if you don’t remember your password.'
            },
            {
              link: (
                <a href="/forgot-password">
                  {formatMessage({
                    id: 'loginErrorMessageLinkAnchor',
                    defaultMessage: 'here'
                  })}
                </a>
              )
            }
          ),
          variant: AlertVariant.Danger
        });
      }
    }
  });

  const isLoggedIn = () => !!Auth.getToken();

  const isAuthRoute = useCallback(
    () =>
      authorizedRoutes
        .filter(
          ({ path }) => !unauthorizedRoutes.find((route) => route.path === path)
        )
        .map(({ path }) => path)
        .includes(history.location.pathname),
    [authorizedRoutes, history.location.pathname, unauthorizedRoutes]
  );

  useEffect(() => {
    if (isAuthRoute()) {
      initializeAuthQuery.refetch().then(() => {
        if (!isLoggedIn() && history.location.pathname !== '/login') {
          history.push({
            pathname: '/login',
            search: `redirectUrl=${encodeURIComponent(
              history.location.pathname + history.location.search
            )}`
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading =
    initializeAuthQuery.isLoading ||
    logoutQuery.isLoading ||
    (isAuthRoute() && !isLoggedIn());

  return (
    <AuthContext.Provider
      value={{
        login: loginMutation,
        logout: logoutQuery,
        refreshToken: refreshTokenQuery,
        isLoggedIn,
        leadUserData,
        setLeadUserData,
        authError
      }}
    >
      {children}
      {isLoading && <FullPageLoader />}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
