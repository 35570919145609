import { FormattedMessage } from 'react-intl';

import { useAuth } from '../../shared/auth/auth-context';
import { MenuButton } from '../Button/MenuButton';
import {
  NavbarMenu,
  NavbarMenuLink,
  NavbarMenuTooltip
} from '../Navbar/NavbarMenu';
import {
  TooltipMenu,
  TooltipMenuItem,
  TooltipMenuLink
} from '../Tooltip/TooltipMenu';
import { Tooltip } from '../Tooltip';
import {
  TooltipActiveMethod,
  TooltipAlignment
} from '../Tooltip/Tooltip.types';
import { DesktopMenuWrapper } from './DesktopMenu.styles';
import { supportUrl } from '../../shared/utils/navigation';

export const DesktopMenu = () => {
  const { logout } = useAuth();

  return (
    <>
      <DesktopMenuWrapper>
        <NavbarMenu>
          <NavbarMenuLink to="/dashboard">
            <FormattedMessage
              id="menuItem.dashboard"
              defaultMessage="Dashboard"
            />
          </NavbarMenuLink>
          <NavbarMenuTooltip
            align={TooltipAlignment.Start}
            offset={-8}
            padding={0}
            method={TooltipActiveMethod.Click}
            content={
              <TooltipMenu>
                <TooltipMenuLink external to="https://emrit.io">
                  <FormattedMessage
                    id="MenuLink.howItWorks"
                    defaultMessage="How it works"
                  />
                </TooltipMenuLink>
                <TooltipMenuLink external to={supportUrl}>
                  <FormattedMessage
                    id="menuItem.support"
                    defaultMessage="Support"
                  />
                </TooltipMenuLink>
              </TooltipMenu>
            }
          >
            <FormattedMessage
              tagName="p"
              id="menuItem.help"
              defaultMessage="Help"
            />
          </NavbarMenuTooltip>
          {/* <NavbarMenuLink to="/apply-for-a-coolspot" variant="green">
            <FormattedMessage
              id="menuItem.coolSpot"
              defaultMessage="Apply for a CoolSpot™"
            />
          </NavbarMenuLink> */}
        </NavbarMenu>
      </DesktopMenuWrapper>
      <Tooltip
        align={TooltipAlignment.End}
        padding={0}
        method={TooltipActiveMethod.Click}
        content={
          <TooltipMenu>
            <TooltipMenuLink to="/edit-wallet">
              <FormattedMessage
                id="menuItem.wallet"
                defaultMessage="Update wallet address"
              />
            </TooltipMenuLink>
            <TooltipMenuLink to="/terms-and-conditions">
              <FormattedMessage
                id="menuItem.termsAndConditions"
                defaultMessage="Terms and Conditions"
              />
            </TooltipMenuLink>
            <TooltipMenuItem onClick={() => logout.refetch()}>
              <FormattedMessage id="menuItem.logout" defaultMessage="Logout" />
            </TooltipMenuItem>
          </TooltipMenu>
        }
      >
        <MenuButton icon="More" />
      </Tooltip>
    </>
  );
};
