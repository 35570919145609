import React, { FC } from 'react';

import { TabListWrapper } from './Tabs.styles';
import { TabListProps } from './Tabs.types';

export const TabList: FC<TabListProps> = ({ children, ...props }) => {
  const childrenWithProps = React.Children.map(children, (child, index) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        index
      });
    }

    return child;
  });

  return <TabListWrapper {...props}>{childrenWithProps}</TabListWrapper>;
};
