import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { useAuth } from '../../../shared/auth/auth-context';
import { LinkButton } from '../../../components/Button';
import { BUTTON_SIZE } from '../../../components/Button/Button.types';
import { EmptyStateLayout } from '../../../components/Layout/EmptyStateLayout';
import { EmptyLayoutButtonsRow } from '../../../components/Layout/EmptyStateLayout/EmptyStateLayout';
import { Heading } from '../../../components/Typography';
import { StyledSubtitle } from './OrderNotFound.styles';

const OrderNotFound: FC<{ isOverlay?: boolean }> = () => {
  const { isLoggedIn } = useAuth();

  const gotItLink = isLoggedIn() ? '/dashboard' : '/login';

  return (
    <EmptyStateLayout isOverlay>
      <div>
        <Heading size="h2" align="center" variant="white">
          <FormattedMessage
            id="deviceAlreadyOrdered.header"
            defaultMessage="Good news!"
          />
        </Heading>
        <StyledSubtitle size="big" align="center" variant="white">
          <p>
            <FormattedMessage
              id="deviceAlreadyOrdered.subtitle1"
              defaultMessage="Your order has been placed and paid for."
            />
            <br />
            <FormattedMessage
              id="deviceAlreadyOrdered.subtitle2"
              defaultMessage="Check your email for updates on your order."
            />
          </p>
        </StyledSubtitle>
      </div>
      <EmptyLayoutButtonsRow>
        <LinkButton
          to={gotItLink}
          external
          fullWidth
          isPrimary
          size={BUTTON_SIZE.SMALL}
        >
          <FormattedMessage id="gotItButton" defaultMessage="Got it" />
        </LinkButton>
      </EmptyLayoutButtonsRow>
    </EmptyStateLayout>
  );
};

export default OrderNotFound;
