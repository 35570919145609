import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { Button } from '../../../../../components/Button';
import { BUTTON_SIZE } from '../../../../../components/Button/Button.types';
import { Checkbox } from '../../../../../components/Checkbox/Checkbox';
import {
  StyledForm,
  SubmitButtonRow
} from './AcceptTermsAndConditionsForm.styles';
import { AcceptTermsAndConditionsFormProps } from './AcceptTermsAndConditionsForm.types';
import { useTermsAndConditionsValidation } from './AcceptTermsAndConditionsForm.validation';

export const AcceptTermsAndConditionsForm: FC<AcceptTermsAndConditionsFormProps> =
  ({ onSubmit, onDecline, isLoading }) => {
    const {
      register,
      handleSubmit,
      watch,
      formState: { errors }
    } = useForm({
      defaultValues: { acceptTermsAndConditions: false },
      resolver: yupResolver(useTermsAndConditionsValidation())
    });

    const watchCheckbox = watch('acceptTermsAndConditions');

    return (
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Checkbox
          {...register('acceptTermsAndConditions')}
          error={errors.acceptTermsAndConditions?.message}
        >
          <FormattedMessage
            id="termsAndConditions.agreeNewVersion"
            defaultMessage="I agree to the new version of Terms of Service"
          />
        </Checkbox>
        <SubmitButtonRow mt="xl">
          <Button size={BUTTON_SIZE.SMALL} onClick={onDecline}>
            <FormattedMessage
              id="termsAndConditions.declineButton"
              defaultMessage="Decline"
            />
          </Button>
          <Button
            disabled={!watchCheckbox}
            isPrimary
            isLoading={isLoading}
            size={BUTTON_SIZE.SMALL}
          >
            <FormattedMessage
              id="termsAndConditions.submitButton"
              defaultMessage="Continue"
            />
          </Button>
        </SubmitButtonRow>
      </StyledForm>
    );
  };
