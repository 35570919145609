import { forwardRef } from 'react';

import { BasicInputProps } from '../Input.types';
import { StyledInput } from './BasicInput.styles';

export const BasicInput = forwardRef<HTMLInputElement, BasicInputProps>(
  ({ selectable, variant, ...props }, ref) => {
    return (
      <StyledInput
        selectable={selectable}
        variant={variant}
        ref={ref}
        {...props}
      />
    );
  }
);
