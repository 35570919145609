import { FC } from 'react';

import { theme } from '../../themes/main';
import { Icon } from '../Icon';
import { PaymentProviderIconProps } from './PaymentProviderIcon.types';

export const PaymentProviderIcon: FC<PaymentProviderIconProps> = ({
  paymentProvider
}) => {
  switch (paymentProvider) {
    case 'paypal':
      return (
        <Icon icon="PaymentProviderPaypal" scale="190%" translate="9%, 3%" />
      );
    case 'amazon_payments':
      return (
        <Icon icon="PaymentProviderAmazonPay" scale="190%" translate="9%, 3%" />
      );
    case 'authorize_net':
      return (
        <Icon
          icon="PaymentProviderAuthorizeNet"
          scale="190%"
          translate="9%, 3%"
        />
      );
    case 'coinbase_commerce_':
      return (
        <Icon icon="PaymentProviderCoinbase" scale="190%" translate="9%, 3%" />
      );
    default:
      return <Icon icon="MoneyMultiple" color={theme.color.dimmed} />;
  }
};
