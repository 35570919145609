import { FC } from 'react';

import { Stepper } from '../../../../components/Stepper';
import { SignUpLayout } from '../../../../components/Layout';
import { NewsletterRegistration } from './NewsletterRegistration';
import { NotSupportedSuccessPage } from './NotSupportedSuccess';
import { WaitlistEmailValidate } from './WaitlistEmailValidate';

export const NotSupportedCountry: FC = () => {
  return (
    <Stepper
      steps={[
        <NewsletterRegistration />,
        <WaitlistEmailValidate />,
        <NotSupportedSuccessPage />
      ]}
    >
      {(step) => <SignUpLayout>{step}</SignUpLayout>}
    </Stepper>
  );
};
