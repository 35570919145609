/* eslint-disable sort-keys */
import { CurrentUser } from '../../shared/api/Identity/Identity.types';

export const userFixture: CurrentUser = {
  acceptedTermsAndConditions: false,
  uuid: 'ed62de71-3a49-4712-b2d8-29a9c0a4bdeb',
  firstName: 'MRX',
  lastName: 'Studio',
  tel: '+48507000123',
  email: 'user@example.com',
  emailVerified: false,
  phoneVerified: false,
  role: 1,
  country: 'PL',
  userType: 'V2',
  emailVerificationCodeSentAt: '2021-07-08T13:50:59.425Z',
  phoneVerificationCodeSentAt: null,
  passwordResetCodeSentAt: null,
  createdDate: '2021-07-08T11:50:59.401Z',
  updatedDate: '2021-07-08T11:50:59.401Z',
  deletedDate: null,
  recommendedByUserId: null
};

export const verifyUserFixture = {
  isLegacyUser: false,
  isUserV2: true
};
