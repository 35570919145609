import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Box } from '../../../../components/Box';
import { LinkButton } from '../../../../components/Button';
import { BUTTON_SIZE } from '../../../../components/Button/Button.types';
import { ContentWrapper } from '../../../../components/Layout';
import { RegistrationForm } from '../../../../components/RegistrationForm';
import { RegistrationFormWrapper } from '../../../../components/RegistrationForm/RegistrationForm.styles';
import { RegistrationFormValues } from '../../../../components/RegistrationForm/RegistrationForm.types';
import { Heading } from '../../../../components/Typography';
import { StyledSubtitle } from './CreateLegacyAccount.styles';
import { CreateLegacyAccountProps } from './CreateLegacyAccount.types';

export const CreateLegacyAccount: FC<CreateLegacyAccountProps> = ({
  userData,
  onSubmit,
  countries,
  verificationCode
}) => {
  const initialCountry = countries.find(
    ({ name }) => name.toLowerCase() === userData?.country?.toLowerCase()
  );

  const handleSubmit = (data: RegistrationFormValues) => {
    if (onSubmit) {
      onSubmit(data);
    }
  };

  const countryOptions = countries.map((country) => ({
    label: country.name,
    value: country.code
  }));

  return (
    <>
      <ContentWrapper>
        <Heading size="h4" element="h1" variant="grey">
          <FormattedMessage
            id="createLegacyAccount.title"
            defaultMessage="Welcome back!"
          />
        </Heading>
        <StyledSubtitle variant="grey" size="big">
          <FormattedMessage
            id="createLegacyAccount.subtitle"
            defaultMessage="We have updated the Emrit application. Please confirm, change or enter any required data."
          />
        </StyledSubtitle>
        <RegistrationFormWrapper>
          <RegistrationForm
            onSubmit={handleSubmit}
            isLegacyUser
            countryOptions={countryOptions}
            defaultValue={{
              ...userData,
              country: initialCountry
                ? {
                    label: initialCountry?.name,
                    value: initialCountry?.code
                  }
                : undefined
            }}
            verificationCode={verificationCode}
            submitButtonLabel={
              <FormattedMessage
                id="createLegacyAccount.submitButton"
                defaultMessage="Update account"
              />
            }
          />
        </RegistrationFormWrapper>
        <Box mt="m">
          <LinkButton
            to="/feedback"
            isPlain
            isPrimary={false}
            fullWidth
            size={BUTTON_SIZE.BIG}
          >
            <FormattedMessage
              id="createAccount.cancelButton"
              defaultMessage="Cancel"
            />
          </LinkButton>
        </Box>
      </ContentWrapper>
    </>
  );
};
