import { FC, useMemo, useRef, useState } from 'react';
import { bbox } from 'turf';
import ReactMapGL, {
  FlyToInterpolator,
  GeolocateControl,
  Layer,
  MapEvent,
  MapRef,
  NavigationControl,
  Source,
  WebMercatorViewport
} from 'react-map-gl';

import {
  geolocateControlStyle,
  LoaderWrapper,
  MapContainer,
  MapWrapper,
  navControlStyle
} from './Map.styles';
import { MapLayer, MapProps, ViewportType } from './Map.types';
import { theme } from '../../../themes/main';
import { Loader } from '../../../components/Loader';
import { HeadingControls } from './HeadingControls';
import { useDeviceScroll } from '../Devices/device-scroll-context';
import { MapLegend } from './MapLegend';
import { Coolspot } from '../../../shared/api/Wallet/Wallet.types';
import { Tooltip } from './Tooltip';
import { formatCoolspotName } from '../../../shared/utils/format-coolspot-name';
import { Text } from '../../../components/Typography';
import { MobileCollapse } from './MobileCollapse';

const GeoJSON = require('geojson');

const LAYERS: MapLayer[] = [
  {
    id: 'active-coolspots',
    color: theme.color.blue,
    status: 'online'
  },
  {
    id: 'offline-coolspots',
    color: theme.color.secondaryRed,
    status: 'offline'
  },
  {
    id: 'loading-coolspots',
    color: theme.color.blue,
    status: 'loading'
  }
];

export const Map: FC<MapProps> = ({ hotspotData, isLoading }) => {
  const mapWrapperRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<MapRef>(null);
  const [tooltipData, setTooltipData] = useState<Coolspot | null>(null);
  const { scrollTo, resetFocus } = useDeviceScroll();

  const [viewport, setViewport] = useState<ViewportType>({
    latitude: 0,
    longitude: 0,
    zoom: 1
  });

  const coolspotsData = useMemo(() => {
    if (!hotspotData) {
      return {};
    }

    return GeoJSON.parse(hotspotData, {
      Point: ['lat', 'lng']
    });
  }, [hotspotData]);

  const handleOnLoad = () => {
    const [minX, minY, maxX, maxY] = bbox(coolspotsData);

    const { latitude, longitude, zoom } = new WebMercatorViewport({
      width: mapWrapperRef.current?.offsetWidth || 640,
      height: mapWrapperRef.current?.offsetHeight || 640
    }).fitBounds(
      [
        [minX, minY],
        [maxX, maxY]
      ],
      {
        padding: 50
      }
    );

    setViewport({
      ...viewport,
      latitude,
      longitude,
      zoom,
      transitionInterpolator: new FlyToInterpolator({ speed: 0.25 }),
      transitionDuration: 1000
    });
  };

  const handleOnClick = (event: MapEvent) => {
    const feature = event ? event?.features?.[0] : undefined;
    const hotspot = hotspotData?.find(
      ({ coolspotId }) => feature?.properties?.coolspotId === coolspotId
    );

    if (hotspot) {
      setTooltipData(hotspot);

      scrollTo(hotspot.coolspotId);

      setViewport({
        ...viewport,
        latitude: hotspot.lat,
        longitude: hotspot.lng,
        zoom: 17,
        transitionInterpolator: new FlyToInterpolator({ speed: 0.25 }),
        transitionDuration: 1000
      });
    } else {
      resetFocus();
    }
  };

  if (isLoading) {
    return (
      <LoaderWrapper>
        <Loader variant="blue" size="big" />
      </LoaderWrapper>
    );
  }

  const isMapVisible = coolspotsData && coolspotsData?.features?.length > 0;

  return (
    <MobileCollapse isMapVisible={isMapVisible}>
      <MapContainer hasDevices={!!hotspotData?.length}>
        <HeadingControls hotspotData={hotspotData} />
        {isMapVisible && (
          <>
            <MapWrapper ref={mapWrapperRef}>
              <ReactMapGL
                {...viewport}
                ref={mapRef}
                width="100%"
                height="100%"
                mapStyle="mapbox://styles/mapbox/light-v9"
                mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_API || ''}
                dragRotate={false}
                touchRotate={false}
                interactiveLayerIds={LAYERS.map(({ id }) => id)}
                onViewportChange={setViewport}
                onClick={handleOnClick}
                minZoom={1}
                maxZoom={17}
                onLoad={handleOnLoad}
                attributionControl={false}
              >
                <GeolocateControl
                  style={geolocateControlStyle}
                  positionOptions={{ enableHighAccuracy: true }}
                  trackUserLocation
                  showAccuracyCircle={false}
                />
                <NavigationControl
                  style={navControlStyle}
                  showCompass={false}
                />
                <Source type="geojson" data={coolspotsData}>
                  {LAYERS.map((layer) => (
                    <Layer
                      key={layer.id}
                      id={layer.id}
                      type="circle"
                      source="hotspot-data"
                      filter={['==', 'status', layer.status]}
                      paint={{
                        'circle-color': layer.color,
                        'circle-radius': 8
                      }}
                    />
                  ))}
                </Source>
                {tooltipData && (
                  <Tooltip
                    longitude={tooltipData?.lng}
                    latitude={tooltipData?.lat}
                    onClose={() => setTooltipData(null)}
                  >
                    <Text level="big" color="white">
                      {formatCoolspotName(tooltipData.name!)}
                    </Text>
                    <Text level="small" color="white">
                      {tooltipData?.installationAddress}
                    </Text>
                  </Tooltip>
                )}
              </ReactMapGL>
            </MapWrapper>
            <MapLegend />
          </>
        )}
      </MapContainer>
    </MobileCollapse>
  );
};
