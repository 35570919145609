import { FC } from 'react';

import { Icon } from '../../Icon';
import { StyledHeader, StyledHeaderLabel } from './AccordionHeader.styles';
import { AccordionHeaderProps } from './AccordionHeader.types';

export const AccordionHeader: FC<AccordionHeaderProps> = ({
  isActive,
  onClick,
  title
}) => (
  <StyledHeader isActive={isActive} onClick={onClick}>
    <StyledHeaderLabel>{title}</StyledHeaderLabel>
    {isActive ? <Icon icon="ChevronUp" /> : <Icon icon="ChevronDown" />}
  </StyledHeader>
);
