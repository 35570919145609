import { FC, KeyboardEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { AlertVariant } from '../../../../../components/Alert/Alert.types';
import { Box } from '../../../../../components/Box';
import { Button } from '../../../../../components/Button';
import { Input } from '../../../../../components/Input';
import { ModalHeader } from '../../../../../components/Modal/Modal.styles';
import { RadioGroup } from '../../../../../components/RadioGroup/RadioGroup';
import { RadioGroupVariant } from '../../../../../components/RadioGroup/RadioGroup.types';
import { Text } from '../../../../../components/Typography';
import { useNotification } from '../../../../../shared/utils/notifications/notification-context';
import { useCreateUserRequest } from '../../../../../shared/api/Identity/Identity.queries';
import { UserRequestType } from '../../../../../shared/api/Identity/Identity.types';
import {
  feetToMeters,
  metersToFeet
} from '../../../../../shared/utils/unit-conversion';
import {
  FieldWrapper,
  Label,
  ModalCopy,
  UnitLabel
} from '../ElevationModal.styles';
import {
  ElevationFormData,
  ElevationFormProps,
  ElevationUnit
} from './ElevationForm.types';
import { useElevationFormValidation } from './ElevationForm.validation';

export const ElevationForm: FC<ElevationFormProps> = ({
  deviceId,
  isSubmitted,
  setIsOpen,
  setIsSubmitted
}) => {
  const { formatMessage } = useIntl();
  const { addNotification } = useNotification();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm({
    defaultValues: {
      unit: ElevationUnit.Metric,
      value: undefined
    },
    mode: 'onSubmit',
    resolver: yupResolver(useElevationFormValidation())
  });

  const selectedUnit = watch('unit');
  const elevationValue = watch('value');

  const { mutate: sendRequest, isLoading } = useCreateUserRequest({
    onSuccess: () => {
      setIsSubmitted(true);
    },
    onError: (error) => {
      if (error.data) {
        addNotification({
          id: 'verificationError',
          text: error.data.message,
          variant: AlertVariant.Danger
        });
      }
    }
  });

  const onSubmit = async (data: ElevationFormData) => {
    const { value } = data;

    sendRequest({
      requestType: UserRequestType.ChangeElevation,
      data: {
        coolspotId: deviceId,
        elevation:
          selectedUnit === ElevationUnit.Imperial ? feetToMeters(value) : value
      }
    });
  };

  const getConvertedValue = (value: number) => {
    const label = formatMessage({
      defaultMessage: 'Equivalent of approx.',
      id: 'changeElevation.equivalentOf'
    });

    let convertedValue;

    if (selectedUnit === ElevationUnit.Metric) {
      convertedValue = `${metersToFeet(value, 1)} ft`;
    } else if (selectedUnit === ElevationUnit.Imperial) {
      convertedValue = `${feetToMeters(value)} m`;
    }

    return `${label} ${convertedValue}`;
  };

  if (isSubmitted) {
    return (
      <Box>
        <ModalHeader center>
          <FormattedMessage
            id="changeElevation.successMessage"
            defaultMessage="Your request has been sent."
          />
        </ModalHeader>
        <ModalCopy>
          <FormattedMessage
            id="changeElevation.successCopy"
            defaultMessage="It may take up to 30 business days to see your device info updated"
          />
        </ModalCopy>
        <Box mt="l">
          <Button
            isPrimary
            fullWidth
            type="button"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <FormattedMessage id="done" defaultMessage="Done" />
          </Button>
        </Box>
      </Box>
    );
  }

  const onKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (!event.key.match(/^([0-9]|-)+$/)) {
      event.preventDefault();
    }
  };

  return (
    <Box>
      <ModalHeader hasCloseButton>
        <FormattedMessage
          id="deviceSettings.changeElevation"
          defaultMessage="Edit Elevation"
        />
      </ModalHeader>
      <Text level="small">
        <FormattedMessage
          id="changeElevation.modalCopy"
          defaultMessage="What is the height of your device in relation to the ground? Please use a negative (-) to indicate elevation below ground-level (ex -10 Feet)."
        />
      </Text>
      <Box mt="m">
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Label>
            <FormattedMessage
              id="changeElevation.units"
              defaultMessage="Units"
            />
          </Label>
          <RadioGroup
            {...register('unit')}
            defaultValue={ElevationUnit.Metric}
            options={[
              {
                label: formatMessage({
                  defaultMessage: 'Meters',
                  id: 'changeElevation.metricSystem'
                }),
                value: ElevationUnit.Metric
              },
              {
                label: formatMessage({
                  defaultMessage: 'Feet',
                  id: 'changeElevation.imperialSystem'
                }),
                value: ElevationUnit.Imperial
              }
            ]}
            horizontal
            variant={RadioGroupVariant.Simple}
          />
          <FieldWrapper>
            <Input
              {...register('value')}
              label={formatMessage({
                defaultMessage: 'Elevation',
                id: 'changeElevation.label'
              })}
              placeholder={formatMessage({
                defaultMessage: 'Type in the value',
                id: 'assignAntenna.inputPlaceholder'
              })}
              type="number"
              caption={elevationValue && getConvertedValue(elevationValue)}
              error={errors.value?.message}
              onKeyPress={onKeyPress}
            />
            <UnitLabel>
              {selectedUnit === ElevationUnit.Metric && (
                <FormattedMessage
                  id="changeElevation.unitMetic"
                  defaultMessage="meters"
                />
              )}
              {selectedUnit === ElevationUnit.Imperial && (
                <FormattedMessage
                  id="changeElevation.unitImperial"
                  defaultMessage="feet"
                />
              )}
            </UnitLabel>
          </FieldWrapper>
          <Box mt="l">
            <Button isPrimary fullWidth isLoading={isLoading} type="submit">
              <FormattedMessage
                id="changeElevation.send"
                defaultMessage="Send a request"
              />
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};
