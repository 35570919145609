import { createContext, FC, useCallback, useContext, useState } from 'react';

import { NotificationType } from '../../../components/Alert/Alert.types';
import { Notification } from '../../../components/Alert';
import { NotificationContainer } from './notification-context.styles';

type NotificationContextType = {
  addNotification: (item: NotificationType) => void;
};

export const NotificationContext = createContext<NotificationContextType>(
  {} as NotificationContextType
);

const NotificationProvider: FC = ({ children }) => {
  const [notifications, setNotifications] = useState<NotificationType[]>([]);

  const deleteNotification = useCallback(
    (id: string) => {
      setNotifications(notifications.filter((n) => n.id !== id));
    },
    [notifications]
  );

  const addNotification = (item: NotificationType) => {
    const match = notifications.find((existing) => existing.id === item.id)!;

    if (match) {
      return;
    }

    setNotifications([...notifications, item]);
  };

  return (
    <NotificationContext.Provider value={{ addNotification }}>
      <>
        <NotificationContainer>
          {notifications.map((notification) => (
            <Notification
              key={notification.id}
              id={notification.id}
              variant={notification.variant}
              onClick={() => deleteNotification(notification.id)}
              autoRemove={notification.autoRemove}
              autoRemoveDelay={notification.autoRemoveDelay}
              text={notification.text}
            />
          ))}
        </NotificationContainer>
        {children}
      </>
    </NotificationContext.Provider>
  );
};

const useNotification = () => useContext(NotificationContext);

export { NotificationProvider, useNotification };
