import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import queryString from 'query-string';

import { Box } from '../../../../components/Box';
import { Button, LinkButton } from '../../../../components/Button';
import { BUTTON_SIZE } from '../../../../components/Button/Button.types';
import { ContentWrapper } from '../../../../components/Layout';
import { Link } from '../../../../components/Link';
import { Heading, Subtitle, Text } from '../../../../components/Typography';
import { useAuth } from '../../../../shared/auth/auth-context';
import { CreateAccountStepProps } from '../CreateAccountPage.types';

export const AccountHasBeenCreated: FC<CreateAccountStepProps> = ({
  previousStepValues
}) => {
  const history = useHistory();
  const { isLoggedIn } = useAuth();

  const { registrationData, leadOrderId } = previousStepValues!;
  const { email } = registrationData!;

  const handleConfirm = async () => {
    history.push(
      queryString.stringifyUrl({
        query: {
          flow: 'new-user',
          email: email!,
          leadOrderId: leadOrderId!
        },
        url: '/order-a-coolspot'
      })
    );
  };

  return (
    <ContentWrapper showHero hideLogo>
      <Heading size="h4" element="h1" variant="grey">
        <FormattedMessage
          id="accountHasBeenCreated.title"
          defaultMessage="Your account has been created!"
        />
      </Heading>
      <Box mt="m">
        <Subtitle variant="grey" size="big">
          <FormattedMessage
            id="accountHasBeenCreated.subtitle"
            defaultMessage="Next, just choose your earnings plan and order your CoolSpot™."
          />
        </Subtitle>
      </Box>
      <Box mt="l">
        <Button onClick={handleConfirm} fullWidth isPrimary variant="blue">
          <FormattedMessage
            id="accountHasBeenCreated.chooseMyPlanButton"
            defaultMessage="Choose my plan"
          />
        </Button>
      </Box>
      <Box mt="m">
        {isLoggedIn() ? (
          <LinkButton
            to="/dashboard"
            isPrimary={false}
            fullWidth
            size={BUTTON_SIZE.BIG}
          >
            <FormattedMessage
              id="accountHasBeenCreated.goToDashboard"
              defaultMessage="Go to dashboard"
            />
          </LinkButton>
        ) : (
          <LinkButton
            to="/login"
            isPrimary={false}
            fullWidth
            size={BUTTON_SIZE.BIG}
          >
            <FormattedMessage
              id="accountHasBeenCreated.goToLogin"
              defaultMessage="Go to login"
            />
          </LinkButton>
        )}
      </Box>
      <Box mt="xl">
        <Text type="default" level="big">
          <FormattedMessage
            id="accountHasBeenCreated.unsubscribe"
            defaultMessage="If you don’t want to be on the list for a CoolSpot™ at this installation address {clickHere}."
            values={{
              clickHere: (
                <Link
                  to={`/remove-from-wait-list?${queryString.stringify({
                    email: email!,
                    leadOrderId: leadOrderId!
                  })}`}
                >
                  <FormattedMessage
                    id="accountHasBeenCreated.unsubscribeLink"
                    defaultMessage="click here"
                  />
                </Link>
              )
            }}
          />
        </Text>
      </Box>
    </ContentWrapper>
  );
};
