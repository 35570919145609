import styled, { css } from 'styled-components';

import { DashboardBox } from '../../../../components/DashboardBox';
import { Caption, Subtitle } from '../../../../components/Typography';
import { getTypography } from '../../../../shared/utils/style-utils';

export const StyledDashboardBox = styled(DashboardBox).attrs({
  tabIndex: 0
})<{ isFocused?: boolean; status?: string }>`
  position: relative;
  overflow: hidden;
  display: flex;
  flex: none;
  flex-direction: column;
  margin: 2px;
  padding: 0;
  white-space: normal;
  box-shadow: 0 0 0 2px transparent;
  transition: box-shadow 1s ease-in-out;
  outline: none;

  ${({ status, theme }) =>
    status === 'offline' &&
    css`
      box-shadow: 0 0 0 2px ${theme.color.secondaryRed};
    `}

  ${({ isFocused }) =>
    isFocused &&
    css`
      box-shadow: 0 0 0 2px ${({ theme }) => theme.color.blue} !important;
    `}
`;

export const DataPane = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${({ theme }) => theme.padding.s};
  width: 100%;
  padding: ${({ theme }) => theme.padding.l};
`;

export const HeaderPane = styled.div`
  display: flex;
  flex-direction: row;

  @media (${({ theme }) => theme.breakpoints.phone}) {
    padding-bottom: ${({ theme }) => theme.padding.s};
  }

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    padding-bottom: 0;
  }
`;

export const IconPane = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${({ theme }) => theme.padding.xs};
  padding-right: ${({ theme }) => theme.padding.s};
`;

export const IconCircle = styled.picture`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 99px;
  background-color: ${({ theme }) => theme.color.darkBlue};
  width: 24px;
  height: 24px;
`;
export const SettingsMenuPane = styled.div`
  display: flex;
  padding-top: 10px;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  max-width: calc(100% - 68px);
  padding-right: ${({ theme }) => theme.padding.xxs};
`;

export const BottomRow = styled.div`
  display: flex;
  gap: 18px;
  justify-content: space-between;
`;

export const PricesRow = styled(BottomRow)``;

export const CenterRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: ${({ theme }) => theme.margin.s};
  white-space: nowrap;
  align-items: center;
  border-bottom: ${({ theme }) =>
    theme.border(theme.color.secondaryWhiteBlue, 1)};
`;

export const StyledAddressPane = styled.div`
  position: relative;

  svg {
    display: none;
    position: absolute;

    @media (${({ theme }) => theme.breakpoints.phone}) {
      display: block;
      top: 0;
      right: 0;
    }
  }
`;

export const IconsContainer = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.margin.m};
  right: ${({ theme }) => theme.margin.m};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  & > div {
    display: inline-flex;
  }
`;

export const StyledAddress = styled(Caption)`
  display: inline-block;
  max-width: 240px;
  overflow: hidden;
  color: ${({ theme }) => theme.color.grey};
  line-height: 1.33em;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (${({ theme }) => theme.breakpoints.phone}) {
    padding-right: ${({ theme }) => theme.padding.m};
  }
`;

export const StyledSubtitle = styled(Subtitle)<{ isPlaceholder?: boolean }>`
  overflow: hidden;
  ${({ theme }) => getTypography(theme.typography.body.big)};
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ isPlaceholder, theme }) =>
    isPlaceholder &&
    css`
      color: ${theme.color.dimmed};
    `};
`;

export const CoolspotLabel = styled.p`
  margin: 0;
  ${({ theme }) => getTypography(theme.typography.caption)};
  line-height: 1;
  color: ${({ theme }) => theme.color.grey};
`;

export const MultiplierCaption = styled(Caption)`
  @media (${({ theme }) => theme.breakpoints.phone}) {
    display: none;
  }
`;
