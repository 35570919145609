import styled from 'styled-components';

export const LayoutInnerWrapper = styled.main`
  width: 85%;
  max-width: 1280px;
  min-height: 188px;
  margin: 0 auto;

  margin-top: -144px;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: -38px;
  }

  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: ${({ theme }) => theme.shadow.white.s};
  padding: ${({ theme }) => theme.padding.l};
`;
