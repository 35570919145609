import styled from 'styled-components';

export const StyledTooltip = styled.div`
  .mapboxgl-popup-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 16px;
    padding: ${({ theme }) => theme.padding.s};
    background-color: ${({ theme }) => theme.color.blue};
    color: ${({ theme }) => theme.color.white};
  }
`;
