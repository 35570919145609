import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { DashboardBox } from '../../../components/DashboardBox';
import { DashboardLayout } from '../../../components/Layout';
import { Stepper } from '../../../components/Stepper';
import { Heading } from '../../../components/Typography';
import { useGetCurrentUser } from '../../../shared/api/Identity/Identity.queries';
import { useGetWalletAddress } from '../../../shared/api/Wallet';
import { DashboardContentWrapper } from '../Dashboard.styles';
import { EditWalletEmailValidation } from './EditWalletEmailValidation';
import { EditWalletForm } from './EditWalletForm/EditWalletForm';
import { EditWalletSuccess } from './EditWalletSuccess/EditWalletSuccess';

export const EditWallet: FC = () => {
  const { data: currentUserData, isLoading: isGetCurrentUserLoading } =
    useGetCurrentUser();
  const {
    data: walletData,
    isLoading: isGetWalletAddressLoading,
    failureCount
  } = useGetWalletAddress({
    retry: true,
    cacheTime: 0
  });

  const isLoading = isGetCurrentUserLoading || isGetWalletAddressLoading;

  return (
    <Stepper
      steps={[
        <EditWalletForm
          defaultValues={{ walletAddress: walletData?.heliumWalletId || '' }}
        />,
        <EditWalletEmailValidation email={currentUserData?.email} />,
        <EditWalletSuccess />
      ]}
    >
      {(step) => (
        <DashboardLayout>
          <DashboardContentWrapper>
            <DashboardBox
              isFullHeight
              isLoading={isLoading && failureCount < 1}
            >
              <Heading size="h5" element="h1" variant="black">
                <FormattedMessage
                  id="editWallet.title"
                  defaultMessage="Update wallet address"
                />
              </Heading>
              {step}
            </DashboardBox>
          </DashboardContentWrapper>
        </DashboardLayout>
      )}
    </Stepper>
  );
};
