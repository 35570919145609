import styled, { css } from 'styled-components';

import { getTypography } from '../../../../shared/utils/style-utils';

export const menuItemStyle = css<{ $isActive?: boolean; variant?: string }>`
  width: 100%;
  height: 40px;

  border: none;
  cursor: pointer;
  appearance: none;
  border-radius: 22px;
  text-align: left;

  ${({ theme }) => getTypography(theme.typography.subtitle.small)};
  font-family: inherit;
  font-weight: 600;

  color: #fff;

  display: flex;
  align-items: center;

  padding: ${({ theme }) => theme.padding.s};

  user-select: none;

  ${({ theme, variant }) =>
    variant === 'green'
      ? css`
          background-color: ${theme.color.green};
          color: ${theme.color.darkBlue};
        `
      : css`
          background-color: transparent;
        `}

  ${({ $isActive }) =>
    $isActive &&
    css`
      background-color: ${({ theme }) => theme.color.darkBlue};
      color: #fff;
    `}

  &:hover {
    background-color: ${({ theme }) => theme.color.hoverBlue};
    color: #fff;
  }

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    display: flex;
    justify-content: center;

    width: auto;
  }
`;

export const NavbarMenuListItem = styled.li``;

export const StyledNavbarMenuItem = styled.button`
  ${menuItemStyle}
`;

export const TextWrapper = styled.span`
  @media (${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }
`;
