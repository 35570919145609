import { FC } from 'react';

import { Bar, Progress } from './ProgressBar.styles';
import { ProgressBarProps } from './ProgressBar.types';

export const ProgressBar: FC<ProgressBarProps> = ({
  color,
  progress = 0,
  variant = 'light',
  ...props
}) => {
  return (
    <Bar variant={variant} {...props}>
      <Progress color={color} progress={progress} />
    </Bar>
  );
};
