import { FC } from 'react';

import { ArrowContainer, Background } from './CarouselArrow.styles';
import { CarouselArrowProps } from './CarouselArrow.types';
import { ReactComponent as ArrowLeft } from './svg/ArrowLeft.svg';
import { ReactComponent as ArrowRight } from './svg/ArrowRight.svg';

export const CarouselArrow: FC<CarouselArrowProps> = ({
  disabled,
  left,
  right,
  withBackground,
  onClick
}) => {
  return (
    <>
      {withBackground && <Background left={left} right={right} />}
      <ArrowContainer
        left={left}
        right={right}
        disabled={disabled}
        onClick={onClick}
      >
        {left && <ArrowLeft />}
        {right && <ArrowRight />}
      </ArrowContainer>
    </>
  );
};
