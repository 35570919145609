import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Icon } from '../../../components/Icon';
import {
  CopyTooltipAnchor,
  InnerContainer,
  ReferralInputColumn,
  ReferralsCountSection,
  StyledDashboardBox,
  StyledHeading,
  StyledInput,
  StyledReferralsCount
} from './ReferAFriend.styles';
import { Text } from '../../../components/Typography';
import {
  Coolspot,
  CoolspotResponse
} from '../../../shared/api/Wallet/Wallet.types';
import { useGetCurrentUser } from '../../../shared/api/Identity/Identity.queries';
import { CopyBoxTooltip } from '../../../components/CopyBox/CopyBox.styles';
import {
  TooltipAlignment,
  TooltipPosition
} from '../../../components/Tooltip/Tooltip.types';
import { theme } from '../../../themes/main';
import { useBreakpoints } from '../../../shared/utils/use-breakpoints';

interface ReferAFriendProps {
  coolspotsData?: CoolspotResponse;
  isCoolspotsDataLoading: boolean;
}

export const ReferAFriend: FC<ReferAFriendProps> = ({
  coolspotsData,
  isCoolspotsDataLoading
}) => {
  const { formatMessage } = useIntl();
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const referralInputRef = useRef<HTMLInputElement>(null);
  const { data: currentUserData, isLoading: isGetCurrentUserLoading } =
    useGetCurrentUser();
  const { isTablet: isMobileDevice } = useBreakpoints();

  const referralCount = useMemo(
    () =>
      coolspotsData?.entities?.filter(
        ({ isReferralVisible }: Coolspot) => isReferralVisible
      ).length,
    [coolspotsData]
  );

  const handleCopy = () => {
    if (referralInputRef && referralInputRef?.current) {
      referralInputRef.current.select();
      navigator.clipboard.writeText(referralInputRef.current.value);

      setShowTooltip(true);

      setTimeout(() => {
        setShowTooltip(false);
      }, 2000);
    }
  };

  const getReferralLink = useCallback(() => {
    return `${window.location.origin}/sign-up?ref=${currentUserData?.uuid}`;
  }, [currentUserData]);

  return (
    <StyledDashboardBox
      isLoading={isGetCurrentUserLoading || isCoolspotsDataLoading}
    >
      <InnerContainer>
        <StyledHeading size="h5" element="h4">
          <FormattedMessage
            defaultMessage="Earn extra 5% from every Coolspot™ that is referred to your invited friend."
            id="dashboard.referAFriendHeading"
          />
        </StyledHeading>
        <ReferralInputColumn>
          <StyledInput
            iconRight="FileCopy"
            ref={referralInputRef}
            label={formatMessage({
              defaultMessage: 'Copy and send the link to your friends',
              id: 'dashboard.referAFriendShare'
            })}
            placeholder={formatMessage({
              defaultMessage: 'Copy and send the link to your friends',
              id: 'dashboard.referAFriendShare'
            })}
            onIconClick={handleCopy}
            readOnly
            value={getReferralLink()}
          />
          <CopyTooltipAnchor>
            {showTooltip && (
              <CopyBoxTooltip
                align={TooltipAlignment.Center}
                position={
                  isMobileDevice ? TooltipPosition.TopLeft : TooltipPosition.Top
                }
              >
                <Icon icon="Tick2" color={theme.color.green} />
                <FormattedMessage
                  id="copyBox.copySuccessMessage"
                  defaultMessage="Copied to Clipboard!"
                />
              </CopyBoxTooltip>
            )}
          </CopyTooltipAnchor>
        </ReferralInputColumn>
        <ReferralsCountSection>
          <Icon icon="CoolSpot" />
          <Text level="big">
            <StyledReferralsCount>{referralCount}</StyledReferralsCount>{' '}
            <FormattedMessage
              id="dashboard.referAFriendCount"
              defaultMessage="Referred {referralCount, plural, one {Device} other {Devices}}"
              values={{ referralCount }}
            />
          </Text>
        </ReferralsCountSection>
      </InnerContainer>
    </StyledDashboardBox>
  );
};
