import { forwardRef, Fragment, useState } from 'react';

import { RadioGroupWrapper, RadioInput, RadioLabel } from './RadioGroup.styles';
import { RadioGroupProps, RadioGroupVariant } from './RadioGroup.types';

export const RadioGroup = forwardRef<HTMLInputElement, RadioGroupProps>(
  (
    {
      defaultValue,
      horizontal,
      name,
      onBlur,
      onChange,
      options,
      variant = RadioGroupVariant.Default
    },
    ref
  ) => {
    const [checkedValue, setCheckedValue] = useState(defaultValue);

    return (
      <RadioGroupWrapper horizontal={horizontal} variant={variant}>
        {options.map(({ value, label }) => (
          <Fragment key={value}>
            <RadioInput
              ref={ref}
              id={value}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              type="radio"
              value={value}
              variant={variant}
              checked={checkedValue === value}
              onClick={() => setCheckedValue(value)}
            />
            <RadioLabel htmlFor={value} variant={variant}>
              {label}
            </RadioLabel>
          </Fragment>
        ))}
      </RadioGroupWrapper>
    );
  }
);
