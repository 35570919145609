import styled, { css } from 'styled-components';

export const LoaderWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  flex: 2.3;
  margin-top: ${({ theme }) => theme.margin.l};
  min-height: 640px;
`;

export const MapContainer = styled.div<{ hasDevices: boolean }>`
  flex-grow: 1;
  margin-top: ${({ theme }) => theme.margin.m};
  position: relative;
  flex: 1;

  ${({ hasDevices }) =>
    !hasDevices &&
    css`
      flex: 0;
    `}

  ${({ hasDevices }) =>
    hasDevices &&
    css`
      @media (${({ theme }) => theme.breakpoints.desktop}) {
        max-width: 85%;
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
      }

      @media (${({ theme }) => theme.breakpoints.phone}) {
        max-width: 100%;
        padding-left: ${({ theme }) => theme.padding.s};
        padding-right: ${({ theme }) => theme.padding.s};
      }
    `}}
`;

export const MapWrapper = styled.div`
  width: 100%;
  height: calc(100% - 112px);
  margin-top: ${({ theme }) => theme.margin.m};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    height: calc(100% - 10px);
  }

  & > div {
    border-top-right-radius: ${({ theme }) => theme.borderRadius};
    border-top-left-radius: ${({ theme }) => theme.borderRadius};
    overflow: hidden;
  }

  .device-marker {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background-color: ${({ theme }) => theme.color.blue};
    cursor: pointer;
  }

  .mapboxgl {
    &-ctrl {
      & button:hover {
        background-color: #fff;
      }
    }

    &-ctrl-group {
      background: none;
      box-shadow: none !important;

      & > button {
        border-radius: ${({ theme }) => theme.borderRadius};
      }

      & button + button {
        border-top: none;
      }
    }

    &-ctrl-geolocate {
      & > .mapboxgl-ctrl-icon,
      & > .mapboxgl-ctrl-icon:hover {
        background-color: transparent;
        border: 0;
        box-shadow: none !important;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z' stroke='%239E9E9E' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M9.99992 16.6667C13.6818 16.6667 16.6666 13.6819 16.6666 10C16.6666 6.31811 13.6818 3.33334 9.99992 3.33334C6.31802 3.33334 3.33325 6.31811 3.33325 10C3.33325 13.6819 6.31802 16.6667 9.99992 16.6667Z' stroke='%239E9E9E' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10 1.66666V3.33332' stroke='%239E9E9E' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10 16.6667V18.3333' stroke='%239E9E9E' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M16.6667 10H18.3334' stroke='%239E9E9E' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1.66675 10H3.33341' stroke='%239E9E9E' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") !important;
      }
    }

    &-ctrl-icon {
      width: 40px;
      height: 40px;
      background-color: #fff;
      box-shadow: ${({ theme }) => theme.shadow.white.s} !important;
      border-radius: ${({ theme }) => theme.borderRadius} !important;
      margin-bottom: ${({ theme }) => theme.margin.s};

      &:hover {
        background-color: #fff;
      }
    }

    &-ctrl-zoom-in {
      & > .mapboxgl-ctrl-icon {
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 4.16667V15.8333' stroke='%239E9E9E' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M4.16675 10H15.8334' stroke='%239E9E9E' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") !important;
      }
    }

    &-ctrl-zoom-out {
      & > .mapboxgl-ctrl-icon {
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.16675 10H15.8334' stroke='%239E9E9E' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") !important;
      }
    }

    &-user-location-dot {
      position: relative;
      width: 40px;
      height: 40px;
      padding: 10px;
      margin-top: -20px;
      border-radius: ${({ theme }) => theme.borderRadius};
      background-color: #fff;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.16667 10H2.5L10 2.5L17.5 10H15.8333' stroke='%239E9E9E' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M4.1665 10V15.8333C4.1665 16.2754 4.3421 16.6993 4.65466 17.0118C4.96722 17.3244 5.39114 17.5 5.83317 17.5H14.1665C14.6085 17.5 15.0325 17.3244 15.345 17.0118C15.6576 16.6993 15.8332 16.2754 15.8332 15.8333V10' stroke='%239E9E9E' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.5 17.5V12.5C7.5 12.058 7.6756 11.6341 7.98816 11.3215C8.30072 11.0089 8.72464 10.8333 9.16667 10.8333H10.8333C11.2754 10.8333 11.6993 11.0089 12.0118 11.3215C12.3244 11.6341 12.5 12.058 12.5 12.5V17.5' stroke='%239E9E9E' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-size: 20px 20px;
      background-position: center;

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: unset;
        bottom: -13px;
        left: calc(50% - 8px);
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 13px solid #fff;
        border-radius: 0;
        box-shadow: none;
      }

      &::before {
        content: unset;
      }
    }
  }
`;

export const navControlStyle = {
  right: 10,
  bottom: 8
};

export const geolocateControlStyle = {
  right: 10,
  bottom: 120
};
