import { ReactElement } from 'react';

export enum ErrorPageType {
  LinkInvalid,
  OrderNotFound,
  Default
}

export type CopyElement = {
  header?: ReactElement;
  copy?: ReactElement;
  subtitle?: ReactElement;
};

export type Copies = {
  [key in ErrorPageType]: CopyElement;
};

export type ErrorPageProps = {
  isOverlay?: boolean;
  type?: ErrorPageType;
};
