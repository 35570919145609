import * as yup from 'yup';
import { useIntl } from 'react-intl';

export const useNewPasswordValidation = () => {
  const { formatMessage } = useIntl();

  return yup.object().shape({
    newPassword: yup
      .string()
      .test({
        message: formatMessage({
          defaultMessage:
            'Password must contain minimum 8 characters, one number, one special character and at least one capitalise',
          id: 'createAccount.passwordFieldHint'
        }),
        name: 'strength',
        test: (value) => {
          if (!value) {
            return false;
          }

          // eslint-disable-next-line array-element-newline
          const tests = ['[a-z]+', '[A-Z]+', '\\W+', '[0-9]+', '^.{8,}'];
          let passed = 0;

          tests.forEach((test, index) => {
            if (new RegExp(tests[index]).test(value)) {
              passed += 1;
            }
          });

          return passed === 5;
        }
      })
      .test({
        message: formatMessage({
          defaultMessage: 'Password contains disallowed characters',
          id: 'createAccount.passwordSpecialCharaxters'
        }),
        name: 'passwordCharactersValidation',
        test: (value) => {
          if (value?.match(/[^!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~\w]/)) {
            return false;
          }

          return true;
        }
      })
  });
};
