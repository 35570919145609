import styled, { css } from 'styled-components';

import { Label } from '../Label/Label';
import { DeviceStatus, StatusProps } from './Status.types';

export const StatusLabel = styled(Label)<StatusProps>`
  ${({ status }) =>
    status === DeviceStatus.Online &&
    css`
      background-color: #e1fcf2;
    `}
  ${({ status }) =>
    status === DeviceStatus.Offline &&
    css`
      background-color: #fde8e8;
    `}
`;

export const StatusIndicator = styled.span<StatusProps>`
  border-radius: 99px;
  width: 8px;
  height: 8px;

  ${({ status }) =>
    status === DeviceStatus.Online &&
    css`
      background-color: ${({ theme }) => theme.color.green};
    `}
  ${({ status }) =>
    status === DeviceStatus.Offline &&
    css`
      background-color: ${({ theme }) => theme.color.secondaryRed};
    `}
`;
