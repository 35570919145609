import { FC, useState } from 'react';

import { ChildWrapper, ChildWrapperInner } from './Accordion.styles';
import { AccordionProps } from './Accordion.types';
import { AccordionHeader } from './AccordionHeader/AccordionHeader';

export const Accordion: FC<AccordionProps> = ({
  children,
  renderHeader,
  title,
  isOpenByDefault = false
}) => {
  const [isExpanded, setIsExpanded] = useState(isOpenByDefault);

  const handleOnClick = () => setIsExpanded(!isExpanded);

  return (
    <>
      {typeof renderHeader === 'function' ? (
        renderHeader({ isExpanded, onClick: handleOnClick })
      ) : (
        <AccordionHeader
          title={title}
          isActive={isExpanded}
          onClick={handleOnClick}
        />
      )}
      <ChildWrapper>
        <ChildWrapperInner isExpanded={isExpanded}>
          {children}
        </ChildWrapperInner>
      </ChildWrapper>
    </>
  );
};
