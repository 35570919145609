import { FC } from 'react';

import { StyledHeading } from './Heading.styles';
import { HeadingProps } from './Heading.types';

export const Heading: FC<HeadingProps> = ({
  align,
  children,
  element,
  size,
  variant,
  bold = false,
  whiteSpace,
  ...rest
}) => {
  return (
    <StyledHeading
      size={size}
      align={align}
      variant={variant}
      as={element || size}
      bold={bold}
      whiteSpace={whiteSpace}
      {...rest}
    >
      {children}
    </StyledHeading>
  );
};
