import styled from 'styled-components';

import { DashboardBox } from '../../../../components/DashboardBox';
import { Caption, Text } from '../../../../components/Typography';

export const StyledDashboardBox = styled(DashboardBox)`
  position: relative;
  display: flex;
  gap: 20px;
  padding: 20px;
  background: ${({ theme }) => theme.color.lightGrey};
  white-space: normal;

  min-height: 96px;
`;

export const DataPane = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: calc(100% - 128px);
`;

export const IconPane = styled.div`
  display: flex;
  flex: 0;
  width: 28px;
  padding-top: 18px;
`;

export const Label = styled(Text).attrs({ level: 'small' })`
  line-height: 1.2em;
`;

export const OrderIdLabel = styled(Text).attrs({ level: 'big' })`
  font-weight: 700;
`;

export const StyledAddress = styled(Caption)`
  display: block;
  overflow: hidden;
  color: ${({ theme }) => theme.color.grey};
  line-height: 1.3em;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StatusPane = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  width: 80px;
  text-align: right;
`;

export const StatusLabel = styled(Text).attrs({ level: 'big' })`
  margin-bottom: 8px;
  text-transform: capitalize;
`;
