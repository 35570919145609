import styled, { css } from 'styled-components';

import { getTypography } from '../../../shared/utils/style-utils';
import TickIcon from './Tick.svg';

export const RadioGroupWrapper = styled.div`
  align-items: flex-start;
  position: relative;
`;

export const ProductVariantWrapper = styled.div`
  padding: ${({ theme }) => `${theme.padding.s} ${theme.padding.xs}`};
`;

export const RadioInput = styled.input`
  position: absolute;
  width: 0;
  height: 0;
  display: none;
`;

interface RadioLabelProps {
  isActive?: boolean;
}

export const RadioLabel = styled.label<RadioLabelProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 136px;
  margin-bottom: 56px;
  gap: ${({ theme }) => theme.margin.xs};
  border-radius: ${({ theme }) => theme.borderRadius};
  background: ${({ theme }) => theme.color.secondaryWhiteBlue};
  color: ${({ theme }) => theme.color.grey};
  transition: all 0.2s ease-in-out, margin-bottom ease-out 150ms;

  // override global styles
  &,
  * {
    cursor: pointer;
  }

  &:hover,
  ${RadioInput}:focus + & {
    box-shadow: ${({ theme }) => theme.shadow.blue.m};
    background: ${({ theme }) => theme.color.white};
    margin-bottom: 0;
  }

  ${({ isActive, theme }) =>
    isActive &&
    css`
      box-shadow: ${theme.shadow.blue.m};
      background: ${theme.color.white};
      margin-bottom: 0;
    `};

  ${RadioInput}:checked + & {
    background: ${({ theme }) => theme.color.white};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.color.green},
      ${({ theme }) => theme.shadow.blue.m};
    margin-bottom: 0;
  }
`;

interface HighlightBoxProps {
  isActive?: boolean;
}

export const HighlightBox = styled.div<HighlightBoxProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 0;

  border-top-left-radius: ${({ theme }) => theme.borderRadius};
  border-top-right-radius: ${({ theme }) => theme.borderRadius};
  background: ${({ theme }) => theme.color.lightGrey};
  transition: height ease-out 150ms, color ease-out 500ms;
  overflow: hidden;

  ${RadioLabel}:hover &,
  ${RadioInput}:focus + ${RadioLabel} & {
    height: 56px;
    margin-bottom: 0;
    background: ${({ theme }) => theme.color.lightGrey};
  }

  ${({ isActive, theme }) =>
    isActive &&
    css`
      height: 56px;
      margin-bottom: 0;
      background: ${theme.color.lightGrey};
    `};

  ${RadioInput}:checked + ${RadioLabel} & {
    height: 56px;
    margin-bottom: 0;
    background: ${({ theme }) => theme.color.green};
  }
`;

export const Highlight = styled.h1`
  margin: 0;
  ${({ theme }) => getTypography(theme.typography.button.small)};
  color: ${({ theme }) => theme.color.grey};
  text-align: center;
  text-transform: uppercase;
`;

export const ContentWrapper = styled.div<{
  isVisible?: boolean;
}>`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  flex-direction: column;
  padding: 0 ${({ theme }) => theme.padding.l};
`;

export const Header = styled.h2`
  width: 100%;
  margin: 0;
  margin-bottom: ${({ theme }) => theme.margin.xs};
  ${({ theme }) => getTypography(theme.typography.heading.h5)};
  color: ${({ theme }) => theme.color.black};
  font-weight: 400;
  text-align: center;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 18px;
  }
`;

export const Price = styled.p`
  width: 100%;
  margin: 0;
  ${({ theme }) => getTypography(theme.typography.heading.h4)};
  color: ${({ theme }) => theme.color.blue};
  font-weight: 700;
  text-align: center;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 18px;
  }
`;

export const Subheader = styled.h4`
  width: 100%;
  margin: 0;
  ${({ theme }) => getTypography(theme.typography.subtitle.big)};
  color: ${({ theme }) => theme.color.blue};
  font-weight: 400;
  text-align: center;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 18px;
  }
`;

export const Copy = styled.div`
  margin: 0;
  margin-top: ${({ theme }) => theme.margin.xs};
  ${({ theme }) => getTypography(theme.typography.body.big)};
  color: ${({ theme }) => theme.color.grey};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 14px;
  }

  p {
    margin: 0;
  }

  ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
  }

  li {
    display: flex;
    ${({ theme }) => getTypography(theme.typography.body.small)};

    &:before {
      content: ' ';
      display: block;
      flex: 0 0 auto;
      width: 13px;
      height: ${({ theme }) => theme.typography.body.small.lineHeight};
      margin-right: ${({ theme }) => theme.margin.s};
      background-image: url(${TickIcon});
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &:not(:last-child) {
      margin-bottom: ${({ theme }) => theme.margin.xs};
    }
  }
`;
