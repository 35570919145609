import { FC, useEffect } from 'react';

import { NotificationType } from './Alert.types';
import { Alert } from './Alert';
import { NotificationContainer } from './Notification.styles';

export const Notification: FC<NotificationType> = ({
  text,
  variant,
  autoRemove = true,
  autoRemoveDelay = 5000,
  onClick
}) => {
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (autoRemove && onClick) {
      const interval = setInterval(() => {
        onClick();
      }, autoRemoveDelay);

      return () => {
        clearInterval(interval);
      };
    }
  }, [onClick, autoRemove, autoRemoveDelay]);

  return (
    <NotificationContainer mb="m">
      <Alert onClose={onClick} showCloseButton variant={variant}>
        {text}
      </Alert>
    </NotificationContainer>
  );
};
