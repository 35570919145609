import { ReactNode } from 'react';

export enum TooltipAlignment {
  Start = 'start',
  Center = 'center',
  End = 'end'
}

export enum TooltipActiveMethod {
  Click = 'start',
  MouseOver = 'mouseover'
}
export enum TooltipPosition {
  Center = 'center',
  Top = 'top',
  TopLeft = 'top-left',
  TopRight = 'top-right',
  Right = 'right',
  BottomLeft = 'bottom-left',
  Bottom = 'bottom',
  BottomRight = 'bottom-right',
  Left = 'left'
}

export enum TooltipVariant {
  Default = 'default',
  blueBorder = 'blue-border'
}

export type StyledTooltipProps = {
  align?: TooltipAlignment;
  offset?: number;
  padding?: number;
  position?: TooltipPosition;
  variant?: TooltipVariant;
};

export type TooltipProps = {
  content: ReactNode;
  method?: TooltipActiveMethod;
} & StyledTooltipProps;
