import styled, { css, Sizing } from 'styled-components';

import { Box } from '../../components/Box';
import { Text } from '../../components/Typography';
import { getTypography } from '../../shared/utils/style-utils';

export const ImageBox = styled(Box)`
  display: flex;
  justify-content: center;
  margin: ${({ theme }) => theme.margin.xl} 0;
`;

export const StyledList = styled.ol`
  padding-left: 17px;
  margin: ${({ theme }) => theme.margin.m} 0;
`;

export const ListItem = styled.li`
  margin-bottom: ${({ theme }) => theme.margin.m};
  ${({ theme }) => getTypography(theme.typography.body.big)};
  color: ${({ theme }) => theme.color.grey};
`;

export const OrderNumberPanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  border: ${({ theme }) => theme.border(theme.color.blue)};
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: ${({ theme }) =>
    `${theme.shadow.white.m}, ${theme.shadow.white.xs}`};

  padding-top: ${({ theme }) => theme.padding.l};
  padding-right: ${({ theme }) => theme.padding.xl};
  padding-bottom: ${({ theme }) => theme.padding.l};
  padding-left: ${({ theme }) => theme.padding.xl};
`;

export const Label = styled(Text).attrs({ level: 'big' })`
  margin-bottom: ${({ theme }) => theme.margin.xxs};
  color: ${({ theme }) => theme.color.grey};
`;

export const Value = styled(Text).attrs({ level: 'big' })<{ type?: string }>`
  display: flex;
  align-items: center;
  ${({ type, theme }) =>
    type !== 'link' &&
    css`
      color: ${theme.color.black};
    `}
`;

interface IconWrapperProps {
  alingSelf?: 'flex-start';
  mr?: Sizing;
}

export const IconWrapper = styled.picture<IconWrapperProps>`
  display: flex;
  align-items: center;
  width: 20px;
  height: 24px;
  margin-right: ${({ theme, mr }) => theme.margin[mr || 'm']};

  ${({ alingSelf }) =>
    alingSelf &&
    css`
      align-self: ${alingSelf};
    `};
`;
