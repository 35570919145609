import styled, { css } from 'styled-components';

export const DashboardContentWrapper = styled.main`
  width: 85%;
  max-width: 1280px;
  margin: 0 auto;

  @media (${({ theme }) => theme.breakpoints.phone}) {
    width: 100%;
    padding-left: ${({ theme }) => theme.padding.s};
    padding-right: ${({ theme }) => theme.padding.s};
  }
`;

export const DashboardRow = styled.main<{
  deviceCount: number;
  isLoading: boolean;
  readyToOrderLeadOrdersCount: number;
}>`
  display: flex;
  flex-direction: row;
  position: relative;
  gap: 30px;

  width: 85%;
  max-width: 1280px;
  max-height: 720px;
  margin: 0 auto;

  ${({ deviceCount, isLoading }) =>
    !isLoading &&
    deviceCount === 0 &&
    css`
      max-height: initial;
      flex-direction: column;
    `}

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    ${({ deviceCount }) =>
      deviceCount > 0 &&
      css`
        width: 100%;
        max-width: 100%;
      `}
    max-height: none;
  }

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
  }

  @media (${({ theme }) => theme.breakpoints.phone}) {
    ${({ deviceCount, isLoading, readyToOrderLeadOrdersCount }) => {
      let minHeight = 120;

      if (isLoading) {
        minHeight = 500;
      } else if (readyToOrderLeadOrdersCount > 0) {
        minHeight = 690;
      } else if (deviceCount > 0) {
        minHeight = 660;
      }

      return css`
        min-height: ${minHeight}px;
      `;
    }}
  }
`;
