import { FC } from 'react';

import { FullPageLoader } from '../../../components/FullPageLoader';
import { SignUpLayout } from '../../../components/Layout';
import { Stepper } from '../../../components/Stepper';
import { useGetCountries } from '../../../shared/api/Order';
import { useAuth } from '../../../shared/auth/auth-context';
import { ErrorPage } from '../../ErrorPage';
import { ReadyToShip } from './ReadyToShip';
import { AccountHasBeenCreated } from './AccountHasBeenCreated';
import { CreateAccount } from './CreateAccount';

export const CreateAccountPage: FC = () => {
  const countryQuery = useGetCountries();

  const { login } = useAuth();

  if (countryQuery.isLoading || login.isLoading) {
    return <FullPageLoader />;
  }

  if (countryQuery.isError || countryQuery.isIdle) {
    return <ErrorPage />;
  }

  return (
    <Stepper
      steps={[<ReadyToShip />, <CreateAccount />, <AccountHasBeenCreated />]}
    >
      {(step) => <SignUpLayout>{step}</SignUpLayout>}
    </Stepper>
  );
};
