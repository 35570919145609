import { MockedRequest, rest } from 'msw';

import { userFixture, verifyUserFixture } from '../fixture/identity';

export const login = rest.post(
  '/identity/api/v2/auth/login',
  (req: MockedRequest<{ email: string }>, res, ctx) => {
    if (req.body.email === 'error@example.com') {
      return res(
        ctx.delay(1000),
        ctx.status(422),
        ctx.json({
          statusCode: 422,
          errorCode: 7,
          message: 'Validation failed',
          details: [{ errorCode: 105, message: 'Invalid password' }]
        })
      );
    }

    if (req.body.email === 'legacy@example.com') {
      return res(
        ctx.status(400),
        ctx.json({
          statusCode: 400,
          errorCode: 114,
          message: 'Email is associated with legacy user account'
        })
      );
    }

    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({ jwt: 'testJWT123' })
    );
  }
);

export const logout = rest.post(
  '/identity/api/v2/auth/logout',
  (req, res, ctx) => {
    return res(ctx.status(204));
  }
);

export const getCurrentUser = rest.get(
  '/identity/api/v2/users/me',
  (req, res, ctx) => {
    return res(ctx.delay(1000), ctx.status(200), ctx.json(userFixture));
  }
);

export const signUp = rest.post(
  '/identity/api/v2/auth/signup',
  (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({ jwt: 'testJWT123' })
    );
  }
);

export const userEmailVerify = rest.post(
  '/identity/api/v2/users/email/verify',
  (req, res, ctx) => {
    return res(ctx.delay(1000), ctx.status(204));
  }
);

export const waitlistEmailVerify = rest.post(
  '/identity/api/v2/waitlist/email/verify',
  (req, res, ctx) => {
    return res(ctx.delay(1000), ctx.status(204));
  }
);

export const waitlistEmailResend = rest.post(
  '/identity/api/v2/waitlist/email/resend',
  (req, res, ctx) => {
    return res(ctx.delay(1000), ctx.status(204));
  }
);

export const usersEmailResend = rest.post(
  '/identity/api/v2/users/email/resend',
  (req, res, ctx) => {
    return res(ctx.delay(1000), ctx.status(204));
  }
);

export const passwordCodeVerify = rest.post(
  '/identity/api/v2/auth/password/code/verify',
  (req, res, ctx) => {
    return res(ctx.delay(1000), ctx.status(204));
  }
);

export const legacyCodeVerify = rest.post(
  '/identity/api/v2/auth/legacy/code/verify',
  (req, res, ctx) => {
    return res(ctx.delay(1000), ctx.status(200), ctx.json(userFixture));
  }
);

export const passwordCodeSend = rest.post(
  '/identity/api/v2/auth/password/code/send',
  (req, res, ctx) => {
    return res(ctx.delay(1000), ctx.status(204));
  }
);

export const legacyCodeSend = rest.post(
  '/identity/api/v2/auth/legacy/code/send',
  (req, res, ctx) => {
    return res(ctx.delay(1000), ctx.status(204));
  }
);

export const passwordReset = rest.post(
  '/identity/api/v2/auth/password/reset',
  (req, res, ctx) => {
    return res(ctx.delay(1000), ctx.status(204));
  }
);

export const verifyEmail = rest.post(
  '/identity/api/v2/auth/email-verification',
  (req, res, ctx) => {
    return res(ctx.delay(1000), ctx.status(200), ctx.json(verifyUserFixture));
  }
);

export const addToWaitlist = rest.post(
  '/identity/api/v2/waitlist',
  (req, res, ctx) => {
    return res(ctx.delay(1000), ctx.status(204));
  }
);
