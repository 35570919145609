import styled, { css } from 'styled-components';

import { getTypography } from '../../shared/utils/style-utils';
import { DashboardBoxWrapperProps } from './DashboardBox.types';

export const DashboardBoxCenterWrapper = styled.div<{ contentWidth?: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: normal;

  ${({ contentWidth }) =>
    Number.isFinite(contentWidth) &&
    css`
      width: 100%;
      max-width: ${contentWidth}px;
      margin: 0 auto;
    `}
`;
export const DashboardBoxWrapper = styled.section<DashboardBoxWrapperProps>`
  ${({ variant }) =>
    variant === 'blue' &&
    css`
      background-color: ${({ theme }) => theme.color.hoverBlue};
      color: ${({ theme }) => theme.color.white};
    `}
  ${({ variant }) =>
    variant === 'grey' &&
    css`
      background-color: ${({ theme }) => theme.color.secondaryWhiteBlue};
    `}
  ${({ variant }) =>
    variant === 'white' &&
    css`
      background-color: ${({ theme }) => theme.color.white};
    `}
  border-radius: ${({ theme }) => theme.borderRadius};

  min-height: ${({ isFullHeight }) =>
    isFullHeight ? 'calc(100vh - 186px)' : '186px'};

  box-shadow: ${({ theme }) => theme.shadow.white.s};

  padding: ${({ isFullHeight, theme }) =>
    isFullHeight ? '32px' : theme.padding.l};

  ${({ disableSidePadding }) =>
    disableSidePadding &&
    css`
      padding-left: 0;
      padding-right: 0;
    `}

  ${({ isFullHeight }) =>
    isFullHeight &&
    css`
      display: flex;
      flex-direction: column;

      ${DashboardBoxCenterWrapper} {
        flex: 1;
      }
    `}
  flex: 1;

  ${({ isFullHeight }) =>
    !isFullHeight &&
    css`
      @media (${({ theme }) => theme.breakpoints.desktop}) {
        min-height: auto;
      }
    `}
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  align-self: center;
`;

export const BackButton = styled.button`
  display: flex;
  align-items: center;
  font-weight: 700;

  font-family: inherit;
  ${({ theme }) => getTypography(theme.typography.body.big)};

  border: 0;
  background: 0;
  color: ${({ theme }) => theme.color.blue};

  svg {
    margin-right: ${({ theme }) => theme.margin.xxs};
  }
`;
