import { FC, useMemo } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { Accordion } from '../../../../components/Accordion';
import { Box } from '../../../../components/Box';
import { EllipsizedText } from '../../../../components/EllipsizedText';
import { EmptyStateBox } from '../../../../components/EmptyStateBox';
import { Icon } from '../../../../components/Icon';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow
} from '../../../../components/Table';
import { PaymentEntity } from '../../../../shared/api/Wallet/Wallet.types';
import { formatHelium } from '../../../../shared/utils/format-helium';
import { theme } from '../../../../themes/main';
import {
  AccordionHeader,
  CenteredCopyBox,
  IconWrapper,
  PaymentTimeWrapper
} from './PayoutHistoryTable.styles';
import { PayoutHistoryTableProps } from './PayoutHistoryTable.types';

const sortByPaymentTime = (
  payoutHistory: Array<PaymentEntity>
): Array<PaymentEntity> =>
  payoutHistory.sort(
    (a, b) =>
      new Date(b.paymentTime).getTime() - new Date(a.paymentTime).getTime()
  );

export const PayoutHistoryTable: FC<PayoutHistoryTableProps> = ({
  payoutHistory
}) => {
  const newestFirstPayoutHistory = useMemo(
    () => sortByPaymentTime(payoutHistory || []),
    [payoutHistory]
  );

  if (!payoutHistory || !payoutHistory.length) {
    return (
      <EmptyStateBox direction="row">
        <IconWrapper>
          <Icon icon="Search" />
        </IconWrapper>
        <FormattedMessage
          id="payoutHistoryTable.emptyStateTitle"
          defaultMessage="You have no payouts to be displayed"
        />
      </EmptyStateBox>
    );
  }

  return (
    <Box mt="m">
      {newestFirstPayoutHistory.map(
        ({ paymentTime: paymentMonth, payments }, index) => (
          <Box mb="s">
            <Accordion
              isOpenByDefault={index === 0}
              key={paymentMonth}
              renderHeader={({ onClick, isExpanded }) => (
                <AccordionHeader onClick={onClick}>
                  <FormattedDate
                    value={paymentMonth}
                    month="long"
                    year="numeric"
                  />
                  {isExpanded ? (
                    <Icon icon="ChevronUp" />
                  ) : (
                    <Icon icon="ChevronDown" />
                  )}
                </AccordionHeader>
              )}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeadCell>
                      <FormattedMessage
                        id="payoutHistoryTable.dateOfPayment"
                        defaultMessage="Date of payment"
                      />
                    </TableHeadCell>
                    <TableHeadCell align="center">
                      <FormattedMessage
                        id="payoutHistoryTable.payoutAmount"
                        defaultMessage="Payout amount"
                      />
                    </TableHeadCell>
                    <TableHeadCell align="center">
                      <FormattedMessage
                        id="payoutHistoryTable.walletPaid"
                        defaultMessage="Wallet paid"
                      />
                    </TableHeadCell>
                    <TableHeadCell align="right">
                      <FormattedMessage
                        id="payoutHistoryTable.transactionId"
                        defaultMessage="Blockchain transaction ID"
                      />
                    </TableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payments.map(
                    ({
                      paymentTime,
                      paymentHash,
                      amount,
                      recipientWalletAddress
                    }) => (
                      <TableRow key={paymentHash}>
                        <TableCell>
                          <PaymentTimeWrapper>
                            <Icon icon="ArrowDown" color={theme.color.green} />
                            <FormattedDate
                              value={paymentTime}
                              year="numeric"
                              month="2-digit"
                              day="2-digit"
                            />
                          </PaymentTimeWrapper>
                        </TableCell>
                        <TableCell align="center">
                          {formatHelium(amount, { fractionDigits: 4 })}
                        </TableCell>
                        <TableCell align="center">
                          <CenteredCopyBox>
                            {recipientWalletAddress}
                          </CenteredCopyBox>
                        </TableCell>
                        <TableCell align="right">
                          <EllipsizedText maxLength={15}>
                            {paymentHash}
                          </EllipsizedText>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </Accordion>
          </Box>
        )
      )}
    </Box>
  );
};
