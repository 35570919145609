import styled from 'styled-components';

export const LayoutWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const LeftPanel = styled.div`
  position: relative;
  overflow: hidden;

  width: 50%;
  height: 100vh;
  background-color: ${({ theme }) => theme.color.blue};

  display: flex;
  align-items: center;
  justify-content: center;

  padding: ${({ theme }) => theme.padding.xl};

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    display: none;
  }
`;

export const RightPanel = styled.div`
  flex: 1 0;
  height: 100vh;
  overflow-y: scroll;

  display: flex;
  flex-direction: column;

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    height: auto;
    overflow: hidden;
  }
`;

export const StyledImage = styled.img`
  max-width: 100%;
`;

export const LogoWrapper = styled.div`
  position: absolute;
  top: 10%;
  left: 15%;
  max-width: 176px;
`;
