import styled, { css } from 'styled-components';

import { getTypography } from '../../shared/utils/style-utils';
import { Box } from '../Box';
import { AddressBoxProps } from './AddressBox.types';

export const StyledAddressBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 20px;

  svg {
    min-width: 20px;
    height: 20px;
  }
`;

export const Address = styled.span<Pick<AddressBoxProps, 'isSmall'>>`
  ${({ theme }) => getTypography(theme.typography.heading.h5)};

  ${({ isSmall }) =>
    isSmall &&
    css`
      ${({ theme }) => getTypography(theme.typography.body.big)};
    `}
`;
