import styled from 'styled-components';

export const StyledPin = styled.svg`
  & > path {
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }
`;
