import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';
import * as heliumCrypto from '@helium/crypto';
import { useHistory } from 'react-router-dom';

import { Box } from '../../../components/Box';
import { Button, InlineLinkButton } from '../../../components/Button';
import { BUTTON_SIZE } from '../../../components/Button/Button.types';
import { Input } from '../../../components/Input';
import { ContentWrapper } from '../../../components/Layout';
import { Heading, Subtitle, Text } from '../../../components/Typography';
import { GetWallet } from '../SignUp/SupportedCountry/GetWallet';
import { FormElementWrapper } from './AddWallet.styles';
import { StepProps } from '../../../components/Stepper';
import { useEditWallet } from '../../../shared/api/Wallet';
import { useAuth } from '../../../shared/auth/auth-context';
import { useNotification } from '../../../shared/utils/notifications/notification-context';
import { AlertVariant } from '../../../components/Alert/Alert.types';
import { useQueryParams } from '../../../shared/utils/use-query-params';

const schema = yup.object().shape({
  walletAddress: yup
    .string()
    .required('Wallet address is a required field')
    .test('id-correct-helium', 'Helium address incorrect', (value) => {
      if (!value) {
        return false;
      }

      return heliumCrypto.Address.isValid(value);
    })
});

export const AddWallet: FC<
  { isEditMode?: boolean; defaultValue?: string } & StepProps
> = ({
  handleNextStep,
  isEditMode = false,
  defaultValue = '',
  previousStepValues
}) => {
  const [showTutorial, setShowTutorial] = useState(false);
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { login } = useAuth();
  const { addNotification } = useNotification();
  const [params] = useQueryParams({
    user: ''
  });
  const isLegacyUser = params.user === 'legacy';

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: { walletAddress: defaultValue },
    mode: 'onBlur',
    resolver: yupResolver(schema)
  });

  const { mutate: editWallet, isLoading: isEditWalletLoading } = useEditWallet({
    onError: (error: { message: string }) => {
      addNotification({
        id: 'walletError',
        text: error.message,
        variant: AlertVariant.Danger
      });
    },
    onSuccess: () => {
      if (isEditMode) {
        history.push('/dashboard');
      }

      if (handleNextStep) {
        handleNextStep(previousStepValues);
      }
    }
  });

  const onSubmit = (data: { walletAddress: string }) => {
    editWallet({
      heliumWalletId: data.walletAddress,
      jwt: previousStepValues?.token ? previousStepValues.token : undefined
    });
  };

  const handleCancel = async () => {
    if (!isEditMode && previousStepValues) {
      await login.mutate({
        email: previousStepValues.email,
        password: previousStepValues.password
      });
    } else {
      history.push('/dashboard');
    }
  };

  if (showTutorial) {
    return <GetWallet onClose={() => setShowTutorial(false)} />;
  }

  const getTitle = () => {
    if (isLegacyUser) {
      return (
        <FormattedMessage
          id="addWallet.validateTitle"
          defaultMessage="Please validate or edit your Helium Wallet"
        />
      );
    }

    if (isEditMode && defaultValue) {
      return (
        <FormattedMessage
          id="addWallet.editTitle"
          defaultMessage="Edit your Helium Wallet address"
        />
      );
    }

    return (
      <FormattedMessage
        id="addWallet.title"
        defaultMessage="Add your Helium Wallet address"
      />
    );
  };

  return (
    <>
      <ContentWrapper>
        <Heading size="h4" element="h1" variant="grey">
          {getTitle()}
        </Heading>
        <Box mt="s">
          <Subtitle variant="grey" size="big">
            <FormattedMessage
              id="addWallet.subtitle"
              defaultMessage="Paste your wallet address into the field below"
            />
          </Subtitle>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormElementWrapper>
            <Input
              {...register('walletAddress')}
              iconLeft="Wallet"
              label={formatMessage({
                defaultMessage: 'Helium Wallet address',
                id: 'addWallet.label'
              })}
              placeholder={formatMessage({
                defaultMessage: 'Wallet address',
                id: 'addWallet.placeholder'
              })}
              error={errors.walletAddress?.message}
              prefilled={!!defaultValue}
            />
          </FormElementWrapper>
          <FormElementWrapper>
            <Button
              isPrimary
              fullWidth
              type="submit"
              isLoading={isEditWalletLoading}
            >
              <FormattedMessage id="addWallet.submit" defaultMessage="Submit" />
            </Button>
          </FormElementWrapper>
        </form>
        <Box mt="m">
          <Button
            onClick={() => handleCancel()}
            isPlain
            isPrimary={false}
            fullWidth
            size={BUTTON_SIZE.BIG}
          >
            <FormattedMessage id="addWallet.cancel" defaultMessage="Cancel" />
          </Button>
        </Box>
        {!isEditMode && (
          <Box mt="xl" pb="m">
            <Text type="default" level="big">
              <FormattedMessage
                id="addWallet.captionText"
                defaultMessage="Don’t have the wallet address? "
              />
              <InlineLinkButton
                type="button"
                onClick={() => setShowTutorial(true)}
              >
                <FormattedMessage
                  id="addWallet.captionLink"
                  defaultMessage="Learn how to get it"
                />
              </InlineLinkButton>
            </Text>
          </Box>
        )}
      </ContentWrapper>
    </>
  );
};
