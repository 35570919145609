import { FC, KeyboardEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { AlertVariant } from '../../../../../components/Alert/Alert.types';
import { Input } from '../../../../../components/Input';
import { Box } from '../../../../../components/Box';
import { Button } from '../../../../../components/Button';
import { ModalHeader } from '../../../../../components/Modal/Modal.styles';
import { Text } from '../../../../../components/Typography';
import { useNotification } from '../../../../../shared/utils/notifications/notification-context';
import { useCreateUserRequest } from '../../../../../shared/api/Identity/Identity.queries';
import { UserRequestType } from '../../../../../shared/api/Identity/Identity.types';
import { FieldWrapper, ModalCopy, UnitLabel } from '../AntennaModal.styles';
import { AntennaDbiFormProps, AntennaFormData } from './AntennaDbiForm.types';
import { useAntennaDbiValidation } from './AntennaDbiForm.validation';

export const AntennaDbiForm: FC<AntennaDbiFormProps> = ({
  deviceId,
  isSubmitted,
  setIsOpen,
  setIsSubmitted
}) => {
  const { formatMessage } = useIntl();
  const { addNotification } = useNotification();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(useAntennaDbiValidation())
  });

  const { mutate: sendRequest, isLoading } = useCreateUserRequest({
    onSuccess: () => {
      setIsSubmitted(true);
    },
    onError: (error) => {
      if (error.data) {
        addNotification({
          id: 'verificationError',
          text: error.data.message,
          variant: AlertVariant.Danger
        });
      }
    }
  });

  const onSubmit = async (data: AntennaFormData) => {
    const { value } = data;

    sendRequest({
      requestType: UserRequestType.ChangeAntennaDbi,
      data: {
        coolspotId: deviceId,
        dbi: value
      }
    });
  };

  if (isSubmitted) {
    return (
      <Box>
        <ModalHeader center>
          <FormattedMessage
            id="changeElevation.successMessage"
            defaultMessage="Your request has been sent."
          />
        </ModalHeader>
        <ModalCopy>
          <FormattedMessage
            id="changeElevation.successCopy"
            defaultMessage="It may take up to 30 business days to see your device info updated"
          />
        </ModalCopy>
        <Box mt="l">
          <Button
            isPrimary
            fullWidth
            type="button"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <FormattedMessage id="done" defaultMessage="Done" />
          </Button>
        </Box>
      </Box>
    );
  }

  const onKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (!event.key.match(/^([0-9]|\.|,)+$/)) {
      event.preventDefault();
    }
  };

  return (
    <Box>
      <ModalHeader hasCloseButton>
        <FormattedMessage
          id="assignAntenna.header"
          defaultMessage="Assign a 3rd Party Antenna"
        />
      </ModalHeader>
      <Text level="small">
        <FormattedMessage
          id="assignAntenna.copy"
          defaultMessage="If you have purchased a 3rd party antenna, you can enter the dBi or antenna gain here to request Emrit make an update to the device on the Helium blockchain. If you are using the CoolSpot’s™ original antenna you do not need to take any action."
        />
      </Text>
      <Box mt="m">
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <FieldWrapper>
            <Input
              {...register('value')}
              label={formatMessage({
                defaultMessage: 'Antenna',
                id: 'assignAntenna.label'
              })}
              placeholder={formatMessage({
                defaultMessage: 'Type in the value',
                id: 'assignAntenna.inputPlaceholder'
              })}
              type="number"
              error={errors.value?.message}
              onKeyPress={onKeyPress}
            />
            <UnitLabel>dBi</UnitLabel>
          </FieldWrapper>
          <Box mt="l">
            <Button isPrimary fullWidth isLoading={isLoading} type="submit">
              <FormattedMessage
                id="assignAnntenna.send"
                defaultMessage="Send a request"
              />
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};
