import { FC } from 'react';

import {
  StyledBottomSection,
  StyledContentWrapper
} from './BottomSection.styles';

export const BottomSection: FC = ({ children, ...rest }) => {
  return (
    <StyledBottomSection {...rest}>
      <StyledContentWrapper hideLogo>{children}</StyledContentWrapper>
    </StyledBottomSection>
  );
};
