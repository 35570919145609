import { LetMeKnowNextTime } from './features/ApplyForACoolspot/LetMeKnowNextTime';
import { Feedback } from './features/Auth/Feedback';
import { ForgotPassword } from './features/Auth/ForgotPassword';
import { LegacySignUp } from './features/Auth/LegacySignUp';
import { Login, LoginEmailValidate } from './features/Auth/Login';
import { NotSupportedCountry } from './features/Auth/SignUp/NotSupportedCountry';
import { SupportedCountry } from './features/Auth/SignUp/SupportedCountry';
import { CreateAccountPage } from './features/BecomeAHost/CreateAccount';
import { CreateLeadUser } from './features/BecomeAHost/CreateLeadUser';
import { OrderACoolspot } from './features/OrderACoolspot';
import { RemoveFromWaitList } from './features/RemoveFromWaitList';
import { TermsAndConditions } from './features/TermsAndConditions';

export const unauthorizedRoutes = [
  { path: '/forgot-password', component: ForgotPassword },
  { path: '/login', component: Login },
  { path: '/feedback', component: Feedback },
  { path: '/sign-up', component: CreateLeadUser },
  { path: '/sign-up/create-account', component: CreateAccountPage },
  { path: '/verify-email', component: LoginEmailValidate },
  { path: '/legacy-sign-up', component: LegacySignUp },
  { path: '/sign-up/country-supported', component: SupportedCountry },
  { path: '/sign-up/country-not-supported', component: NotSupportedCountry },
  { path: '/let-me-know-later', component: LetMeKnowNextTime },
  { path: '/remove-from-wait-list', component: RemoveFromWaitList },
  { path: '/order-a-coolspot', component: OrderACoolspot },
  { path: '/terms-and-conditions', component: TermsAndConditions }
];
