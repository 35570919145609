import { forwardRef, useState } from 'react';
import VisuallyHidden from '@reach/visually-hidden';
import { FormattedMessage } from 'react-intl';

import { Input } from '../Input';
import { Icon } from '../../Icon';
import { StyledEyeButton } from './PasswordInput.styles';
import { InputProps } from '../Input.types';

export const PasswordInput = forwardRef<HTMLInputElement, InputProps>(
  ({ error, caption, progress, ...props }, ref) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
      <>
        <Input
          type={showPassword ? 'text' : 'password'}
          caption={caption}
          progress={progress}
          iconLeft="Lock"
          ref={ref}
          error={error}
          iconRight={
            <StyledEyeButton
              type="button"
              onClick={() => setShowPassword(!showPassword)}
            >
              <Icon aria-hidden icon={showPassword ? 'Eye' : 'EyeOff'} />
              <VisuallyHidden>
                <FormattedMessage
                  id="showPasswordButton"
                  defaultMessage="Show password"
                />
              </VisuallyHidden>
            </StyledEyeButton>
          }
          {...props}
        />
      </>
    );
  }
);
