import { FC } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import Skeleton from 'react-loading-skeleton';

import { Heading, Subtitle, Text } from '../../../../components/Typography';
import {
  StyledBox,
  StyledEarningsTooltip,
  StyledPrice,
  TooltipIconWrapper,
  TooltipWrapper,
  Wrapper
} from './CurrentEarnings.styles';
import { CurrentEarningsProps } from './CurrentEarnings.types';
import { ExchangeValue } from '../../ExchangeValue/ExchangeValue';
import { Icon } from '../../../../components/Icon';
import { theme } from '../../../../themes/main';
import { useGetTotalEarningsData } from '../../../../shared/api/Wallet/Wallet.queries';
import { formatHelium } from '../../../../shared/utils/format-helium';
import { useBreakpoints } from '../../../../shared/utils/use-breakpoints';
import {
  TooltipActiveMethod,
  TooltipPosition,
  TooltipVariant
} from '../../../../components/Tooltip/Tooltip.types';

export const CurrentEarnings: FC<CurrentEarningsProps> = ({
  exchange,
  lastHourAverage
}) => {
  const { data, isSuccess } = useGetTotalEarningsData();
  const { isTablet } = useBreakpoints();

  const getEarningsInCurrency = () => {
    if (data?.currentEarnings) {
      return exchange * data?.currentEarnings.hnt;
    }

    return 0;
  };

  return (
    <Wrapper>
      <Subtitle size="big" variant="grey">
        <FormattedMessage
          id="dashboard.emritEarningsSubtitle1"
          defaultMessage="My current Emrit earnings"
        />
      </Subtitle>
      <StyledBox mt="s">
        {isSuccess ? (
          <Heading size="h4" element="h2">
            {formatHelium(data?.currentEarnings?.hnt || 0)}
            <StyledEarningsTooltip
              method={TooltipActiveMethod.Click}
              position={
                isTablet ? TooltipPosition.Bottom : TooltipPosition.Right
              }
              variant={TooltipVariant.blueBorder}
              content={
                <TooltipWrapper>
                  <Text level="big">
                    <FormattedMessage
                      defaultMessage="This balance reflects your Emrit earnings for the current month. Earnings are transferred to your assigned Helium wallet by the 10th of&nbsp;the following month. If you don't receive your earnings by the 10th please contact customer service for assistance."
                      id="dashboard.payoutTooltip"
                    />
                  </Text>
                </TooltipWrapper>
              }
            >
              <TooltipIconWrapper>
                <Icon icon="Info" color={theme.color.dimmed} />
              </TooltipIconWrapper>
            </StyledEarningsTooltip>
          </Heading>
        ) : (
          <Skeleton width={180} height={46} />
        )}
      </StyledBox>
      <StyledBox mt="xxs">
        {isSuccess ? (
          <>
            <StyledPrice level="big">
              <FormattedNumber
                value={getEarningsInCurrency()}
                style="currency"
                currency="USD"
              />
            </StyledPrice>
            <ExchangeValue
              exchange={exchange}
              lastHourAverage={lastHourAverage}
            />
          </>
        ) : (
          <Skeleton width={210} height={34} />
        )}
      </StyledBox>
    </Wrapper>
  );
};
