import { FC } from 'react';

import { Logo } from '../../Logo/Logo';
import {
  ButtonsRow,
  ChildrenWrapper,
  EmptyStateContainer,
  LogoWrapper,
  SingleButtonsRow
} from './EmptyStateLayout.styles';

export const EmptyStateLayout: FC<{ isOverlay?: boolean }> = ({
  isOverlay,
  children
}) => (
  <EmptyStateContainer isOverlay={isOverlay}>
    <LogoWrapper>
      <Logo />
    </LogoWrapper>
    <ChildrenWrapper>{children}</ChildrenWrapper>
  </EmptyStateContainer>
);

export const EmptyLayoutButtonsRow: FC = ({ children }) => {
  return <ButtonsRow>{children}</ButtonsRow>;
};

export const EmptyLayoutSingleButtonRow: FC = ({ children }) => {
  return <SingleButtonsRow>{children}</SingleButtonsRow>;
};
