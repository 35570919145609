import { FC } from 'react';

import { Box } from '../Box';
import { Text } from '../Typography';
import {
  LoaderContainer,
  ProcessingOrderContainer
} from './ProcessingOrder.styles';
import { Loader } from '../Loader';

interface ProcessingOrderProps {
  label?: string;
  width?: string;
}

export const ProcessingOrder: FC<ProcessingOrderProps> = ({ label, width }) => {
  return (
    <ProcessingOrderContainer width={width}>
      <Box mt="xl">
        <Text level="big">{label}</Text>
      </Box>
      <Box mt="xl">
        <LoaderContainer>
          <Loader variant="blue" size="big" />
        </LoaderContainer>
      </Box>
    </ProcessingOrderContainer>
  );
};
