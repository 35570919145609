import queryString from 'query-string';

import Auth from '../auth/auth.service';
import { IDENTITY_SERVICE, ORDER_SERVICE, WALLET_SERVICE } from './endpoints';
import {
  FetchInstance,
  HTTPError,
  HttpOptions,
  HttpResponse,
  HttpResponseType
} from './fetcher.types';

function normalizeURL(url: string, baseUrl: string) {
  return (
    (/\/$/.test(baseUrl) ? baseUrl : `${baseUrl}/`) +
    (/^\//.test(url) ? url.replace(/^\//, '') : url)
  );
}

function fetcher<T = any>(url: string, opts: HttpOptions = {}) {
  const getResponse = async () => {
    const {
      method = 'GET',
      json,
      headers,
      baseUrl,
      authorized = true,
      query,
      queryArrayFormat = 'none',
      jwt,
      ...rest
    } = opts;

    const token = jwt ?? Auth.getToken();
    const isEmritServiceRequest = [
      IDENTITY_SERVICE,
      WALLET_SERVICE,
      ORDER_SERVICE
    ].some((serviceUrl) => url.indexOf(serviceUrl) === 0);

    const options: RequestInit = {
      headers: {
        ...opts.headers,
        ...(isEmritServiceRequest ? { 'Emrit-Client-Origin': 'web' } : null),
        ...(authorized && token && { Authorization: `Bearer ${token}` })
      },
      method,
      ...(authorized && { credentials: 'same-origin' }),
      ...rest
    };

    if (json) {
      options.headers = {
        'Content-Type': 'application/json',
        ...options.headers
      };

      options.body = JSON.stringify(json);
    }

    let normalizedUrl =
      baseUrl !== undefined ? normalizeURL(url, baseUrl) : url;

    if (query) {
      normalizedUrl = normalizedUrl.concat(
        `?${queryString.stringify(query, { arrayFormat: queryArrayFormat })}`
      );
    }

    const response = await fetch(normalizedUrl, options);
    const { status, statusText } = response;

    if (!response.ok) {
      const jsonResponse = await response.json();

      throw new HTTPError({
        message: `${status} ${jsonResponse?.message}`,
        errorCode: status,
        details: statusText,
        data: jsonResponse
      });
    }

    return {
      response,
      status,
      url: normalizedUrl
    };
  };

  const getResponseTypeHandler = (key: HttpResponseType) => async () => {
    const { url: responseUrl, status, response } = await getResponse();

    let data: T;

    if (status !== 204 && response.headers.get('Content-Length') !== '0') {
      data = await response[key]();
    } else {
      data = null as unknown as T;
    }

    const res: HttpResponse<T> = {
      data,
      response,
      status,
      url: responseUrl
    };

    return res;
  };

  const instance: FetchInstance<T> = {
    arrayBuffer: getResponseTypeHandler('arrayBuffer'),
    blob: getResponseTypeHandler('blob'),
    flush: () => getResponse(),
    formData: getResponseTypeHandler('formData'),
    json: getResponseTypeHandler('json'),
    text: getResponseTypeHandler('text')
  };

  return instance;
}

export { fetcher };
