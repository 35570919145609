import styled, { css } from 'styled-components';

import { getTypography } from '../../shared/utils/style-utils';

export const AddressSearchContainer = styled.div`
  position: relative;
`;

export const StyledDropdown = styled.div`
  z-index: 2;
  position: absolute;
  top: 95px;
  left: -1px;
  right: -1px;
  box-shadow: ${({ theme }) => theme.shadow.blue.s};
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.color.white};
`;

export const StyledOption = styled.div<{
  isSelected?: boolean;
  isFocused?: boolean;
}>`
  display: flex;
  padding-top: ${({ theme }) => theme.padding.xs};
  padding-right: ${({ theme }) => theme.padding.s};
  padding-bottom: ${({ theme }) => theme.padding.xs};
  padding-left: ${({ theme }) => theme.padding.s};

  ${({ theme }) => getTypography(theme.typography.button.big)};
  color: ${({ theme }) => theme.color.grey};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.color.blue};
    background-color: ${({ theme }) => theme.color.secondaryWhiteBlue};
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      color: ${({ theme }) => theme.color.dimmed};
      background-color: #FFFFFFF;
      pointer-events: none;
    `}

  ${({ isFocused }) =>
    isFocused &&
    css`
      color: ${({ theme }) => theme.color.blue};
      background-color: ${({ theme }) => theme.color.secondaryWhiteBlue};
    `}
`;
