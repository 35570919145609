import { FC } from 'react';

import { StyledSubtitle } from './Subtitle.styles';
import { SubtitleProps } from './Subtitle.types';

export const Subtitle: FC<SubtitleProps> = ({
  children,
  variant,
  size = 'big',
  align,
  whiteSpace,
  ...rest
}) => {
  return (
    <StyledSubtitle
      role="doc-subtitle"
      size={size}
      variant={variant}
      align={align}
      whiteSpace={whiteSpace}
      {...rest}
    >
      {children}
    </StyledSubtitle>
  );
};
