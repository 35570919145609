import { FC, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  getCountryCallingCode,
  parsePhoneNumber
} from 'react-phone-number-input';
import PhoneNumberInput from 'react-phone-number-input/react-hook-form-input';
import { getCountries } from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en.json';
import Flags from 'country-flag-icons/react/1x1';

import { getVariant } from '../../../shared/utils/style-utils';
import { FieldCaption, FieldVariant } from '../../FieldCaption';
import {
  DropdownItem,
  DropdownSelectedItem,
  FlagWrapper,
  PhoneInputWrapper,
  StyledDropdown,
  StyledInput
} from './PhoneInput.styles';
import { CountryObject, PhoneInputProps } from './PhoneInput.types';

const countries: CountryObject[] = getCountries()
  .map((country) => ({
    label: en[country],
    value: country
  }))
  .sort((a, b) => a.label.localeCompare(b.label));

const DEFAULT_COUNTRY = countries.find(({ value }) => value === 'US');

export const PhoneInput: FC<PhoneInputProps> = ({
  control,
  error,
  disabled = false,
  onChange = () => {},
  name
}) => {
  const { formatMessage } = useIntl();
  const variant = getVariant<
    Exclude<FieldVariant, FieldVariant.VALID | FieldVariant.PREFILLED>
  >({
    [FieldVariant.DISABLED]: disabled,
    [FieldVariant.ERROR]: !!error
  });

  const defaultCountryOption = parsePhoneNumber(
    control.defaultValuesRef?.current?.[name]
  );

  const defaultSelectedCountry =
    countries.find(
      (country) => country.value === defaultCountryOption?.country
    ) || DEFAULT_COUNTRY;

  const [selectedCountry, setSelectedCountry] = useState(
    defaultSelectedCountry
  );

  const handleFilterOption = useCallback((candidate: any, input: string) => {
    const countryCode = `+${getCountryCallingCode(candidate.value)}`;

    const label = candidate.label.toLowerCase();

    return label.includes(input.toLowerCase()) || countryCode.includes(input);
  }, []);

  const formatOptionLabel: FC<CountryObject> = (
    { label, value },
    { context }
  ) => {
    const Flag = Flags[value];

    if (context === 'menu') {
      return (
        <DropdownItem>
          <p>
            <FlagWrapper>
              <Flag title={label} />
            </FlagWrapper>
            <span>{label}</span>
          </p>
          <p>+{getCountryCallingCode(value)}</p>
        </DropdownItem>
      );
    }

    return (
      <DropdownSelectedItem>
        <FlagWrapper>
          <Flag title={label} />
        </FlagWrapper>
        <span>+{getCountryCallingCode(value)}</span>
      </DropdownSelectedItem>
    );
  };

  const CountrySelect: FC = () => (
    <StyledDropdown
      label={formatMessage({
        defaultMessage: 'Prefix',
        id: 'prefix'
      })}
      hasError={!!error}
      filterOption={handleFilterOption}
      formatOptionLabel={formatOptionLabel}
      onChange={(country: CountryObject) => {
        setSelectedCountry(country);
      }}
      options={countries}
      value={selectedCountry}
    />
  );

  return (
    <>
      <PhoneInputWrapper hasError={!!error}>
        <CountrySelect />
        <PhoneNumberInput
          control={control}
          country={selectedCountry && selectedCountry.value}
          international={false}
          label={formatMessage({
            defaultMessage: 'Phone number',
            id: 'phoneInput'
          })}
          name={name}
          onChange={onChange}
          inputComponent={StyledInput}
          useNationalFormatForDefaultCountryValue
        />
      </PhoneInputWrapper>
      {error && <FieldCaption variant={variant}>{error}</FieldCaption>}
    </>
  );
};
