import { forwardRef, Fragment, useState } from 'react';

import {
  DeviceItemHeader,
  DeviceName,
  DevicesToUpgradeWrapper,
  InstallationAddress,
  Price,
  RadioInput,
  RadioLabel
} from './DevicesToUpgrade.styles';
import { DevicesToUpgradeProps } from './DevicesToUpgrade.types';

export const DevicesToUpgrade = forwardRef<
  HTMLInputElement,
  DevicesToUpgradeProps
>(({ defaultValue, horizontal, name, onBlur, onChange, options }, ref) => {
  const [checkedValue, setCheckedValue] = useState(defaultValue);

  return (
    <DevicesToUpgradeWrapper horizontal={horizontal}>
      {options.map(({ label, installationAddress, value }) => (
        <Fragment key={value}>
          <RadioInput
            ref={ref}
            id={value}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            type="radio"
            value={value}
            checked={checkedValue === value}
            onClick={() => setCheckedValue(value)}
          />
          <RadioLabel htmlFor={value}>
            <DeviceItemHeader>
              <DeviceName>{label}</DeviceName>
              <Price>${process.env.REACT_APP_UPGRADE_PLAN_PRICE}</Price>
            </DeviceItemHeader>
            <InstallationAddress>{installationAddress}</InstallationAddress>
          </RadioLabel>
        </Fragment>
      ))}
    </DevicesToUpgradeWrapper>
  );
});
