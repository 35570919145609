import { FC, useState } from 'react';

import { Modal } from '../../../../components/Modal/Modal';
import { AntennaModalProps } from './AntennaModal.types';
import { AntennaDbiForm } from './AntennaModalForm/AntennaDbiForm';

export const AntennaModal: FC<AntennaModalProps> = ({
  deviceId,
  isOpen,
  setIsOpen
}) => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  return (
    <Modal
      isOpen={isOpen}
      hasCloseButton={!isSubmitted}
      setIsOpen={setIsOpen}
      onAfterClose={() => setIsSubmitted(false)}
    >
      <AntennaDbiForm
        deviceId={deviceId}
        isSubmitted={isSubmitted}
        setIsOpen={setIsOpen}
        setIsSubmitted={setIsSubmitted}
      />
    </Modal>
  );
};
