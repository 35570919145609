import { FC } from 'react';

import { Logo } from '../../Logo/Logo';
import {
  LayoutWrapper,
  LeftPanel,
  LogoWrapper,
  RightPanel
} from './TwoColumnsLayout.styles';
import { ReactComponent as LoginImage } from '../../../assets/images/LoginImage.svg';
import { ReactComponent as RegisterImage } from '../../../assets/images/RegisterImage.svg';
import { useBreakpoints } from '../../../shared/utils/use-breakpoints';

const TwoColumnsLayout: FC<{ image: JSX.Element }> = ({ image, children }) => {
  const { isDesktop } = useBreakpoints();

  return (
    <LayoutWrapper>
      {!isDesktop && (
        <LeftPanel>
          {image}
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
        </LeftPanel>
      )}
      <RightPanel id="scroll-container">{children}</RightPanel>
    </LayoutWrapper>
  );
};

export const LoginLayout: FC = ({ children }) => (
  <TwoColumnsLayout image={<LoginImage />}>{children}</TwoColumnsLayout>
);

export const SignUpLayout: FC = ({ children }) => (
  <TwoColumnsLayout image={<RegisterImage />}>{children}</TwoColumnsLayout>
);

export const scrollToTop: VoidFunction = () =>
  document
    .getElementById('scroll-container')
    ?.scrollTo({ top: 0, behavior: 'smooth' });
