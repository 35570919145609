import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { ConsentCheckboxes } from '../ConsentCheckboxes/ConsentCheckboxes';
import { Box } from '../Box';
import { Button } from '../Button';
import { Input, PasswordInput } from '../Input';
import { PhoneInput } from '../Input/PhoneInput';
import {
  RegistrationFormProps,
  RegistrationFormValues
} from './RegistrationForm.types';
import { Dropdown } from '../Dropdown';
import { FieldCaption, FieldVariant } from '../FieldCaption';
import { useAuth } from '../../shared/auth/auth-context';
import { useCreateAccount } from '../../shared/api/Identity';
import { useCreateLegacyAccount } from '../../shared/api/Identity/Identity.queries';
import { useRegistrationValidation } from './RegistrationForm.validation';
import { useQueryParams } from '../../shared/utils/use-query-params';

const formDefaultValues: RegistrationFormValues = {
  age: false,
  agreePolicies: false,
  country: undefined,
  email: '',
  firstName: '',
  lastName: '',
  password: '',
  recommendedByUserId: null,
  tel: ''
};

export const RegistrationForm: FC<RegistrationFormProps> = ({
  onSubmit,
  defaultValue,
  isLegacyUser,
  countryOptions,
  verificationCode,
  submitButtonLabel
}) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const [apiError, setApiError] = useState<string>();
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [params] = useQueryParams({
    ref: ''
  });
  const {
    register,
    control,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm<RegistrationFormValues>({
    defaultValues: { ...formDefaultValues, ...defaultValue },
    mode: 'onChange',
    resolver: yupResolver(useRegistrationValidation(setPasswordStrength))
  });

  const { login } = useAuth();

  const handleSuccessResponse = async () => {
    const values = getValues();

    if (isLegacyUser) {
      await login.mutateAsync({
        email: values.email,
        password: values.password
      });

      history.push('/dashboard');
    } else {
      onSubmit(values);
    }
  };

  const handleErrorResponse = (error: any) => {
    setApiError(error.message);
  };

  const { mutate: createAccount, isLoading } = useCreateAccount({
    onError: handleErrorResponse,
    onSuccess: handleSuccessResponse
  });

  const {
    mutate: createLegacyAccount,
    isLoading: isCreateLegacyAccountLoading
  } = useCreateLegacyAccount({
    onError: handleErrorResponse,
    onSuccess: handleSuccessResponse
  });

  const registrationFormSubmit = (values: RegistrationFormValues) => {
    values.recommendedByUserId = params.ref || null;

    if (isLegacyUser) {
      createLegacyAccount({ ...values, code: verificationCode });
    } else {
      createAccount(values);
    }
  };

  return (
    <form onSubmit={handleSubmit(registrationFormSubmit)}>
      <Box>
        <Input
          {...register('firstName')}
          iconLeft="Person"
          label={formatMessage({
            defaultMessage: 'First Name',
            id: 'createAccount.nameField'
          })}
          placeholder={formatMessage({
            defaultMessage: 'First Name',
            id: 'createAccount.nameFieldPlaceholder'
          })}
          error={errors.firstName?.message}
          prefilled={!!defaultValue?.firstName}
        />
      </Box>
      <Box mt="m">
        <Input
          {...register('lastName')}
          iconLeft="Person"
          label={formatMessage({
            defaultMessage: 'Last Name',
            id: 'createAccount.lastNameField'
          })}
          placeholder={formatMessage({
            defaultMessage: 'Last Name',
            id: 'createAccount.lastNameFieldPlaceholder'
          })}
          error={errors.lastName?.message}
          prefilled={!!defaultValue?.lastName}
        />
      </Box>
      <Box mt="m">
        <Input
          {...register('email')}
          iconLeft="Mail"
          label={formatMessage({
            defaultMessage: 'Email address',
            id: 'createAccount.emailField'
          })}
          placeholder={formatMessage({
            defaultMessage: 'email@sample.com',
            id: 'createAccount.emailFieldPlaceholder'
          })}
          error={errors.email?.message}
          caption={formatMessage({
            defaultMessage: 'This email will be also your login',
            id: 'createAccount.emailFieldCaption'
          })}
          readOnly={isLegacyUser}
        />
      </Box>
      <Box mt="m">
        <Controller
          name="password"
          control={control}
          render={({
            field: { onChange, onBlur, ref },
            fieldState: { error }
          }) => (
            <PasswordInput
              onChange={onChange}
              onBlur={onBlur}
              ref={ref}
              progress={passwordStrength}
              label={
                isLegacyUser
                  ? formatMessage({
                      defaultMessage: 'New Password',
                      id: 'createAccount.newPasswordField'
                    })
                  : formatMessage({
                      defaultMessage: 'Password',
                      id: 'createAccount.passwordField'
                    })
              }
              placeholder={formatMessage({
                defaultMessage: 'Your password',
                id: 'createAccount.passwordField'
              })}
              iconLeft="Lock"
              error={error?.message}
              caption={formatMessage({
                defaultMessage:
                  'Password must contain minimum 8 characters, one number, one special character and at least one capitalise',
                id: 'createAccount.passwordFieldHint'
              })}
            />
          )}
        />
      </Box>
      <Box mt="m">
        <Controller
          name="tel"
          control={control}
          render={({ fieldState: { error } }) => (
            <PhoneInput control={control} error={error?.message} name="tel" />
          )}
        />
      </Box>
      {!defaultValue?.country && countryOptions && (
        <Box mt="m" mb="xl">
          <Controller
            name="country"
            control={control}
            render={({
              field: { onChange, onBlur, value, ref },
              fieldState: { error }
            }) => (
              <Dropdown
                options={countryOptions}
                label={formatMessage({
                  defaultMessage: 'Country of residence',
                  id: 'createAccount.countryField'
                })}
                placeholder={formatMessage({
                  defaultMessage: 'Select country',
                  id: 'createAccount.countryFieldPlaceholder'
                })}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                inputRef={ref}
                error={error?.message}
                defaultValue={value}
              />
            )}
          />
        </Box>
      )}
      <ConsentCheckboxes register={register} errors={errors} />
      <Box mt="m">
        {apiError && (
          <FieldCaption variant={FieldVariant.ERROR}>{apiError}</FieldCaption>
        )}
      </Box>
      <Box mt="l">
        <Button
          isPrimary
          fullWidth
          type="submit"
          isLoading={isLoading || isCreateLegacyAccountLoading}
        >
          {submitButtonLabel || (
            <FormattedMessage
              id="createAccount.submitButton"
              defaultMessage="Sign up"
            />
          )}
        </Button>
      </Box>
    </form>
  );
};
