import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { FC, useEffect, useMemo } from 'react';

import { AlertVariant } from '../../components/Alert/Alert.types';
import { Box } from '../../components/Box';
import { ContentWrapper, SignUpLayout } from '../../components/Layout';
import { Heading, Text } from '../../components/Typography';
import { ReactComponent as SuccessTick } from '../../assets/images/SuccessTick.svg';
import { ImageBox } from './RemoveFromWaitList.styles';
import { Button, LinkButton } from '../../components/Button';
import { useAuth } from '../../shared/auth/auth-context';
import { useNotification } from '../../shared/utils/notifications/notification-context';
import { useQueryParams } from '../../shared/utils/use-query-params';
import {
  useGetLeadOrder,
  useRemoveLeadOrderFromWaitList
} from '../../shared/api/Order/Order.queries';
import { FullPageLoader } from '../../components/FullPageLoader';
import { ErrorPage } from '../ErrorPage';
import { ErrorPageType } from '../ErrorPage/ErrorPage.types';
import {
  userTracking,
  UserTrackingEvent
} from '../../shared/utils/user-tracking';

export const RemoveFromWaitList: FC = () => {
  const history = useHistory();
  const { isLoggedIn } = useAuth();
  const { addNotification } = useNotification();

  const hasPreviousRoute = useMemo(() => history.length >= 2, [history.length]);
  const nextPath = isLoggedIn() ? '/dashboard' : '/login';

  const goBack = () => {
    if (hasPreviousRoute) {
      history.goBack();
    } else {
      history.push(nextPath);
    }
  };

  const [{ email, leadOrderId }] = useQueryParams({
    email: '',
    leadOrderId: ''
  });

  const decodedEmail = decodeURIComponent(email);

  const {
    refetch: getLeadOrder,
    isLoading: isGetLeadOrderLoading,
    isError: isGetLeadOrderError
  } = useGetLeadOrder(leadOrderId, decodedEmail);

  const {
    mutate: removeLeadOrderFromWaitList,
    isLoading: isRemoveLeadOrderLoading,
    isSuccess: isRemovedLeadOrder
  } = useRemoveLeadOrderFromWaitList({
    onError: (error) => {
      addNotification({
        id: 'removeLeadOrderError',
        text: error.message,
        variant: AlertVariant.Danger
      });
    }
  });

  useEffect(() => {
    getLeadOrder();
  }, [getLeadOrder]);

  useEffect(() => {
    userTracking(UserTrackingEvent.RegisteredUser);
  }, []);

  if (isGetLeadOrderLoading) {
    return <FullPageLoader />;
  }

  if (isGetLeadOrderError) {
    return <ErrorPage type={ErrorPageType.LinkInvalid} />;
  }

  return (
    <SignUpLayout>
      {!isRemovedLeadOrder && (
        <ContentWrapper>
          <Heading size="h4" element="h1" variant="grey">
            <FormattedMessage
              id="removeFromWaitList.header"
              defaultMessage="Remove me from the wait list."
            />
          </Heading>
          <Box mt="l">
            <Text level="small">
              <FormattedMessage
                id="removeFromWaitList.copy"
                defaultMessage="Are you sure you don't want to be on the CoolSpot™ wait list?"
              />
            </Text>
          </Box>
          <Box mt="xl">
            <Button
              isLoading={isRemoveLeadOrderLoading}
              isPrimary
              fullWidth
              onClick={() =>
                removeLeadOrderFromWaitList({ leadOrderId, email })
              }
            >
              <FormattedMessage
                id="removeFromWaitList.remove"
                defaultMessage="Remove"
              />
            </Button>
          </Box>
          <Box mt="m">
            <Button fullWidth isPlain onClick={goBack}>
              {hasPreviousRoute && (
                <FormattedMessage
                  id="removeFromWaitList.back"
                  defaultMessage="Back"
                />
              )}

              {!hasPreviousRoute && (
                <FormattedMessage
                  id="removeFromWaitList.cancel"
                  defaultMessage="Cancel"
                />
              )}
            </Button>
          </Box>
        </ContentWrapper>
      )}
      {isRemovedLeadOrder && (
        <ContentWrapper>
          <Heading size="h4" element="h1" variant="grey">
            <FormattedMessage
              id="removeFromWaitList.successHeader"
              defaultMessage="You’ve been removed from the wait list"
            />
          </Heading>
          <ImageBox mt="l">
            <SuccessTick />
          </ImageBox>
          <Box mt="xl">
            <LinkButton to={nextPath} fullWidth variant="blue">
              <FormattedMessage
                id="removeFromWaitList.done"
                defaultMessage="Done"
              />
            </LinkButton>
          </Box>
        </ContentWrapper>
      )}
    </SignUpLayout>
  );
};
