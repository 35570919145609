import { FC, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { addWalletSchema } from './WalletForm.validation';
import { Box } from '../Box';
import { Input } from '../Input';
import { Button } from '../Button';
import { WalletFormProps } from './WalletFormTypes';

export const WalletForm: FC<WalletFormProps> = ({
  defaultValues,
  onSubmit,
  isLoading
}) => {
  const { formatMessage } = useIntl();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues
  } = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(addWalletSchema)
  });

  useEffect(() => {
    if (!getValues('walletAddress')) {
      setValue('walletAddress', defaultValues.walletAddress);
    }
  }, [defaultValues.walletAddress, getValues, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mt="xl">
        <Input
          {...register('walletAddress')}
          iconLeft="Wallet"
          label={formatMessage({
            defaultMessage: 'Helium Wallet address',
            id: 'walletForm.label'
          })}
          placeholder={formatMessage({
            defaultMessage: 'Wallet address',
            id: 'walletForm.placeholder'
          })}
          error={errors.walletAddress?.message}
          prefilled={!!defaultValues.walletAddress}
        />
      </Box>
      <Box mt="l">
        <Button isPrimary fullWidth type="submit" isLoading={isLoading}>
          <FormattedMessage id="editWallet.submit" defaultMessage="Update" />
        </Button>
      </Box>
    </form>
  );
};
