import { FC } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { Box } from '../../../components/Box';
import { Icon } from '../../../components/Icon';
import { Text } from '../../../components/Typography';
import { useGetHeliumPrice } from '../../../shared/api/Wallet/Wallet.queries';
import {
  DashboardCaption,
  InformationPane,
  StyledBox
} from './BottomInfo.styles';

export const BottomInfo: FC = () => {
  const { data: currencyData } = useGetHeliumPrice();

  const priceTimestamp = currencyData?.current.timestamp;

  return (
    <>
      <StyledBox mt="m">
        <InformationPane>
          <Icon icon="Info" color="#999" />
        </InformationPane>
        <InformationPane>
          <Text level="small">
            <FormattedMessage
              id="dashboard.bottomInfo"
              defaultMessage="Due to limitations in updating the data will not always be returned in real time, but this does not affect their reliability"
            />
          </Text>
        </InformationPane>
      </StyledBox>
      {priceTimestamp && (
        <Box mt="m">
          <DashboardCaption>
            <FormattedMessage
              id="priceTimeStamp"
              defaultMessage="Note: All dollar references are based on Helium oracle price with
            timestamp: {priceTimestamp}"
              values={{
                priceTimestamp: (
                  <FormattedDate
                    value={priceTimestamp}
                    day="2-digit"
                    month="2-digit"
                    year="numeric"
                    hour="numeric"
                    minute="numeric"
                  />
                )
              }}
            />
          </DashboardCaption>
        </Box>
      )}
    </>
  );
};
