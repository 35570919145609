import styled from 'styled-components';

export const StyledPicture = styled.picture`
  display: block;
  line-height: 0;

  a {
    display: block;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`;
