import { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useKeenSlider } from 'keen-slider/react';
import { useTheme } from 'styled-components';

import { Box } from '../Box';
import {
  EarningPlanFormProps,
  EarningPlanFormValues
} from './EarningPlanForm.types';
import { RadioGroupWrapper } from './ProductVariant/ProductVariant.styles';
import { ProductVariant } from './ProductVariant';
import { addEarningPlanSchema } from './EarningPlanForm.validation';
import { ButtonsBox } from './EarningPlanForm.styles';
import { Button } from '../Button';
import { CarouselArrow } from '../CarouselArrow/CarouselArrow';
import { Text } from '../Typography';
import { CouponCodeInput } from '../CouponCodeInput';

export const EarningPlanForm: FC<EarningPlanFormProps> = ({
  variants,
  onFormSubmit,
  renderBackButton,
  defaultValues
}) => {
  const {
    formState: { isValid },
    handleSubmit,
    register,
    getValues,
    trigger,
    control
  } = useForm<EarningPlanFormValues>({
    defaultValues,
    resolver: yupResolver(addEarningPlanSchema),
    mode: 'onChange'
  });
  const { breakpoints } = useTheme();
  const [sliderLoaded, setSliderLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [activeSlidesOptions, setActiveSlidesOptions] = useState<{
    slidesPerView: number;
    origin?: string;
  }>({
    slidesPerView: 1
  });
  const [sliderRef, instanceRef] = useKeenSlider({
    slides: {
      origin: 'auto',
      perView: 3,
      spacing: 0
    },
    rubberband: false,
    breakpoints: {
      [`screen and (${breakpoints.desktop})`]: {
        slides: {
          origin: 'center',
          perView: 2,
          spacing: 0
        }
      },
      [`screen and (${breakpoints.tablet})`]: {
        slides: {
          origin: 'center',
          perView: 1.5,
          spacing: 0
        }
      },
      [`screen and (${breakpoints.phone})`]: {
        slides: {
          origin: 'center',
          perView: 1.25,
          spacing: 0
        }
      }
    },
    created(slider) {
      setActiveSlidesOptions({
        slidesPerView: (slider.options?.slides as { perView: number })?.perView,
        origin: (slider.options?.slides as { origin: string })?.origin
      });
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    optionsChanged(slider) {
      setActiveSlidesOptions({
        slidesPerView: (slider.options?.slides as { perView: number })?.perView,
        origin: (slider.options?.slides as { origin: string })?.origin
      });
    }
  });

  useEffect(() => {
    setTimeout(() => {
      instanceRef?.current?.update();
      setSliderLoaded(true);
    });
  });

  const showNavArrows =
    sliderLoaded && activeSlidesOptions.slidesPerView < variants.length;

  const selectedVariant = getValues('productVariantId');

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <Box
        mt="l"
        pl={showNavArrows ? undefined : 'l'}
        pr={showNavArrows ? undefined : 'l'}
        relative
      >
        <RadioGroupWrapper ref={sliderRef} className="keen-slider">
          {variants.map(
            ({ description, earningsFactor, price, variantId }, index) => (
              <ProductVariant
                {...register('productVariantId')}
                className="keen-slider__slide"
                key={variantId}
                variantId={variantId}
                name="productVariantId"
                description={description}
                earningsFactor={earningsFactor}
                price={price}
                isActive={
                  activeSlidesOptions.slidesPerView < 3 &&
                  currentSlide === index
                }
                isVisible={sliderLoaded}
                onClick={() => {
                  instanceRef.current?.moveToIdx(index);
                  trigger('productVariantId');
                }}
              />
            )
          )}
        </RadioGroupWrapper>
        {showNavArrows && (
          <>
            <CarouselArrow
              left
              withBackground
              disabled={currentSlide === 0}
              onClick={(e: any) =>
                e.preventDefault() || instanceRef.current?.prev()
              }
            />
            <CarouselArrow
              right
              withBackground
              disabled={
                activeSlidesOptions.origin === 'center'
                  ? currentSlide === variants.length - 1
                  : currentSlide ===
                    variants.length -
                      Math.floor(activeSlidesOptions.slidesPerView)
              }
              onClick={(e: any) =>
                e.preventDefault() || instanceRef.current?.next()
              }
            />
          </>
        )}
      </Box>
      <ButtonsBox pl="l" pr="l">
        <Box mt="xl">
          <Text level="big">
            <FormattedMessage
              id="chooseEarningPlan.couponCodeTitile"
              defaultMessage="Coupon code"
            />
          </Text>
          <Controller
            name="coupon"
            control={control}
            render={({ field: { name, value, ref, onBlur, onChange } }) => (
              <CouponCodeInput
                name={name}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                inputRef={ref}
                label="Have a coupon? enter it below and get your discount!"
                placeholder="Enter your code"
                productVariantIds={selectedVariant ? [selectedVariant] : null}
              />
            )}
          />
        </Box>
      </ButtonsBox>
      {sliderLoaded && (
        <ButtonsBox pl="l" pr="l">
          <Box mt="xl">
            <Button
              disabled={!variants.length || !isValid}
              isPrimary
              fullWidth
              type="submit"
            >
              <FormattedMessage
                id="chooseEarningPlan.continue"
                defaultMessage="Order my coolspot™"
              />
            </Button>
          </Box>
          {renderBackButton && <Box mt="s">{renderBackButton(getValues)}</Box>}
        </ButtonsBox>
      )}
    </form>
  );
};
