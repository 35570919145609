import styled from 'styled-components';

import { LinkButton } from '../../components/Button';
import { Heading } from '../../components/Typography';

export const StyledHeading = styled(Heading)`
  font-weight: 400;
  margin-bottom: ${({ theme }) => theme.margin.l};
`;

export const StyledLink = styled.a`
  color: inherit;

  &:hover {
    color: ${({ theme }) => theme.color.lightGrey};
  }
`;

export const ContentContainer = styled.div`
  max-width: 450px;
`;

export const StyledLinkButton = styled(LinkButton)`
  max-width: 280px;
`;
