import { css, TypographyParameters } from 'styled-components';

export const getTypography = (params: TypographyParameters) => {
  return css`
    font-size: ${params.fontSize};
    line-height: ${params.lineHeight};
    letter-spacing: ${params.letterSpacing};
  `;
};

export const getVariant = <T extends string>(obj: Record<T, boolean>) => {
  return Object.keys(obj).find((key) => obj[key as T] === true) as T;
};
