import styled, { css } from 'styled-components';

import { Box } from '../Box';

export const LatLngPickerContainer = styled(Box)`
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.color.secondaryWhiteBlue};
`;

export const MapWrapper = styled.div<{ isError?: boolean }>`
  width: 100%;
  height: 460px;
  border-radius: ${({ theme }) => theme.borderRadius};
  overflow: hidden;

  ${({ isError, theme }) =>
    isError &&
    css`
      border: ${theme.border(theme.color.secondaryRed)};
    `}
`;

export const GoToPinButton = styled.button`
  position: absolute;
  bottom: 45px;
  right: 5px;

  width: 40px;
  height: 40px;

  border: 0;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: ${({ theme }) => theme.shadow.white.s};

  &:hover {
    box-shadow: ${({ theme }) => theme.shadow.white.l};
  }

  & > svg {
    stroke: ${({ theme }) => theme.color.dimmed};
  }
`;
