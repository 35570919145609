import styled from 'styled-components';

import { Box } from '../../../Box';
import { LinkButton } from '../../../Button';

export const ButtonWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

export const SocialButton = styled(LinkButton)`
  width: 30%;
`;
