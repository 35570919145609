import styled from 'styled-components';

export const NotificationContainer = styled.div`
  position: absolute;
  width: 66%;
  margin: 0 auto;

  top: 64px;
  left: 0;
  right: 0;

  z-index: 50;
`;
