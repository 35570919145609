import styled, {
  Color,
  ComponentSize,
  css,
  TextAlign
} from 'styled-components';

import { getTypography } from '../../../shared/utils/style-utils';

export const StyledSubtitle = styled.div<{
  size: ComponentSize;
  variant?: Extract<Color, 'darkBlue' | 'grey' | 'dimmed' | 'white' | 'black'>;
  align?: TextAlign;
  whiteSpace?: 'normal' | 'pre-line' | 'nowrap';
}>`
  ${({ theme, size }) => getTypography(theme.typography.subtitle[size])};

  color: ${({ theme, variant }) => (variant ? theme.color[variant] : '#000')};
  text-align: ${({ align }) => align || 'left'};

  ${({ size }) =>
    size === 'small' &&
    css`
      font-weight: 600;
    `};

  ${({ whiteSpace }) =>
    whiteSpace &&
    css`
      white-space: ${whiteSpace};
    `}
`;
