import { forwardRef, MutableRefObject } from 'react';
import { FormattedMessage } from 'react-intl';

import { useAuth } from '../../shared/auth/auth-context';
import { Accordion } from '../Accordion';
import {
  Divider,
  MobileMenuAccordionHeader,
  MobileMenuLabel,
  MobileMenuSublist,
  StyledIconButton,
  StyledMobileMenu
} from './MobileMenu.styles';
import { Icon } from '../Icon';
import { IconWrapper } from './MobileMenuItem/MobileMenuItem.styles';
import { MobileMenuItem } from './MobileMenuItem/MobileMenuItem';
import { MobileMenuLink } from './MobileMenuLink/MobileMenuLink';
import { supportUrl } from '../../shared/utils/navigation';

export const MobileMenu = forwardRef<
  MutableRefObject<HTMLElement | undefined>,
  {
    isActive: boolean;
    onMenuClose: () => void;
  }
>(({ isActive, onMenuClose }, ref) => {
  const { logout } = useAuth();

  return (
    <StyledMobileMenu isActive={isActive} ref={ref as any}>
      <MobileMenuLink to="/dashboard" icon="Dashboard" onClick={onMenuClose}>
        <FormattedMessage id="menuItem.dashboard" defaultMessage="Dashboard" />
      </MobileMenuLink>
      {/* <MobileMenuLink to="/apply-for-a-coolspot" icon="CoolSpot">
        <FormattedMessage
          id="menuItem.coolSpot"
          defaultMessage="Apply for a CoolSpot™"
        />
      </MobileMenuLink> */}
      <MobileMenuLink to="/edit-wallet" icon="Wallet">
        <FormattedMessage
          id="menuItem.wallet"
          defaultMessage="Update wallet address"
        />
      </MobileMenuLink>
      <Accordion
        renderHeader={({ onClick, isExpanded }) => (
          <MobileMenuAccordionHeader onClick={onClick} isActive={isExpanded}>
            <MobileMenuLabel>
              <IconWrapper>
                <Icon icon="Faq" />
              </IconWrapper>
              <FormattedMessage id="menuItem.help" defaultMessage="Help" />
            </MobileMenuLabel>
            {isExpanded ? (
              <Icon icon="ChevronUp" />
            ) : (
              <Icon icon="ChevronDown" />
            )}
          </MobileMenuAccordionHeader>
        )}
      >
        <MobileMenuSublist>
          <MobileMenuLink external to="https://emrit.io" icon="CircleWhite">
            <FormattedMessage
              id="MobileMenuLink.howItWorks"
              defaultMessage="How it works"
            />
          </MobileMenuLink>
          <MobileMenuLink external icon="CircleWhite" to={supportUrl}>
            <FormattedMessage id="menuItem.support" defaultMessage="Support" />
          </MobileMenuLink>
        </MobileMenuSublist>
      </Accordion>
      <MobileMenuLink icon="Analytics" to="/terms-and-conditions">
        <FormattedMessage
          id="menuItem.termsAndConditions"
          defaultMessage="Terms and Conditions"
        />
      </MobileMenuLink>
      <Divider />
      <MobileMenuItem icon="Logout" onClick={() => logout.refetch()}>
        <FormattedMessage id="menuItem.logout" defaultMessage="Logout" />
      </MobileMenuItem>
      <StyledIconButton icon="Cancel" onClick={onMenuClose} />
    </StyledMobileMenu>
  );
});
