import { useIntl } from 'react-intl';
import * as yup from 'yup';

export const useFeedbackValidation = () => {
  const { formatMessage } = useIntl();

  return yup.object().shape({
    reasonMessage: yup.string().when('reason', {
      is: 'Other',
      then: yup
        .string()
        .required(
          formatMessage({
            defaultMessage: 'Description is a required field',
            id: 'feedback.reasonMessageRequired'
          })
        )
        .min(
          3,
          formatMessage({
            defaultMessage: 'Give us more information please',
            id: 'feedback.reasonMessageMin'
          })
        )
        .max(
          255,
          formatMessage({
            defaultMessage:
              'The description cannot be more than 255 characters',
            id: 'feedback.reasonMessageMax'
          })
        )
    }),
    reason: yup.string().required()
  });
};
