import { FC } from 'react';

import { Icon } from '../../../../../components/Icon';
import { RewardScaleProps } from './RewardScale.types';
import { Icons } from '../../../../../icons';
import { Value } from './RewardScale.styles';

export const RewardScale: FC<RewardScaleProps> = ({ rewardScale = 0 }) => {
  const fixedScale = Number(rewardScale.toFixed(2));

  const getLevelIcon = () => {
    const levels = [
      fixedScale < 0.1,
      fixedScale >= 0.1 && fixedScale <= 0.25,
      fixedScale >= 0.26 && fixedScale <= 0.59,
      fixedScale >= 0.6 && fixedScale <= 0.99,
      fixedScale === 1
    ];

    const currentLevel = levels.findIndex((level) => level);

    return `Multiplier${currentLevel}` as Icons;
  };

  return (
    <>
      <Icon icon={getLevelIcon()} />
      <Value>{rewardScale.toFixed(2)}</Value>
    </>
  );
};
