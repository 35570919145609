import styled from 'styled-components';

import { Box } from '../Box';

export const StyledForm = styled.form`
  margin-top: ${({ theme }) => theme.margin.l};
`;

export const StyledButtonWrapper = styled(Box)`
  margin-top: ${({ theme }) => theme.margin.xl};

  @media (${({ theme }) => theme.breakpoints.phone}) {
    margin-top: ${({ theme }) => theme.margin.l};
  }
`;

export const InstallationAddressBox = styled(Box)`
  overflow: hidden;
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.color.secondaryWhiteBlue};
`;
