import styled from 'styled-components';

export const StyledTooltipMenu = styled.ul`
  display: flex;
  flex-direction: column;

  list-style: none;

  margin: 0;
  padding: 0;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    width: auto;
  }
`;
