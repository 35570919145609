import styled, {
  Color,
  ComponentSize,
  css,
  DefaultTheme
} from 'styled-components';

import { getTypography } from '../../shared/utils/style-utils';
import { BUTTON_SIZE, StyledButtonProps } from './Button.types';

export const getButtonStyles = (
  theme: DefaultTheme,
  isPrimary?: boolean,
  size?: ComponentSize,
  fullWidth?: boolean,
  variant?:
    | Extract<Color, 'blue' | 'white'>
    | 'blueOverBlueBackground'
    | 'blueOverLightBlueBackground',
  disableHoverStyles?: boolean
) => css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  font-family: inherit;
  border-radius: ${theme.borderRadius};
  background-color: transparent;
  transition: all 0.2s ease-in-out;

  cursor: pointer;

  padding: ${size === BUTTON_SIZE.BIG
    ? `${theme.padding.s} ${theme.padding.l}`
    : `${theme.padding.xs} ${theme.padding.m}`};
  border: ${theme.border(theme.color[variant || 'blue'])};
  font-size: ${size && theme.typography.button[size].fontSize};
  letter-spacing: ${size && theme.typography.button[size].letterSpacing};
  text-transform: uppercase;
  font-weight: bold;
  color: ${theme.color[variant || 'blue']};
  text-align: center;

  svg {
    stroke: ${theme.color[variant || 'blue']};
    transition: all 0.2s ease-in-out;
  }

  ${!disableHoverStyles &&
  css`
    &:hover,
    &:focus {
      background-color: ${theme.color.hoverBlue};
      border-color: ${theme.color.hoverBlue};
      color: #fff;
      box-shadow: ${theme.shadow.blue.m};

      svg {
        stroke: #fff;
      }
    }
  `}

  &:active {
    background-color: ${theme.color.darkBlue};
    border-color: ${theme.color.darkBlue};
    color: #fff;

    svg {
      stroke: #fff;
    }
  }

  &[disabled],
  &[disabled]:hover {
    border-color: ${theme.color.lightGrey};
    background-color: transparent;
    color: ${theme.color.dimmed};
    box-shadow: none;

    svg {
      stroke: ${theme.color.dimmed};
    }
  }

  ${isPrimary &&
  css`
    background: ${theme.color.blue};
    color: #fff;

    svg {
      stroke: #fff;
    }

    &[disabled],
    &[disabled]:hover {
      cursor: not-allowed;
      background-color: ${theme.color.lightGrey};
      border-color: ${theme.color.lightGrey};
      color: ${theme.color.dimmed};

      svg {
        stroke: ${theme.color.dimmed};
      }
    }
  `}

  ${variant === 'white' &&
  css`
    background: ${theme.color.white};
    color: ${theme.color.darkBlue};
    border-color: ${theme.color.white};

    svg {
      stroke: ${theme.color.blue};
    }

    &:hover,
    &:focus {
      background: ${theme.color.white};
      color: ${theme.color.blue};
      border-color: ${theme.color.white};
      box-shadow: none;

      svg {
        stroke: ${theme.color.darkBlue};
      }
    }

    &:disabled {
      border-color: ${theme.color.white};

      &:hover {
        border-color: ${theme.color.white};
      }
    }
  `}

  ${(variant === 'blueOverBlueBackground' ||
    variant === 'blueOverLightBlueBackground') &&
  css`
    background: ${theme.color[variant]};
    color: ${theme.color.white};
    svg {
      stroke: ${theme.color.white};
    }

    ${isPrimary &&
    css`
      background: ${theme.color[variant]};
      color: ${theme.color.white};
      border-color: ${theme.color.white};

      svg {
        stroke: ${theme.color.white};
      }

      &:hover {
        background: ${theme.color.white};
        color: ${theme.color[variant]};
        border-color: ${theme.color.white};

        svg {
          stroke: ${theme.color[variant]};
        }
      }
    `}

    ${!isPrimary &&
    css`
      background: ${theme.color[variant]};
      color: ${theme.color.white};

      svg {
        stroke: ${theme.color.white};
      }

      &:hover {
        background: ${theme.color[variant]};
        color: ${theme.color.white};
        border-color: ${theme.color.white};

        svg {
          stroke: ${theme.color.white};
        }
      }
    `}
  `}


  ${fullWidth &&
  css`
    width: 100%;
  `}
`;

export const StyledButton = styled.button<StyledButtonProps>`
  ${({ theme, isPrimary, size, fullWidth, variant, disableHoverStyles }) =>
    getButtonStyles(
      theme,
      isPrimary,
      size,
      fullWidth,
      variant,
      disableHoverStyles
    )}

  ${({ isDestructive }) =>
    isDestructive &&
    css`
      background: ${({ theme }) => theme.color.secondaryRed};
      border-color: ${({ theme }) => theme.color.secondaryRed};
      color: ${({ theme }) => theme.color.white};

      &:hover,
      &:active {
        background: ${({ theme }) => theme.color.secondaryRed};
        border-color: ${({ theme }) => theme.color.secondaryRed};
        color: ${({ theme }) => theme.color.white};
        box-shadow: none;
      }

      svg {
        stroke: ${({ theme }) => theme.color.white};
      }

      &[disabled],
      &[disabled]:hover {
        background-color: ${({ theme }) => theme.color.lightGrey};
        border-color: ${({ theme }) => theme.color.lightGrey};
        color: ${({ theme }) => theme.color.dimmed};

        svg {
          stroke: ${({ theme }) => theme.color.dimmed};
        }
      }
    `}

    ${({ theme, isPlain }) =>
    isPlain &&
    css`
      border: ${theme.border('transparent')};

      &:focus,
      &:active,
      &:hover {
        background-color: transparent;
        box-shadow: none;

        color: ${theme.color.blue};
        border: ${theme.border(theme.color.blue)};
      }
    `}
`;

const InnerElementWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LeftElementWrapper = styled(InnerElementWrapper)<
  Pick<StyledButtonProps, 'size'>
>`
  margin-right: ${({ theme }) => theme.margin.xs};
`;

export const RightElementWrapper = styled(InnerElementWrapper)<
  Pick<StyledButtonProps, 'size'>
>`
  margin-left: ${({ theme }) => theme.margin.xs};
`;

export const InlineLinkButton = styled.button`
  ${({ theme }) => getTypography(theme.typography.body.big)};
  color: ${({ theme }) => theme.color.blue};
  font-family: ${({ theme }) => theme.typography.fontFamily};

  display: inline;
  cursor: pointer;

  border: none;
  background: none;

  padding: 0;

  font-weight: 600;

  &:hover {
    color: ${({ theme }) => theme.color.darkBlue};
  }
`;
