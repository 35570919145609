import styled from 'styled-components';

import { Box } from '../Box';

export const ButtonsBox = styled(Box)`
  display: block;
  width: 100%;
  max-width: 488px;
  margin: 0 auto;
`;
