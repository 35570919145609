import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { ReactComponent as SuccessTick } from '../../../../assets/images/SuccessTick.svg';
import { StepProps } from '../../../../components/Stepper';
import { Heading } from '../../../../components/Typography';
import { Button } from '../../../../components/Button';
import { TickContainer } from './EditWalletSuccess.styles';
import { DashboardBoxCenterWrapper } from '../../../../components/DashboardBox/DashboardBox.styles';

export const EditWalletSuccess: FC<StepProps> = () => {
  const history = useHistory();
  const { fromAddWallet } =
    (history.location.state as {
      fromAddWallet?: boolean;
    }) || {};

  const handleDone = async () => {
    const redirectUrl = fromAddWallet ? '/payout-history' : '/dashboard';

    history.push(redirectUrl);
  };

  return (
    <DashboardBoxCenterWrapper contentWidth={592}>
      <Heading size="h4" element="h1" variant="grey" align="center">
        <FormattedMessage
          id="editWallet.successTitle"
          defaultMessage="Wallet edited successfully!"
        />
      </Heading>
      <TickContainer mt="xl" mb="xl">
        <SuccessTick />
      </TickContainer>
      <Button isPrimary fullWidth type="button" onClick={handleDone}>
        {fromAddWallet ? (
          <FormattedMessage
            id="editWallet.goToPayoutHistory"
            defaultMessage="See payout history"
          />
        ) : (
          <FormattedMessage id="editWallet.done" defaultMessage="Done" />
        )}
      </Button>
    </DashboardBoxCenterWrapper>
  );
};
